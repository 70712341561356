import apiService from 'api/ApiService';

export function getProfileType(limit, offset, search, ordering) {
    return () => apiService.get(`profile-type/?page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}
export function getProfileTypeDetail(id) {
    return () => apiService.get(`profile-type/${id}/`);
}
export function createProfileType(params) {
    return () => apiService.post('profile-type/', params);
}
export function editProfileType(id, params) {
    return () => apiService.patch(`profile-type/${id}/`, params);
}
export function deleteProfileType(id) {
    return () => apiService.delete(`profile-type/${id}/`);
}