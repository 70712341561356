import MasterTable from "components/MasterTable/MasterTable";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import React from "react";
import { useSelector } from "react-redux";

function List(props) {

  const { getList, pageLimit, pageOffset, searchText } = props;
  const contactUsList = useSelector(state => state.contactUsReducer?.contactUsList);

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        customBodyRender: (value) => {
          return (value) ? value : '-'
        },
        sortThirdClickReset: true
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        customBodyRender: (value) => {
          return (value) ? value : '-'
        },
        sortThirdClickReset: true
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (value) ? value : '-'
        }
      }
    },
    {
      name: "message",
      label: "Message",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (value) ?
            <ReadMoreReadLess
              charLimit={100}
              readMoreText={"Read More"}
              readLessText={"Read Less"}
              readMoreClassName="read-moreLink"
              readLessClassName="read-moreLink"
              children={value || ''} //eslint-disable-line
              textType={true}
            /> : '-'
        },
        setCellProps: () => ({
          style: {
            maxWidth: "350px",
            wordBreak: "break-word",
          }
        }),
      }
    }
  ]
  return (
    <MasterTable
      list={contactUsList}
      getList={getList}
      pageLimit={pageLimit}
      pageOffset={pageOffset}
      columns={columns}
      name="Contact Us"
      searchText={searchText}
    />
  );
}

export default List;
