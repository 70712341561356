import { Container, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Form from "./Form";
import { useParams, useHistory } from "react-router-dom";
import { LoaderContext } from "utils/LoaderContext";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../store/actions/index";
import { USER_TYPES } from "utils/constant";
import { toast } from "react-toastify";
import { errorToast } from "utils/Comman";

function ManageSubscriptionForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { toggalLoader } = useContext(LoaderContext);
  const [currencyList, setCurrencyList] = useState([]);
  const [recurringList, setRecurringList] = useState([]);
  const userTypeId = USER_TYPES[params?.type?.toUpperCase()];
  const getSubsriptionDetails = useSelector(
    (state) => state.subscription.subscriptionDetail
  );

  const handleSubmit = (values) => {
    toggalLoader(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("user_type", userTypeId);
    formData.append("amount", values.amount);
    formData.append("currency", values.currency.id);
    formData.append("recurring", values.recurring.id);
    formData.append("description", values.description||"");
    formData.append("tax_percentage", values.tax_percentage);
    if (values.plan_details) {   
      formData.append("plan_details", values.plan_details.replace("<p><br></p>", ""));
    }
    formData.append("status", values.status);
    if (userTypeId === 3) {
      formData.append("num_of_jobs", values.num_of_jobs);
    } else if (userTypeId === 4) {
      formData.append("num_of_developers", values.num_of_developers);
    } 

    toggalLoader(false);
    dispatch(Actions.createSubscription(formData))
      .then((response) => {
        if (response.status === 201) {
          toast.success("Subscription plan created successfully");
          history.push("/manage-subscription/");
          toggalLoader(false);
        } else {
          toggalLoader(false);
          errorToast(response);
        }
      })
      .catch((response) => {
        toggalLoader(false);
        errorToast(response);
      });
  };

  const UpdateSubscription = (values) => {
    toggalLoader(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("user_type", userTypeId);
    formData.append("amount", values.amount);
    formData.append("currency", values.currency.id);
    formData.append("recurring", values.recurring.id);
    formData.append("description", values.description||"");
    formData.append("tax_percentage", values.tax_percentage);
    formData.append("status", values.status);
    if (values.plan_details) {   
      formData.append("plan_details", values.plan_details.replace("<p><br></p>", ""));
    }
    if (userTypeId === 3) {
      formData.append("num_of_jobs", values.num_of_jobs);
    } else if (userTypeId === 4) {
      formData.append("num_of_developers", values.num_of_developers);
    } 

    toggalLoader(false);
    dispatch(Actions.updateSubscription(params.id, formData))
      .then((response) => {
        if (response.status === 200) {
          toast.success("Subscription plan updated successfully");
          history.push("/manage-subscription/");
          toggalLoader(false);
        } else {
          toggalLoader(false);
          errorToast(response);
        }
      })
      .catch((response) => {
        toggalLoader(false);
        errorToast(response);
      });
  };

  const fetchRecurringList = () => {
    dispatch(Actions.getRecurringList())
      .then((response) => {
        if (response.status === 200) {
          setRecurringList(response.data);
        } else {
          setRecurringList([]);
        }
      })
      .catch(() => {
        setRecurringList([]);
      });
  };

  const fetchCurrencyList = () => {
    dispatch(Actions.getCurrencyList())
      .then((response) => {
        if (response.status === 200) {
          setCurrencyList(response.data);
        } else {
          setCurrencyList([]);
        }
      })
      .catch(() => {
        setCurrencyList([]);
      });
  };

  const fetchSubscriptionData = (id) => {
    dispatch(Actions.getSubscriptionDetails(id))
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "GET_SUBSCRIPTION_DETAILS",
            payload: response.data,
          });
        } else {
          dispatch({ type: "GET_SUBSCRIPTION_DETAILS", payload: {} });
        }
      })
      .catch(() => {
        dispatch({ type: "GET_SUBSCRIPTION_DETAILS", payload: {} });
      });
  };

  useEffect(() => {
    if (params.id) {
      fetchSubscriptionData(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    fetchRecurringList();
    fetchCurrencyList();
  }, []);

  return (
    <Container className="profile-container my_profile-container">
      <Grid
        container
        item
        xs={12}
        justifyContent="flex-end"
        alignItems="flex-start"
      ></Grid>
      <Grid className="profile-inner add_profile-inner">
        <Form
          recurringList={recurringList}
          currencyList={currencyList}
          initialValues={
            params.id
              ? { ...getSubsriptionDetails, status: getSubsriptionDetails.status?.toString() }
              : { status: "1" }
          }
          onSubmit={(values) => {
            if (params.id) {
              UpdateSubscription(values);
            } else {
              handleSubmit(values);
            }
          }}
        />
      </Grid>
    </Container>
  );
}

export default ManageSubscriptionForm;
