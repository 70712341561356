import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import { Avatar, FormControl, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import 'assets/css/skeleton.css'; 

const ClientDetailSkeleton = (props) => {
    const { classes } = props;
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <Skeleton className='innerBackBtn transform-scale-1 bg-dark m-t-5' width={140} height={42} />
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={12} lg={12} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Skeleton variant="circle" width={220} height={220}>
                            <Avatar className={classes.profileImg} />
                        </Skeleton>
                        <div className={classes.profileContent}>
                            <Grid className={classes.userInfo}>
                                <div>
                                    <Typography variant='h4'><Skeleton width={195} height={34} /></Typography>
                                    <Typography variant='p' component='p' className={classes.clientId}><Skeleton width={151} height={23} /></Typography>
                                </div>
                                <a className={classes.viewLogBtn}><Skeleton width={130} height={20} /></a>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={70} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={586} height={28} />
                                    </Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={40} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={586} height={28} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={120} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={586} height={28} />
                                    </Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={60} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={586} height={28} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={8} md={8}>
                    <Grid className='profile-inner my_profile-inner'>
                        <Grid className='add_profile-form'>
                            <Grid container className='formGrid'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid style={{ width: '100%', }}>
                                            <label className="website-label"><Skeleton width={60} height={15} /> <span></span></label>
                                            <Skeleton width={955} height={25} />
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container className='formGrid'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label className="idProof-label"><Skeleton width={60} height={15} /> <span></span></label>
                                            <Typography variant='p' component='p' className='idProof-name'>
                                                <Skeleton className='bg-dark' variant="circle" width={40} hight={40} />
                                                <Skeleton className='bg-dark' width={100} height={20} />
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" style={{ margin: '24px 0', }} />
                            <Typography variant='h5' component='h5' className='title'>
                                <Skeleton width={156} height={25} />
                            </Typography>
                            <Grid container className='formGrid'>
                                <Grid className='formGrid-inner'>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label><Skeleton width={60} height={15} /></label>
                                            <Typography variant='p' component='p' className='value'>
                                                <Skeleton width={300} height={25} />
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={4}>
                    <div className={classes.innerContainer}>
                        <Grid className='moreDetails'>
                            <List className='moreDetails-list'>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={440} height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={440} height={25} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={440} height={20} /> <span></span>
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={440} height={25} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={440} height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={440} height={25} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={440} height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={440} height={25} />
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default ClientDetailSkeleton