import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import viewIcon from "assets/img/icons/visibility-icon.svg";
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../store/actions/index'
import { toast } from 'react-toastify';
import CertificateDetailDialog from 'components/Dialog/CertificateDetailDialog';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { certificationListImage, dispatchContentLoading } from 'utils/Comman';
import AgencyDeveloperCertificateSkeleton from '../Skeleton/AgencyDeveloperSkeleton/AgencyDeveloperCertificateSkeleton';

const columns = [
	{ id: 'image_id', label: '', minWidth: 100 },
	{
		id: 'certi_title',
		label: 'Certificate Title',
		minWidth: 170,
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'certi_date',
		label: 'Certification Date',
		minWidth: 170,
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'action',
		label: '',
		minWidth: 50,
		format: (value) => value.toLocaleString('en-US'),
	},
];

function AgencyDeveloperCertificate() {
	const [dialogDetail, setDialogDetail] = useState({});
	const developerDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperDetail));
	const CertificateDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperCertificationList));
	const contentLoading = useSelector(state => state.commonReducer.contentLoading);
	const pageSize = 10;
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	const handleClickOpen = (project) => {
		setDialogDetail(project);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const agencyDeveloperDetail = (page) => {
		dispatch(Actions.getDeveloperDetail(developerDetail?.id, 'certifications', page)).then(response => {
			if (response.status === 200) {
				dispatch({ type: "AGENCY_DEVELOPER_CERTIFICATE_LIST", payload: response.data });
				dispatchContentLoading(dispatch);
			} else {
				dispatch({ type: "AGENCY_DEVELOPER_CERTIFICATE_LIST", payload: null });
				dispatchContentLoading(dispatch);
				Object.keys(response.data).forEach((error) => {
					toast.error(response.data[error]);
				})
			}
		}).catch((response) => {
			dispatchContentLoading(dispatch);
			Object.keys(response.data).forEach((error) => {
				toast.error(response.data[error]);
			})
		})
	}
	useEffect(() => {
		agencyDeveloperDetail();
		return () => {
			dispatch({ type: 'CONTENT_LOADING', payload: true });
		}
	}, [])
	return (
		<>
			{contentLoading ? <AgencyDeveloperCertificateSkeleton />
				:
				<>
					{CertificateDetail?.results && CertificateDetail?.results?.length > 0 ?
						<TableContainer>
							<Table className='project_detail-table certificate_table'>
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ top: 50, minWidth: column.minWidth }}
												className='tableCellHead'
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{CertificateDetail?.results?.map((certificate) => (
										<TableRow hover role="checkbox" tabIndex={-1} key={certificate.id}>
											<TableCell className='tableDataCell'>
												<div className="certificateImg">
													<a href={certificate?.certificate_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'>
														<img src={certificationListImage(certificate?.certificate_file)} alt="img" />
													</a>
												</div>
											</TableCell>
											<TableCell className='tableDataCell'>
												{certificate?.title}
											</TableCell>
											<TableCell className='tableDataCell'>
												{moment(certificate?.certification_date).format('DD/MM/YYYY')}
											</TableCell>
											<TableCell className='tableDataCell eyeIcon'>
												<img src={viewIcon} onClick={() => handleClickOpen(certificate)} alt='view' />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<Grid container direction="row" justifyContent="center" alignItems="center">
								<PaginationRounded
									totalPages={Math.ceil(CertificateDetail?.count / pageSize) || 0}
									handlePages={(page) => agencyDeveloperDetail(page)}
								/>
							</Grid>
						</TableContainer>
						: <Typography variant='p' component='p'>No Records Found</Typography>
					}
					<CertificateDetailDialog open={open} close={handleClose} detail={dialogDetail} />
				</>
			}
		</>
	);
}

export default AgencyDeveloperCertificate;
