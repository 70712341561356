import React from "react";
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';

const SingleAutocomplete = ({ input, options, id, inputProps, meta: { touched, error },
    labelText, displayKey, onInputChange, noOptionsText,disabled }) => {
    return (
        <>
            <FormControl
                variant="standard"
                className="formControl"
                fullWidth={true}
                error={touched && Boolean(error)}
            >
                {labelText !== undefined ? (
                    <InputLabel shrink htmlFor={id}>
                        {labelText}
                    </InputLabel>
                ) : null}
                <Autocomplete
                    className="selectTextField"
                    options={options || []}
                    selectOnFocus={false}
                    disablePortal={true}
                    autoHighlight
                    clearOnBlur
                    disabled={disabled}
                    getOptionLabel={(option) => option[displayKey] || ""}
                    value={input.value}
                    getOptionSelected={(option, value) => option.displayKey === value.displayKey}
                    // isOptionEqualToValue={(option, value) => option[displayKey] === value[displayKey]}
                    onChange={(event, newValue) => {
                        input.onChange(newValue);
                    }}
                    onInputChange={(event, value) => {
                        onInputChange(value);
                    }}
                    noOptionsText={noOptionsText || "No Options"}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    ...inputProps,
                                }}
                            />
                        );
                    }}
                />

                {touched && error ? (
                    <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>
                ) : null}
            </FormControl>
        </>
    );
};

export default SingleAutocomplete;
