import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../store/actions/index'
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CertificateDetailsDialog from "design/dialog/CertificateDetailsDialog";
import PersonalDetailForm from "./Form/PersonalDetailForm";
import ProfessionalDetailForm from "./Form/ProfessionalDetailForm";
import CertificationForm from './Form/CertificationForm';
import { toast } from "react-toastify";
import "assets/css/profile.css";
import { getUpdateUrl } from 'utils/constant';
import ProjectListTab from './Form/ProjectListTab';
import { LoaderContext } from 'utils/LoaderContext';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomButton from "components/CustomButtons/Button";
import { errorToast } from 'utils/Comman';

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        '@media (max-width: 1279px)': {
            width: '154px',
            height: '154px',
        },
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 244px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '@media (max-width: 1279px)': {
            width: 'calc(100% - 178px)',
        },
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#6A8CAA',
        textAlign: 'right',
        '& span': {
            color: '#323232',
        }
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    },
    formGridInner: {
        width: '48%',
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '32px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            textTransform: 'capitalize',
            marginBottom: '0',
        }
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ManageFreelancers(props) {
    const { classes } = props;
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const userDetail = useSelector((state) => (state.manageUsersReducer.portFolio));
    const pageNumber = useSelector((state) => (state.commonReducer.pageNumber));
    const { toggalLoader } = useContext(LoaderContext);
    const [currentTab, setCurrentTab] = React.useState(false);
    const formDetails = useSelector((state) => state.manageUsersReducer?.[currentTab?.stateKey]);
    const [files, setFiles] = useState({ profile: null, identity: null });

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event, newValue) => {
        const tabUrl = getUpdateUrl(newValue);
        getTabDetails(tabUrl);
        setCurrentTab(tabUrl);
        setValue(newValue);
    };
    const getTabDetails = (tab) => {
        toggalLoader(true);
        dispatch(Actions.getFreelancerDetail(params.id, tab?.url, false, pageNumber)).then(response => {
            toggalLoader(false);
            if (response.status === 200) {
                dispatch({ type: tab.type, payload: response.data });
            } else {
                toggalLoader(false);
                dispatch({ type: tab.type, payload: null });
                errorToast(response);
            }
        });
    }

    const updatePersonalDetail = (value) => {
        toggalLoader(true);
        const payload = {
            contact_number: value.contact_number,
            email: value.email,
            first_name: value.first_name,
            last_name: value.last_name,
            city: value.city.id,
            country: value.country.id,
        }
        dispatch(Actions.updateFreelancer(params.id, payload)).then((response) => {
            toggalLoader(false);
            if (response.status === 200) {
                toast.success("free lancer's profile updated successfully");
                history.push("/manage-users");
                toggalLoader(false);
            } else {
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        });
    }
    useEffect(() => {
        getTabDetails(getUpdateUrl(0));
    }, []);

    const uploadFieldArrayImage = (values) => {
        const tempArray = [];
        values.forEach(async (data) => {
            if (data.certificate_file instanceof File) {
                const formData = new FormData();
                formData.append("file_obj", data.certificate_file);
                const response = await dispatch(Actions.uploadImage(formData));
                data.certificate_file = response.data;
            } else {
                delete data.certificate_file;
            }
            tempArray.push(data);
            if (values.length === tempArray.length) {
                handleSubmit(values);
            }
        });
    }
    const handleImageUpload = async (e) => {
        let file = null;
        let whichFile = null;
        if (e instanceof File) {
            file = e;
            whichFile = 'identity';
        } else if (e.target.files.length !== 0) {
            file = e.target.files[0];
            whichFile = 'profile';
        }
        if (file) {
            const formData = new FormData();
            formData.append("file_obj", file);
            dispatch(Actions.uploadImage(formData)).then(response => {
                const filesObject = { ...files }
                filesObject[whichFile] = response.data;
                setFiles(filesObject);
            });
        }
    };
    const handleSubmit = (data) => {
        toggalLoader(true);
        dispatch(Actions.updateDetails(params.id, currentTab?.url, data)).then(response => {
            toggalLoader(false);
            if (response.status === 201) {
                toggalLoader(false);
                toast.success('Details updated successfully');
                getTabDetails(currentTab);
            } else {
                errorToast(response);
            }
        });
    }
    const handleProfessionalDetail = (details) => {
        toggalLoader(false);
        const payload = {
            skills: details.portfolio.skills.map((skill) => (skill.id)),
            sub_skills: details.portfolio.sub_skills.map((subskill) => (subskill.id)),
            profile_type: details.portfolio.profile_type.id,
            experience: details.portfolio.experience,
            availability: details.portfolio.availability,
            technologies: details.portfolio.technologies.map((techno) => (techno.id)),
            education_details: details.portfolio.education_details,
            communication_language: details.portfolio.communication_language.map(val => {
                return {
                    id: val.id,
                    communication_language: val.communication_language.key,
                    proficiency: val.proficiency.id,
                }
            }),
            specialties: details.portfolio.specialties.trim(),
            description: details.portfolio.description.trim(),
        }
        if (typeof (files.identity) === 'string') {
            payload.company.document_file = files.identity
        }
        dispatch(Actions.updateProfessionalDetail(params.id, payload))
            .then((response) => {
                if (response.status === 201) {
                    toast.success("Freelancer's profile update successfully");
                    history.push("/manage-users");
                } else {
                    errorToast(response);
                }
                toggalLoader(false);
            })
            .catch((response) => {
                errorToast(response);
                toggalLoader(false);
            });
    }

    return (
        <>
            <Grid container className='profile-top-inner'>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant='h4'>{`${userDetail?.first_name} ${userDetail?.last_name}`}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{userDetail?.uid}</span></Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <CustomButton
                    variant="contained"
                    className="primaryButton innerBackBtn"
                    startIcon={<ArrowBackIosIcon />}
                    color="info"
                    onClick={() => {
                        history.push("/manage-users");
                    }}
                >
                    Back
                </CustomButton>
            </Grid>
            <Grid className="profile-container my_profile-container">
                <Grid className="profile-inner">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Personal Details"
                            {...a11yProps(0)}
                            className="tab-title"
                        />
                        <Tab
                            label="Professional Details"
                            {...a11yProps(1)}
                            className="tab-title"
                        />
                        <Tab
                            label="Projects"
                            {...a11yProps(2)}
                            className="tab-title"
                        />
                        <Tab
                            label="Certificates"
                            {...a11yProps(3)}
                            className="tab-title"
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <PersonalDetailForm
                            initialValues={userDetail}
                            onSubmit={(value) => updatePersonalDetail(value)}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ProfessionalDetailForm
                            initialValues={userDetail}
                            uploadImage={handleImageUpload}
                            onSubmit={(value) => handleProfessionalDetail(value)} />
                    </TabPanel>
                    <TabPanel value={value} index={2} className="projects">
                        <Grid container className="projectsList">
                            <ProjectListTab currentTab={currentTab} getTabDetails={getTabDetails} />
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <CertificationForm onSubmit={(values) => uploadFieldArrayImage(values?.certification)}
                            initialValues={{ 'certification': formDetails?.results }} />
                        <CertificateDetailsDialog open={open} close={handleClose} />
                    </TabPanel>
                </Grid>
            </Grid>
        </>
    )
}
export default withStyles(styles)(ManageFreelancers);