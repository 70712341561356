import apiService from 'api/ApiService';

export function getUser(url, limit, offset, search, ordering) {
    return () => apiService.get(`${url}?page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}
/* Client API */

export function deleteClient(id) {
    return () => apiService.delete(`user-client/${id}/`);
}
export function updateClient(id, params) {
    return () => apiService.patch(`user-client/${id}/`, params);
}
export function getCity(id) {
    return () => apiService.get(`city/${id}/`);
}
export function getClientDetails(id) {
    return () => apiService.get(`user-client/${id}/`);
}
export function assignAgencyLog(id) {
    return () => apiService.get(`user-client/${id}/assigned-agency-list/`);
}

/* Agency API */

export function deleteAgency(id) {
    return () => apiService.delete(`user-agency/${id}/`);
}
export function updateAgency(id, params) {
    return () => apiService.patch(`user-agency/${id}/`, params);
}
export function getCategories() {
    return () => apiService.get(`categories/?depth=1`);
}
export function getSubCategories(parent) {
    return () => apiService.get(`categories/?parent=${parent}`);
}
export function getAgencyDetails(id) {
    return () => apiService.get(`user-agency/${id}/`);
}
export function getAgencyDeveloperDetails(id, developer_id) {
    return () => apiService.get(`user-agency/${id}/agency-developer/${developer_id}/`);
}
export function getDeveloperRequestDetails(id, url, is_user_request, page) {
    return () => apiService.get(`user-agency-developer/${id}/${url}/?is_user_request=${is_user_request}&page=${page || 1}`);
}
export function getDeveloperDetail(id, url, page) {
    return () => apiService.get(`user-agency-developer/${id}/${url}/?page=${page || 1}`);
}
export function getDeveloperList(id) {
    return () => apiService.get(`user-agency/${id}/agency-developers/`);
}
export function getDeveloperRequest(id) {
    return () => apiService.get(`user-agency/${id}/agency-developers-requests/`);
}
export function getDeveloper(id, is_user_request) {
    return () => apiService.get(`user-agency-developer/${id}/?is_user_request=${is_user_request}`);
}

/* Freelancer API */

export function getFreelancerDetail(id, url, is_user_request, page) {
    return () => apiService.get(`user-freelancer/${id}/${url}?is_user_request=${is_user_request}&page=${page || 1}`);
}
export function updateFreelancer(id, params) {
    return () => apiService.patch(`user-freelancer/${id}/`, params);
}
export function deleteFreelancer(id) {
    return () => apiService.delete(`user-freelancer/${id}/`);
}
export function updateDetails(id, url, params) {
    return () => apiService.post(`user-freelancer/${id}/${url}`, params);
}
export function getTechnologyList() {
    return (dispatch) => apiService.get(`technologies/`).then(response => {
        if (response.status === 200) {
            dispatch({ type: 'TECHNOLOGIES', payload: response.data });
        } else {
            dispatch({ type: 'TECHNOLOGIES', payload: [] });
        }
    });
}
export function ProfileTypes() {
    return () => apiService.get(`profiletypes/`);
}
export function createProject(id, params) {
    return () => apiService.post(`user-freelancer/${id}/project/`, params);
}
export function updateProject(id, project_id, params) {
    return () => apiService.patch(`user-freelancer/${id}/project/${project_id}/`, params);
}
export function updateProfessionalDetail(id, params) {
    return () => apiService.post(`user-freelancer/${id}/portfolio/`, params);
}
export function deleteWorkExperience(id, work_experience_id) {
    return () => apiService.delete(`user-freelancer/${id}/work-experience-delete/${work_experience_id}/`);
}
export function deleteCertificate(id, certification_id) {
    return () => apiService.delete(`user-freelancer/${id}/certification/${certification_id}/`);
}
export function deleteProject(id, project_id) {
    return () => apiService.delete(`user-freelancer/${id}/project/${project_id}/`);
}
export function getAllAgencies(search) {
    return (dispatch) => apiService.get(`user-agency/?search=${search}`).then((response) => {
        if (response.status === 200) {
            let data = response.data?.results || response.data
            dispatch({ type: 'GET_ALL_AGENCIES', payload: data });
        } else {
            dispatch({ type: 'GET_ALL_AGENCIES', payload: [] });
        }
    }).catch(() => {
        dispatch({ type: 'GET_ALL_AGENCIES', payload: [] });
    });
}