import App from './App';
import React from "react";
import ReactDOM from "react-dom";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import './index.css';
import Progress from 'components/Progress';
import LoaderContextProvider from "./utils/LoaderContext"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import moment from "moment-timezone";

moment.tz.setDefault("UTC");

ReactDOM.render(
  <React.Fragment>
    <LoaderContextProvider>
      <App />
      <Progress />
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </LoaderContextProvider>
  </React.Fragment>,
  document.getElementById("root")
);
