import React, { useContext, useEffect } from "react";
import Form from "./components/Form";
import { useDispatch } from 'react-redux';
import * as Actions from '../../../store/actions/index';
import { toast } from 'react-toastify';
import { LoaderContext } from "utils/LoaderContext";
import { errorToast } from "utils/Comman";

function UserConfirmation(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);

    const handledSetUserDetails = (user) => {
        toggalLoader(true);
        const formData = new FormData();
        formData.append('first_name', user.first_name);
        formData.append('last_name', user.last_name);
        formData.append('new_password1', user.new_password1);
        formData.append('new_password2', user.new_password2);
        formData.append('uid', match.params.uid);
        formData.append('token', match.params.token);
        dispatch(Actions.setUserDetails(formData)).then(res => {
            if (res.status === 200) {
                toggalLoader(false);
                toast.success(res.data.detail);
                props.history.push('/login');
            } else {
                props.history.push('/login');
                toggalLoader(false);
                errorToast(res);
            }
        }).catch((res) => {
            toggalLoader(false);
            errorToast(res);
        });
    }
    const validateInvitation = () => {
        const payload = {
            'uid': match.params.uid,
            'token': match.params.token
        }
        dispatch(Actions.validateToken(payload)).then(res => {
            if (res.status === 200) {
                toggalLoader(false);
            } else {
                props.history.push('/login');
                toggalLoader(false);
                errorToast(res);
            }
        }).catch((res) => {
            toggalLoader(false);
            errorToast(res);
        });
    }
    useEffect(() => {
        validateInvitation()
    }, [])
    return (
        <div>
            <Form onSubmit={(user) => {
                handledSetUserDetails(user);
            }}
            />;
        </div>
    )
}

export default UserConfirmation;
