import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import MasterTable from "components/MasterTable/MasterTable";
import IconButton from "@material-ui/core/IconButton";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router-dom";
import { canHaveAnyPermissions } from "utils/auth";
import * as Actions from "../../../store/actions/index";

function List(props) {
    const history = useHistory();
    const { subAdminList, getList, pageLimit, removeSubAdmin, pageOffset, searchText } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [subAdminId, setSubAdminId] = useState('');
    const [fileEl, setFileEl] = useState(null);
    const [fileElIndex, setFileElIndex] = useState(0);
    const tableName = 'Sub Admin';
    const dispatch = useDispatch();
    const user = useSelector(state => state.authReducer?.userDetails);
    const actionPermissions = [PERMISSION.SUB_ADMIN_EDIT, PERMISSION.SUB_ADMIN_DELETE];

    const handleConfirmDialog = (value) => {
        setOpenDialog(true);
        setSubAdminId(value);
    };
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        removeSubAdmin(subAdminId)
    };
    const handleClose = () => {
        setOpenDialog(false);
        setSubAdminId(null);
    }
    const handleFileELClick = (event, i) => {
        setFileEl(event.currentTarget);
        setFileElIndex(i)
    };
    const handleCloseFileEL = () => {
        setFileEl(null);
    }
    const handleDelete = (value) => {
        handleConfirmDialog(value);
        setFileEl(null);
    }
    const editForm = (value) => {
        history.push({ pathname: `/sub-admin/edit`, state: value })
    }

    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
            } else {
                dispatch({ type: 'GET_USER_DETAILS', payload: '' });
            }
        });
    }

    useEffect(() => {
        getUserDetails();
    }, [])

    const columns = [
        {
            name: "first_name",
            label: "First Name",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "last_name",
            label: "Last Name",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                }
            }
        },
        {
            name: "contact_number",
            label: "Contact Number",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                }
            }
        },
        {
            name: "groups",
            label: "Role",
            options: {
                sort: false,
                customBodyRender: (groups) => {
                    return (groups.length <= 0) ? '-' : groups.map((g) => g.name).join(', ')
                }
            }
        },
        {
            name: "is_active",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value === true) ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>
                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                display: canHaveAnyPermissions(actionPermissions, user?.user_permissions),
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-owns={fileEl ? "long-menu" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleFileELClick(e, value)}
                            >
                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                id="long-menu"
                                anchorEl={fileEl}
                                open={Boolean(fileEl) && fileElIndex === value}
                                onClose={handleCloseFileEL}
                            >
                                <CheckPermission
                                    component={<MenuItem onClick={() => editForm(value)}>Edit Sub Admin</MenuItem>}
                                    permission={PERMISSION.SUB_ADMIN_EDIT} />
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleDelete(value)}>Delete Sub Admin</MenuItem>}
                                    permission={PERMISSION.SUB_ADMIN_DELETE} />
                            </Menu>
                        </>
                    )
                }
            }
        },
    ]
    return (
        <div>
            <MasterTable
                list={subAdminList}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
            <ConfirmDialog open={openDialog} close={handleClose}
                save={handleConfirmDelete}
                title="Alert" alertMessage="Are you sure you want to delete sub admin"
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        subAdminList: state.subAdminReducer.subAdminList,
        roleFormList: state.subAdminReducer.roleFormList
    }
}
export default connect(mapStateToProps, '')(List);
