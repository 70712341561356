import React, { useContext, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import "assets/css/skeleton.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Avatar from '@material-ui/core/Avatar';
import CustomButton from "components/CustomButtons/Button";
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/actions/index'
import { toast } from 'react-toastify';
import DeveloperPortfolio from './DeveloperPortfolio';
import DeveloperProject from './DeveloperProject';
import DeveloperCertificate from './DeveloperCertificate';
import { checkApproval, errorToast, findAmountPerType } from 'utils/Comman';
import { LoaderContext } from 'utils/LoaderContext';
import MessageDialog from 'components/Dialog/MessageDialog'
import ImagePreviewDialog from 'components/Dialog/ImagePreviewDialog';
import DeveloperRequestDetailsSkeleton from '../Skeleton/DeveloperRequestDetilsSkeleton';
import { Skeleton } from '@material-ui/lab';


const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: "100%",
        width: '220px',
        height: '220px',
        '@media (max-width: 1279px)': {
            width: '154px',
            height: '154px',
        },
        cursor: 'pointer'
    },
    profileImgApproval: {
        border: '6px solid rgba(255, 158, 45, 0.4)',
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 244px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '@media (max-width: 1279px)': {
            width: 'calc(100% - 178px)',
        },
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    },
    formGridInner: {
        width: '48%',
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '32px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            textTransform: 'capitalize',
            marginBottom: '0',
        }
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function DeveloperRequestDetails(props) {
    const developerDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperDetail));
    const requestDetail = useSelector((state) => (state.userRequestReducer.developerRequestDetail));
    const manageTab = useSelector(state => state.commonReducer.developerDetailTab);
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const [openPreview, setOpenPreview] = useState(false);
    const { toggalLoader } = useContext(LoaderContext);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { classes } = props;
    const params = useParams();
    const filter = history?.location?.state?.filter ?? null;

    const handleClickOpen = () => {
        setOpen(!open);
    };
    const handleChange = (event, newValue) => {
        if (manageTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({
            type: 'SET_DEVELOPER_DETAIL_TAB',
            payload: {
                activeTab: newValue,
            }
        })
        setValue(newValue);
    };
    const handleOpenPreview = () => {
        setOpenPreview(!openPreview);
    }
    const agencyDeveloperDetail = () => {
        dispatch(Actions.getDeveloper(requestDetail?.user?.id, true)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "AGENCY_DEVELOPER_DETAIL", payload: response.data });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
            } else {
                dispatch({ type: "AGENCY_DEVELOPER_DETAIL", payload: null });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
                errorToast(response);
            }
        }).catch((response) => {
            dispatch({ type: "PAGE_LOADING", payload: false });
            dispatch({ type: "CONTENT_LOADING", payload: false });
            errorToast(response);
        })
    }
    const getRequestDetail = () => {
        dispatch(Actions.getRequestDetail(params.id))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: 'DEVELOPER_REQUEST_DETAIL', payload: response.data });
                    setTimeout(() => {
                        dispatch({ type: "PAGE_LOADING", payload: false });
                        dispatch({ type: "CONTENT_LOADING", payload: false });
                    }, 1000);
                } else {
                    dispatch({ type: 'DEVELOPER_REQUEST_DETAIL', payload: null });
                    setTimeout(() => {
                        dispatch({ type: "PAGE_LOADING", payload: false });
                        dispatch({ type: "CONTENT_LOADING", payload: false });
                    }, 1000);
                    errorToast(response);
                }
            })
            .catch((response) => {
                dispatch({ type: "PAGE_LOADING", payload: false });
                dispatch({ type: "CONTENT_LOADING", payload: false });
                errorToast(response);
            });
    };
    const handleChangeStatus = (detail) => {
        toggalLoader(true);
        setButtonLoading(true)
        dispatch(Actions.updateRequestStaus(params.id, detail))
            .then((response) => {
                if (response.status === 200) {
                    let message = response.data.status === 3 ? 'Request rejected successfully' : 'Request approved successfully';
                    toast.success(message);
                    toggalLoader(false);
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: response.data });
                    setButtonLoading(false);
                    history.push(`/developer-requests`, { filter });
                } else {
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: null });
                    setButtonLoading(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                setButtonLoading(false);
                errorToast(response);
            });
    }

    const checkProfileApproval = (profileReqKey, profileKey) => {
        const check = profileReqKey?.indexOf(profileKey);
        return (check === -1) ? classes.profileImg : classes.profileImgApproval;
    }

    const agencyRedirection = () => {
        history.push(`/manage-user/agency-details/${developerDetail?.agency?.id}`)
    }

    useEffect(() => {
        if (params.id) {
            getRequestDetail();
        }
        return () => {
            dispatch({ type: 'DEVELOPER_REQUEST_DETAIL', payload: null });
        }
    }, []);

    useEffect(() => {
        if (requestDetail?.user?.id) {
            agencyDeveloperDetail();
        }
    }, [requestDetail]);
    useEffect(() => {
        return () => {
            history.replace({ ...history.location, state: { filter } });
            dispatch({ type: "PAGE_LOADING", payload: true });
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
    }, [])

    return (
        <>
            {pageLoading ?
                <>
                    <DeveloperRequestDetailsSkeleton classes={classes} activeTab={value} />
                    <Grid className='profile-container my_profile-container action-div'>
                        <Skeleton className='transform-scale-1 bg-dark m-t-5' width={140} height={42} />
                        <Skeleton className='nextBtn transform-scale-1 bg-dark m-t-5' width={140} height={42} />
                    </Grid>
                </>
                :
                <>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <CustomButton
                            variant="contained"
                            className="primaryButton innerBackBtn"
                            startIcon={<ArrowBackIosIcon />}
                            color="info"
                            onClick={() => {
                                dispatch({ type: "AGENCY_DEVELOPER_CERTIFICATEDETAIL", payload: null });
                                dispatch({ type: "AGENCY_DEVELOPER_DETAIL", payload: null });
                                dispatch({ type: "AGENCY_DEVELOPER_PROJECTDETAIL", payload: null });
                                history.push({ pathname: `/developer-requests`, state: { filter } });
                            }}
                        >
                            Back
                        </CustomButton>
                    </Grid>
                    <Grid container className={classes.innerContainer}>
                        <Grid item xl={12} lg={12} md={12} sm={12}>
                            <div className={classes.profileDetail}>
                                <Avatar alt="profile-img"
                                    onClick={() => handleOpenPreview()}
                                    src={developerDetail?.profile_image?.url ?? developerDetail?.profile_image}
                                    className={checkProfileApproval(requestDetail?.requested_changes_keys, 'profile_image')} />
                                <div className={classes.profileContent}>
                                    <Grid className={classes.userInfo}>
                                        <div>
                                            <Typography variant='h4'>{developerDetail?.first_name ? `${developerDetail?.first_name} ${developerDetail?.last_name}` : '-'}</Typography>
                                            <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{developerDetail?.uid}</span></Typography>
                                        </div>
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Email
                                            </label>
                                            <Typography variant='p' component='p'>{developerDetail?.email ? developerDetail?.email : '-'}</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Contact Number {checkApproval(requestDetail?.requested_changes_keys, 'contact_number')}
                                            </label>
                                            <Typography variant='p' component='p'>{developerDetail?.contact_number ? developerDetail?.contact_number : '-'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Profile Type {checkApproval(requestDetail?.requested_changes_keys, 'profile_type')}
                                            </label>
                                            <Typography variant='p' component='p'>{developerDetail?.portfolio?.profile_type?.title ? developerDetail?.portfolio?.profile_type?.title : '-'}</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Availability {checkApproval(requestDetail?.requested_changes_keys, 'availability')}
                                            </label>
                                            <Typography variant='p' component='p'>{developerDetail?.portfolio?.availability === 1 ? 'Part Time' : 'Full Time'}</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className="profile-container my_profile-container">
                        {/* Freelancer Profile Details */}
                        <Grid container spacing={3} className="freelancer_profile-details">
                            <Grid item lg={8} md={8} sm={12} xs={12}>
                                <Grid className="profile-inner my_profile-inner">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab
                                            label={
                                                <>
                                                    ProfessionalDetails
                                                    {checkApproval(requestDetail?.requested_changes_keys, 'portfolio')}
                                                </>}
                                            {...a11yProps(0)}
                                            className="tab-title"
                                        />
                                        <Tab
                                            label={
                                                <>
                                                    Projects
                                                    {checkApproval(requestDetail?.requested_changes_keys, 'projects')}
                                                </>
                                            }
                                            {...a11yProps(1)}
                                            className="tab-title"
                                        />
                                        <Tab
                                            label={
                                                <>
                                                    Certificates
                                                    {checkApproval(requestDetail?.requested_changes_keys, 'certifications')}
                                                </>
                                            }
                                            {...a11yProps(2)}
                                            className="tab-title"
                                        />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <DeveloperPortfolio detail={requestDetail} />
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="projects">
                                        <DeveloperProject detail={requestDetail} />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <DeveloperCertificate detail={requestDetail} />
                                    </TabPanel>
                                </Grid>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <div className={classes.innerContainer}>
                                    <Grid className='moreDetails'>
                                        <List className='moreDetails-list'>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Agency ID
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    <Link className='agency-id-dev-request cursor-pointer' onClick={agencyRedirection}>{developerDetail?.agency?.uid || '-'}</Link>
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Experience {checkApproval(requestDetail?.requested_changes_keys, 'experience')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {`${developerDetail?.portfolio?.experience} Years` || 0}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Projects {checkApproval(requestDetail?.requested_changes_keys, 'project_count')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {developerDetail?.portfolio?.projects_count || 0}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Education {checkApproval(requestDetail?.requested_changes_keys, 'education_details')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {developerDetail?.portfolio?.education_details || '-'}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Price {checkApproval(requestDetail?.requested_changes_keys, 'salary_type') || checkApproval(requestDetail?.requested_changes_keys, 'amount')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {findAmountPerType(developerDetail?.portfolio?.amount, developerDetail?.portfolio?.salary_type) || '-'}
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className='action-div'>
                            <Link underline="none" className='previousBtn' onClick={() => handleClickOpen()}>Reject</Link>
                            <Button variant="contained" className='primaryButton nextBtn' disabled={buttonLoading} onClick={() => handleChangeStatus({ status: 2 })}>Approve</Button >
                        </Grid>
                    </Grid>
                    <MessageDialog open={open} close={handleClickOpen} title={'Reject'} disabled={buttonLoading}
                        onSubmit={(data) => {
                            handleChangeStatus({ ...data, status: 3 })
                        }} />
                    {developerDetail?.profile_image &&
                        <ImagePreviewDialog open={openPreview} close={handleOpenPreview} attachment={developerDetail?.profile_image?.url ?? developerDetail?.profile_image} />
                    }
                </>
            }
        </>
    );
}

export default withStyles(styles)(DeveloperRequestDetails);