import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions/index';
import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import List from "./component/List";
import Button from "components/CustomButtons/Button";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from 'react-toastify';
import CheckPermission from "components/Hoc/CheckPermission";
import * as PERMISSION from "utils/permissionConstant";
import { errorToast } from "utils/Comman";
import { dispatchPageLoading } from "utils/Comman";
import MasterSkeleton from "components/MasterSkeleton/MasterSkeleton";

export default function Role(props) {
	const dispatch = useDispatch();
	const { toggalLoader } = useContext(LoaderContext);
	const { history } = props;
	const [rowLimit, setRowLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [searchString, setSearchString] = useState('');
	const pageLoading = useSelector(state => state.commonReducer.pageLoading);

	const handleRemove = (id) => {
		toggalLoader(true);
		dispatch(Actions.deleteRole(id)).then(response => {
			if (response.status === 204) {
				toast.success("Role deleted successfully");
				toggalLoader(false);
				getListData(10, 0, '');
			} else {
				toggalLoader(false);
				errorToast(response);
			}
		}).catch((response) => {
			toggalLoader(false);
			errorToast(response);
		})
	};

	const getListData = (limit, page, search) => {
		setRowLimit(limit);
		setOffset(page)
		setSearchString(search)
		dispatch(Actions.getRole(limit, page, search))
			.then(response => {
				if (response.status === 200) {
					dispatch({ type: 'GET_ROLE', payload: response.data });
					dispatchPageLoading(dispatch);
					window.scrollTo(0, 0);
				} else {
					dispatch({ type: 'GET_ROLE', payload: null });
					dispatchPageLoading(dispatch);
					errorToast(response);
				}
			}).catch((response) => {
				dispatchPageLoading(dispatch);
				errorToast(response);
			})
	};
	useEffect(() => {
		getListData(rowLimit, offset, '');
		return () => {
			dispatch({ type: 'PAGE_LOADING', payload: true });
		}
	}, []);

	return (
		<div>
			{pageLoading ?
				<MasterSkeleton length={2} type='role' />
				:
				<Grid
					container
					spacing={2}
					direction="row"
					justifyContent="center"
					alignItems="flex-start"
				>
					<Grid
						container
						item
						xs={12}
						justifyContent="flex-end"
						alignItems="flex-start"
					>
						<CheckPermission
							component={
								<Button
									variant="contained"
									className={"primaryButton"}
									startIcon={<AddIcon />}
									color="info"
									onClick={() => {
										history.push("/role/add");
									}}
								>
									Add Role
								</Button>
							}
							permission={PERMISSION.ROLE_ADD}
						/>
					</Grid>
					<Grid item xs>
						<List
							pageLimit={rowLimit}
							pageOffset={offset}
							remove={handleRemove}
							getList={getListData}
							searchText={searchString}
							{...props}
						/>
					</Grid>
				</Grid>
			}
		</div>
	);
}
