import React from "react";
import 'assets/css/allModals.css';
import 'assets/css/agency-developer-request.css';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import { useHistory } from "react-router-dom";
import 'assets/css/custom.css'

const columns = [
    { id: 'first_name', label: 'Developer Name', },
    { id: 'parent_name', label: 'Agency Name', },
    {
        id: 'parent_email',
        label: 'Agency Email',
    },
    {
        id: 'experience',
        label: 'Experience',
    },
    {
        id: 'projects',
        label: 'Projects',
    },
    {
        id: 'certifications',
        label: 'Certificates',
    },
    {
        id: 'is_new_registered',
        label: 'Status',
    },
    {
        id: 'modified',
        label: 'Requested Date',
    }
];

const DeveloperRequestTable = (props) => {
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
    });
    const classes = useStyles();
    const { requestDetail, filter, getList } = props;
    const history = useHistory();



    const handleChangePage = (_, newPage) => {
        getList(filter.rowLimit, newPage + 1, filter.search, filter.ordering, filter.agency, filter.status);
    };

    const handleChangeRowsPerPage = (event) => {
        const dataPerPage = parseInt(event.target.value, 10)
        getList(dataPerPage, 1, filter.search, filter.ordering, filter.agency, filter.status);
    };

    return (
        <Grid className="agency-developer-table">
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(requestDetail?.results && requestDetail?.results?.length > 0) ? requestDetail?.results?.map((row) => {
                                return (
                                    <TableRow hover className="cursor-pointer" role="checkbox" tabIndex={-1} key={row?.id} onClick={() => history.push(`/developer-requests/${row?.id}`, { filter })}>
                                        <TableCell>{row?.first_name + ' ' + row?.last_name}</TableCell>
                                        <TableCell>{row?.parent_name}</TableCell>
                                        <TableCell>{row?.parent_email}</TableCell>
                                        <TableCell>{row?.experience}</TableCell>
                                        <TableCell>{row?.projects}</TableCell>
                                        <TableCell>{row?.certifications}</TableCell>
                                        <TableCell className="developer_request_column"><span className={row?.is_new_registered ? "new" : "update"}>{row?.is_new_registered ? "New" : "Update"}</span></TableCell>
                                        <TableCell>{moment(row?.modified).format('DD/MM/YYYY') ?? '-'}</TableCell>
                                    </TableRow>
                                );
                            })
                                :
                                <TableRow tabIndex={-1}>
                                    <TableCell className='single-td-no-data' scope="row" padding="none" align="center" colSpan={9}>Sorry, no matching records found</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={requestDetail?.count}
                    rowsPerPage={filter?.rowLimit || 10}
                    page={filter?.offset ? filter.offset - 1 : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Grid >
    )
}

export default DeveloperRequestTable