const initialState = {
	contactUsList: [],

};

const contactUsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CONTACT_US_LIST': {
			return { ...state, contactUsList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default contactUsReducer;
