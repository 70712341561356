import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import classNames from "classnames";
import 'assets/css/admin-navbarlink.css';


const useStyles = makeStyles(styles);
export default function Header(props) {
  const classes = useStyles();

  // function makeBrand() {
  //   var name;
  //   props.routes.map(prop => {
  //     if (window.location.href.indexOf(prop.path) !== -1) {
  //       name = prop.name;
  //     }
  //     return null;
  //   });
  //   return name;
  // }
  return (
    <AppBar className={`${classes.appBar} top-main-header-detail`} elevation={4}>
      <Toolbar className={classes.container}>
        <div className={classNames(classes.flex, classes.headerMain)}>
          {/* <Button color="inherit" href="#" className={classes.title}>
            {makeBrand()}
          </Button> */}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
          {/* <AdminNavbarLinks location={props.location} history={props.history} /> */}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>

  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
