import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import MasterSkeletonForTable from 'components/MasterSkeleton/MasterSkeletonForTable';

const ManageUsersSkeleton = (props) => {
    const { classes, manageActiveTab } = props;
    return (
        <div className={`${classes} disabled-pointer-event`}>
            <CustomTabs
                headerColor="info"
                tabChange={e => e}
                activeTab={manageActiveTab.activeTab}
                tabs={[
                    {
                        tabName: <Skeleton className='bg-light' width={77} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={8} type="client" /> 
                        )
                    },
                    {
                        tabName: <Skeleton className='bg-light' width={86} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={8} type="agency" />
                        )
                    },
                    {
                        tabName: <Skeleton className='bg-light' width={123} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={8} type="freelancer" />
                        )
                    },
                ]}
            />
        </div>
    );
}

export default ManageUsersSkeleton;