import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'assets/css/skeleton.css';
import { Skeleton } from '@material-ui/lab';

export default function CertificatesSkeleton() {
    return (
        <>
            <TableContainer>
                <Table className='project_detail-table certificate_table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCellHead singleTableCell'>
                                <Skeleton width="100%" height={24} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell className='tableDataCell singleTableCell'>
                                <Skeleton width="100%" height={48} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
