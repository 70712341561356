import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';
import closeIcon from 'assets/img/icons/close-icon.svg';
import plusIcon from 'assets/img/icons/plus-icon.svg';
import { Field } from "redux-form";
import CustomInput from "components/CustomInput/CustomInput";
import DatePickerField from "components/CustomDatePicker/DatePickerField";
import DeleteProjectDialog from "components/Dialog/DeleteProjectDialog";
import { useDispatch } from "react-redux";
import * as Actions from '../../store/actions/index'
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { checkString, checkUrl } from "utils/validation";
import { Typography } from "@material-ui/core";
import CustomCertiUpload from "components/customUpload/CustomCertiUpload";
import { errorToast } from "utils/Comman";
import { LoaderContext } from "utils/LoaderContext";

const CertificationFields = ({ fields, required, formStates = {} }) => {
    const [certificateId, setCertificateId] = useState(null);
    const { toggalLoader } = useContext(LoaderContext);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const params = useParams()

    if (fields?.length === 0) fields.push();
    const removeField = (index) => {
        if (fields.get(index)?.id) {
            setOpen(true)
            setCertificateId(fields.get(index).id)
        } else {
            fields.remove(index)
        }
    }
    const handleCloseDialog = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        toggalLoader(true);
        dispatch(Actions.deleteCertificate(params.id, certificateId)).then(response => {
            toggalLoader(false);
            if (response.status === 204) {
                toast.success('Certificate deleted Successfully')
                setOpen(false);
                dispatch(Actions.getFreelancerDetail(params.id, 'certifications/', false)).then(result => {
                    if (result.status === 200) {
                        toggalLoader(false);
                        dispatch({ type: 'GET_CERTIFICATE', payload: result.data.results });
                    } else {
                        toggalLoader(false);
                        dispatch({ type: 'certificateList', payload: null });
                    }
                });
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            errorToast(response);
        })
    }
  
    return (
        <>
            {fields && fields.length>0 &&  fields?.map((name, index) => (
                <Grid className="work-history certificate-form" key={index}>
                    <div className="history-count">
                        <p>Certificate {index + 1}</p>
                    </div>
                    <a onClick={() => removeField(index)} className="closebtn">
                        <img src={closeIcon} alt="close" />
                    </a>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name={`${name}.title`}
                                id={`${name}.name`}
                                component={CustomInput}
                                labelText="Certificate Title*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkString]}
                                inputProps={{
                                    placeholder: "Enter company name",
                                    type: "text",
                                }}
                            />
                        </Grid>
                        <Grid className='formGrid-inner'>
                            <Grid container className="formGrid datepicker-formGrid">
                                <Grid className="formGrid-inner" item lg={12} md={12} sm={12} xs={12}>
                                    <Field
                                        name={`${name}.certification_date`}
                                        id={`${name}.date`}
                                        labelText="Certification Date*"
                                        placeholder="Select date"
                                        component={DatePickerField}
                                        inputFormat="YYYY-MM-DD"
                                        onChange={(e) => e}
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner" item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                type="file"
                                label="Certification Image*"
                                name={`${name}.certificate_file`}
                                component={CustomCertiUpload}
                                id={`${name}.certificate_file`}
                                validate={(formStates[index]?.certification_link) ? '' : required}
                            />
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', textAlign: 'center' }}>
                        <Typography>or</Typography>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid className="formGrid-inner" item lg={12} md={12} sm={12}>
                            <Field
                                name={`${name}.certification_link`}
                                id={`${name}.certification_link`}
                                labelText="Cerificate Link"
                                className='inputField'
                                component={CustomInput}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                validate={(formStates[index]?.certificate_file) ? [checkUrl] : [required, checkUrl]}
                                inputProps={{
                                    type: "text",
                                    placeholder: "Enter Link",
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
            <Link onClick={() => fields.push()} underline="none" className="addBranchBtn cursor-pointer">
                <img src={plusIcon} alt="add" /> add New
            </Link>
            <DeleteProjectDialog open={open} close={handleCloseDialog}
                message='Are you Sure you want to Delete this Certificate?'
                description='This action can’t be undo, When you delete all data of this certificate it will be erased our system.'
                backToHome={handleDelete}
            />
        </>
    );
};

export default CertificationFields;