import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import reducer from "../../../store/reducers";
import AddIcon from "@material-ui/icons/Add";
import withReducer from "../../../store/withReducer";
import { LoaderContext } from "utils/LoaderContext";
import * as Actions from "../../../store/actions/index";
import Button from "components/CustomButtons/Button";
import List from "./component/List";
import MasterForm from "components/MasterForm/MasterForm";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import { errorToast } from "utils/Comman";
import { dispatchPageLoading } from "utils/Comman";
import MasterSkeleton from "components/MasterSkeleton/MasterSkeleton";

function ProfileType() {
    const { toggalLoader } = useContext(LoaderContext);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [action, setAction] = useState("add");
    const [selectedProfileType, setSelectedProfileType] = useState(null);
    const [searchString, setSearchString] = useState('');
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);

    const dispatch = useDispatch();
    const getProfileTypeListData = (limit, page, search, ordering) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search)
        dispatch(Actions.getProfileType(limit, page, search, ordering))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: "GET_PROFILE_TYPE", payload: response.data });
                    dispatchPageLoading(dispatch);
                } else {
                    dispatch({ type: "GET_PROFILE_TYPE", payload: null });
                    dispatchPageLoading(dispatch);
                    errorToast(response);
                }
            })
            .catch((response) => {
                dispatch({ type: "GET_PROFILE_TYPE", payload: null });
                dispatchPageLoading(dispatch);
                errorToast(response);
            });
    };
    const handleCreateProfileType = (profileType) => {
        Object.keys(profileType).forEach((key) => {
            profileType[key] = profileType[key].trim();
        })
        toggalLoader(true);
        dispatch(Actions.createProfileType(profileType))
            .then((response) => {
                if (response.status === 201) {
                    toast.success('Profile Type Created Successfully');
                    toggalLoader(false);
                    getProfileTypeListData(rowLimit, offset, '');
                    setOpenFormDialog(false);
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    const handleUpdateProfileType = (profileType) => {
        const payload = {
            title: profileType.title.trim(),
            description: profileType.description.trim(),
            status: profileType.status
        }
        toggalLoader(true);
        dispatch(Actions.editProfileType(selectedProfileType.id, payload))
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Profile Type Updated Successfully');
                    toggalLoader(false);
                    getProfileTypeListData(rowLimit, offset, '');
                    handleCloseFormDialog();
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    const handleRemoveProfileType = (profileTypeId) => {
        toggalLoader(true);
        dispatch(Actions.deleteProfileType(profileTypeId))
            .then((response) => {
                if (response.status === 204) {
                    toast.success("Profile Type delete successfully");
                    toggalLoader(false);
                    getProfileTypeListData(10, 0, '');
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    const handleOpenDialog = (data) => {
        dispatch(Actions.getProfileTypeDetail(data.profileType))
            .then((response) => {
                if (response.status === 200) {
                    response.data.status = (response.data.status).toString();
                    setSelectedProfileType(response.data);
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
        setAction(data.action);
        setOpenFormDialog(true);
    };
    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setSelectedProfileType(null);
        setAction("add");
    };
    useEffect(() => {
        getProfileTypeListData(rowLimit, offset, '', '');
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
        }
    }, []);

    return (
        <div>
            {pageLoading ?
                <MasterSkeleton length={4} type='profileType' />
                :
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        <CheckPermission
                            component={
                                <Button
                                    variant="contained"
                                    className={"primaryButton"}
                                    startIcon={<AddIcon />}
                                    color="info"
                                    onClick={() => {
                                        setOpenFormDialog(true);
                                    }}
                                >
                                    Add Profile Type
                                </Button>
                            }
                            permission={PERMISSION.MASTER_PROFILE_TYPE_ADD}
                        />
                    </Grid>
                    <Grid item xs>
                        <List
                            pageLimit={rowLimit}
                            pageOffset={offset}
                            removeProfileType={handleRemoveProfileType}
                            handleOpenDialog={handleOpenDialog}
                            getList={getProfileTypeListData}
                            searchText={searchString}
                        />
                    </Grid>
                </Grid>
            }
            {openFormDialog &&
                <MasterForm
                    action={action}
                    initialValues={action == "add" ? { status: "1" } : selectedProfileType}
                    message={
                        action === "add" ? "Create a new profile type" : "Edit profile type"
                    }
                    open={openFormDialog}
                    handleClose={handleCloseFormDialog}
                    onSubmit={(values) => {
                        action === "add"
                            ? handleCreateProfileType(values)
                            : handleUpdateProfileType(values);
                    }}
                />
            }
        </div>
    );
}
export default withReducer("", reducer)(ProfileType);
