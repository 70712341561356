import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

export const renderRadioGroup = ({ input, ...rest }) => (
    <RadioGroup
        {...input}
        {...rest}
        valueselected={input.value}
        onChange={(event, value) => input.onChange(value)}
    />
);
export const renderSelectField = ({
    input,
    label,
    name,
    meta: { touched, error },
    children,
    required,
    ...custom
}) => (
    <FormControl
        style={{ width: '100%' }}
    >
        <InputLabel required={required} htmlFor="selection">
            {label}
        </InputLabel>
        <Select
            {...input}
            {...custom}
            inputProps={{
                name: `${name}`
            }}
        >
            {children}
        </Select>
        {touched && error && <FormHelperText>{touched && error}</FormHelperText>}
    </FormControl>
);
export const renderSwitch = ({ input, label, checked }) => {
    return (
        <FormControlLabel
            control={
                <Switch name="status"
                    color="#00acc1"
                    checked={checked}
                    {...input} />
            }
            label={label}
        />
    );
}