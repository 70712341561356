import React from 'react';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import { Skeleton } from '@material-ui/lab';
import MasterSkeletonForTable from 'components/MasterSkeleton/MasterSkeletonForTable';

const RejectedLogSkeleton = (props) => {
    const { classes, manageActiveTab } = props;
    return (
        <div className={classes}>
            <CustomTabs
                headerColor="info"
                tabChange={e => e}
                activeTab={manageActiveTab.activeTab}
                tabs={[
                    {
                        tabName: <Skeleton className='bg-light' width={77} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={5} type="client" />
                        )
                    },
                    {
                        tabName: <Skeleton className='bg-light' width={86} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={5} type="agency" />
                        )
                    },
                    {
                        tabName: <Skeleton className='bg-light' width={123} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={5} type="freelancer" />
                        )
                    },
                    {
                        tabName: <Skeleton className='bg-light' width={202} />,
                        tabPermission: true,
                        tabContent: (
                            <MasterSkeletonForTable length={5} type="agencydeveloper" />
                        )
                    },
                ]}
            />
        </div>
    );
}

export default RejectedLogSkeleton;