import Grid from '@material-ui/core/Grid';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import 'assets/css/skeleton.css';
import { Skeleton } from '@material-ui/lab';

function ClientDetailViewSkeleton(props) {
    const { classes } = props;
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <Skeleton className='innerBackBtn transform-scale-1 bg-dark m-t-5' width={140} height={42} />
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='rejection-container'>
                        <Typography variant='h5' component='h5' className='title'>
                            <Skeleton width='100%' height={26} />
                        </Typography>
                        <div className='rejection-container-inner bg-dark skeleton-b-l'>
                            <Typography className='admin-name'>
                                <Skeleton width='100%' height={23} />
                            </Typography>
                            <Typography className='reason-content'>
                                <Skeleton width='100%' height={22} />
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={9} lg={10} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Skeleton variant="circle" width={224} height={224}>
                            <Avatar alt="profile-img" className={classes.profileImg} />
                        </Skeleton>
                        <div className={classes.profileContent}>
                            <Typography variant='h4'><Skeleton width={195} height={34} /></Typography>
                            <Typography variant='p' component='p' className={classes.clientId}><Skeleton width={195} height={23} /></Typography>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={70} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={38} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={118} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={60} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={8} >
                    <Grid className='profile-inner my_profile-inner'>
                        <Grid className='add_profile-form'>
                            <Grid container className='formGrid'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid style={{ width: '100%', }}>
                                            <label className="website-label"><Skeleton width={60} height={15} /> </label>
                                            <Skeleton width={955} height={25} />
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container className='formGrid'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label className="idProof-label">
                                                <Skeleton width={60} height={15} />
                                            </label>
                                            <Typography variant='p' component='p' className='idProof-name'>
                                                <Skeleton className='bg-dark' variant="circle" width={40} hight={40} />
                                                <Skeleton className='bg-dark' width={100} height={20} />
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider variant="middle" style={{ margin: '24px 0', }} />
                            <Typography variant='h5' component='h5' className='title'>
                                <Skeleton width={156} height={25} />
                            </Typography>
                            <Grid container className='formGrid'>
                                <Grid className='formGrid-inner'>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label><Skeleton width={60} height={15} /></label>
                                            <Typography variant='p' component='p' className='value'>
                                                <Skeleton width={300} height={25} />
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4}>
                    <div className={classes.innerContainer}>
                        <Grid className='moreDetails'>
                            <List className='moreDetails-list'>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default ClientDetailViewSkeleton