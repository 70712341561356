import apiService from "api/ApiService";

export function getSubscriptionList(limit, offset, search, ordering, type) {
  return () =>
    apiService.get(
      `subscription-plan/?user_type=${type}&page_size=${limit}&page=${
        offset + 1
      }&search=${search}&ordering=${ordering}`
    );
}

export function createSubscription(params) {
  return () => apiService.post("subscription-plan/", params);
}

export function getSubscriptionDetails(params) {
  return () => apiService.get(`subscription-plan/${params}/`);
}

export function updateSubscription(id, params) {
  return () => apiService.patch(`subscription-plan/${id}/`, params);
}

export function getRecurringList() {
  return () => apiService.get(`recurring/`);
}

export function getCurrencyList() {
  return () => apiService.get(`currency/`);
}
