import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import React, { useState } from 'react'

export const SpliceList = (props) => {
    const {data} = props;
    const [open, setOpen] = useState(false);
    if (Array.isArray(data) && data.length > 0) {
        let wholeDataArray = [];
        let spliceDataArray = [];
        const tech = [...data];
        if (tech.length > 5) {
            const technology = tech || [];
            wholeDataArray = technology?.splice(0, 5);
            spliceDataArray = technology;
        } else {
        wholeDataArray = [...data]
        }
        const onCounterClick = () => {
            setOpen(true);
        }
        return (
            <>
                {wholeDataArray && wholeDataArray?.map((element) => (
                    <ListItem className='categoryList-item' key={element.id}>
                        <ListItemText primary={element.title} />
                    </ListItem>
                ))}
                
                {open === false && spliceDataArray?.length >= 1 ?
                    <ListItem className='subcategoryList-item more-subcategory cursor-pointer' onClick={onCounterClick} >
                        <ListItemText primary={'+' + spliceDataArray?.length} />
                    </ListItem>
                    :
                    spliceDataArray && spliceDataArray?.map((element) => (
                        <ListItem className='categoryList-item' key={element.id}>
                            <ListItemText primary={element.title} />
                        </ListItem>
                    ))}
            </>
        )
    }
    return <Typography variant='p' component='p' className='value'>-</Typography>
}