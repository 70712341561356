const initialState = {
	skillList: [],
	subSkillList:[],
};

const categoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_SKILL': {
			return { ...state, skillList: action.payload };
		}
		case 'GET_SUB_SKILL': {
			return { ...state, subSkillList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default categoryReducer;
