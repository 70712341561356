import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import MasterTable from "components/MasterTable/MasterTable";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CheckPermission from "components/Hoc/CheckPermission";
import * as PERMISSION from "utils/permissionConstant";
import { canHaveAnyPermissions } from "utils/auth";

const List = (props) => {
    const { list, getList, pageLimit, remove, pageOffset, history, searchText } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [id, setId] = useState('');
    const [fileEl, setFileEl] = useState(null);
    const [fileElIndex, setFileElIndex] = useState(0);
    const tableName = 'Role';
    const user = useSelector((state) => state.authReducer?.userDetails);
    const actionPermissions = [PERMISSION.ROLE_EDIT, PERMISSION.ROLE_DELETE];

    const handleEditData = (value) => {
        history.push(`/role/edit/${value}`);
    };
    const handleConfirmDialog = (value) => {
        setFileEl(null);
        setOpenDialog(true);
        setId(value);
    };
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        remove(id)
    };
    const handleClose = () => {
        setOpenDialog(false);
        setId(null);
    }
    const handleFileELClick = (event, i) => {
        setFileEl(event.currentTarget);
        setFileElIndex(i)
    };
    const handleCloseFileEL = () => {
        setFileEl(null);
    }
    const columns = [
        {
            name: "name",
            label: "Role Name",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                display: canHaveAnyPermissions(actionPermissions, user?.user_permissions),
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-owns={fileEl ? "long-menu" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleFileELClick(e, value)}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={fileEl}
                                open={Boolean(fileEl) && fileElIndex === value}
                                onClose={handleCloseFileEL}>
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleEditData(value)}>Edit Role</MenuItem>}
                                    permission={PERMISSION.ROLE_EDIT} />
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleConfirmDialog(value)}>Delete Role</MenuItem>}
                                    permission={PERMISSION.ROLE_DELETE} />
                            </Menu>
                        </>
                    )
                }
            }
        },
    ]

    return (
        <div>
            <MasterTable
                list={list}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
            <ConfirmDialog open={openDialog} close={handleClose}
                save={handleConfirmDelete}
                title="Alert" alertMessage=" Are you sure you want to Delete Role" />
        </div>
    )
}
const mapStateToProps = (state) => {
    return { list: state.roleReducer.roleList }
}
export default connect(mapStateToProps, '')(List);
