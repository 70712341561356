import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ProjectDetailDialog from 'components/Dialog/ProjectDetailDialog';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import linkIcon from 'assets/img/icons/link-icon.svg';
import * as Actions from '../../../../../../store/actions/index';
import 'assets/css/projectCard.css';
import Tooltip from "@material-ui/core/Tooltip";
import { SpliceList } from 'components/SpliceList/SpliceList';

export default function Projects(props) {
    const { detail } = props;
    const projectDetail = useSelector((state) => (state.manageUsersReducer.projectList))
    const [dialogDetail, setDialogDetail] = useState({});
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const pageSize = 10;

    const handleClickOpen = (project) => {
        setDialogDetail(project)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handlePageChange = (page) => {
        dispatch(Actions.getFreelancerDetail(detail?.user?.id, 'projects/', true, page)).then(response => {
            if (response.status === 200) {
                //window.scrollTo(0, 0);
                dispatch({ type: 'GET_PROJECT', payload: response?.data });
            } else {
                dispatch({ type: 'GET_PROJECT', payload: null });
            }
        });
    }
    return (
        <>
            {projectDetail?.results && projectDetail?.results?.length > 0 ?
                <Grid container className="projectsList">
                    {projectDetail?.results?.map((project) => (
                        <Grid item className="projectListItem" key={project.id}>
                            <Card className="project-card" >
                                <CardContent>
                                    <Grid className="project-top">
                                        <div className="icon-container">
                                            <Typography variant='p' component='p'>{project?.title?.substring(0, 2).toUpperCase()}</Typography>
                                        </div>
                                        <div className="project-title">
                                            <Typography variant='p' component='p' className="name cursor-pointer" onClick={() => handleClickOpen(project)}>{project.title}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid className='skillset'>
                                        <Typography variant='p' component='p' className='profile-subtitle'>Used Technologies</Typography>
                                        {project?.technologies?.length > 0 ?
                                            <List className="categoryList">
                                                <SpliceList data={project?.technologies} />
                                            </List> : '-'
                                        }
                                    </Grid>
                                    <Grid className='skillset'>
                                        <Typography variant='p' component='p' className='profile-subtitle'>Used Skills</Typography>
                                        {project?.skills?.length > 0 ?
                                            <List className="categoryList">
                                                <SpliceList data={project?.skills} />
                                            </List> : '-'
                                        }
                                    </Grid>
                                    <Grid className='duration-details'>
                                        <Typography>
                                            {(project?.duration > 0) &&
                                                <>
                                                    <span>{project?.duration} Months</span>
                                                    {project?.is_on_going && <span> - Ongoing</span>}
                                                </>
                                            }
                                        </Typography>
                                        {project?.link &&
                                            <Tooltip title="Project Link">
                                                <a href={project?.link} target="blank">
                                                    <img src={linkIcon} alt="img" />
                                                </a>
                                            </Tooltip>
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <PaginationRounded
                            totalPages={Math.ceil(projectDetail?.count / pageSize) || 0}
                            handlePages={(page) => handlePageChange(page)}
                        />
                    </Grid>
                </Grid> : <Typography variant='p' component='p'>No Records Found</Typography>}
            <ProjectDetailDialog open={open} detail={dialogDetail} close={() => handleClose()}
            />
        </>
    )
}
