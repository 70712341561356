const initialState = {
	experienceList: []
};

const experienceReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_EXPERIENCE': {
			return { ...state, experienceList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default experienceReducer;
