import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import MasterTable from "components/MasterTable/MasterTable";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useHistory } from "react-router-dom";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import { canHaveAnyPermissions } from "utils/auth";
import * as Actions from "../../../../store/actions/index";

function List(props) {

    const history = useHistory();
    const { skillList, getList, pageLimit, removeSkill, pageOffset, searchText } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [skillId, setSkillId] = useState('');
    const [fileEl, setFileEl] = useState(null);
    const [fileElIndex, setFileElIndex] = useState(0);
    const tableName = 'Skill';
    const dispatch = useDispatch();
    const user = useSelector(state => state.authReducer?.userDetails);
    const actionPermissions = [PERMISSION.MASTER_SKILL_EDIT, PERMISSION.MASTER_SKILL_DELETE];

    const handleConfirmDialog = (value) => {
        setOpenDialog(true);
        setSkillId(value);
    };
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        removeSkill(skillId)
    };
    const handleClose = () => {
        setOpenDialog(false);
        setSkillId(null);
    }
    const handleFileELClick = (event, i) => {
        setFileEl(event.currentTarget);
        setFileElIndex(i)
    };
    const handleCloseFileEL = () => {
        setFileEl(null);
    }
    const handleDelete = (value) => {
        handleConfirmDialog(value);
        setFileEl(null);
    }

    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
            } else {
                dispatch({ type: 'GET_USER_DETAILS', payload: '' });
            }
        });
    }

    useEffect(() => {
        getUserDetails();
    }, [])

    const columns = [
        {
            name: "title",
            label: "Title",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ?
                        <ReadMoreReadLess
                            charLimit={100}
                            readMoreText={"Read More"}
                            readLessText={"Read Less"}
                            readMoreClassName="read-moreLink"
                            readLessClassName="read-moreLink"
                            children={value || ''} //eslint-disable-line
                            textType={true}
                        /> : '-'
                },
                setCellProps: () => ({
                    style: {
                        maxWidth: "350px",
                        wordBreak: "break-word",
                    }
                }),
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value === 1) ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>
                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                display: canHaveAnyPermissions(actionPermissions, user?.user_permissions),
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-owns={fileEl ? "long-menu" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleFileELClick(e, value)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={fileEl}
                                open={Boolean(fileEl) && fileElIndex === value}
                                onClose={handleCloseFileEL}
                            >
                                <CheckPermission
                                    component={<MenuItem onClick={() => {
                                        history.push({ pathname: "/skill/" + value });
                                    }}>Edit Skill</MenuItem>}
                                    permission={PERMISSION.MASTER_SKILL_EDIT} />
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleDelete(value)}>Delete Skill</MenuItem>}
                                    permission={PERMISSION.MASTER_SKILL_DELETE} />
                            </Menu>
                        </>
                    )
                }
            }
        },
    ]

    return (
        <>
            <MasterTable
                list={skillList}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
            <ConfirmDialog open={openDialog} close={handleClose}
                save={handleConfirmDelete}
                title="Alert" alertMessage="Are you sure you want to delete Skill" />
        </>
    )
}
const mapStateToProps = (state) => {
    return { skillList: state.skillReducer.skillList }
}
export default connect(mapStateToProps, '')(List);
