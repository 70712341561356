import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import Avatar from '@material-ui/core/Avatar';
import ProfessionalDetails from "./component/ProfessionalDetails";
import Projects from "./component/Projects";
// import WorkExperience from "./component/WorkExperience";
import Certificates from "./component/Certificates";
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../../../store/actions/index';
import CustomButton from "components/CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getUrl } from "utils/constant";
import { getAvailability, errorToast } from "utils/Comman";
import ImagePreviewDialog from "components/Dialog/ImagePreviewDialog";
import { findAmountPerType } from "utils/Comman";

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileImgApproval: {
        border: '6px solid rgba(255, 158, 45, 0.4)',
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 120px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        }
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
        }
    },
    formGridInner: {
        width: '48%',
    },

});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function FreelancerDetail(props) {
    const userDetail = useSelector((state) => (state.manageUsersReducer.portFolio));
    const [openPreview, setOpenPreview] = useState(false);
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const { classes } = props;
    const params = useParams();

    const handleChange = (event, newValue) => {
        const currentTab = getUrl(newValue);
        getTabDetails(currentTab)
        setValue(newValue);
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    const getTabDetails = (currentTab) => {
        dispatch(Actions.getFreelancerDetail(params.id, currentTab?.url, false)).then(response => {
            if (response.status === 200) {
                dispatch({ type: currentTab.type, payload: response?.data });
            } else {
                dispatch({ type: currentTab.type, payload: null });
                errorToast(response);
            }
        });
    }
    useEffect(() => {
        getTabDetails(getUrl(0));
    }, []);
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <CustomButton
                    variant="contained"
                    className="primaryButton innerBackBtn"
                    startIcon={<ArrowBackIosIcon />}
                    color="info"
                    onClick={() => {
                        history.push("/manage-users");
                        dispatch({ type: 'GET_PORTFOLIO', payload: null });
                    }}
                >
                    Back
                </CustomButton>
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={9} lg={10} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Avatar alt="profile-img" onClick={() => handleOpenPreview()} src={userDetail?.profile_image?.url ?? userDetail?.profile_image} className={classes.profileImg} />
                        <div className={classes.profileContent}>
                            <Typography variant='h4'>{
                                userDetail?.first_name ?
                                    `${userDetail?.first_name} ${userDetail?.last_name}` : '-'
                            }</Typography>
                            <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{userDetail?.uid}</span></Typography>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Full Name
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        userDetail?.first_name ?
                                            `${userDetail?.first_name} ${userDetail?.last_name}` : '-'
                                    }</Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Email
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        userDetail?.email ?
                                            userDetail?.email : '-'
                                    }</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Contact Number
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        userDetail?.contact_number ?
                                            userDetail?.contact_number : '-'
                                    }</Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Location
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        userDetail?.city?.display_name ?
                                            userDetail?.city?.display_name : '-'
                                    }</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid className="profile-container my_profile-container">
                <Grid container spacing={3} className="freelancer_profile-details">
                    <Grid item lg={8} md={8} sm={12}>
                        <Grid className="profile-inner my_profile-inner">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    label="Professional Details"
                                    {...a11yProps(0)}
                                    className="tab-title"
                                />
                                <Tab
                                    label="Projects"
                                    {...a11yProps(1)}
                                    className="tab-title"
                                />
                                {/* <Tab
                                    label="Work Experience"
                                    {...a11yProps(2)}
                                    className="tab-title"
                                /> */}
                                <Tab
                                    label="Certificates"
                                    {...a11yProps(2)}
                                    className="tab-title"
                                />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <ProfessionalDetails />
                            </TabPanel>
                            <TabPanel value={value} index={1} className="projects">
                                <Projects />
                            </TabPanel>
                            {/* <TabPanel value={value} index={2}>
                                <WorkExperience />
                            </TabPanel> */}
                            <TabPanel value={value} index={2}>
                                <Certificates />
                            </TabPanel>
                        </Grid>
                    </Grid>
                    <Grid item lg={4}>
                        <div className={classes.innerContainer}>
                            <Grid className='moreDetails'>
                                <List className='moreDetails-list'>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Experience
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {userDetail?.portfolio?.experience ?
                                                `${userDetail?.portfolio?.experience} Years` : 0
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Projects <span></span>
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {userDetail?.portfolio?.projects_count ?
                                                userDetail?.portfolio?.projects_count : 0
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Availability
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {userDetail?.portfolio?.availability ?
                                                getAvailability?.map((check) => {
                                                    if (check.id === userDetail?.portfolio?.availability) {
                                                        return check.name
                                                    }
                                                }) : '-'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Education
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {userDetail?.portfolio?.education_details ?
                                                userDetail?.portfolio?.education_details : '-'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Profile Type
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {userDetail?.portfolio?.profile_type?.title ?
                                                userDetail?.portfolio?.profile_type?.title : '-'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Price
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {findAmountPerType(userDetail?.portfolio?.amount, userDetail?.portfolio?.salary_type) || '-'}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {userDetail?.profile_image &&
                <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={userDetail?.profile_image?.url ?? userDetail?.profile_image} />
            }
        </>
    );
}

export default withStyles(styles)(FreelancerDetail);
