import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { Field, reduxForm } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput'
import Button from "components/CustomButtons/Button.js";
import { STATUS } from 'utils/constant';
import { renderRadioGroup } from 'utils/formComponent';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import { checkString, required, normalizeCharacter } from 'utils/validation';

const useStyles = makeStyles(() => ({
    radio: {
        '&$checked': {
            color: '#548CA8'
        }
    },
    checked: {}
}));

function SkillDetailForm(props) {

    const classes = useStyles();
    const { handleSubmit } = props

    return (
        <Paper elevation={4} style={{ padding: 20 }}>
            <Grid container justifyContent="flex-start" alignItems='flex-start'>
                <Grid item xs={12}>
                    <Typography variant='h6'>EDIT SKILL</Typography>
                </Grid>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner' item xs={12}>
                                <Field
                                    name="title"
                                    component={CustomInput}
                                    id="title"
                                    labelText="Title*"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    required
                                    validate={[required, checkString]}
                                    inputProps={{
                                        type: "text"
                                    }}
                                    normalize={normalizeCharacter(100)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner description' item xs={12}>
                                <Field
                                    name="description"
                                    component={CustomTextArea}
                                    id="description"
                                    labelText="Description"
                                    normalize={normalizeCharacter(500)}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    rows='3'
                                    maxRows='5'
                                    multiline
                                    validate={[]}
                                    inputProps={{
                                        placeholder: "Enter Description",
                                        type: "text",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <FormControl component="fieldset">
                            <FormLabel className='status'>Status</FormLabel>
                            <Field name="status" row
                                component={renderRadioGroup}
                                id="status"
                                required
                                validate={[required]}
                            >
                                {STATUS.map((radio, index) => (
                                    <FormControlLabel key={index} value={radio.value} control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />} label={radio.label} />
                                ))}
                            </Field>
                        </FormControl>
                        <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end">
                            <Button round type="submit" color="info">
                                Update
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Paper >
    )
}
const ReduxFormMapped = reduxForm({
    form: 'CreateSubSkillForm', // a unique identifier for this form
    enableReinitialize: true,
})(SkillDetailForm)
export default (ReduxFormMapped);
