import React, { useEffect, useState } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
// import Person from "@material-ui/icons/Person";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import * as Actions from '../../store/actions/index'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import notification from "assets/img/icons/notification.svg";
import userIcon from "assets/img/default-avatar.png";
import Avatar from "@material-ui/core/Avatar";
import NotificationDrawer from "./NotificationDrawer";
import Badge from "@material-ui/core/Badge";
// import NotificationDrawer from '@material-ui/core/Drawer';

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const history = useHistory();
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const [drawerState, setDrawerState] = useState(false);
  const dispatch = useDispatch();
  const profileImageDetails = useSelector((state) => state.authReducer?.userDetails);
  const notificationData = useSelector(state => state.commonReducer?.notifications);
  const path = window.location.pathname;

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    history.push('/user-profile');
    setOpenProfile(null);
  };
  const handleLogoutProfile = () => {
    dispatch(Actions.logoutUser()).then(() => {
      setOpenProfile(null);
      dispatch({ type: 'LOGOUT_USER', payload: null });
      window.location.assign("/login");
      localStorage.clear();
    });
  };

  const getNotifications = () => {
    dispatch(Actions.getNotifications());
  }

  const getUserProfileImage = () => {
    dispatch(Actions.getUserDetails()).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
      }
    });
  }
  const toggleDrawer = (open) => {
    setDrawerState(open);
  };
  useEffect(() => {
    getUserProfileImage();
  }, [])

  useEffect(() => {
    getNotifications();
    return () => {
      if (history.location) {
        history.replace({ ...history.location, prevPath: path });
      }
    }
  }, [path]);

  return (
    <div>
      <div className={classes.manager}>
        <Button
          onClick={() => toggleDrawer(true)}
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          // aria-owns={openProfile ? "profile-menu-list-grow" : null}
          // aria-haspopup="true"
          // onClick={handleClickProfile}
          className='admin-bellbtn'
        >
          {/* <Person className={classes.icons} /> */}
          {notificationData?.unread !== 0 ?
            <Badge className="bell-badge" badgeContent={notificationData?.unread}>
              <img src={notification} className={classes.icons} alt="img" />
            </Badge>
            :
            <img src={notification} className={classes.icons} alt="img" />
          }

          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Notification</p>
          </Hidden>
        </Button>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          {/* <Person className={classes.icons} /> */}
          <Avatar src={profileImageDetails?.profile_image ?? userIcon} className={classNames(classes.icons) + classes.header_icon_user_profile_img} alt="" />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => { setOpenProfile(null) }}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogoutProfile}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <NotificationDrawer open={drawerState} close={() => toggleDrawer(false)} />
    </div>

  );
}
