import React, { useContext, useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import Avatar from '@material-ui/core/Avatar';
import CustomButton from "components/CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useParams } from "react-router-dom";
import Projects from "./component/Projects";
// import WorkExperience from "./component/WorkExperience";
import Certificates from "./component/Certificates";
import ProfessionalDetails from "./component/ProfessionalDetails";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../../../store/actions/index';
import { getUrl } from "utils/constant";
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import MessageDialog from 'components/Dialog/MessageDialog';
import { hasPermission } from 'utils/auth';
import * as PERMISSION from "utils/permissionConstant";
import { getAvailability, checkApproval, errorToast } from "utils/Comman";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import ImagePreviewDialog from "components/Dialog/ImagePreviewDialog";
import { findAmountPerType } from "utils/Comman";

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileImgApproval: {
        border: '6px solid rgba(255, 158, 45, 0.4)',
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 120px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        }
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
        }
    },
    formGridInner: {
        width: '48%',
    },

});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function FreelancerDetailView(props) {
    const requestDetail = useSelector((state) => (state.userRequestReducer.freelancerUpdateRequestDetail));
    const FreelancerDetail = useSelector((state) => (state.manageUsersReducer.portFolio));
    const user = useSelector(state => state.authReducer?.userDetails);
    const [openPreview, setOpenPreview] = useState(false);
    const { toggalLoader } = useContext(LoaderContext);
    const { classes } = props;
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory()

    const handleChange = (event, newValue) => {
        const currentTab = getUrl(newValue);
        getTabDetails(currentTab, requestDetail?.user?.id)
        setValue(newValue);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    const getTabDetails = (tab, id) => {
        dispatch(Actions.getFreelancerDetail(id, tab?.url, true)).then(response => {
            if (response.status === 200) {
                dispatch({ type: tab.type, payload: response.data });
            } else {
                dispatch({ type: tab.type, payload: null });
            }
        });
    }
    const getRequestDetail = () => {
        toggalLoader(true);
        dispatch(Actions.getRequestDetail(params.id))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: 'FREELANCER_UPDATE_REQUEST_DETAIL', payload: response.data });
                    getTabDetails(getUrl(0), response?.data?.user?.id);
                    toggalLoader(false);
                } else {
                    dispatch({ type: 'FREELANCER_UPDATE_REQUEST_DETAIL', payload: null });
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    const handleChangeStatus = (data) => {
        toggalLoader(true);
        setButtonLoading(true);
        dispatch(Actions.updateRequestStaus(requestDetail.id, data))
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.status === 3) {
                        toast.success("Profile rejected successfully");
                    } else {
                        toast.success("Profile approved successfully");
                    }
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: response.data });
                    toggalLoader(false);
                    setButtonLoading(false);
                    history.push('/updated-request');
                } else {
                    toggalLoader(false);
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: null });
                    setButtonLoading(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                setButtonLoading(false);
                errorToast(response);
            });
    }
    const checkProfileApproval = (profileReqKey, profilekey) => {
        const index = profileReqKey?.indexOf(profilekey);
        if (index === -1) {
            return classes.profileImg
        } else {
            return classes.profileImgApproval
        }
    }
    useEffect(() => {
        getRequestDetail();
    }, []);
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <CustomButton
                    variant="contained"
                    className="primaryButton innerBackBtn"
                    startIcon={<ArrowBackIosIcon />}
                    color="info"
                    onClick={() => {
                        dispatch({ type: "GET_PORTFOLIO", payload: null });
                        dispatch({ type: 'FREELANCER_UPDATE_REQUEST_DETAIL', payload: null });
                        history.push("/updated-request");
                    }}
                >
                    Back
                </CustomButton>
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={9} lg={10} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Avatar alt="profile-img"
                            onClick={() => handleOpenPreview()}
                            src={FreelancerDetail?.profile_image?.url ?? FreelancerDetail?.profile_image}
                            className={checkProfileApproval(requestDetail?.requested_changes_keys, 'profile_image')}
                        />
                        <div className={classes.profileContent}>
                            <Typography variant='h4'>{
                                FreelancerDetail?.first_name ?
                                    `${FreelancerDetail?.first_name} ${FreelancerDetail?.last_name}` : '-'
                            }</Typography>
                            <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{FreelancerDetail?.uid}</span></Typography>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Full Name
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        FreelancerDetail?.first_name ?
                                            `${FreelancerDetail?.first_name} ${FreelancerDetail?.last_name}` : '-'
                                    }</Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Email
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        FreelancerDetail?.email ?
                                            FreelancerDetail?.email : '-'
                                    }</Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Contact Number
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        FreelancerDetail?.contact_number ?
                                            FreelancerDetail?.contact_number : '-'
                                    }</Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        Location
                                    </label>
                                    <Typography variant='p' component='p'>{
                                        FreelancerDetail?.city?.display_name ?
                                            FreelancerDetail?.city?.display_name : '-'
                                    }</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid className="profile-container my_profile-container">
                <Grid container spacing={3} className="freelancer_profile-details">
                    <Grid item lg={8} md={8} sm={12}>
                        <Grid className="profile-inner my_profile-inner">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    label={
                                        <>
                                            ProfessionalDetails
                                            {checkApproval(requestDetail?.requested_changes_keys, 'portfolio')}
                                        </>
                                    }
                                    {...a11yProps(0)}
                                    className="tab-title"
                                />
                                <Tab
                                    label={
                                        <>
                                            Projects
                                            {checkApproval(requestDetail?.requested_changes_keys, 'projects')}
                                        </>
                                    }
                                    {...a11yProps(1)}
                                    className="tab-title"

                                />
                                {/* <Tab
                                    label={
                                        <>
                                            Work Experience
                                            {checkApproval(userDetail?.requested_changes_keys, 'work_experiences')}
                                        </>
                                    }
                                    {...a11yProps(2)}
                                    className="tab-title"
                                /> */}
                                <Tab
                                    label={
                                        <>
                                            Certificates
                                            {checkApproval(requestDetail?.requested_changes_keys, 'certifications')}
                                        </>
                                    }
                                    {...a11yProps(2)}
                                    className="tab-title"
                                />
                            </Tabs>
                            <TabPanel value={value} index={0} >
                                <ProfessionalDetails detail={requestDetail} />
                            </TabPanel>
                            <TabPanel value={value} index={1} className="projects">
                                <Projects detail={requestDetail} />
                            </TabPanel>
                            {/* <TabPanel value={value} index={2}>
                                <WorkExperience />
                            </TabPanel> */}
                            <TabPanel value={value} index={2}>
                                <Certificates detail={requestDetail} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                    <Grid item lg={4}>
                        <div className={classes.innerContainer}>
                            <Grid className='moreDetails'>
                                <List className='moreDetails-list'>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Experience {checkApproval(requestDetail?.requested_changes_keys, 'experience')}
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {FreelancerDetail?.portfolio?.experience ?
                                                `${FreelancerDetail?.portfolio?.experience} Years` : 0
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Projects  {checkApproval(requestDetail?.requested_changes_keys, 'project_count')} <span></span>
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {FreelancerDetail?.portfolio?.projects_count ?
                                                FreelancerDetail?.portfolio?.projects_count : 0
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Availability {checkApproval(requestDetail?.requested_changes_keys, 'availability')}
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {FreelancerDetail?.portfolio?.availability ?
                                                getAvailability.map((check) => {
                                                    if (check.id === FreelancerDetail?.portfolio?.availability) {
                                                        return check.name
                                                    }
                                                }) : '-'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Education {checkApproval(requestDetail?.requested_changes_keys, 'education_details')}
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {FreelancerDetail?.portfolio?.education_details ?
                                                FreelancerDetail?.portfolio?.education_details : '-'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Profile Type {checkApproval(requestDetail?.requested_changes_keys, 'profile_type')}
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {FreelancerDetail?.portfolio?.profile_type?.title ?
                                                FreelancerDetail?.portfolio?.profile_type?.title : '-'
                                            }
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            Price {checkApproval(requestDetail?.requested_changes_keys, 'salary_type') || checkApproval(requestDetail?.requested_changes_keys, 'amount')}
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            {findAmountPerType(FreelancerDetail?.portfolio?.amount, FreelancerDetail?.portfolio?.salary_type) || '-'}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className='action-div'>
                {hasPermission(PERMISSION.DELETE_USER_REQUEST, user?.user_permissions) &&
                    <Link underline="none" className='previousBtn' onClick={() => handleClickOpen()}>Reject</Link>
                }
                {hasPermission(PERMISSION.CHANGE_USER_REQUEST, user?.user_permissions) &&
                    <Button variant="contained" className='primaryButton nextBtn' disabled={buttonLoading} onClick={() => handleChangeStatus({ status: 2 })} >Approve</Button >
                }
            </Grid>
            <MessageDialog open={open} close={handleClose} title={'Reject'} disabled={buttonLoading}
                onSubmit={(data) => {
                    handleChangeStatus({ ...data, status: 3 })
                }} />
            {FreelancerDetail?.profile_image &&
                <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={FreelancerDetail?.profile_image?.url ?? FreelancerDetail?.profile_image
                } />
            }
        </>
    );
}

export default withStyles(styles)(FreelancerDetailView);
