import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import reducer from "../../store/reducers";
import withReducer from "../../store/withReducer";
import ClientList from "./component/Client/List";
import AgencyList from "./component/Agency/List";
import FreelancerList from "./component/Freelancers/List";
import { useDispatch, useSelector } from "react-redux";
import { LoaderContext } from "utils/LoaderContext";
import * as Actions from '../../store/actions/index'
import { toast } from "react-toastify";
import CustomTabs from "components/CustomTabs/CustomTabs";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import DomainIcon from '@material-ui/icons/Domain';
import PeopleIcon from '@material-ui/icons/People';
import { getUserRequest } from "utils/constant";
import { canHaveAnyPermissions } from "utils/auth";
import * as PERMISSION from "utils/permissionConstant";
import history from "utils/history";
import { errorToast } from "utils/Comman";
import ManageUsersSkeleton from "./Skeletons/ManageUsersSkeleton";
import { dispatchPageLoading, dispatchContentLoading } from "utils/Comman";
import MasterSkeletonForTable from "components/MasterSkeleton/MasterSkeletonForTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function ManageUsers() {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const classes = useStyles();
    const [value, setValue] = useState({ tab: 3, url: "user-client/" });
    const manageActiveTab = useSelector(state => state.commonReducer.manageActiveTab);
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [searchString, setSearchString] = useState('');
    const clientView = [PERMISSION.REGISTERED_USER_CLIENTS_VIEW];
    const agencyView = [PERMISSION.REGISTERED_USER_AGENCY_VIEW];
    const freelancerView = [PERMISSION.REGISTERED_USER_FREELANCER_VIEW];
    const userDetail = useSelector(state => state.authReducer?.userDetails);
    const permissionArray = [PERMISSION.REGISTERED_USER_CLIENTS_VIEW, PERMISSION.REGISTERED_USER_AGENCY_VIEW, PERMISSION.REGISTERED_USER_FREELANCER_VIEW];

    const getUserList = (limit, page, search, user, ordering) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search);
        dispatch(Actions.getUser(user?.url, limit, page, search, ordering)).then((response) => {
            if (response.status === 200) {
                dispatch({ type: "GET_USERS", payload: response.data });
                window.scrollTo(0, 0);
                dispatchPageLoading(dispatch);
                dispatchContentLoading(dispatch);
            } else {
                dispatch({ type: "GET_USERS", payload: null });
                errorToast(response);
                dispatchPageLoading(dispatch);
                dispatchContentLoading(dispatch);
            }
        }).catch((response) => {
            dispatch({ type: "GET_USERS", payload: [] });
            errorToast(response);
            dispatchPageLoading(dispatch);
            dispatchContentLoading(dispatch);

        });
    };
    const handleDeleteClient = (clientId) => {
        toggalLoader(true);
        dispatch(Actions.deleteClient(clientId)).then(response => {
            if (response.status === 204) {
                toast.success("Client deleted successfully");
                toggalLoader(false);
                getUserList(rowLimit, offset, '', value, '')
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleDeleteAgency = (agencyId) => {
        toggalLoader(true);
        dispatch(Actions.deleteAgency(agencyId)).then(response => {
            if (response.status === 204) {
                toast.success("Agency deleted successfully");
                toggalLoader(false);
                getUserList(rowLimit, offset, '', value, '')
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleDeleteFreelancers = (categoryId) => {
        toggalLoader(true);
        dispatch(Actions.deleteFreelancer(categoryId)).then(response => {
            if (response.status === 204) {
                toast.success("Freelancer deleted successfully");
                toggalLoader(false);
                getUserList(rowLimit, offset, '', value, '')
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleChange = (event, newValue) => {
        if (manageActiveTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({ type: 'SET_ACTIVE_TAB', payload: { activeTab: newValue, } })
        setRowLimit(10)
        setOffset(0)
    };
    const handleListEvent = (page, set, search, ordering) => {
        getUserList(page, set, search, value, ordering);
    }
    useEffect(() => {
        if (userDetail?.user_permissions) {
            if (!canHaveAnyPermissions(permissionArray, userDetail?.user_permissions)) {
                history.push('/dashboard');
            } else {
                let tempValue = getUserRequest(customTab[manageActiveTab.activeTab]?.tabName);
                setValue(tempValue);
                getUserList(rowLimit, offset, '', tempValue, '');
            }
        }
    }, [manageActiveTab]);

    useEffect(() => {
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
            dispatch({ type: 'CONTENT_LOADING', payload: true });
        }
    }, [])

    let customTab = [];
    if (canHaveAnyPermissions(clientView, userDetail?.user_permissions)) {
        customTab.push({
            tabName: "Client",
            tabIcon: PeopleIcon,
            tabPermission: canHaveAnyPermissions(clientView, userDetail?.user_permissions),
            tabContent: (
                <>
                    {contentLoading ?
                        <MasterSkeletonForTable length={7} type='client' />
                        :
                        <ClientList
                            pageLimit={rowLimit}
                            getList={handleListEvent}
                            pageOffset={offset}
                            tableName="Client"
                            searchText={searchString}
                            deleteClients={handleDeleteClient}
                        />
                    }
                </>
            )
        });
    }
    if (canHaveAnyPermissions(agencyView, userDetail?.user_permissions)) {
        customTab.push({
            tabName: "Agency",
            tabIcon: DomainIcon,
            tabPermission: canHaveAnyPermissions(agencyView, userDetail?.user_permissions),
            tabContent: (
                <>
                    {contentLoading ?
                        <MasterSkeletonForTable length={8} type='agency' />
                        :
                        <AgencyList
                            pageLimit={rowLimit}
                            getList={handleListEvent}
                            pageOffset={offset}
                            tableName="Agency"
                            searchText={searchString}
                            deleteAgency={handleDeleteAgency}
                        />
                    }
                </>
            )
        });
    }
    if (canHaveAnyPermissions(freelancerView, userDetail?.user_permissions)) {
        customTab.push({
            tabName: "Freelancers",
            tabIcon: PeopleOutlineIcon,
            tabPermission: canHaveAnyPermissions(freelancerView, userDetail?.user_permissions),
            tabContent: (
                <>
                    {contentLoading ?
                        <MasterSkeletonForTable length={7} type='freelancer' />
                        :
                        <FreelancerList
                            pageLimit={rowLimit}
                            getList={handleListEvent}
                            pageOffset={offset}
                            tableName="Freelancers"
                            searchText={searchString}
                            deleteFreelancers={handleDeleteFreelancers}
                        />
                    }
                </>
            )
        });
    }

    return (
        <>
            {(() => {
                if (userDetail?.user_permissions) {
                    if (!canHaveAnyPermissions(permissionArray, userDetail?.user_permissions)) {
                        history.push('/dashboard');
                    } else {
                        return (
                            <>
                                {pageLoading ?
                                    <ManageUsersSkeleton classes={classes.root} manageActiveTab={manageActiveTab} />
                                    :
                                    <div className={classes.root}>
                                        <CustomTabs
                                            headerColor="info"
                                            tabChange={handleChange}
                                            activeTab={manageActiveTab.activeTab}
                                            tabs={customTab}
                                        />
                                    </div>
                                }
                            </>
                        )
                    }
                }
            })()}

        </>
    )
}

export default withReducer('', reducer)(ManageUsers);