import React from 'react';
import { Skeleton } from '@material-ui/lab';
import MUIDataTable from 'mui-datatables';
import 'assets/css/skeleton.css';

const MasterSkeletonForTable = (props) => {
    const { length, type } = props
    let columns = [];
    let singleData = {};
    let data = [];
    const options = {
        filter: false,
        search: true,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
        serverSide: false,
        searchText: '',
        pagination: false,
    };

    const titleWidth = {
        client: 62,
        agency: 78,
        freelancer: 117,
        newAgencyRequest: 217,
        agencydeveloper: 195,
        contactus: 114,
    }

    const searchSkeleton = () => {
        return (<Skeleton variant='circle' width={24} height={24} />)
    }

    const components = {
        icons: {
            SearchIcon: searchSkeleton
        }
    }
    for (let index = 1; index < length + 1; index++) {
        columns.push({
            name: `data${index}`,
            label: <Skeleton />,
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
            }
        });
        singleData[`data${index}`] = <Skeleton />;
        if (index === length) {
            data.push(singleData);
        }
    }
    return (
        <MUIDataTable
            className='master-table disabled-pointer-event'
            title={<Skeleton width={titleWidth[type]} height={32} />}
            data={data}
            columns={columns}
            options={options}
            components={components}
        />
    );
}

export default MasterSkeletonForTable;