import MUIDataTable from 'mui-datatables';
import React, { useState } from 'react'

let time = '';

function MasterTable(props) {
    const [orderLabel, useOrderLabel] = useState('');
    const { columns, list, pageLimit, pageOffset, getList, name, searchText, customOptions } = props;
    const options = {
        filter: false,
        search: true,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
        serverSide: true,
        count: list?.count,
        rowsPerPage: pageLimit,
        page: pageOffset,
        searchText: searchText,
        onChangePage(currentPage) {
            getList(pageLimit, currentPage, searchText, orderLabel)
        },
        onChangeRowsPerPage(numberOfRows) {
            getList(numberOfRows, 0, searchText, orderLabel)
        },
        onSearchChange(_search) {
            clearTimeout(time);
            time = setTimeout(() => {
                getList(pageLimit, 0, (_search === null) ? '' : _search, orderLabel);
            }, 1000);
        },
        onColumnSortChange: (data, direction) => {
            if (direction === 'asc') {
                useOrderLabel(data)
                getList(pageLimit, 0, searchText, data)
            }
            else if (direction === 'desc') {
                useOrderLabel(`-${data}`)
                getList(pageLimit, 0, searchText, `-${data}`)
            }
            else {
                useOrderLabel('')
                getList(pageLimit, 0, searchText, '')
            }
        },
        ...customOptions,
    };

    return (
        <>
            <MUIDataTable
                className='master-table'
                title={name}
                data={(list) ? list.results : []}
                columns={columns}
                options={options}
            />
        </>
    )
}
export default MasterTable