
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import fileIcon from 'assets/img/icons/file-icon.svg';
import clientProfileImg from "assets/img/client_profile-img.png";
import editIcon from 'assets/img/icons/edit-icon.svg';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import 'assets/css/profile.css';

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius:" 12px",
        width: '220px',
        height: '220px',
        '@media (max-width: 1279px)': {
            width: '154px',
            height: '154px',
        },
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 244px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '@media (max-width: 1279px)': {
            width: 'calc(100% - 178px)',
        },
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    },
    formGridInner: {
        width: '48%',
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '32px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            textTransform: 'capitalize',
            marginBottom: '0',
        }
    },
});

function ClientProfile(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const { classes } = props;

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return(
        <>
        <Grid container className={classes.innerContainer}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className={classes.profileDetail}>
                    <Avatar alt="profile-img" src={ clientProfileImg } className={classes.profileImg} />
                    <div className={classes.profileContent}>
                        <Grid className={classes.userInfo}>
                            <div>
                                <Typography variant='h4'>Goal Getters</Typography>
                                <Typography variant='p' component='p' className={classes.clientId}>Client ID : <span>GG58168798</span></Typography>
                            </div>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={openMenu ? 'long-menu' : undefined}
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                    className='more_action'
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleMenuClose}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: '14ch',
                                        },
                                    }}
                                >
                                    <MenuItem onClick={handleMenuClose} className='more_actionList'>
                                        <Link><img src={editIcon} style={{ width: '12px' }} alt='img' />Edit</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleMenuClose} className='more_actionList'>
                                        <Link><img src={deleteIcon} style={{ width: '12px' }} alt='img' />Delete</Link>
                                    </MenuItem>
                                </Menu>
                        </Grid>
                        <Grid className={classes.formGrid}>
                            <Grid className={classes.formGridInner}>
                                <label>
                                    Full Name
                                </label>
                                <Typography variant='p' component='p'>Thomas Madigan</Typography>
                            </Grid>
                            <Grid className={classes.formGridInner}>
                                <label>
                                    Email
                                </label>
                                <Typography variant='p' component='p'>goalgretters123@test.com</Typography>
                            </Grid>
                        </Grid>
                        <Grid className={classes.formGrid}>
                            <Grid className={classes.formGridInner}>
                                <label>
                                    Contact Number
                                </label>
                                <Typography variant='p' component='p'>+918424575478</Typography>
                            </Grid>
                            <Grid className={classes.formGridInner}>
                                <label>
                                    Location
                                </label>
                                <Typography variant='p' component='p'>New York, America</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
                <Grid className='profile-inner my_profile-inner'>
                    <Grid className='add_profile-form'>
                        <Grid container className='formGrid'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <FormControl variant="standard" className='formControl'>
                                    <Grid>
                                        <label className="idProof-label">ID Proof <span className='updated-dot'></span></label>
                                        <Typography variant='p' component='p' className='idProof-name'>
                                            <span><img src={fileIcon} height="16px" alt="img" /></span>
                                            PAN Card.pdf
                                            <a href="#" className="downloadBtn">Download</a>
                                        </Typography>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" style={{ margin: '24px 0', }}/>
                        <Typography variant='h5' component='h5' className='title'>
                            Branches Details
                        </Typography>
                        <Grid container className='formGrid'>
                                <Grid className='formGrid-inner'>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label>Branch 1</label>
                                            <Typography variant='p' component='p' className='value'>
                                                Mumbai
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid className='formGrid-inner'>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label>Branch 2</label>
                                            <Typography variant='p' component='p' className='value'>
                                                Mumbai
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={4}  md={4} sm={12} xs={12}>
                <div className={classes.innerContainer}>
                    <Grid className='moreDetails'>
                        <List className='moreDetails-list'>
                            <ListItem>
                                <Typography variant='p' component='p'>
                                    Main Agency Office Location
                                </Typography>
                                <Typography variant='h6' component='h6'>
                                    New York, America
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant='p' component='p'>
                                    Agency Size <span className='updated-dot'></span>
                                </Typography>
                                <Typography variant='h6' component='h6'>
                                    22
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant='p' component='p'>
                                    Number of Branch
                                </Typography>
                                <Typography variant='h6' component='h6'>
                                    2
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant='p' component='p'>
                                    Agency Registration Number
                                </Typography>
                                <Typography variant='h6' component='h6'>
                                    +918424575478
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                </div>
            </Grid>
        </Grid>
        <Grid className='action-div'>
            <Link href="#" underline="none" className='previousBtn'>Reject</Link>
            <Button variant="contained" className='primaryButton nextBtn' >Approve</Button >
        </Grid>
        </>
    );
}

export default withStyles(styles)(ClientProfile);