import React from "react";
import { reduxForm, Field } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput';
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import Icon from "@material-ui/core/Icon";
import { required, password, passwordsMatch } from "utils/validation";

function Form(props) {
    const { classes, handleSubmit } = props;

    return (
        <div className={classes.container}>
            <GridContainer justifyContent="center" style={{ justifyContent: "center" }}>
                <GridItem xs={12} sm={12} md={4}>
                    <Card className={classes[""]}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h4>Reset Password</h4>
                        </CardHeader>
                        <form onSubmit={handleSubmit} noValidate>
                            <CardBody>
                                <Field
                                    name="new_password1"
                                    component={CustomInput}
                                    id="new_password1"
                                    labelText="Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, password, passwordsMatch]}
                                    inputProps={{
                                        type: "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputIconsColor}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Field
                                    name="new_password2"
                                    component={CustomInput}
                                    id="new_password2"
                                    labelText="Confirm Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, passwordsMatch]}
                                    inputProps={{
                                        type: "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputIconsColor}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Button type="submit" color="info" size="lg">
                                    Submit
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    )
}
const ReduxFormMapped = reduxForm({
    form: 'ResetPasswordForm', // a unique identifier for this form
    enableReinitialize: false
})(Form)
export default withStyles(styles)(ReduxFormMapped);