const validate = values => {
    const errors = {}
    if (!values.first_name) {
        errors.first_name = 'This field is required'
    } else if (values.first_name.length > 30) {
        errors.first_name = 'Must be 15 characters or less'
    }
    if (!values.last_name) {
        errors.last_name = 'This field is required'
    } else if (values.last_name.length > 30) {
        errors.last_name = 'Must be 15 characters or less'
    }

    return errors
}
export default validate