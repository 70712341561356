
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { findIndex, remove } from 'lodash';
import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { required } from 'utils/validation';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(checkboxAdnRadioStyle);

const CheckBox = ({ options, name, input, meta: { touched, error } }) => {
    const classes = useStyles();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const handleSelectAll = (e) => {
        const newValue = [...input.value];
        if (e.target.checked) {
            options.permissions.forEach(li => {
                const found = newValue.some(el => el.id === li.id);
                if (!found) {
                    newValue.push({ id: li.id })
                }

            });
        } else {
            let data = options.permissions.map(li => ({ id: li.id }))
            data.forEach((val) => {
                newValue.forEach((nVal) => {
                    if (nVal.id === val.id) {
                        remove(newValue, (n) => +n.id === +val.id);
                    }
                })
            })
        }
        input.onChange(newValue);
        setIsCheckAll(!isCheckAll);
    };

    const CheckAllData = () => {
        const newValue = [...input.value];
        let count = 0;
        options.permissions.forEach((val) => {
            const filterCount = newValue.filter((nVal) => {
                return nVal.id === val.id
            })
            count += filterCount.length;
            if (count === options.permissions.length) {
                setIsCheckAll(true)
            }
            else {
                setIsCheckAll(false)
            }
        })
    }

    useEffect(() => {
        CheckAllData()
    }, [[...input.value]])

    return (
        <>
            <Grid>
                <FormControlLabel
                    key={options.id}
                    className={classes.labelRoot}
                    control={(
                        <Checkbox
                            className={classes.checked}
                            inputProps={{
                                name: `${options.model}`,

                            }}
                            validate={required}
                            checked={isCheckAll}
                            onChange={handleSelectAll}
                        />
                    )}
                    label={'All'}
                />
            </Grid>

            {options.permissions.map((option, index) => (
                <Grid key={index}>
                    <FormControlLabel
                        key={option.id}
                        className={classes.labelRoot}
                        control={(
                            <Checkbox
                                className={classes.checked}
                                checked={findIndex(input.value, (o) => +o.id === +option.id) !== -1}
                                inputProps={{
                                    name: `${name}[${index}]`,
                                }}
                                value={option.value}
                                onChange={(event, checked) => {
                                    const newValue = [...input.value];
                                    if (checked) {
                                        newValue.push({ id: option.id });
                                    } else {
                                        remove(newValue, (n) => +n.id === +option.id);
                                    }
                                    input.onChange(newValue);
                                }}
                            />
                        )}
                        label={option.name}
                    />
                </Grid>
            ))}
            {/* {touched && error ? (
                <FormHelperText>{touched && error}</FormHelperText>
            ) : null
            } */}
        </>
    )
};

export default CheckBox;
