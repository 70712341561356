import React, { useState } from "react";
import { reduxForm, Field } from 'redux-form';
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import CustomInput from 'components/CustomInput/CustomInput';
import InputAdornment from "@material-ui/core/InputAdornment";
import { required, password, passwordsMatch, normalizeCharacter } from "utils/validation";

function Form(props) {
    const { handleSubmit, classes } = props;
    const [visibility, setVisibility] = useState({ password: false, confirmPassword: false });

    const handleChangeVisibility = (which) => () => {
        setVisibility({ ...visibility, [which]: !visibility[which] });
    }

    return (
        <div className={classes.container}>
            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <Card className={classes[""]}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h4>User Details</h4>
                        </CardHeader>
                        <form onSubmit={handleSubmit} noValidate>
                            <CardBody>
                                <Field
                                    name="first_name"
                                    component={CustomInput}
                                    id="first_name"
                                    labelText="First Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required]}
                                    inputProps={{
                                        type: "text"
                                    }}
                                    normalize={normalizeCharacter(25)}
                                />
                                <Field
                                    name="last_name"
                                    component={CustomInput}
                                    id="last_name"
                                    labelText="Last Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required]}
                                    inputProps={{
                                        type: "text"
                                    }}
                                    normalize={normalizeCharacter(25)}
                                />
                                <Field
                                    name="new_password1"
                                    component={CustomInput}
                                    id="new_password1"
                                    labelText="Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, password, passwordsMatch]}
                                    inputProps={{
                                        type: visibility.password ? "text" : "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className="cursor-pointer" onClick={handleChangeVisibility('password')}>
                                                    <Icon className={classes.inputIconsColor}>
                                                        {visibility.password ? 'visibility' : 'visibility_off'}
                                                    </Icon>
                                                </span>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Field
                                    name="new_password2"
                                    component={CustomInput}
                                    id="new_password2"
                                    labelText="Confirm Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, passwordsMatch]}
                                    inputProps={{
                                        type: visibility.confirmPassword ? "text" : "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <span className="cursor-pointer" onClick={handleChangeVisibility('confirmPassword')}>
                                                    <Icon className={classes.inputIconsColor}>
                                                        {visibility.confirmPassword ? 'visibility' : 'visibility_off'}
                                                    </Icon>
                                                </span>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Button type="submit" color="info" size="lg">
                                    Submit
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

const ReduxFormMapped = reduxForm({
    form: 'UserConfirmationForm', // a unique identifier for this form
    enableReinitialize: true
})(Form)

export default withStyles(styles)(ReduxFormMapped);

