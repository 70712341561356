
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import 'assets/css/profile.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as Actions from '../../../../store/actions/index'
import CustomButton from "components/CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Tab, Tabs } from '@material-ui/core';
import AgencyDetailTab from './AgencyDetailTab';
import DevelopersDetailTab from './DevelopersDetailTab';
// import RequestTab from './RequestTab';
import { errorToast } from 'utils/Comman';
import ImagePreviewDialog from 'components/Dialog/ImagePreviewDialog';
import AgencyDetailSkeleton from './Skeleton/AgencyDetailSkeleton';

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: "100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 120px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        }
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
        }
    },
    formGridInner: {
        width: '48%',
    },

});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function AgencyDetail(props) {
    const userDetail = useSelector((state) => (state.manageUsersReducer.agencyUserDetail));
    const manageTab = useSelector(state => state.commonReducer.developerTab);
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const [openPreview, setOpenPreview] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const { classes } = props;

    const handleChange = (event, newValue) => {
        if (manageTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({
            type: 'SET_DEVELOPER_TAB',
            payload: {
                activeTab: newValue,
            }
        })
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    useEffect(() => {
        dispatch(Actions.getAgencyDetails(params.id)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "AGENCY_USER_DETAIL", payload: response.data });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
                window.scrollTo(0, 0);
            } else {
                dispatch({ type: "AGENCY_USER_DETAIL", payload: null });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
                errorToast(response);
            }
        }).catch((response) => {
            dispatch({ type: "PAGE_LOADING", payload: false });
            dispatch({ type: "CONTENT_LOADING", payload: false });
            errorToast(response);
        })
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
    }, []);

    return (
        <>
            {pageLoading ?
                <AgencyDetailSkeleton classes={classes} activeTab={manageTab.activeTab} /> :
                <>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <CustomButton
                            variant="contained"
                            className="primaryButton innerBackBtn"
                            startIcon={<ArrowBackIosIcon />}
                            color="info"
                            onClick={() => {
                                dispatch({ type: 'SET_DEVELOPER_TAB', payload: { activeTab: 0, } });
                                dispatch({ type: "AGENCY_USER_DETAIL", payload: null });
                                history.push("/manage-users");
                            }}
                        >
                            Back
                        </CustomButton>
                    </Grid>
                    <Grid container className={classes.innerContainer}>
                        <Grid item xl={9} lg={10} md={12} sm={12}>
                            <div className={classes.profileDetail}>
                                <Avatar alt="profile-img" onClick={() => handleOpenPreview()} src={userDetail?.profile_image?.url ?? userDetail?.profile_image} className={classes.profileImg} />
                                <div className={classes.profileContent}>
                                    <Typography variant='h4'>{
                                        userDetail?.company?.name ?
                                            userDetail?.company?.name : '-'
                                    }</Typography>
                                    <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{userDetail?.uid}</span></Typography>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Full Name
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.first_name ?
                                                    `${userDetail?.first_name} ${userDetail?.last_name}` : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Email
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.email ?
                                                    userDetail?.email : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Contact Number
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.contact_number ?
                                                    userDetail?.contact_number : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Location
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.city?.display_name ?
                                                    userDetail?.city?.display_name : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={12}>
                            <div className={classes.innerContainer}>
                                <Grid className='moreDetails manage_users-moreDetails'>
                                    <List className='moreDetails-list'>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Agency Registration Number
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.company?.registration_number ?
                                                    userDetail.company.registration_number : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Number of Branch
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.branches?.length ?
                                                    userDetail.branches.length : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Main Agency Office Location
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.branches?.length <= 0 ? '-' :
                                                    userDetail?.branches?.map((branch) =>
                                                        (branch.is_head_quarter === true ? branch.city.display_name : null))
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Agency Size <span></span>
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.company?.size ?
                                                    userDetail.company?.size : 0
                                                }
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item lg={12} >
                            <Grid className='profile-inner my_profile-inner'>
                                <Tabs
                                    value={manageTab.activeTab}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab
                                        label="Agency Details"
                                        {...a11yProps(0)}
                                        className="tab-title"
                                    />
                                    <Tab
                                        label="Developer Details"
                                        {...a11yProps(1)}
                                        className="tab-title"
                                    />
                                    {/* <Tab
                                        label="Request"
                                        {...a11yProps(2)}
                                        className="tab-title"
                                    /> */}
                                </Tabs>
                                <TabPanel value={manageTab.activeTab} index={0}>
                                    <AgencyDetailTab />
                                </TabPanel>
                                <TabPanel value={manageTab.activeTab} index={1} className="projects">
                                    <DevelopersDetailTab />
                                </TabPanel>
                                {/* <TabPanel value={manageTab.activeTab} index={2} className="projects">
                                    <RequestTab />
                                </TabPanel> */}
                            </Grid>
                        </Grid>
                        {userDetail?.profile_image &&
                            <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={userDetail?.profile_image?.url ?? userDetail?.profile_image} />
                        }
                    </Grid>
                </>
            }
        </>
    );
}

export default withStyles(styles)(AgencyDetail);