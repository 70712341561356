import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import MasterTable from "components/MasterTable/MasterTable";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { canHaveAnyPermissions } from "utils/auth";
import * as Actions from "../../../../store/actions/index";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";

function List(props) {
  const history = useHistory();
  const {
    pageLimit,
    pageOffset,
    getList,
    searchText,
    tableName,
  } = props;

  const [fileEl, setFileEl] = useState(null);
  const [fileElIndex, setFileElIndex] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer?.userDetails);
  const agencyList = useSelector(
    (state) => state.subscription.subscriptionList
  );

  const actionPermissions = [
    PERMISSION.CHANGE_SUBSCRIPTION_DETAIL
  ];

  const handleFileELClick = (event, i) => {
    setFileEl(event.currentTarget);
    setFileElIndex(i);
  };
  const handleCloseFileEL = () => {
    setFileEl(null);
  };


  const getUserDetails = () => {
    dispatch(Actions.getUserDetails()).then((response) => {
      if (response.status === 200) {
        dispatch({ type: "GET_USER_DETAILS", payload: response.data });
      } else {
        dispatch({ type: "GET_USER_DETAILS", payload: "" });
      }
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const columns = [
    {
      name: "id",
      label: "Plan Id",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
      },
    },
    {
      name: "name",
      label: "Plan Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "-";
        },
        sortThirdClickReset: true,
      },
    },

    {
      name: "recurring",
      label: "Recurring",
      options: {
        customBodyRender: (value) => {
          return value ? value.name : "-";
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        customBodyRender: (value) => {
          return value ? value : "-";
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "currency",
      label: "Currency",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value ? value.sign : "-";
        },
      },
    },
    {
      name: "num_of_developers",
      label: "Number Of Developers",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value ? value : "-";
        },
        sortThirdClickReset: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
          sort: false,
          customBodyRender: (value) => {
              return (value) ?
                  <ReadMoreReadLess
                      charLimit={100}
                      readMoreText={"Read More"}
                      readLessText={"Read Less"}
                      readMoreClassName="read-moreLink"
                      readLessClassName="read-moreLink"
                      children={value || ''} //eslint-disable-line
                      textType={true}
                  /> : '-'
          },
          setCellProps: () => ({
              style: {
                  maxWidth: "350px",
                  wordBreak: "break-word",
              }
          }),
      }
  },
    {
      name: "id",
      label: "Action",
      options: {
        display: canHaveAnyPermissions(
          actionPermissions,
          user?.user_permissions
        ),
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                aria-label="More"
                aria-owns={fileEl ? "long-menu" : null}
                aria-haspopup="true"
                onClick={(e) => handleFileELClick(e, value)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={fileEl}
                open={Boolean(fileEl) && fileElIndex === value}
                onClose={handleCloseFileEL}
              >
                <CheckPermission
                  component={
                    <MenuItem
                      onClick={() =>
                        history.push({
                          pathname: `/manage-subscription/agency/edit/${value}`,
                        })
                      }
                    >
                      Edit Plan
                    </MenuItem>
                  }
                  permission={PERMISSION.CHANGE_SUBSCRIPTION_DETAIL}
                />
              </Menu>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <MasterTable
        list={agencyList}
        getList={getList}
        pageLimit={pageLimit}
        pageOffset={pageOffset}
        columns={columns}
        name={tableName}
        searchText={searchText}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return { agencyList: state.manageUsersReducer.userList };
};
export default connect(mapStateToProps, "")(List);
