import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import MasterTable from "components/MasterTable/MasterTable";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { canHaveAnyPermissions } from "utils/auth";
import * as Actions from "../../../../store/actions/index";

function List(props) {

    const history = useHistory();
    const { freelancerList, pageLimit, deleteFreelancers, pageOffset, getList, searchText, tableName } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [freelancerId, setFreelancerId] = useState('');
    const [fileEl, setFileEl] = useState(null);
    const [fileElIndex, setFileElIndex] = useState(0);
    const dispatch = useDispatch();
    const user = useSelector(state => state.authReducer?.userDetails);
    const actionPermissions = [PERMISSION.REGISTERED_USER_FREELANCER_VIEW, PERMISSION.REGISTERED_USER_FREELANCER_EDIT, PERMISSION.REGISTERED_USER_FREELANCER_DELETE];

    const handleConfirmDialog = (value) => {
        setFileEl(null);
        setOpenDialog(true);
        setFreelancerId(value);
    };
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        deleteFreelancers(freelancerId)
    };
    const handleClose = () => {
        setOpenDialog(false);
        setFreelancerId(null);
    }
    const handleFileELClick = (event, i) => {
        setFileEl(event.currentTarget);
        setFileElIndex(i)
    };
    const handleCloseFileEL = () => {
        setFileEl(null);
    }

    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
            } else {
                dispatch({ type: 'GET_USER_DETAILS', payload: '' });
            }
        });
    }
    
    useEffect(() => {
        getUserDetails();
    }, []);

    const columns = [
        {
            name: "uid",
            label: "Freelancer Id",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
            }
        },
        {
            name: "first_name",
            label: "First Name",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "last_name",
            label: "Last Name",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                }
            }
        },
        {
            name: "contact_number",
            label: "Contact Number",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                }
            }
        },
        {
            name: "is_active",
            label: "Status",
            options: {
                customBodyRender: (value) => {
                    return <span style={{ color: (value === true) ? "green" : "red" }}>{(value === true) ? 'Active' : 'Inactive'}</span>
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                display: canHaveAnyPermissions(actionPermissions, user?.user_permissions),
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-owns={fileEl ? "long-menu" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleFileELClick(e, value)}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={fileEl}
                                open={Boolean(fileEl) && fileElIndex === value}
                                onClose={handleCloseFileEL}>
                                <CheckPermission
                                    component={
                                        <MenuItem onClick={() => history.push({ pathname: `/manage-user/freelancer-details/${value}`, state: value })}>View Details</MenuItem>}
                                    permission={PERMISSION.REGISTERED_USER_FREELANCER_VIEW} />
                                <CheckPermission
                                    component={<MenuItem disabled={freelancerList.results[tableMeta.rowIndex].is_pending_request ? true : false} onClick={() => history.push({ pathname: `/manage-user/freelancer-update/${value}`, state: value })}>Edit User</MenuItem>}
                                    permission={PERMISSION.REGISTERED_USER_FREELANCER_EDIT} />
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleConfirmDialog(value)}>Delete User</MenuItem>}
                                    permission={PERMISSION.REGISTERED_USER_FREELANCER_DELETE} />
                            </Menu>
                        </>
                    )
                }
            }
        },
    ]

    return (
        <>
            <MasterTable
                list={freelancerList}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
            <ConfirmDialog open={openDialog} close={handleClose}
                save={handleConfirmDelete}
                title="Alert" alertMessage="Are you sure you want to delete freelancer" />
        </>
    )
}
const mapStateToProps = (state) => {
    return { freelancerList: state.manageUsersReducer.userList }
}
export default connect(mapStateToProps, '')(List);
