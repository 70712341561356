import { makeStyles } from "@material-ui/core/styles";
import MasterSkeletonForTable from "components/MasterSkeleton/MasterSkeletonForTable";
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchPageLoading, errorToast } from "utils/Comman";
import { LoaderContext } from "utils/LoaderContext";
import * as Actions from '../../../store/actions/index';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import DeveloperRequestTable from "./Components/DeveloperRequestTable";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import { REQUEST_LIST_STATUS } from "utils/constant";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const DeveloperRequest = () => {
    const { toggalLoader } = useContext(LoaderContext);
    const history = useHistory();
    const requestDetail = useSelector((state) => (state.userRequestReducer.developerRequestDetail));
    const agencyList = useSelector((state) => (state.manageUsersReducer.allAgencies));
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [time, setTime] = useState('');
    const [open, setOpen] = useState(false);
    const [searchOption, setSearchOption] = useState(false);
    const [filter, setFilter] = useState({ rowLimit: 10, offset: 1, search: '', ordering: '', agency: null, status: '' });
    const type = 6;

    const handleAction = (value) => {
        if (value == 'search') {
            setSearchOption(!searchOption)
        } else {
            setOpen(!open)
        }
    }

    const clearAll = () => {
        if (filter.agency || filter.status) {
            handleListEvent(filter.rowLimit, 1, filter.search, null, '');
        }
    }

    const getRequestDetail = (limit = filter?.rowLimit, page = filter.offset, type, search = filter.search, ordering = filter.ordering, agency = filter.agency, status = filter.status) => {
        dispatch(Actions.getDeveloperRequestList(limit, page, type, search, ordering, agency?.id, status))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: 'DEVELOPER_REQUEST_DETAIL', payload: response.data });
                    toggalLoader(false);
                    dispatchPageLoading(dispatch);
                } else {
                    dispatch({ type: 'DEVELOPER_REQUEST_DETAIL', payload: null });
                    toggalLoader(false);
                    dispatchPageLoading(dispatch);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                dispatchPageLoading(dispatch);
                errorToast(response);
            });
    };

    const getAllAgencies = (searchString) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            if (searchString?.length > 2) {
                dispatch(Actions.getAllAgencies(searchString))
            }
        }, 1000));
    };

    const handleListEvent = (page, set, search, ordering, agency, status) => {
        if (JSON.stringify(filter) !== JSON.stringify({ rowLimit: page, offset: set, search, ordering, agency, status })) {
            toggalLoader(true)
            setFilter({ ...filter, rowLimit: page, offset: set, search, ordering, agency, status });
            getRequestDetail(page, set, type, search, ordering, agency, status);
            if (!agency) {
                dispatch({ type: 'GET_ALL_AGENCIES', payload: [] });
            }
        }
    }

    const handleSearch = (search) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            handleListEvent(filter.rowLimit, 1, search, filter.ordering, filter.agency, filter.status)
        }, 1000));
    }

    const handleAgencySelection = (value) => {
        handleListEvent(filter.rowLimit, 1, filter.search, filter.ordering, value, filter.status);
    }

    const clearSearch = () => {
        handleListEvent(filter.rowLimit, 1, '', filter.ordering, filter.agency, filter.status)
        setSearchOption(false)
    }

    useEffect(() => {
        getRequestDetail(filter.rowLimit, filter.offset, type);
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
        }
    }, []);

    useEffect(() => {
        const previousPath = history?.location?.prevPath;
        if (previousPath && !previousPath?.includes('/developer-requests')) {
            history.replace({ ...history.location, state: null });
        } else if (history.location.state) {
            const filterState = history?.location?.state?.filter ?? null;
            if (filterState) {
                setFilter({ ...filter, ...filterState });
                setOpen((filterState?.agency || filterState?.status) ? true : false);
                setSearchOption(filterState?.search ? true : false);
                getRequestDetail(filterState?.rowLimit, filterState.offset, type, filterState.search, filterState.ordering, filterState.agency, filterState.status);
            }
        }
    }, [history.location.state, history.location.pathname]);

    return (
        <div className={classes.root}>
            {pageLoading ?
                <MasterSkeletonForTable length={9} type='agencydeveloper' />
                :
                <Grid className="agency-developer-section">
                    <Grid className="agency-developer-heading">
                        {searchOption ?
                            <div className="custom-search-agencydev">
                                <SearchIcon />
                                <TextField
                                    id="standard-basic"
                                    variant="standard"
                                    defaultValue={filter?.search}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                                <CloseIcon className="cursor-pointer" onClick={clearSearch} />
                            </div>
                            :
                            <Typography variant="h4">Agency Developer</Typography>
                        }

                        <Grid className="developer-request-icon">
                            <div className="developer-search-icon cursor-pointer">
                                <SearchIcon onClick={() => handleAction('search')} />
                            </div>

                            <div className="developer-filter-icon cursor-pointer">
                                <FilterListTwoToneIcon onClick={() => handleAction('filter')} />
                            </div>
                        </Grid>
                    </Grid>

                    {open &&
                        <Grid className="select-agency-content">
                            <div className="select-developer-agency-box">
                                <div className="agency-auto-complete">
                                    <Autocomplete
                                        value={filter.agency || ''}
                                        id="filter-demo"
                                        className='autocomplete-custom-agency select-agency-box'
                                        options={agencyList || []}
                                        noOptionsText="No Agency Found"
                                        onChange={(__, value) => handleAgencySelection(value)}
                                        getOptionLabel={(option) => option['company_name'] || ""}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                variant='outlined'
                                                autoComplete="off"
                                                InputLabelProps={{ shrink: false }}
                                                placeholder="Search Agency"
                                                onChange={(e) => getAllAgencies(e.target.value)}
                                                className="agency-text-field"
                                            />
                                        } />
                                </div>
                                <div className="agency-select-box">
                                    <Select
                                        variant='outlined'
                                        value={filter.status || ''}
                                        onChange={(e) => {
                                            handleListEvent(filter.rowLimit, 1, filter.search, filter.ordering, filter.agency, e.target.value)
                                        }}
                                        className="inputField select-custom-agency status-options "
                                        placeholder="Select Status"
                                    >
                                        {REQUEST_LIST_STATUS?.map((option) => (
                                            <MenuItem value={option.value} key={option.value}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                            </div>
                            <div className="agency-view-btn cursor-pointer">
                                <Typography variant="h5" onClick={clearAll}>Clear all</Typography>
                            </div>
                        </Grid>
                    }
                    <DeveloperRequestTable
                        requestDetail={requestDetail}
                        detailRoute="/developer-requests/"
                        getList={handleListEvent}
                        filter={filter}
                    />
                </Grid>
            }
        </div>
    );
}

export default DeveloperRequest;