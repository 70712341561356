import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import reducer from "../../store/reducers";
import withReducer from "../../store/withReducer";
import ClientList from "./component/Client/List";
import AgencyList from "./component/Agency/List";
import FreelancerList from "./component/Freelancers/List";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions/index";
import CustomTabs from "components/CustomTabs/CustomTabs";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import DomainIcon from "@material-ui/icons/Domain";
import PeopleIcon from "@material-ui/icons/People";
import { getUserRequest } from "utils/constant";
import { errorToast } from "utils/Comman";
import ManageUsersSkeleton from "./Skeletons/ManageUsersSkeleton";
import { dispatchPageLoading, dispatchContentLoading } from "utils/Comman";
import MasterSkeletonForTable from "components/MasterSkeleton/MasterSkeletonForTable";
import { useHistory } from "react-router-dom";
import * as PERMISSION from "utils/permissionConstant";
import { canHaveAnyPermissions } from "utils/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Subscription() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState({ tab: 3, url: "user-client/" });
  const manageActiveTab = useSelector(
    (state) => state.commonReducer.manageActiveTab
  );
  const pageLoading = useSelector((state) => state.commonReducer.pageLoading);
  const contentLoading = useSelector(
    (state) => state.commonReducer.contentLoading
  );
  const actionPermissions = [
    PERMISSION.ADD_SUBSCRIPTION_DETAIL,
  ];
  const user = useSelector((state) => state.authReducer?.userDetails);
  const [rowLimit, setRowLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchString, setSearchString] = useState("");

  const getSubscriptionList = (limit, page, search, user, ordering) => {
    setRowLimit(limit);
    setOffset(page);
    setSearchString(search);
    dispatch(
      Actions.getSubscriptionList(limit, page, search, ordering, user?.tab)
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "GET_SUBSCRIPTION_LIST", payload: response.data });
          window.scrollTo(0, 0);
          dispatchPageLoading(dispatch);
          dispatchContentLoading(dispatch);
        } else {
          dispatch({ type: "GET_SUBSCRIPTION_LIST", payload: null });
          errorToast(response);
          dispatchPageLoading(dispatch);
          dispatchContentLoading(dispatch);
        }
      })
      .catch((response) => {
        dispatch({ type: "GET_SUBSCRIPTION_LIST", payload: [] });
        errorToast(response);
        dispatchPageLoading(dispatch);
        dispatchContentLoading(dispatch);
      });
  };

  const handleChange = (event, newValue) => {
    if (manageActiveTab.activeTab !== newValue) {
      dispatch({ type: "CONTENT_LOADING", payload: true });
    }
    dispatch({ type: "SET_ACTIVE_TAB", payload: { activeTab: newValue } });
    setRowLimit(10);
    setOffset(0);
  };

  const handleListEvent = (page, set, search, ordering) => {
    getSubscriptionList(page, set, search, value, ordering);
  };

  const handleClick = () => {
    if (manageActiveTab.activeTab == 0) {
      history.push(`/manage-subscription/client/add`);
    } else if (manageActiveTab.activeTab == 1) {
      history.push(`/manage-subscription/agency/add`);
    } else {
      history.push(`/manage-subscription/freelancer/add`);
    }
  };

  useEffect(() => {
    let tempValue = getUserRequest(
      customTab[manageActiveTab.activeTab]?.tabName
    );
    setValue(tempValue);
    getSubscriptionList(rowLimit, offset, "", tempValue, "");
  }, [manageActiveTab]);

  useEffect(() => {
    return () => {
      dispatch({ type: "PAGE_LOADING", payload: true });
      dispatch({ type: "CONTENT_LOADING", payload: true });
      dispatch({
        type: "GET_SUBSCRIPTION_DETAILS",
        payload: {},
      });
    };
  }, []);

  let customTab = [
    {
      tabName: "Client",
      tabIcon: PeopleIcon,
      tabPermission: true,
      tabContent: (
        <>
          {contentLoading ? (
            <MasterSkeletonForTable length={7} type="client" />
          ) : (
            <>
              <ClientList
                pageLimit={rowLimit}
                getList={handleListEvent}
                pageOffset={offset}
                tableName="Client"
                searchText={searchString}
              />
            </>
          )}
        </>
      ),
    },
    {
      tabName: "Agency",
      tabIcon: DomainIcon,
      tabPermission: true,
      tabContent: (
        <>
          {contentLoading ? (
            <MasterSkeletonForTable length={8} type="agency" />
          ) : (
            <AgencyList
              pageLimit={rowLimit}
              getList={handleListEvent}
              pageOffset={offset}
              tableName="Agency"
              searchText={searchString}
            />
          )}
        </>
      ),
    },
    {
      tabName: "Freelancers",
      tabIcon: PeopleOutlineIcon,
      tabPermission: true,
      tabContent: (
        <>
          {contentLoading ? (
            <MasterSkeletonForTable length={7} type="freelancer" />
          ) : (
            <FreelancerList
              pageLimit={rowLimit}
              getList={handleListEvent}
              pageOffset={offset}
              tableName="Freelancers"
              searchText={searchString}
            />
          )}
        </>
      ),
    },
  ];
 
  return (
    <>
      {(() => {
        return (
          <>
            {pageLoading ? (
              <ManageUsersSkeleton
                classes={classes.root}
                manageActiveTab={manageActiveTab}
              />
            ) : (
              <div className={classes.root}>
                <CustomTabs
                  showButton={canHaveAnyPermissions(
                    actionPermissions,  
                    user?.user_permissions
                  )}
                  headerColor="info"
                  tabChange={handleChange}
                  activeTab={manageActiveTab.activeTab}
                  tabs={customTab}
                  handleClick={handleClick}
                />
              </div>
            )}
          </>
        );
      })()}
    </>
  );
}

export default withReducer("", reducer)(Subscription);
