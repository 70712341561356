import React from "react";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import 'assets/css/allModals.css';
import profileImg from 'assets/img/client_profile-img.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
};


function AssignNewAgencyDialog(props) {
    const { open, close } = props;
    const [agency, setAgency] = React.useState("");

    const handleChangeAgency = (event) => {
        setAgency(event.target.value);
      };

    return (
        <div>
            <BootstrapDialog
                onClose={close}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='approval_modal agency-log-modal'
            >
                <BootstrapDialogTitle id="customized-dialog-title" className='modal-title' onClose={close}>
                    Assign New Agency
                </BootstrapDialogTitle>
                <DialogContent className='content'>
                    <Grid className="log-profile">
                        <div className="icon-container">
                            <img src={profileImg} alt="img" />
                        </div>
                        <div className="log-profile-content">
                            <Typography className="agency-name">
                                Sleek Solutions
                            </Typography>
                            <div>
                                <Typography className="agency-Id">Client ID : GG58168798</Typography>
                                <Typography><a href="#">View Profile</a></Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="assigned-log-container">
                        <div className="assigned-agency">
                            <Typography className="assigned-agency-name">Infrastructure Pvt. Ltd</Typography>
                            <div className="assigned-duration">
                                <Typography>Assigned Date : 02/05/2022</Typography>
                            </div>
                            <div className="reason">
                                <Typography className="rejection-title">Reason of Rejection</Typography>
                                <Typography>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text.
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="assign-new-agency">
                        <Grid container className="formGrid">
                            <FormControl variant="standard" className="formControl">
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Assign New Agency
                                </InputLabel>
                                <Select
                                    value={agency}
                                    onChange={handleChangeAgency}
                                    className="inputField"
                                    placeholder="Select agency"
                                >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={10}>New York, America</MenuItem>
                                    <MenuItem value={20}>USA</MenuItem>
                                    <MenuItem value={30}>Canada</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='action-div'>
                    <Link underline="none" className="previousBtn">Cancel</Link>
                    <Button type='submit' variant="contained" className="primaryButton nextBtn">
                        Assign
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

export default AssignNewAgencyDialog;
