import React, { useEffect, useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CustomButton from "components/CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Form from "./Form";
import * as Actions from '../../../../store/actions/index';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { LoaderContext } from "utils/LoaderContext";
import "assets/css/profile.css";
import { checkForImageValidations, errorToast } from 'utils/Comman';

function ManageAgency() {

    const history = useHistory();
    const userId = history.location.state;
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [agencyDetails, setAgencyDetails] = useState(null);
    const [technologyList, setTechnologyList] = useState([]);
    const [files, setFiles] = useState({ profile: null, identity: null });


    const getTechnologies = () => {
        dispatch(Actions.getTechnologies()).then(response => {
            if (response.status === 200) {
                setTechnologyList(response.data)
            } else {
                setTechnologyList([])
            }
        })
    }
    const submitUserProfile = (values) => {
        const addUserProfileData = {
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            contact_number: values.contact_number,
            is_active: (values.is_active === "1") ? true : false,
            city: values.city.id,
            profile_image: files.profile,
            company: {
                name: values.company.name.trim(),
                registration_number: values.company.registration_number,
                company_url: values.company.company_url,
                size: values.company.size,
                technologies: values.company.technologies.map(val => val.id),
                is_registered_company: (values.company.is_registered_company) ? values.company.is_registered_company : false,
                identity: files.identity,
            },
            branches: values.branches.map((val) => ({ city: val.city.id, is_head_quarter: val.is_head_quarter }))
        };
        if (typeof addUserProfileData.profile_image === 'string' || addUserProfileData.profile_image === null) delete addUserProfileData.profile_image
        if (typeof addUserProfileData.company.identity === 'string' || addUserProfileData.company.identity === null) delete addUserProfileData.company.identity
        toggalLoader(true);
        dispatch(Actions.updateAgency(userId, addUserProfileData)).then((response) => {
            if (response.status === 200) {
                toast.success("Agency's profile updated successfully");
                toggalLoader(false);
                history.push("/manage-users");
            } else {
                toggalLoader(false);
                errorToast(response);
            }
            toggalLoader(false);
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        });
    }

    useEffect(() => {
        dispatch(Actions.getAgencyDetails(userId)).then(response => {
            if (response.status === 200) {
                const userData = { ...response.data }
                userData.is_active = (userData.is_active === true) ? "1" : "0";
                const filesObject = {
                    ...files,
                    profile: userData?.profile_image,
                    identity: userData?.company?.identity
                }
                setFiles(filesObject);
                setAgencyDetails(userData);
            } else {
                errorToast(response);
            }
        }).catch((response) => {
            errorToast(response);
        })
        //window.scrollTo(0, 0);
        getTechnologies()
    }, []);
    const handleImageUpload = async (e) => {
        let file = null;
        let whichFile = null;
        let fileType = null;
        let dimensionCheck = true;
        if (e instanceof File) {
            file = e;
            whichFile = 'identity';
            dimensionCheck = false;
            fileType = 'file';
        } else if (e.target.files.length !== 0) {
            file = e.target.files[0];
            whichFile = 'profile';
            fileType = 'image';
        }
        if (file) {
            const fileCheck = await checkForImageValidations(file, fileType, dimensionCheck);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message)
            } else {
                const formData = new FormData();
                formData.append("file_obj", file);
                dispatch(Actions.uploadImage(formData)).then(response => {
                    const filesObject = { ...files }
                    filesObject[whichFile] = response.data;
                    setFiles(filesObject);
                });
            }
        }
    };
    return (
        <Container className='profile-container my_profile-container'>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <CustomButton
                    variant="contained"
                    className="primaryButton innerBackBtn"
                    startIcon={<ArrowBackIosIcon />}
                    color="info"
                    onClick={() => {
                        history.push("/manage-users");
                    }}
                >
                    Back
                </CustomButton>
            </Grid>
            <Grid className='profile-inner add_profile-inner'>
                <Form initialValues={agencyDetails} technologyList={technologyList}
                    files={files}
                    uploadImage={handleImageUpload}
                    onSubmit={(values) => {
                        submitUserProfile(values);
                    }} />
            </Grid>
        </Container>
    );
}

export default ManageAgency;