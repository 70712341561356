import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CertificateDetailDialog from 'components/Dialog/CertificateDetailDialog';
import viewIcon from "assets/img/icons/visibility-icon.svg";
import moment from 'moment';
import { certificationListImage } from 'utils/Comman';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import * as Actions from '../../../../../../store/actions/index';
import { Grid } from '@material-ui/core';

export default function Certificates(props) {
    const certificateDetail = useSelector((user) => (user.manageUsersReducer.certificateList));
    const [dialogDetail, setDialogDetail] = useState(null);
    const [open, setOpen] = useState(false);
    const { detail } = props;
    const dispatch = useDispatch();
    const pageSize = 10;

    const handleClickOpen = (project) => {
        setDialogDetail(project)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const columns = [
        { id: 'image_id', label: '', minWidth: 100 },
        {
            id: 'certi_title',
            label: 'Certificate Title',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'certi_date',
            label: 'Certification Date',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'action',
            label: '',
            minWidth: 50,
            format: (value) => value.toLocaleString('en-US'),
        },
    ];
    const handlePageChange = (page) => {
        dispatch(Actions.getFreelancerDetail(detail?.user?.id, 'certifications/', true, page)).then(response => {
            if (response.status === 200) {
                //window.scrollTo(0, 0);
                dispatch({ type: 'GET_CERTIFICATE', payload: response?.data });
            } else {
                dispatch({ type: 'GET_CERTIFICATE', payload: null });
            }
        });
    }
    return (
        <>
            {certificateDetail?.results && certificateDetail?.results?.length > 0 ?
                <TableContainer>
                    <Table className='project_detail-table certificate_table'>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ top: 50, minWidth: column.minWidth }}
                                        className='tableCellHead'
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {certificateDetail?.results?.map((certificate) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={certificate.id}>
                                    <TableCell className='tableDataCell'>
                                        <div className="certificateImg">
                                            <img src={certificationListImage(certificate?.certificate_file)} alt="img" />
                                        </div>
                                    </TableCell>
                                    <TableCell className='tableDataCell'>
                                        {certificate?.title}
                                    </TableCell>
                                    <TableCell className='tableDataCell'>
                                        {moment(certificate?.certification_date).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell className='tableDataCell eyeIcon'>
                                        <img src={viewIcon} onClick={() => handleClickOpen(certificate)} alt='view' />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <PaginationRounded
                            totalPages={Math.ceil(certificateDetail?.count / pageSize) || 0}
                            handlePages={(page) => handlePageChange(page)}
                        />
                    </Grid>
                </TableContainer>
                : <Typography variant='p' component='p'>No Records Found</Typography>
            }
            <CertificateDetailDialog open={open} detail={dialogDetail} close={() => handleClose()} />
        </>
    )
}
