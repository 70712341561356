import React, { useContext } from 'react';
import Form from './component/Form';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../store/actions/index';
import { LoaderContext } from "utils/LoaderContext";
import { toast } from 'react-toastify';
import { errorToast } from 'utils/Comman';

function LoginPage(props) {

  const dispatch = useDispatch();
  const { toggalLoader } = useContext(LoaderContext);

  const login = (values) => {
    toggalLoader(true);
    dispatch(Actions.loginUser(values))
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: 'LOGIN_USER', payload: response.data });
          localStorage.setItem('ACCESS_TOKEN', response.data.token);
          localStorage.setItem('EXPIRE', response.data.expiry);
          getUserDetails();
          getUserPermissions();
          toast.success("Login Successfully");
          toggalLoader(false);
        } else {
          dispatch({ type: 'LOGIN_USER', payload: null });
          toggalLoader(false);
          errorToast(response);
        }
      }).catch((response) => {
        toggalLoader(false);
        errorToast(response);
      });
  }
  const getUserDetails = () => {
    dispatch(Actions.getUserDetails()).then(response => {
      if (response.status === 200) {
        localStorage.setItem('USER_DETAILS', JSON.stringify(response.data));
        dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
      } else {
        dispatch({ type: 'GET_USER_DETAILS', payload: '' });
      }
    });
  }
  const getUserPermissions = () => {
    dispatch(Actions.getUserPermissions()).then(response => {
      if (response.status === 200) {
        let newData = [...response.data, 'main.view_dashboard', 'main.view_userProfile'];
        localStorage.setItem('USER_PERMISSIONS', JSON.stringify(newData));
        dispatch({ type: 'GET_USER_PERMISSIONS', payload: newData });
        props.history.push('/dashboard');
      } else {
        dispatch({ type: 'GET_USER_PERMISSIONS', payload: [] });
      }
    });
  }

  return (
    <div>
      <Form onSubmit={(values) => {
        login(values);
      }} />
    </div>
  )
}
export default LoginPage;