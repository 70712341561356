import React from "react";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import 'assets/css/allModals.css';
import { Divider } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import userIcon from 'assets/img/user.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
};

function AgencyLogDialog(props) {
    const { open, close, detail } = props;
    const history = useHistory();
    const path = history.location.pathname;

    const handleClose = (__, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") return false;
        close();
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='approval_modal agency-log-modal'
            >
                <BootstrapDialogTitle id="customized-dialog-title" className='modal-title' onClose={handleClose}>
                    Assigned Agency Log
                </BootstrapDialogTitle>
                <DialogContent className='content'>
                    <Grid className="log-profile">
                        <div className="icon-container">
                            <img src={detail?.profile_image || userIcon} alt="img" />
                        </div>
                        <div className="log-profile-content">
                            <Typography className="agency-name">
                                {`${detail?.first_name} ${detail?.last_name}`}
                            </Typography>
                            <div>
                                <Typography className="agency-Id">Client ID : {detail?.uid}</Typography>
                                {path === '/new-agency-request' ?
                                    <Typography><a className='cursor-pointer' onClick={() => history.push(`/manage-user/client-details/${detail?.id}`)}>View Profile</a></Typography> : ''
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid className="assigned-log-container">
                        {detail?.assigned_agencies_log?.map((value) => (
                            <>
                                <div className="assigned-agency" key={value.id}>
                                    <Typography className="assigned-agency-name">{value?.agency}</Typography>
                                    <div className="assigned-duration">
                                        <Typography>Assigned Date : {moment(value?.created).format('L')}</Typography>
                                    </div>
                                </div>
                                <Divider />
                            </>
                        ))}
                        {detail?.pending_agency_request?.map((request) => (
                            <div className="assigned-agency" key={request.id}>
                                <div className="request-agency">
                                    <Typography className="requested-for-agency">Requested</Typography>
                                </div>
                                <div className="assigned-duration">
                                    <Typography>Date : {moment(request?.created).format('L')}</Typography>
                                </div>
                            </div>
                        ))}
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default AgencyLogDialog;
