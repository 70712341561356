const initialState = {
    newAgencyRequestList: [],
    newAgencyRequestDetail: null,
    agencyList: [],
};

const newAgencyRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_AGENCY_REQUEST': {
            return { ...state, newAgencyRequestList: action.payload };
        }
        case 'GET_AGENCY_REQUEST_DETAIL': {
            return { ...state, newAgencyRequestDetail: action.payload };
        }
        case 'GET_AGENCY_LIST': {
            return { ...state, agencyList: action.payload };
        }
        default:
            return { ...state };
    }
};
export default newAgencyRequestReducer;