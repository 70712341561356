import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import * as Actions from '../../../../store/actions/index';
import { errorToast,dispatchContentLoading } from 'utils/Comman';
import DevelopersDetailTabSkeleton from './Skeleton/DevelopersDetailTabSkeleton';

const columns = [
    { id: 'id', label: 'User ID', minWidth: 170 },
    { id: 'name', label: 'Name', minWidth: 170 },
    {
        id: 'experience',
        label: 'Experience',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'projects',
        label: 'Projects',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'certificate',
        label: 'Certificates',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
];

const useStyles = makeStyles({
    container: {
        maxHeight: 550,
    },
});

export default function DevelopersDetailTab() {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const params = useParams();
    const userDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperList));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    useEffect(() => {
        dispatch(Actions.getDeveloperList(params.id)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "AGENCY_DEVELOPER_LIST", payload: response.data });
                dispatchContentLoading(dispatch);
            } else {
                dispatch({ type: "AGENCY_DEVELOPER_LIST", payload: null });
                dispatchContentLoading(dispatch);
                errorToast(response);
            }
        }).catch((response) => {
            dispatchContentLoading(dispatch);
            errorToast(response);
        })
        return () => {
            dispatch({ type: 'CONTENT_LOADING', payload: true });
        }
    }, []);
    return (
        <>
            {contentLoading ?
                <DevelopersDetailTabSkeleton />
                :
                <div>
                    {userDetail && userDetail.length > 0 ?
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table" className='project_detail-table manage_developer-table'>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                className='tableCellHead'
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userDetail && userDetail?.map((row) => {
                                        return (
                                            <TableRow key={row.id} hover role="checkbox" tabIndex={-1} onClick={() => history.push(`/manage-user/${params.id}/developer-details/${row.id}`)}>
                                                <TableCell className='tableDataCell'>
                                                    {row.uid ? row.uid : '-'}
                                                </TableCell>
                                                <TableCell className='tableDataCell name'>
                                                    {row.name ? row.name : '-'}
                                                </TableCell>
                                                <TableCell className='tableDataCell'>
                                                    {`${row?.experience} years` || 0}
                                                </TableCell>
                                                <TableCell className='tableDataCell'>
                                                    {row?.projects || 0}
                                                </TableCell>
                                                <TableCell className='tableDataCell'>
                                                    {row?.certifications || 0}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : <Typography variant='p' component='p'>No Records Found</Typography>
                    }
                </div>
            }
        </>

    )
}
