import * as React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import Avatar from '@material-ui/core/Avatar';
import CustomButton from "components/CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useParams } from "react-router-dom";
import Projects from "./component/Projects";
// import WorkExperience from "./component/WorkExperience";
import Certificates from "./component/Certificates";
import ProfessionalDetails from "./component/ProfessionalDetails";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../../../store/actions/index';
import { getAvailability, checkApproval } from "utils/Comman";
import ImagePreviewDialog from "components/Dialog/ImagePreviewDialog";
import FreelancerDetailViewSkeleton from "../../Skeleton/FreelancerDetailViewSkeleton";
import { findAmountPerType } from "utils/Comman";

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileImgApproval: {
        border: '6px solid rgba(255, 158, 45, 0.4)',
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 120px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        }
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
        }
    },
    formGridInner: {
        width: '48%',
    },

});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function FreelancerDetailView(props) {
    const FreelancerDetail = useSelector((state) => (state.userRequestReducer.requestDetail));
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const manageTab = useSelector(state => state.commonReducer.developerTab);
    const FreelancerUpdatedDetail = FreelancerDetail?.updated_data_with_request_change;
    const [openPreview, setOpenPreview] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const { classes } = props;
    const history = useHistory()
    const dispatch = useDispatch();
    const params = useParams();

    const handleChange = (event, newValue) => {
        if (manageTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({
            type: 'SET_DEVELOPER_TAB',
            payload: {
                activeTab: newValue,
            }
        })
        setValue(newValue);
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    const getRequestRejectionDetail = () => {
        dispatch(Actions.getRequestRejectionDetail(params.id)).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'REQUEST_DETAIL', payload: response.data });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
            } else {
                dispatch({ type: 'REQUEST_DETAIL', payload: null });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
            }
        });
    }
    React.useEffect(() => {
        getRequestRejectionDetail();
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
    }, []);

    const checkProfileApproval = (profileReqKey, profilekey) => {
        const check = profileReqKey?.indexOf(profilekey);
        if (check === -1) {
            return classes.profileImg
        } else {
            return classes.profileImgApproval
        }
    }
    return (
        <>
            {pageLoading ? <FreelancerDetailViewSkeleton classes={classes} />
                :
                <>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <CustomButton
                            variant="contained"
                            className="primaryButton innerBackBtn"
                            startIcon={<ArrowBackIosIcon />}
                            color="info"
                            onClick={() => {
                                dispatch({ type: 'REQUEST_DETAIL', payload: null });
                                history.push("/rejected-logs");
                            }}
                        >
                            Back
                        </CustomButton>
                    </Grid>
                    <Grid container className={classes.innerContainer}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className='rejection-container'>
                                <Typography variant='h5' component='h5' className='title'>
                                    reason of rejection
                                </Typography>
                                <div className='rejection-container-inner'>
                                    <Typography className='admin-name'>
                                        {FreelancerDetail?.request_handled_by}
                                    </Typography>
                                    <Typography className='reason-content'>
                                        {FreelancerDetail?.rejection_message}
                                    </Typography>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.innerContainer}>
                        <Grid item xl={9} lg={10} md={12} sm={12}>
                            <div className={classes.profileDetail}>
                                <Avatar
                                    onClick={() => handleOpenPreview()}
                                    alt="profile-img" src={FreelancerUpdatedDetail?.profile_image?.url ?? FreelancerUpdatedDetail?.profile_image}
                                    className={checkProfileApproval(FreelancerDetail?.requested_changes_keys, 'profile_image')}
                                />
                                <div className={classes.profileContent}>
                                    <Typography variant='h4'>{
                                        FreelancerUpdatedDetail?.first_name ?
                                            `${FreelancerUpdatedDetail?.first_name} ${FreelancerUpdatedDetail?.last_name}` : '-'
                                    }</Typography>
                                    <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{FreelancerUpdatedDetail?.uid}</span></Typography>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Full Name
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                FreelancerUpdatedDetail?.first_name ?
                                                    `${FreelancerUpdatedDetail?.first_name} ${FreelancerUpdatedDetail?.last_name}` : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Email
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                FreelancerUpdatedDetail?.email ?
                                                    FreelancerUpdatedDetail?.email : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Contact Number
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                FreelancerUpdatedDetail?.contact_number ?
                                                    FreelancerUpdatedDetail?.contact_number : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Location
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                FreelancerUpdatedDetail?.city?.display_name ?
                                                    FreelancerUpdatedDetail?.city?.display_name : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className="profile-container my_profile-container">
                        <Grid container spacing={3} className="freelancer_profile-details">
                            <Grid item lg={8} md={8} sm={12}>
                                <Grid className="profile-inner my_profile-inner">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab
                                            label={
                                                <>
                                                    ProfessionalDetails
                                                    {checkApproval(FreelancerDetail?.requested_changes_keys, 'portfolio')}
                                                </>
                                            }
                                            {...a11yProps(0)}
                                            className="tab-title"
                                        />
                                        <Tab
                                            label={
                                                <>
                                                    Projects
                                                    {checkApproval(FreelancerDetail?.requested_changes_keys, 'projects')}
                                                </>
                                            }
                                            {...a11yProps(1)}
                                            className="tab-title"

                                        />
                                        {/* <Tab
                                    label={
                                        <>
                                            Work Experience
                                            {checkApproval(FreelancerDetail?.requested_changes_keys, 'work_experiences')}
                                        </>
                                    }
                                    {...a11yProps(2)}
                                    className="tab-title"
                                /> */}
                                        <Tab
                                            label={
                                                <>
                                                    Certificates
                                                    {checkApproval(FreelancerDetail?.requested_changes_keys, 'certifications')}
                                                </>
                                            }
                                            {...a11yProps(2)}
                                            className="tab-title"
                                        />
                                    </Tabs>
                                    <TabPanel value={value} index={0} >
                                        <ProfessionalDetails />
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="projects">
                                        <Projects />
                                    </TabPanel>
                                    {/* <TabPanel value={value} index={2}>
                                <WorkExperience />
                            </TabPanel> */}
                                    <TabPanel value={value} index={2}>
                                        <Certificates />
                                    </TabPanel>
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <div className={classes.innerContainer}>
                                    <Grid className='moreDetails'>
                                        <List className='moreDetails-list'>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Experience {checkApproval(FreelancerDetail?.requested_changes_keys, 'experience')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {FreelancerUpdatedDetail?.portfolio?.experience ?
                                                        `${FreelancerUpdatedDetail?.portfolio?.experience} Years` : 0
                                                    }
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Projects  {checkApproval(FreelancerDetail?.requested_changes_keys, 'project_count')} <span></span>
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {FreelancerUpdatedDetail?.portfolio?.projects_count ?
                                                        FreelancerUpdatedDetail?.portfolio?.projects_count : 0
                                                    }
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Availability {checkApproval(FreelancerDetail?.requested_changes_keys, 'availability')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {FreelancerUpdatedDetail?.portfolio?.availability ?
                                                        getAvailability.map((check) => {
                                                            if (check.id === FreelancerUpdatedDetail?.portfolio?.availability) {
                                                                return check.name
                                                            }
                                                        }) : '-'
                                                    }
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Education {checkApproval(FreelancerDetail?.requested_changes_keys, 'education_details')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {FreelancerUpdatedDetail?.portfolio?.education_details ?
                                                        FreelancerUpdatedDetail?.portfolio?.education_details : '-'
                                                    }
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Profile Type {checkApproval(FreelancerDetail?.requested_changes_keys, 'profile_type')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {FreelancerUpdatedDetail?.portfolio?.profile_type?.title ?
                                                        FreelancerUpdatedDetail?.portfolio?.profile_type?.title : '-'
                                                    }
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography variant='p' component='p'>
                                                    Price {checkApproval(FreelancerDetail?.requested_changes_keys, 'salary_type') || checkApproval(FreelancerDetail?.requested_changes_keys, 'amount')}
                                                </Typography>
                                                <Typography variant='h6' component='h6'>
                                                    {findAmountPerType(FreelancerUpdatedDetail?.portfolio?.amount, FreelancerUpdatedDetail?.portfolio?.salary_type) || '-'}
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    {FreelancerUpdatedDetail?.profile_image &&
                        <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={FreelancerUpdatedDetail?.profile_image?.url ?? FreelancerUpdatedDetail?.profile_image} />
                    }
                </>
            }
        </>
    );
}

export default withStyles(styles)(FreelancerDetailView);
