import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { reduxForm, Field } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { required } from 'utils/validation';

const password = value => (
    value && !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(value)
        ? 'Password must have eight characters, at least one letter, one number and one special character(!@#$%^&*)'
        : undefined
);
const passwordsMatch = (value, allValues) => {
    if (value !== allValues.new_password1) {
        return 'Passwords does not match';
    }
    return undefined;
};
const styles = (theme) => ({
    inputIconsColor: {
        color: "#495057"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    grid: {
        textAlign: 'end !important'
    }
});


function PasswordForm(props) {
    const { classes, handleSubmit } = props;
    const [values, setValues] = useState({
        showOldPassword: false, showConfirmPassword: false, showNewPassword: false,
    });
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={handleSubmit}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="old_password"
                                    component={CustomInput}
                                    id="old_password"
                                    labelText="Old Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, password]}
                                    inputProps={{
                                        type: (values.showOldPassword ? 'text' : 'password'),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setValues({ ...values, showOldPassword: !values.showOldPassword }); }}
                                                    onMouseDown={handleMouseDownPassword}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                >
                                                    {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="new_password1"
                                    component={CustomInput}
                                    id="new_password1"
                                    labelText="New Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, password, passwordsMatch]}
                                    inputProps={{
                                        type: (values.showNewPassword ? 'text' : 'password'),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle new password visibility"
                                                    onClick={() => { setValues({ ...values, showNewPassword: !values.showNewPassword }); }}
                                                    onMouseDown={handleMouseDownPassword}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                >
                                                    {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="new_password2"
                                    component={CustomInput}
                                    id="new_password2"
                                    labelText="Confirm Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, password, passwordsMatch]}
                                    inputProps={{
                                        type: (values.showConfirmPassword ? 'text' : 'password'),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirm password visibility"
                                                    onClick={() => { setValues({ ...values, showConfirmPassword: !values.showConfirmPassword }); }}
                                                    onMouseDown={handleMouseDownPassword}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                >
                                                    {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                <Button type="submit" color="info">Submit</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
const ReduxFormMapped = reduxForm({
    form: 'PasswordForm', // a unique identifier for this form
    enableReinitialize: false
})(PasswordForm)
export default withStyles(styles)(ReduxFormMapped);