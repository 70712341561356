const initialState = {
    registationRequestList: [],
    requestDetail: null,
    developerRequestDetail: null,
    clientNewRequestDetail: null,
    agencyNewRequestDetail: null,
    freelancerNewRequestDetail: null,
    clientUpdateRequestDetail: null,
    agencyUpdateRequestDetail: null,
    freelancerUpdateRequestDetail: null,
    agencyList: [],
};

const userRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REGISTRATION_REQUEST': {
            return { ...state, registationRequestList: action.payload };
        }
        case 'REQUEST_DETAIL': {
            return { ...state, requestDetail: action.payload };
        }
        case 'CLIENT_NEW_REQUEST_DETAIL': {
            return { ...state, clientNewRequestDetail: action.payload };
        }
        case 'AGENCY_NEW_REQUEST_DETAIL': {
            return { ...state, agencyNewRequestDetail: action.payload };
        }
        case 'FREELANCER_NEW_REQUEST_DETAIL': {
            return { ...state, freelancerNewRequestDetail: action.payload };
        }
        case 'CLIENT_UPDATE_REQUEST_DETAIL': {
            return { ...state, clientUpdateRequestDetail: action.payload };
        }
        case 'AGENCY_UPDATE_REQUEST_DETAIL': {
            return { ...state, agencyUpdateRequestDetail: action.payload };
        }
        case 'FREELANCER_UPDATE_REQUEST_DETAIL': {
            return { ...state, freelancerUpdateRequestDetail: action.payload };
        }
        case 'DEVELOPER_REQUEST_DETAIL': {
            return { ...state, developerRequestDetail: action.payload };
        }
        case 'AGENCY_LIST': {
            return { ...state, agencyList: action.payload };
        }
        default:
            return { ...state };
    }
};
export default userRequestReducer;
