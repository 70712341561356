import React, { useEffect, useContext, useState } from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import "assets/css/profile.css";
import CustomButton from "components/CustomButtons/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Form from "./Form";
import * as Actions from '../../../../store/actions/index';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { LoaderContext } from "utils/LoaderContext";
import { checkForImageValidations, errorToast } from 'utils/Comman';

function ManageClient() {
    const history = useHistory();
    const userId = history.location.state;
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [clientDetails, setClientDetails] = useState(null);

    const [files, setFiles] = useState({ profile: null, identity: null });


    const submitUserProfile = (values) => {
        delete values.company.user
        const addUserProfileData = {
            first_name: values.first_name.trim(),
            is_active: (values.is_active === "1") ? true : false,
            last_name: values.last_name.trim(),
            contact_number: values.contact_number,
            city: values.city.id,
            profile_image: files.profile,
            company: {
                name: values.company.name.trim(),
                is_registered_company: (values.company.is_registered_company) ? values.company.is_registered_company : false,
                company_url: values.company.company_url,
                identity: files.identity,
                size: values.company.size,
                registration_number: values.company.registration_number
            },
            branches: values.branches.map((val) => ({ city: val.city.id, is_head_quarter: val.is_head_quarter }))
        };
        toggalLoader(true);
        if (typeof addUserProfileData.profile_image === 'string' || addUserProfileData.profile_image === null) delete addUserProfileData.profile_image
        if (typeof addUserProfileData.company.identity === 'string' || addUserProfileData.company.identity === null) delete addUserProfileData.company.identity
        dispatch(Actions.updateClient(userId, addUserProfileData))
            .then((response) => {
                if (response.status === 200) {
                    toast.success("Client's profile updated successfully");
                    history.push("/manage-users");
                } else {
                    errorToast(response);
                }
                toggalLoader(false);
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    }

    const handleImageUpload = async (e) => {
        let file = null;
        let whichFile = null;
        let fileType = null;
        let dimensionCheck = true;
        if (e instanceof File) {
            file = e;
            whichFile = 'identity';
            dimensionCheck = false;
            fileType = 'file';
        } else if (e.target.files.length !== 0) {
            file = e.target.files[0];
            whichFile = 'profile';
            fileType = 'image';
        }
        if (file) {
            const fileCheck = await checkForImageValidations(file, fileType, dimensionCheck);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message)
            } else {
                const formData = new FormData();
                formData.append("file_obj", file);
                dispatch(Actions.uploadImage(formData)).then(response => {
                    const filesObject = { ...files }
                    filesObject[whichFile] = response.data;
                    setFiles(filesObject);
                });
            }
        }
    };

    useEffect(() => {
        dispatch(Actions.getClientDetails(userId)).then(response => {
            if (response.status === 200) {
                const userData = { ...response.data }
                userData.is_active = (userData.is_active === true) ? "1" : "0";
                const filesObject = {
                    ...files,
                    profile: userData?.profile_image,
                    identity: userData?.company?.identity
                }
                setFiles(filesObject);
                setClientDetails(userData);
            } else {
                setClientDetails(null);
            }
        })
        //window.scrollTo(0, 0);
    }, []);

    return (
        <Container className='profile-container my_profile-container'>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <CustomButton
                    variant="contained"
                    className="primaryButton innerBackBtn"
                    startIcon={<ArrowBackIosIcon />}
                    color="info"
                    onClick={() => {
                        history.push("/manage-users");
                    }}>
                    Back
                </CustomButton>
            </Grid>
            <Grid className='profile-inner add_profile-inner'>
                <Form initialValues={clientDetails}
                    files={files}
                    uploadImage={handleImageUpload}
                    onSubmit={(values) => {
                        submitUserProfile(values);
                    }} />
            </Grid>
        </Container>
    );
}

export default ManageClient;