import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { required, checkString, numberOnly } from 'utils/validation'
import "assets/css/details-form.css";
import "assets/css/profile.css";
import "assets/css/material-dashboard-react.css";
import ChipAutocomplete from "components/CutomAutoComplete/ChipAutocomplete";
import { Field, FieldArray, getFormValues, reduxForm } from "redux-form";
import CustomInput from "components/CustomInput/CustomInput";
import { CustomEditor } from "components/CustomEditor/CustomEditor";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Actions from '../../../../../store/actions/index';
import SingleAutocomplete from "components/CutomAutoComplete/SingleAutocomplete";
import CustomFileUpload from "components/CustomFileUpload/CustomFIleUpload";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import LanguageField from "components/FieldArrayComponent/LanguageField";
import { MenuItem } from "@material-ui/core";
import { SelectField } from "components/CutomAutoComplete/AsynAutocomplete";
import { getAvailability, errorToast } from "utils/Comman";
import { FILETYPE } from "utils/constant";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function ProfessionalDetailForm(props) {
    const { initialValues, uploadImage, handleSubmit, change, formStates } = props;
    const [time, setTime] = useState('');
    const history = useHistory();
    const dispatch = useDispatch()
    const allList = useSelector((state) => (state.manageUsersReducer))

    const getSkill = () => {
        dispatch(Actions.getSkills()).then(response => {
            if (response.status === 200) {
                dispatch({ type: "SKILL", payload: response.data });
            } else {
                dispatch({ type: "SKILL", payload: null });
                errorToast(response);
            }
        }).catch((response) => {
            dispatch({ type: "SKILL", payload: null });
            errorToast(response);
        });
    }
    const getSubSkill = (parent) => {
        dispatch(Actions.getSubSkills(parent)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "SUB_SKILL", payload: response.data });
            } else {
                dispatch({ type: "SUB_SKILL", payload: null });
                errorToast(response);
            }
        }).catch((response) => {
            dispatch({ type: "SUB_SKILL", payload: null });
            errorToast(response);
        });
    }
    function getDifference(array1, array2) {
        return array1.filter(object1 => {
            return array2.some(object2 => {
                return object1.id === object2.id;
            });
        });
    }
    const changeSubSkill = (skills) => {
        let skillId = []
        skills.map((skill) => (
            skillId.push(skill.id)
        ))
        dispatch(Actions.getSubSkills(skillId)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "SUB_SKILL", payload: response.data });
                change('portfolio.sub_skills', getDifference(response.data, formStates?.portfolio?.sub_skills))
            } else {
                dispatch({ type: "SUB_SKILL", payload: null });
            }
        });
    }
    function getTechnologies() {
        dispatch(Actions.getTechnologies()).then(response => {
            if (response.status === 200) {
                dispatch({ type: "TECHNOLOGIES", payload: response.data });
            } else {
                dispatch({ type: "TECHNOLOGIES", payload: null });
                errorToast(response);
            }
        });
    }
    function getProfileType() {
        dispatch(Actions.ProfileTypes()).then(response => {
            if (response.status === 200) {
                dispatch({ type: "PROFILE_TYPE", payload: response.data });
            } else {
                dispatch({ type: "PROFILE_TYPE", payload: [] });
                errorToast(response);
            }
        });
    }
    function getLanguageDifference(array1, array2) {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1?.key === object2?.key;
            });
        });
    }
    const getLanguages = (name, selectedLanguage) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            dispatch(Actions.getLanguages(name)).then((response) => {
                if (response.status === 200) {
                    const data = getLanguageDifference(response.data, selectedLanguage)
                    dispatch({ type: "LANGUAGE", payload: data });
                } else {
                    dispatch({ type: "LANGUAGE", payload: [] });
                    errorToast(response);
                }
            });
        }, 1000)
        );
    };

    function getProficiency() {
        dispatch(Actions.getProficiency()).then(response => {
            if (response.status === 200) {
                dispatch({ type: "PROFICIENCY", payload: response.data });
            } else {
                dispatch({ type: "PROFICIENCY", payload: [] });
                errorToast(response);
            }
        });
    }
    useEffect(() => {
        getSkill();
        getTechnologies()
        getProfileType();
        getProficiency();
    }, []);

    useEffect(() => {
        if (initialValues?.portfolio?.skills) {
            const parent = []
            initialValues.portfolio.skills.map((skill) => (
                parent.push(skill.id)))
            getSubSkill(parent);
        }
    }, [initialValues]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid className="add_developer-form">
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner" item lg={12} md={12} sm={12}>
                            <Field
                                name="portfolio.profile_type"
                                component={SingleAutocomplete}
                                id="portfolio.profile_type"
                                labelText="Profile Type*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={allList.profileType}
                                displayKey="title"
                                validate={[required]}
                                onInputChange={(value) => value}
                                inputProps={{
                                    placeholder: "Select Profile Type",
                                    type: "text",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className='formGrid-inner' item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="portfolio.technologies"
                                component={ChipAutocomplete}
                                id="technologies"
                                labelText="Technologies"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={allList.technologies}
                                displayKey="title"
                                validate={[required]}
                                onChange={() => { }}
                                inputProps={{
                                    placeholder: "Select technologies",
                                    type: "text",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className='formGrid-inner' item lg={12} md={12} sm={12}>
                            <Field
                                name="portfolio.skills"
                                component={ChipAutocomplete}
                                id="portfolio.skill"
                                labelText="Skill"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={allList.skill}
                                displayKey="title"
                                validate={[required]}
                                onChange={(event, value) => {
                                    changeSubSkill(value);
                                }}
                                inputProps={{
                                    placeholder: "Select skill",
                                    type: "text",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className='formGrid-inner' item lg={12} md={12} sm={12} xs={12}>
                            <Field
                                name="portfolio.sub_skills"
                                component={ChipAutocomplete}
                                id="sub_skills"
                                labelText="Sub-skill"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={allList.subSkill}
                                displayKey="title"
                                onChange={(value) => value}
                                inputProps={{
                                    placeholder: "Select sub-skill",
                                    type: "text",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.experience"
                                component={CustomInput}
                                id=""
                                labelText="Experience*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, numberOnly]}
                                inputProps={{
                                    placeholder: "Enter Experience",
                                    type: "number",
                                }}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.availability"
                                component={SelectField}
                                id="portfolio.availability"
                                label="Availability*"
                                validate={required}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                placeholder="Select Availability"
                            >
                                {getAvailability.map((available) => (
                                    <MenuItem value={available.id} key={available.id}>
                                        {available.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="portfolio.education_details"
                                component={CustomInput}
                                id=""
                                labelText="Education*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkString]}
                                inputProps={{
                                    placeholder: "Enter Education",
                                    type: "text",
                                }}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                type="file"
                                name='portfolio.document_file'
                                id="company.identity"
                                component={CustomFileUpload}
                                onChange={(e) => uploadImage(e)}
                                validate={[required]}
                                mimeType={FILETYPE.file}
                                inputProps={{ accept: FILETYPE.file, required: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid container className="formGrid">
                            <Grid className="formGrid-inner" item lg={12} md={12} sm={12} xs={12}>
                                <Field
                                    name="portfolio.specialties"
                                    component={CustomEditor}
                                    id="portfolio.specialties"
                                    labelText="Specialties"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    multiline={true}
                                    rows={4}
                                    maxRows={10}
                                    // validate={[required]}
                                    placeholder="Enter specialties"
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="formGrid">
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Field
                                    name="portfolio.description"
                                    component={CustomEditor}
                                    id="portfolio.description"
                                    labelText="About*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    multiline={true}
                                    rows={4}
                                    maxRows={10}
                                    validate={[required]}
                                    placeholder="Enter text"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <FieldArray name="portfolio.communication_language"
                        component={LanguageField} required={required}
                        languageList={allList.language} getLanguages={getLanguages} proficiencyList={allList.proficiencyList} />
                    <Grid className="action-div">
                        <Link onClick={() => (history.push('/manage-users'))} underline="none" className="previousBtn">
                            Cancel
                        </Link>
                        <Button
                            variant="contained"
                            className="primaryButton nextBtn"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
const ReduxFormMapped = reduxForm({
    form: "ProfesionalDetailForm", // a unique identifier for this form
    enableReinitialize: true,
})(ProfessionalDetailForm);
const mapStateToProps = (state) => {
    return { formStates: getFormValues('ProfesionalDetailForm')(state) }
}
export default connect(mapStateToProps, "")(ReduxFormMapped)