import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../store/actions';
import { toast } from 'react-toastify';
import { LoaderContext } from "utils/LoaderContext";
import Form from './component/Form';
import { errorToast } from 'utils/Comman';

function ResetPassword(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const handledResetPassword = (values) => {
        toggalLoader(true);
        const formData = new FormData();
        formData.append('new_password1', values.new_password1);
        formData.append('new_password2', values.new_password2);
        formData.append('uid', match.params.uid);
        formData.append('token', match.params.token);
        dispatch(Actions.resetPassword(formData)).then(res => {
            if (res.status === 200) {
                toggalLoader(false);
                toast.success(res.data.detail);
                props.history.push('/login');
            } else {
                errorToast(res);
                toggalLoader(false);
            }
        });
    }
    const validateInvitation = () => {
        const payload = {
            'uid': match.params.uid,
            'token': match.params.token
        }
        dispatch(Actions.validateToken(payload)).then(res => {
            if (res.status === 200) {
                toggalLoader(false);
            } else {
                errorToast(res);
                props.history.push('/login');
                toggalLoader(false);
            }
        }).catch(() => {
            toggalLoader(false);
        });
    }
    useEffect(() => {
        validateInvitation()
    }, [])
    return (
        <div>
            <Form onSubmit={(values) => {
                handledResetPassword(values);
            }} />
        </div>
    );
}
export default ResetPassword;

