
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import fileIcon from 'assets/img/icons/file-icon.svg';
import 'assets/css/profile.css';
import MessageDialog from 'components/Dialog/MessageDialog';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomButton from "components/CustomButtons/Button";
import { useHistory, useParams } from 'react-router-dom';
import { checkApproval, errorToast } from 'utils/Comman';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../store/actions/index';
import { hasPermission } from 'utils/auth';
import * as PERMISSION from "utils/permissionConstant";
import { toast } from 'react-toastify';
import ImagePreviewDialog from 'components/Dialog/ImagePreviewDialog';
import copyIcon from 'assets/img/icons/filecopy-icon.svg';
import checkIcon from 'assets/img/icons/check.svg';
import ClientDetailViewSkeleton from '../../Skeleton/ClientDetailViewSkeleton';
import { dispatchPageLoading } from 'utils/Comman';

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileImgApproval: {
        border: '6px solid rgba(255, 158, 45, 0.4)',
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 120px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        }
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
        }
    },
    formGridInner: {
        width: '48%',
    },

});

function ClientDetailView(props) {
    const userDetail = useSelector((state) => state.userRequestReducer.clientUpdateRequestDetail);
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const user = useSelector(state => state.authReducer?.userDetails);
    const [openPreview, setOpenPreview] = useState(false);
    const [copied, setCopied] = useState(copyIcon);
    const [open, setOpen] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const { classes } = props;

    const handleCopy = (e) => {
        e.preventDefault();
        setCopied(checkIcon);
        navigator.clipboard.writeText(userDetail?.updated_data_with_request_change?.company?.company_url);
        setTimeout(() => {
            setCopied(copyIcon);
        }, 1000);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    const checkProfileApproval = (profileReqKey, profilekey) => {
        const check = profileReqKey?.indexOf(profilekey);
        if (check === -1) {
            return classes.profileImg
        } else {
            return classes.profileImgApproval
        }
    }
    const getRequestDetail = () => {
        dispatch(Actions.getRequestDetail(params.id))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: 'CLIENT_UPDATE_REQUEST_DETAIL', payload: response.data });
                    dispatchPageLoading(dispatch)
                } else {
                    dispatch({ type: 'CLIENT_UPDATE_REQUEST_DETAIL', payload: null });
                    dispatchPageLoading(dispatch)
                    errorToast(response);
                }
            })
            .catch((response) => {
                dispatch({ type: "PAGE_LOADING", payload: false });
                errorToast(response);
            });
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
        }
    };
    const handleChangeStatus = (params) => {
        setButtonLoading(true)
        dispatch(Actions.updateRequestStaus(userDetail.id, params))
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.status === 3) {
                        toast.success("Profile rejected successfully");
                    } else {
                        toast.success("Profile approved successfully");
                    }
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: response.data });
                    dispatchPageLoading(dispatch)
                    setButtonLoading(false)
                    history.push('/updated-request');
                } else {
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: null });
                    dispatchPageLoading(dispatch)
                    setButtonLoading(false)
                    errorToast(response);
                }
            })
            .catch((response) => {
                dispatchPageLoading(dispatch)
                setButtonLoading(false)
                errorToast(response);
            });
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
        }
    }
    useEffect(() => {
        getRequestDetail();
        //window.scrollTo(0, 0);
    }, [])
    return (
        <>
            {pageLoading ?
                <ClientDetailViewSkeleton classes={classes} /> :
                <>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <CustomButton
                            variant="contained"
                            className="primaryButton innerBackBtn"
                            startIcon={<ArrowBackIosIcon />}
                            color="info"
                            onClick={() => {
                                dispatch({ type: 'CLIENT_UPDATE_REQUEST_DETAIL', payload: null });
                                history.push("/updated-request");
                            }}
                        >
                            Back
                        </CustomButton>
                    </Grid>
                    <Grid container className={classes.innerContainer}>
                        <Grid item xl={9} lg={10} md={12} sm={12}>
                            <div className={classes.profileDetail}>
                                <Avatar alt="profile-img"
                                    onClick={() => handleOpenPreview()}
                                    src={userDetail?.updated_data_with_request_change.profile_image?.url ?? userDetail?.updated_data_with_request_change?.profile_image}
                                    className={checkProfileApproval(userDetail?.requested_changes_keys, 'profile_image')}
                                />
                                <div className={classes.profileContent}>
                                    <Typography variant='h4'>{userDetail?.updated_data_with_request_change?.company?.name} {checkApproval(userDetail?.requested_changes_keys, 'name')}</Typography>
                                    <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{userDetail?.user?.uid}</span></Typography>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Full Name
                                            </label>
                                            <Typography variant='p' component='p'>{`${userDetail?.user?.first_name} ${userDetail?.user?.last_name}`}</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Email
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.user?.email ?
                                                    userDetail?.user?.email : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Contact Number
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.updated_data_with_request_change?.contact_number ?
                                                    userDetail?.updated_data_with_request_change?.contact_number : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Location
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.updated_data_with_request_change?.city?.display_name ?
                                                    userDetail?.updated_data_with_request_change?.city?.display_name : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={8} >
                            <Grid className='profile-inner my_profile-inner'>
                                <Grid className='add_profile-form'>
                                    <Grid container className='formGrid'>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControl variant="standard" className='formControl'>
                                                <Grid style={{ width: '100%', }}>
                                                    <label className="website-label">Website {checkApproval(userDetail?.requested_changes_keys, 'company_url')} </label>
                                                    {userDetail?.updated_data_with_request_change?.company?.company_url ?
                                                        <a className='project-link' href={userDetail?.updated_data_with_request_change?.company?.company_url} target="_blank" rel="noreferrer">
                                                            {userDetail?.updated_data_with_request_change?.company?.company_url}
                                                            <img className="cursor-pointer" src={copied} height='16px' alt="img" onClick={(e) => handleCopy(e)} />
                                                        </a>
                                                        : <Typography variant='p' component='p' className='value'>-</Typography>}
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='formGrid'>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControl variant="standard" className='formControl'>
                                                <Grid>
                                                    <label className="idProof-label">ID Proof
                                                        {checkApproval(userDetail?.requested_changes_keys, 'identity')}
                                                    </label>
                                                    {userDetail?.updated_data_with_request_change?.company?.file_name ?
                                                        <Typography variant='p' component='p' className='idProof-name'>
                                                            <span><img src={fileIcon} height="16px" alt="img" /></span>
                                                            {userDetail?.updated_data_with_request_change?.company?.file_name}
                                                            {/* <a onClick={() => saveAs(userDetail?.updated_data_with_request_change?.company?.identity, imageNameFromUrl(userDetail?.updated_data_with_request_change?.company?.identity))} className="downloadBtn"
                                                    >Download</a> */}
                                                            <a href={userDetail?.updated_data_with_request_change?.company?.identity} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                                            >View</a>
                                                        </Typography>
                                                        : <Typography variant='p' component='p' className='idProof-name'>-</Typography>}
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" style={{ margin: '24px 0', }} />
                                    <Typography variant='h5' component='h5' className='title'>
                                        Branches Details  {checkApproval(userDetail?.requested_changes_keys, 'branches')}
                                    </Typography>
                                    <Grid container className='formGrid'>
                                        {userDetail?.updated_data_with_request_change?.branches ?
                                            userDetail?.updated_data_with_request_change?.branches?.map((detail, index) => (
                                                <Grid className='formGrid-inner' key={detail.id}>
                                                    <FormControl variant="standard" className='formControl'>
                                                        <Grid>
                                                            <label>Branch {index + 1}</label>
                                                            <Typography variant='p' component='p' className='value'>
                                                                {detail?.city?.display_name}
                                                            </Typography>
                                                        </Grid>
                                                    </FormControl>
                                                </Grid>
                                            )) : <Typography variant='p' component='p' className='value'>-</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4}>
                            <div className={classes.innerContainer}>
                                <Grid className='moreDetails'>
                                    <List className='moreDetails-list'>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Main Company Office Location {checkApproval(userDetail?.requested_changes_keys, 'head_branch_location')}
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.updated_data_with_request_change?.branches ?
                                                    userDetail?.updated_data_with_request_change?.branches?.map((branch) =>
                                                        (branch.is_head_quarter === true ? branch.city.display_name : null)) : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Company Size {checkApproval(userDetail?.requested_changes_keys, 'size')}
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.updated_data_with_request_change?.company?.size ?
                                                    userDetail?.updated_data_with_request_change?.company?.size : 0
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Number of Branch {checkApproval(userDetail?.requested_changes_keys, 'total_branches')}
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.updated_data_with_request_change?.branches ?
                                                    userDetail?.updated_data_with_request_change?.branches?.length : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Company Registration Number {checkApproval(userDetail?.requested_changes_keys, 'registration_number')}
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.updated_data_with_request_change?.company?.registration_number ?
                                                    userDetail?.updated_data_with_request_change?.company?.registration_number : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className='action-div'>
                        {hasPermission(PERMISSION.DELETE_USER_REQUEST, user?.user_permissions) &&
                            <Link underline="none" className='previousBtn' onClick={() => handleClickOpen()}>Reject</Link>
                        }
                        {hasPermission(PERMISSION.CHANGE_USER_REQUEST, user?.user_permissions) &&
                            <Button variant="contained" className='primaryButton nextBtn' disabled={buttonLoading} onClick={() => handleChangeStatus({ status: 2 })} >Approve</Button >
                        }
                    </Grid>
                    <MessageDialog open={open} close={handleClose} title={'Reject'} disabled={buttonLoading}
                        onSubmit={(data) => {
                            handleChangeStatus({ ...data, status: 3 })
                        }} />
                    {userDetail?.updated_data_with_request_change.profile_image &&
                        <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={userDetail?.updated_data_with_request_change.profile_image?.url ?? userDetail?.updated_data_with_request_change?.profile_image} />
                    }
                </>
            }
        </>
    );
}

export default withStyles(styles)(ClientDetailView);