const initialState = {
	countryList: [],
	manageActiveTab: {
		activeTab: 0,
	},
	developerTab: {
		activeTab: 0,
	},
	developerDetailTab: {
		activeTab: 0,
	},
	pageNumber: 1,
	pageLoading: true,
	contentLoading: true,
	notifications: [],
};

const commonReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_COUNTRY_LIST': {
			return { ...state, countryList: action.payload };
		}
		case 'SET_ACTIVE_TAB': {
			return { ...state, manageActiveTab: action.payload };
		}
		case 'SET_DEVELOPER_TAB': {
			return { ...state, developerTab: action.payload };
		}
		case 'SET_DEVELOPER_DETAIL_TAB': {
			return { ...state, developerDetailTab: action.payload };
		}
		case 'SET_PAGE': {
			return { ...state, pageNumber: action.payload };
		}
		case 'PAGE_LOADING': {
			return { ...state, pageLoading: action.payload };
		}
		case 'CONTENT_LOADING': {
			return { ...state, contentLoading: action.payload };
		}
		case 'GET_NOTIFICATIONS': {
			return { ...state, notifications: action.payload };
		}
		default:
			return { ...state };
	}
};
export default commonReducer;
