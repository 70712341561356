import React from 'react';
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from '@material-ui/core/FormHelperText';
export default function CustomInput(props) {
  const { meta: { touched, error }, formControlProps, labelText, id, labelProps, inputProps, input } = props;

  return (
    <FormControl
      variant='standard'
      className="formControl"
      {...formControlProps}>
      {labelText !== undefined ? (
        <InputLabel
          shrink
          htmlFor={id}
          {...labelProps}

        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        className="inputField"
        inputProps={{
          autoComplete: 'off',

          ...input
        }}
        {...inputProps}
        id={id}
        error={touched && Boolean(error)}
      />
      {touched && error ? (
        <FormHelperText error>{touched && error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}


CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  input: PropTypes.object,
};
