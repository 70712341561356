import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import FormControl from "@material-ui/core/FormControl";
import 'react-phone-input-2/lib/material.css';
import FormHelperText from '@material-ui/core/FormHelperText';
export default function PhoneNumberField(props) {

  const { meta: { touched, error
  }, formControlProps, phone, id, countryCode, handleChangeCity, disableDropdown, input: { onBlur } } = props;

  return (
    <FormControl
      {...formControlProps}
      className={`formControl`}>
      <PhoneInput
        specialLabel="Contact Number*"
        shrink
        className="phoneInputField"
        country={countryCode}
        disableCountryGuess={true}
        // onlyCountries={['in', 'us']}
        autoFormat={true}
        countryCodeEditable={false}
        onChange={(data, value) => {
          onBlur()
          handleChangeCity(data, value)
        }}
        disableDropdown={disableDropdown || false}
        inputProps={{
          autoComplete: 'new-password',
          form: {
            autoComplete: 'off',
          },
        }}
        id={id}
        value={phone}
        error={touched && Boolean(error)}
      />
      {touched && error && <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>}
    </FormControl>
  );
}

PhoneNumberField.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  input: PropTypes.object,
};