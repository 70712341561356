const initialState = {
	domainList: []
};

const domainReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_DOMAIN': {
			return { ...state, domainList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default domainReducer;
