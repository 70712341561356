import React from 'react'
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { checkForImageValidations, imageNameFromUrl } from '../../utils/Comman';
import { FILETYPE } from 'utils/constant';
import { toast } from 'react-toastify';

class CustomCertiUpload extends React.Component {
    constructor(props) {
        super(props)
        this.onChange = this.onChange.bind(this)
    }
    async onChange(e) {
        const { input: { onChange } } = this.props;
        let file = e.target.files[0];
        const fileCheck = await checkForImageValidations(file, 'certification', false);
        if (fileCheck instanceof Error) {
            toast.error(fileCheck.message);
            e.target.value = null;
        } else {
            onChange(file);
        }
    }
    render() {
        const { input: { value }, meta: { touched, error }, id, mimeType, label } = this.props;
        const fileName = (value?.name) ? value.name : (value?.url ? imageNameFromUrl(value.url) : (value ? imageNameFromUrl(value) : 'Select File'));
        return (
            <FormControl variant="standard" className="formControl selectFileFormcontrol">
                <InputLabel shrink htmlFor="bootstrap-input">
                    {label || "ID Proof*"}
                </InputLabel>
                <input
                    accept={mimeType || FILETYPE.certification}
                    style={{ display: "none" }}
                    id={id}
                    type="file"
                    onChange={(e) => this.onChange(e)}
                />
                <label htmlFor={id}>
                    <Button component="span" className="inputField customFile-input selectFileBtn">
                        <span className="browseText">{fileName}</span>
                    </Button>
                </label>
                {touched && error ? (
                    <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>
                ) : null}
            </FormControl>
        )
    }
}

export default CustomCertiUpload