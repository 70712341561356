import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import 'assets/css/admin-navbarlink.css'
import closeBtn from "assets/img/cancle-btn.png";
import NotificationProfile from "assets/img/icons/user-round-icon.svg";
import { CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import { USER_TYPES, REQUEST_STATUS } from 'utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "store/actions/index";

moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.updateLocale('en', {
    relativeTime: {
        s: 'few seconds ago',
        ss: '%d seconds ago',
        m: "a minute ago",
        mm: "%d minutes ago",
        h: "an hour ago",
        hh: "%d hours ago",
        d: "a day ago",
        dd: "%d days ago",
        w: "a week ago",
        ww: "%d weeks ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
    }
});

function NotificationDrawer(props) {
    const { open, close } = props;
    const dispatch = useDispatch();
    const notificationData = useSelector(state => state.commonReducer?.notifications);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(false);

    const getUrl = (type, id, newRegistered, status, actionName) => {
        if (status !== REQUEST_STATUS.PENDING) return false;

        switch (actionName) {
            case "agencyrequest":
                return '/new-agency-request';
            case "userrequest":
                switch (type) {
                    case USER_TYPES.CLIENT:
                        return (newRegistered === true) ? `/new-requests/client-view/${id}` : `/updated-request/client-view/${id}`;
                    case USER_TYPES.AGENCY:
                        return (newRegistered === true) ? `/new-requests/agency-view/${id}` : `/updated-request/agency-view/${id}`;
                    case USER_TYPES.FREELANCER:
                        return (newRegistered === true) ? `/new-requests/freelancer-view/${id}` : `/updated-request/freelancer-view/${id}`;
                    case USER_TYPES.AGENCY_DEVELOPER:
                        return `/developer-requests/${id}`;
                    default:
                        return false;
                }
            default:
                break;
        }
    }
    const handleRead = (id = null, unread = true) => {
        if (notificationData?.unread > 0 && unread) {
            dispatch(Actions.markNotificationAsRead(id)).then(response => {
                if (response.status === 200) {
                    let notifiedData = {};
                    if (id) {
                        notifiedData = {
                            ...notificationData,
                            unread: notificationData?.unread - 1,
                            results: notificationData?.results?.map(notification => {
                                if (notification.id === id) {
                                    notification.unread = false;
                                }
                                return notification
                            })
                        }
                    } else {
                        notifiedData = {
                            ...notificationData,
                            unread: 0,
                            results: notificationData?.results?.map(notification => {
                                notification.unread = false;
                                return notification
                            })
                        }
                    }
                    dispatch({ type: "GET_NOTIFICATIONS", payload: notifiedData });
                }
            })
        }
    }
    const handleScroll = async (event) => {
        let top = event.target.scrollTop + event.target.clientHeight + 70;
        let bottom = event.target.scrollHeight;
        if (top >= bottom && notificationData?.next && !start) {
            !loading && setLoading(true);
            const pageNumber = page + 1;
            await dispatch(Actions.getNotifications(pageNumber, 10, notificationData?.results));
            setPage(pageNumber);
            setLoading(false);
        } else if (!notificationData?.next) {
            setLoading(false);
        }
    }
    useEffect(() => {
        loading ? setStart(true) : setStart(false);
    }, [loading])

    return (
        <div className="right-notification">
            <Drawer anchor="right" open={open} onClose={close}>
                <div role="presentation">
                    <div className="notification-box">
                        <div className="notification-header">
                            <div className='notification-link'>
                                <Link underline="none" className="close-btn cursor-pointer" onClick={close}><img src={closeBtn} /></Link>
                            </div>
                            <h2>Notifications
                                {notificationData?.unread !== 0 &&
                                    <span>{notificationData?.unread}</span>
                                }
                            </h2>
                            {notificationData?.results && notificationData?.results.length > 0 &&
                                <Link underline="none" className="Mark-btn cursor-pointer" onClick={() => handleRead()}>Mark all as read</Link>
                            }
                        </div>
                        <div className="notification-body" onScroll={handleScroll}>
                            <List>
                                {notificationData?.count > 0 && notificationData?.results?.map((notification, index) => (
                                    <ListItem key={index} className={!notification?.unread && 'notification-bg'}>
                                        <Link
                                            className={(notification?.data?.status !== REQUEST_STATUS.PENDING && !notification?.unread) ? 'notification-list cursor-text' : 'notification-list'}
                                            href={getUrl(notification?.data?.user?.user_type, notification?.data?.id, notification?.data?.is_new_registered, notification?.data?.status, notification?.action_name)}
                                            target="_blank"
                                            onClick={() => handleRead(notification?.id, notification?.unread)}>
                                            <div className='Left-profile'>
                                                <img src={!notification?.actor?.profile_image ? NotificationProfile : notification?.actor?.profile_image} alt="" />
                                                {notification?.data?.status === REQUEST_STATUS.PENDING && <span></span>}
                                            </div>
                                            <div className='notification-content'>
                                                <Typography variant='body1' dangerouslySetInnerHTML={{ __html: notification?.verb }}></Typography>
                                                <span>{moment(notification?.timestamp, "YYYYMMDD, h:mm:ss a").fromNow(true)}</span>
                                            </div>
                                        </Link>
                                    </ListItem>
                                ))}
                                {loading &&
                                    <div style={{ textAlign: "center", padding: "10px 0" }}>
                                        <CircularProgress />
                                    </div>
                                }
                            </List>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default NotificationDrawer;
