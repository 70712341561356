import React from 'react';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import 'assets/css/projectCard.css';
import 'assets/css/skeleton.css';
import Tooltip from "@material-ui/core/Tooltip";
import { Skeleton } from '@material-ui/lab';

const AgencyDeveloperProjectSkeleton = () => {
    return (
        <>
            <Grid container className="projectsList" >
                <Grid item className="projectListItem">
                    <Card className="project-card" >
                        <CardContent >
                            <Grid className="project-top">
                                <div className="icon-container">
                                    <Typography variant='p' component='p'><Skeleton variant="circle" width={25} height={23} /></Typography>
                                </div>
                                <div className="project-title">
                                    <Typography variant='p' component='p' className="name cursor-pointer"><Skeleton width={138} height={23} /></Typography>
                                </div>
                            </Grid>
                            <Grid className='about_project'>
                                <Typography>
                                    <Skeleton width='100%' height={24} />
                                </Typography>
                            </Grid>
                            <Grid className='skillset'>
                                <Typography variant='p' component='p' className='profile-subtitle'><Skeleton width={133} height={20} /></Typography>
                                <List className="categoryList">
                                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                                </List>
                            </Grid>
                            <Grid className='skillset'>
                                <Typography variant='p' component='p' className='profile-subtitle'><Skeleton width={75} height={20} /></Typography>
                                <List className="categoryList">
                                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                                </List>
                            </Grid>
                            <Grid className='duration-details'>
                                <Typography>
                                    <Skeleton width={253} height={22} />
                                </Typography>
                                <Tooltip title="Project Link">
                                    <Skeleton width={14} height={24} />
                                </Tooltip>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Skeleton width={114} height={32} />
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyDeveloperProjectSkeleton