import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import Button from "components/CustomButtons/Button.js";
import { required } from 'utils/validation';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import { connect, useDispatch } from 'react-redux';

function MessageDialog(props) {
    const dispatch = useDispatch();
    const { open, close, title, handleSubmit, disabled } = props;

    const handleClose = (__, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") return false;
        close();
    }

    useEffect(() => {
        dispatch(reset('RejectionForm'));
    }, [!open]);
    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            To reject this request, please enter rejection message here. We will send updates
                            occasionally.
                        </DialogContentText>
                        <Field
                            name={`rejection_message`}
                            component={CustomTextArea}
                            className="multiSelect-Textfield"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={required}
                            multiline={true}
                            rows={2}
                            placeholder="Rejection Message"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button round onClick={handleClose} color="transparent">
                            Cancel
                        </Button>
                        <Button round type="submit" color="info" disabled={disabled}>
                            Submit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

const ReduxFormMapped = reduxForm({
    form: "RejectionForm", // a unique identifier for this form
    enableReinitialize: true,
})(MessageDialog);

const mapStateToProps = (state) => {
    return { formStates: getFormValues('RejectionForm')(state) }
}
export default connect(mapStateToProps, "")(ReduxFormMapped)