import axios from 'axios';
/* eslint-disable camelcase */

let baseUrl = process.env.REACT_APP_API_KEY;
class ApiService {
    get = (route) => {
        return new Promise((resolve, reject) => {
            axios.get(baseUrl + route, {
                headers: {
                    Authorization: "Token " + localStorage.getItem('ACCESS_TOKEN'),
                }
            }).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response && response.status === 400) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response && response.status === 401) {
                    localStorage.clear();
                    window.location.assign('/login');
                    reject(response);
                }
                reject(response);
            });
        });
    };
    authGet = (route, params = {}) => {
        return new Promise((resolve, reject) => {
            axios.get(baseUrl + route, { params }).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response && response.status === 400) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response && response.status === 401) {
                    window.location.assign('/login');
                    reject(response);
                }
                reject(response);
            });
        });
    };
    authPost = (route, params) => {
        return new Promise((resolve, reject) => {
            axios.post(baseUrl + route, params).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response && response.status === 400) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response && response.status === 401) {
                    localStorage.clear();
                    window.location.assign('/login');
                    reject(response);
                }
                resolve(response.data);
            });
        });
    }
    post = (route, params) => {
        return new Promise((resolve, reject) => {
            axios.post(baseUrl + route, params, {
                headers: {
                    Authorization: "Token " + localStorage.getItem('ACCESS_TOKEN'),
                }
            }).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response && response.status === 400) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response && response.status === 401) {
                    localStorage.clear();
                    window.location.assign('/login');
                    reject(response);
                }
            });
        });
    }
    delete = (route) => {
        return new Promise((resolve, reject) => {
            axios.delete(baseUrl + route, {
                headers: {
                    Authorization: "Token " + localStorage.getItem('ACCESS_TOKEN'),
                }
            }).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response && (response.status === 400 || response.status === 403)) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response && response.status === 401) {
                    localStorage.clear();
                    window.location.assign('/login');
                    reject(response);
                }
                reject(response);
            });
        });
    }
    patch = (route, params) => {
        return new Promise((resolve, reject) => {
            axios.patch(baseUrl + route, params, {
                headers: {
                    Authorization: "Token " + localStorage.getItem('ACCESS_TOKEN'),
                }
            }).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response.status === 400) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.assign('/login');
                    reject(response);
                }
                resolve(response);
            });
        });
    }
    put = (route, params) => {
        return new Promise((resolve, reject) => {
            axios.put(baseUrl + route, params, {
                headers: {
                    Authorization: "Token " + localStorage.getItem('ACCESS_TOKEN'),
                }
            }).then(response => {
                resolve(response);
            }).catch(({ response }) => {
                if (response.status === 400) {
                    resolve(response);
                }
                if (response && response.status === 404) {
                    window.location.href = '/notfound'
                }
                if (response.status === 401) {
                    localStorage.clear();
                    window.location.assign('/login');
                    reject(response);
                }
                resolve(response);
            });
        });
    }
}

const instance = new ApiService();
export default instance;
