const initialState = {
	profileTypeList: []
};

const profileTypeReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_PROFILE_TYPE': {
			return { ...state, profileTypeList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default profileTypeReducer;
