import React, { useState } from "react";
import DetailView from "./component/DetailView";
import "assets/css/profile.css";
import * as Actions from 'store/actions/index'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { errorToast } from "utils/Comman";
import { useHistory, useParams } from "react-router-dom";
import { dispatchPageLoading } from "utils/Comman";

function AgencyView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const [buttonLoading, setButtonLoading] = useState(false);
    const handleChangeStatus = (value) => {
        setButtonLoading(true)
        dispatch(Actions.updateRequestStaus(params.id, value))
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.status === 3) {
                        toast.success("Profile rejected successfully");
                    } else {
                        toast.success("Profile approved successfully");
                    }
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: response.data });
                    dispatchPageLoading(dispatch)
                    setButtonLoading(false)
                    history.push('/new-request');
                } else {
                    errorToast(response);
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: null });
                    dispatchPageLoading(dispatch)
                    setButtonLoading(false)
                }
            })
            .catch((response) => {
                dispatch({ type: "PAGE_LOADING", payload: false });
                setButtonLoading(false)
                errorToast(response);
            });
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
        }
    }
    return (
        <DetailView handleChangeStatus={handleChangeStatus} buttonLoading={buttonLoading} />
    )
}

export default AgencyView;
