import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import 'assets/css/projectCard.css';
import linkIcon from 'assets/img/icons/link-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../store/actions/index'
import ProjectDetailDialog from 'components/Dialog/ProjectDetailDialog';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import { useParams } from 'react-router-dom';
import { errorToast, dispatchContentLoading } from 'utils/Comman';
import Tooltip from "@material-ui/core/Tooltip";
import { SpliceList } from 'components/SpliceList/SpliceList';
import AgencyDeveloperProjectSkeleton from '../Skeleton/AgencyDeveloperSkeleton/AgencyDeveloperProjectSkeleton';

export default function AgencyDeveloperProject() {
    const projectDetails = useSelector((state) => (state.manageUsersReducer.agencyDeveloperProjectList));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    const [open, setOpen] = useState(false);
    const pageSize = 10;
    const dispatch = useDispatch();
    const params = useParams();
    const [dialogDetail, setDialogDetail] = useState({});

    const handleClickOpen = (project) => {
        setDialogDetail(project);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const getAgencyDeveloperProject = (page) => {
        dispatch(Actions.getDeveloperDetail(params?.id, 'projects', page)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: response.data });
                dispatchContentLoading(dispatch);
            } else {
                dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: null });
                dispatchContentLoading(dispatch);
                errorToast(response);
            }
        }).catch((response) => {
            dispatchContentLoading(dispatch);
            errorToast(response);
        })
    }
    useEffect(() => {
        getAgencyDeveloperProject()
        return () => {
            dispatch({ type: 'CONTENT_LOADING', payload: true });
        }
    }, [])
    return (
        <>
            {
                contentLoading ? <AgencyDeveloperProjectSkeleton />
                    :
                    <>
                        {projectDetails?.results && projectDetails?.results?.length > 0 ?
                            <Grid container className="projectsList" >
                                {projectDetails?.results?.map((project) => (
                                    <Grid item className="projectListItem" key={project.id}>
                                        <Card className="project-card" >
                                            <CardContent >
                                                <Grid className="project-top">
                                                    <div className="icon-container">
                                                        {/* <img src={brackets} className="project-icon" /> */}
                                                        <Typography variant='p' component='p'>{project?.title?.substring(0, 2).toUpperCase()}</Typography>
                                                    </div>
                                                    <div className="project-title">
                                                        <Typography variant='p' component='p' className="name cursor-pointer" onClick={() => handleClickOpen(project)}>{project?.title}</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid className='about_project'>
                                                    <Typography>
                                                        {project.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid className='skillset'>
                                                    <Typography variant='p' component='p' className='profile-subtitle'>Used Technologies</Typography>
                                                    {project?.technologies?.length > 0 ?
                                                        <List className="categoryList">
                                                            <SpliceList data={project?.technologies} />
                                                        </List> : '-'
                                                    }
                                                </Grid>
                                                <Grid className='skillset'>
                                                    <Typography variant='p' component='p' className='profile-subtitle'>Used Skills</Typography>
                                                    {project?.skills?.length > 0 ?
                                                        <List className="categoryList">
                                                            <SpliceList data={project?.skills} />
                                                        </List> : '-'
                                                    }
                                                </Grid>
                                                <Grid className='duration-details'>
                                                    <Typography>
                                                        {(project?.duration > 0) &&
                                                            <>
                                                                <span>{project?.duration} Months</span>
                                                                {project?.is_on_going && <span> - Ongoing</span>}
                                                            </>
                                                        }
                                                    </Typography>
                                                    {project?.link &&
                                                        <Tooltip title="Project Link">
                                                            <a href={project?.link} target="blank">
                                                                <img src={linkIcon} alt="img" />
                                                            </a>
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>))}
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <PaginationRounded
                                        totalPages={Math.ceil(projectDetails?.count / pageSize) || 0}
                                        handlePages={(page) => getAgencyDeveloperProject(page)}
                                    />
                                </Grid>
                            </Grid>
                            : <Typography variant='p' component='p'>No Records Found</Typography>
                        }
                        <ProjectDetailDialog open={open} detail={dialogDetail} close={() => handleClose()} />
                    </>
            }
        </>
    )
}
