/* eslint-disable */
import { FILES, FILETYPE, FILE_DIMENSION, SALARY_TYPE } from "./constant";
import { parsePhoneNumber } from 'libphonenumber-js'
import { toast } from "react-toastify";
import certificateIcon from 'assets/img/certificate.svg';
import pdfImage from 'assets/img/pdfImage.png';
import docImage from 'assets/img/docImage.png';
import { Typography } from "@material-ui/core";
import 'assets/css/profile.css';

export const getUserStatus = (user, username) => {
    if (!user.is_email_verified) {
        return { pathname: "/email-verify", state: { email: username, isNotShowTryAnotherEmail: true } };
    } else if (user.is_new_registered) {
        return { pathname: "/my-profile/add", state: null }
    } else {
        return { pathname: "/" }
    }
}
export const imageNameFromUrl = (url) => {
    if (!url) return false;
    const arr = url.split('/');
    if (arr.length == 0) return false;
    const index = arr.length - 1;
    return arr[index];
}

export const getAvailability = [{ id: 1, name: 'Part Time' }, { id: 2, name: 'Full Time' }]

export const checkApproval = (requestedKey, key) => {
    const check = requestedKey?.indexOf(key);
    if (check === -1) {
        return false
    } else {
        return <span className='updated-dot'></span> //eslint-disable-line
    }
}

const getHeightAndWidthFromDataUrl = dataURL => new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
        resolve({
            height: img.height,
            width: img.width
        })
    }
    img.src = dataURL
})

const getFileDimension = (file) => {
    try {
        if (file instanceof File) {
            let fileAsDataUrl = URL.createObjectURL(file);
            return getHeightAndWidthFromDataUrl(fileAsDataUrl).then(dimension => dimension);
        } else {
            return null
        }
    } catch (error) {
        return null;
    }
}

export const checkForImageValidations = async (file, whichType, checkForDimesion = false) => {
    try {
        // let whichType = certificate_file ? 'certificate_file_type' : (checkForDimesion || onlyImages) ? 'image' : 'file';
        const maxSize = FILES.PROFILE_MAX_SIZE_IN_MB;
        const { height, width } = { ...FILE_DIMENSION };
        const fileTypes = FILETYPE[whichType].split(',');
        const extension = (/[.]/.exec(file.name)) ? '.' + /[^.]+$/.exec(file.name)[0] : undefined;
        //FOR EXTENSION
        if (!extension || !fileTypes.includes(extension)) throw new Error(`${file.name} file is not a valid file please upload from ${FILETYPE[whichType]} files`);
        //FOR FILE SIZE
        if (file.size / 1024 / 1024 > maxSize) throw new Error(`${file.name} file size needs to be less than ${maxSize} MB`);
        //FOR FILE DIMENSION
        if (checkForDimesion) {
            const dimension = await getFileDimension(file);
            if (!dimension) throw new Error('Something went wrong...');
            if (dimension.height > height && dimension.width > width) throw new Error(`The file dimension needs to be ${height} x ${width}`);
        }

        return true;
    } catch (error) {
        return error;
    }
}

export const avoidPasting = event => {
    event.target.onpaste = e => e.preventDefault();
}

export const getCountryCode = (phone) => {
    try {
        const phoneNumber = parsePhoneNumber(phone);
        return phoneNumber.country.toLowerCase();
    } catch (error) {
        return "in";
    }
}

export const isValidNumber = (phone) => {
    try {
        const phoneNumber = parsePhoneNumber(phone);
        return phoneNumber.isValid();
    } catch (error) {
        return undefined
    }
}

export const errorToast = (response) => {
    if (response?.data?.detail) {
        toast.error(response?.data?.detail);
    } else if (response?.data?.non_field_errors) {
        toast.error(response?.data?.non_field_errors[0]);
    } else if (response?.data) {
        Object.keys(response?.data).forEach((error) => {
            toast.error(response?.data[error][0]);
        })
    }
}

export const certificationListImage = (image) => {
    const extension = image?.filename ? image?.filename?.split('.').pop() : image?.split('.').pop();
    if (image) {
        if (extension === 'pdf') {
            return pdfImage;
        } else {
            return image?.url ?? image;
        }
    } else {
        return certificateIcon
    }
}

export const checkImage = (file) => {
    const extension = file?.certificate_file?.split('.').pop();
    if (extension === 'pdf') {
        return (
            <div className="docCertificateView">
                <Typography variant='p' component='p' className="idProof-name">
                    <span><img src={pdfImage} alt="img" /></span>
                    {file?.file_name}
                    <a href={file?.certificate_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'>View</a>
                </Typography>
            </div>
        );
    } else {
        return (
            <div className="certificateImg">
                <img src={file?.certificate_file} alt="img" />
            </div>
        )
    }
}

export const dispatchPageLoading = (dispatch) => {
    setTimeout(() => {
        dispatch({ type: "PAGE_LOADING", payload: false });
    }, 1000);
}

export const dispatchContentLoading = (dispatch) => {
    setTimeout(() => {
        dispatch({ type: "CONTENT_LOADING", payload: false });
    }, 1000);
}

export const findAmountPerType = (amount, salaryId) => {
    try {
        if (!salaryId || !amount) return '-';
        let salaryType = SALARY_TYPE.find((salary) => salary.id === salaryId)?.display;
        return `$${amount}/${salaryType}`;
    } catch (error) {
        return undefined
    }
}