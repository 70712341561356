const initialState = {
	userList: [],
	portFolio: null,
	projectList: [],
	workExperience: [],
	certificateList: [],
	skill: [],
	subSkill: [],
	profileType: [],
	clientDetail: null,
	experience: [],
	technologies: [],
	language: [],
	proficiencyList: [],
	agencyUserDetail: [],
	agencyDeveloperDetail: [],
	agencyDeveloperProjectDetail: null,
	agencyDeveloperList: [],
	developerRequestList: [],
	agencyDeveloperProjectList: [],
	agencyDeveloperCertificationList: [],
	agencyDeveloperCertificateDetail: [],
	clientAgencyLog: [],
	allAgencies: [],
	loader: true
};

const manageUsersReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_USERS': {
			return { ...state, userList: action.payload };
		}
		case 'GET_PORTFOLIO': {
			return { ...state, portFolio: action.payload };
		}
		case 'GET_PROJECT': {
			return { ...state, projectList: action.payload };
		}
		case 'GET_WORK_EXPERIENCE': {
			return { ...state, workExperience: action.payload };
		}
		case 'GET_CERTIFICATE': {
			return { ...state, certificateList: action.payload };
		}
		case 'SKILL': {
			return { ...state, skill: action.payload };
		}
		case 'SUB_SKILL': {
			return { ...state, subSkill: action.payload };
		}
		case 'TECHNOLOGIES': {
			return { ...state, technologies: action.payload };
		}
		case 'LANGUAGE': {
			return { ...state, language: action.payload };
		}
		case 'PROFILE_TYPE': {
			return { ...state, profileType: action.payload };
		}
		case 'PROFICIENCY': {
			return { ...state, proficiencyList: action.payload };
		}
		case 'AGENCY_USER_DETAIL': {
			return { ...state, agencyUserDetail: action.payload };
		}
		case 'AGENCY_DEVELOPER_DETAIL': {
			return { ...state, agencyDeveloperDetail: action.payload };
		}
		case 'AGENCY_DEVELOPER_PROJECTDETAIL': {
			return { ...state, agencyDeveloperProjectDetail: action.payload };
		}
		case 'AGENCY_DEVELOPER_CERTIFICATEDETAIL': {
			return { ...state, agencyDeveloperCertificateDetail: action.payload };
		}
		case 'AGENCY_DEVELOPER_PROJECT_LIST': {
			return { ...state, agencyDeveloperProjectList: action.payload };
		}
		case 'AGENCY_DEVELOPER_CERTIFICATE_LIST': {
			return { ...state, agencyDeveloperCertificationList: action.payload };
		}
		case 'AGENCY_DEVELOPER_LIST': {
			return { ...state, agencyDeveloperList: action.payload };
		}
		case 'DEVELOPER_REQUEST_LIST': {
			return { ...state, developerRequestList: action.payload };
		}
		case 'CLIENT_DETAIL': {
			return { ...state, clientDetail: action.payload };
		}
		case 'CLIENT_AGENCY_LOG': {
			return { ...state, clientAgencyLog: action.payload };
		}
		case 'GET_ALL_AGENCIES': {
			return { ...state, allAgencies: action.payload };
		}
		case 'LOADER': {
			return { ...state, loader: action.payload }
		}
		default:
			return { ...state };
	}
};
export default manageUsersReducer;
