import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { LoaderContext } from 'utils/LoaderContext';
import SkillDetailForm from './SkillDetailForm';
import * as Actions from '../../../../store/actions/index';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SkillDetailList from './SkillDetailList';
import AddIcon from '@material-ui/icons/Add';
import CustomButton from "components/CustomButtons/Button";
import MasterForm from 'components/MasterForm/MasterForm';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { errorToast } from 'utils/Comman';

function SkillDetail() {
    const history = useHistory();
    const params = useParams()
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [action, setAction] = useState('add');
    const [selectedSubSkill, setSelectedSubSkill] = useState(null);
    const [searchString, setSearchString] = useState('');
    const [selectedSkillData, setSelectedSkillData] = useState({})

    const getSkillDetail = () => {
        dispatch(Actions.getSkillDetail(params.id))
            .then((response) => {
                if (response.status === 200) {
                    response.data.status = (response.data.status).toString();
                    setSelectedSkillData(response.data);
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    }
    const getSubSkillListData = (limit, page, search, ordering) => {
        toggalLoader(true);
        setRowLimit(limit);
        setOffset(page)
        setSearchString(search);
        dispatch(Actions.getSubSkill(limit, page, params.id, search, ordering))
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: 'GET_SUB_SKILL', payload: response.data });
                    toggalLoader(false);
                } else {
                    toggalLoader(false);
                    dispatch({ type: 'GET_SUB_SKILL', payload: null });
                    errorToast(response);
                }
            }).catch((response) => {
                toggalLoader(false);
                errorToast(response);
            })
    }
    const handleCreateSubSkill = (subSkill) => {
        Object.keys(subSkill).forEach((key) => {
            subSkill[key] = subSkill[key].trim();
        })
        toggalLoader(true);
        subSkill = Object.assign({ parent: params.id }, subSkill);
        dispatch(Actions.createSkill(subSkill)).then(response => {
            if (response.status === 201) {
                toast.success('Sub skill added successfully');
                toggalLoader(false);
                getSubSkillListData(rowLimit, offset, '');
                setOpenFormDialog(false);
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleUpdateSubSkill = (subSkill) => {
        const payload = {
            title: subSkill.title.trim(),
            description: subSkill.description.trim(),
            status: subSkill.status
        }
        toggalLoader(true);
        dispatch(Actions.editSkill(selectedSubSkill.id, payload)).then(response => {
            if (response.status === 200) {
                toast.success(response.data.message);
                toast.success("Sub skill updated successfully");
                getSubSkillListData(rowLimit, offset, '');
                handleCloseFormDialog();
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleRemoveSubSkill = (subSkillId) => {
        toggalLoader(true);
        dispatch(Actions.deleteSkill(subSkillId)).then(response => {
            if (response.status === 204) {
                toast.success("Sub skill deleted successfully");
                getSubSkillListData(10, 0, '');
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleUpdateSkill = (skill) => {
        const payload = {
            title: skill.title.trim(),
            description: skill.description.trim(),
            status: skill.status
        }
        toggalLoader(true);
        dispatch(Actions.editSkill(params.id, payload)).then(response => {
            if (response.status === 200) {
                toast.success('Skill Updated Successfully');
                history.push('/skill');
            } else {
                toggalLoader(false);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            errorToast(response);
        })
    }
    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setSelectedSubSkill(null);
        setAction('add');
    }
    const handleOpenDialog = (skill) => {
        dispatch(Actions.getSkillDetail(skill.subSkill))
            .then((response) => {
                if (response.status === 200) {
                    response.data.status = (response.data.status).toString();
                    setSelectedSubSkill(response.data);
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
        setAction(skill.action);
        setOpenFormDialog(true);
    }
    useEffect(() => {
        getSubSkillListData(rowLimit, offset, '', '');
        getSkillDetail();
    }, []);

    return (
        <div>
            <Grid spacing={2} container direction="row" justifyContent="flex-start">
                <Grid container item xs={12} justifyContent="space-between" alignItems="flex-start">
                    <Button startIcon={<ArrowBack />} onClick={() => { history.push('/skill') }}>
                        Back to list
                    </Button>
                    <CustomButton variant="contained" className={"primaryButton"} startIcon={<AddIcon />} color="info"
                        onClick={() => { setOpenFormDialog(true) }}>
                        Add Sub Skill
                    </CustomButton>
                </Grid>
                <Grid item xs={12}>
                    <SkillDetailForm initialValues={selectedSkillData} onSubmit={(values) => {
                        handleUpdateSkill(values)
                    }} />
                </Grid>
                <Grid item xs={12}>
                    <SkillDetailList
                        pageLimit={rowLimit}
                        removeSubSkill={handleRemoveSubSkill}
                        pageOffset={offset}
                        handleOpenDialog={handleOpenDialog}
                        getList={getSubSkillListData}
                        searchText={searchString}
                    />
                </Grid>
            </Grid>
            {openFormDialog &&
                <MasterForm
                    action={action}
                    initialValues={action === 'add' ? { status: "1" } : selectedSubSkill}
                    message={(action === 'add') ? 'Create a new sub skill' : 'Edit sub skill'}
                    open={openFormDialog} handleClose={handleCloseFormDialog}
                    onSubmit={(values) => {
                        (action === 'add') ? handleCreateSubSkill(values) : handleUpdateSubSkill(values);
                    }} />
            }
        </div>
    )
}
export default SkillDetail;
