export const STATUS = [
    {
        value: "1",
        label: "Active"
    },
    {
        value: "0",
        label: "In-active"
    }
]
export const USER_TYPES = {
    CLIENT: 3,
    AGENCY: 4,
    FREELANCER: 5,
    AGENCY_DEVELOPER: 6,
}

export const REQUEST_STATUS = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    IN_PROGRESS: 4
}

export const getUserRequest = (value) => {
    switch (value) {
        case "Client":
            return { tab: USER_TYPES.CLIENT, url: "user-client/" }
        case 'Agency':
            return { tab: USER_TYPES.AGENCY, url: "user-agency/" }
        case "Freelancers":
            return { tab: USER_TYPES.FREELANCER, url: "user-freelancer/" }
        default:
            break;
    }
}
export const getUrl = (key) => {
    switch (key) {
        case 0:
            return { url: "", type: "GET_PORTFOLIO" }
        case 1:
            return { url: "projects/", type: "GET_PROJECT" }
        // case 2:
        //     return { url: "work-experience/", type: "GET_WORK_EXPERIENCE" }
        case 2:
            return { url: "certifications/", type: "GET_CERTIFICATE" }
        default:
            break;
    }
}
export const getUpdateUrl = (key) => {
    switch (key) {
        case 0:
            return { url: "", type: "GET_PORTFOLIO" }
        case 1:
            return { url: "", type: "GET_PORTFOLIO" }
        case 2:
            return { url: "projects/", type: "GET_PROJECT", stateKey: "projectList" }
        case 3:
            return { url: "certifications/", type: "GET_CERTIFICATE", stateKey: "certificateList" }
        default:
            break;
    }
}
export const FILES = {
    PROFILE_MAX_SIZE_IN_MB: 2,
    PROJECT_IMAGES_MAX_SIZE_IN_MB: 2
};

export const FILETYPE = {
    image: ".png,.jpg,.jpeg",
    file: ".docx,.doc,.pdf,.png,.jpg,.jpeg",
    certification: ".pdf,.png,.jpg,.jpeg"
}

export const FILE_DIMENSION = {
    height: 250,
    width: 250,
}

export const PROFICIENCY_DIVIDER = {
    3: 3,
    2: 1.5,
    1: 1
}

export const PROFICIENCY_TAG = {
    3: "Beginner",
    2: "Average",
    1: "Fluent"
}
export const REQUEST_LIST_STATUS = [{ id: 1, title: 'New', value: 'true' }, { id: 2, title: 'Update', value: 'false' }];

export const SALARY_TYPE = [
    { id: 1, title: "Hourly", display: "Hour", budget_from: 10, budget_to: 50 },
    { id: 2, title: "Daily", display: "Day", budget_from: 80, budget_to: 400 },
    { id: 3, title: "Weekly", display: "Week", budget_from: 400, budget_to: 2000 },
    { id: 4, title: "Monthly", display: "Month", budget_from: 1600, budget_to: 8000 },
    // { id: 5, title: "Yearly", display: "Year", budget_from: 19200, budget_to: 96000 },
];
