import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import reducer from "../../../store/reducers";
import withReducer from "../../../store/withReducer";
import * as Actions from "../../../store/actions/index";
import AgencyDeveloperList from "./component/AgencyDeveloperList";
import ClientList from "./component/ClientList";
import AgencyList from "./component/AgencyList"
import FreelancerList from "./component/FreelancerList"
import CustomTabs from "components/CustomTabs/CustomTabs";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import DomainIcon from '@material-ui/icons/Domain';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { errorToast } from "utils/Comman";
import { dispatchContentLoading } from "utils/Comman";
import { dispatchPageLoading } from "utils/Comman";
import RejectedLogSkeleton from "./Skeleton/RejectedLogsSkeleton";
import MasterSkeletonForTable from "components/MasterSkeleton/MasterSkeletonForTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const RejectedLogs = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const manageActiveTab = useSelector(state => state.commonReducer.manageActiveTab);
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [searchString, setSearchString] = useState('');

    const getRequestList = (limit, page, type, search, ordering) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search);
        dispatch(Actions.getRejectionlogs(limit, page, type, false, search, ordering))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: response.data });
                    dispatchPageLoading(dispatch);
                    dispatchContentLoading(dispatch);
                    window.scrollTo(0, 0);
                } else {
                    dispatch({ type: "GET_REGISTRATION_REQUEST", payload: null });
                    dispatchPageLoading(dispatch);
                    dispatchContentLoading(dispatch);
                    errorToast(response);
                }
            })
            .catch((response) => {
                dispatch({ type: "GET_REGISTRATION_REQUEST", payload: null });
                errorToast(response);
                dispatchPageLoading(dispatch);
                dispatchContentLoading(dispatch);
            });
    };
    const handleChange = (event, newValue) => {
        if (manageActiveTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({
            type: 'SET_ACTIVE_TAB',
            payload: {
                activeTab: newValue,
            }
        })
        setRowLimit(10)
        setOffset(0)
    };
    const handleListEvent = (page, set, search, ordering) => {
        getRequestList(page, set, value + 3, search, ordering);
    }
    useEffect(() => {
        setValue(manageActiveTab.activeTab);
        getRequestList(rowLimit, offset, manageActiveTab.activeTab + 3, '', '');
    }, [manageActiveTab]);
    useEffect(() => {
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
            dispatch({ type: 'CONTENT_LOADING', payload: true });
        }
    }, [])

    return (
        <>
            {pageLoading ? <RejectedLogSkeleton classes={classes.root} manageActiveTab={manageActiveTab} />
                :
                <div className={classes.root}>
                    <CustomTabs
                        headerColor="info"
                        tabChange={handleChange}
                        activeTab={manageActiveTab.activeTab}
                        tabs={[
                            {
                                tabName: "Client",
                                tabIcon: PeopleIcon,
                                tabPermission: true,
                                tabContent: (
                                    <>
                                        {contentLoading ?
                                            <MasterSkeletonForTable length={5} type='client' />
                                            :
                                            <ClientList
                                                pageLimit={rowLimit}
                                                getList={handleListEvent}
                                                pageOffset={offset}
                                                tableName="Clients"
                                                searchText={searchString}
                                            />
                                        }
                                    </>
                                )
                            },
                            {
                                tabName: "Agency",
                                tabIcon: DomainIcon,
                                tabPermission: true,
                                tabContent: (
                                    <>
                                        {contentLoading ?
                                            <MasterSkeletonForTable length={5} type='agency' />
                                            :
                                            <AgencyList
                                                pageLimit={rowLimit}
                                                getList={handleListEvent}
                                                pageOffset={offset}
                                                tableName="Agency"
                                                searchText={searchString}
                                            />
                                        }
                                    </>
                                )
                            },
                            {
                                tabName: "Freelancers",
                                tabIcon: PeopleOutlineIcon,
                                tabPermission: true,
                                tabContent: (
                                    <>
                                        {contentLoading ?
                                            <MasterSkeletonForTable length={5} type='freelancer' />
                                            :
                                            <FreelancerList
                                                pageLimit={rowLimit}
                                                getList={handleListEvent}
                                                pageOffset={offset}
                                                tableName="Freelancers"
                                                searchText={searchString}
                                            />
                                        }
                                    </>
                                )
                            },
                            {
                                tabName: "Agency Developers",
                                tabIcon: AccountBoxIcon,
                                tabPermission: true,
                                tabContent: (
                                    <>
                                        {contentLoading ?
                                            <MasterSkeletonForTable length={5} type='agencydeveloper' />
                                            :
                                            <AgencyDeveloperList
                                                pageLimit={rowLimit}
                                                getList={handleListEvent}
                                                pageOffset={offset}
                                                tableName="Agency Developers"
                                                searchText={searchString}
                                            />
                                        }
                                    </>
                                )
                            },
                        ]}
                    />
                </div>
            }
        </>
    );
};

export default withReducer("", reducer)(RejectedLogs);
