import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Avatar from '@material-ui/core/Avatar';
import CustomButton from "components/CustomButtons/Button";
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../../store/actions/index'
import AgencyDeveloperPortfolio from './AgencyDeveloperPortfolio';
import AgencyDeveloperProject from './AgencyDeveloperProject';
import AgencyDeveloperCertificate from './AgencyDeveloperCertificate';
import { errorToast, findAmountPerType } from 'utils/Comman';
import ImagePreviewDialog from 'components/Dialog/ImagePreviewDialog';
import AgencyDeveloperProfileSkeleton from '../Skeleton/AgencyDeveloperSkeleton/AgencyDeveloperProfileSkeleton';

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        '@media (max-width: 1279px)': {
            width: '154px',
            height: '154px',
        },
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 244px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '@media (max-width: 1279px)': {
            width: 'calc(100% - 178px)',
        },
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    },
    formGridInner: {
        width: '48%',
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '32px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            textTransform: 'capitalize',
            marginBottom: '0',
        }
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3, }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


function AgencyDeveloperProfile(props) {
    const developerDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperDetail));
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const manageTab = useSelector(state => state.commonReducer.developerDetailTab);
    const [openPreview, setOpenPreview] = useState(false);
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const { classes } = props;

    const handleChange = (event, newValue) => {
        if (manageTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({
            type: 'SET_DEVELOPER_DETAIL_TAB',
            payload: {
                activeTab: newValue,
            }
        })
        setValue(newValue);
    };
    const agencyDeveloperDetail = () => {
        dispatch(Actions.getDeveloper(params.id, false)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "AGENCY_DEVELOPER_DETAIL", payload: response.data });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
            } else {
                dispatch({ type: "AGENCY_DEVELOPER_DETAIL", payload: null });
                setTimeout(() => {
                    dispatch({ type: "PAGE_LOADING", payload: false });
                    dispatch({ type: "CONTENT_LOADING", payload: false });
                }, 1000);
                errorToast(response);

            }
        }).catch((response) => {
            dispatch({ type: "PAGE_LOADING", payload: false });
            dispatch({ type: "CONTENT_LOADING", payload: false });
            errorToast(response);
            if (response.status === 404) {
                history.push(`/manage-user/agency-details/${params?.agencyId}`);
            }
        })
    }
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    useEffect(() => {
        //window.scrollTo(0, 0);
        agencyDeveloperDetail();
        return () => {
            dispatch({ type: "PAGE_LOADING", payload: true });
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
    }, [])
    return (
        <>
            {
                pageLoading ?
                    <AgencyDeveloperProfileSkeleton classes={classes} /> :
                    <>
                        <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                            <CustomButton
                                variant="contained"
                                className="primaryButton innerBackBtn"
                                startIcon={<ArrowBackIosIcon />}
                                color="info"
                                onClick={() => {
                                    dispatch({ type: "AGENCY_DEVELOPER_DETAIL", payload: null });
                                    dispatch({ type: "AGENCY_DEVELOPER_CERTIFICATE_LIST", payload: null });
                                    dispatch({ type: "AGENCY_DEVELOPER_PROJECT_LIST", payload: null });
                                    history.push(`/manage-user/agency-details/${params?.agencyId}`);
                                }}
                            >
                                Back
                            </CustomButton>
                        </Grid>
                        <Grid container className={classes.innerContainer}>
                            <Grid item xl={12} lg={12} md={12} sm={12}>
                                <div className={classes.profileDetail}>
                                    <Avatar alt="profile-img" onClick={() => handleOpenPreview()} src={developerDetail?.profile_image?.url ?? developerDetail?.profile_image} className={classes.profileImg} />
                                    <div className={classes.profileContent}>
                                        <Grid className={classes.userInfo}>
                                            <div>
                                                <Typography variant='h4'>{developerDetail?.first_name ? `${developerDetail?.first_name} ${developerDetail?.last_name}` : '-'}</Typography>
                                                <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{developerDetail?.uid}</span></Typography>
                                            </div>
                                        </Grid>
                                        <Grid className={classes.formGrid}>
                                            <Grid className={classes.formGridInner}>
                                                <label>
                                                    Email
                                                </label>
                                                <Typography variant='p' component='p'>{developerDetail?.email ? developerDetail?.email : '-'}</Typography>
                                            </Grid>
                                            <Grid className={classes.formGridInner}>
                                                <label>
                                                    Contact Number
                                                </label>
                                                <Typography variant='p' component='p'>{developerDetail?.contact_number ? developerDetail?.contact_number : '-'}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.formGrid}>
                                            <Grid className={classes.formGridInner}>
                                                <label>
                                                    Profile Type
                                                </label>
                                                <Typography variant='p' component='p'>{developerDetail?.portfolio?.profile_type?.title ? developerDetail?.portfolio?.profile_type?.title : '-'}</Typography>
                                            </Grid>
                                            <Grid className={classes.formGridInner}>
                                                <label>
                                                    Availability
                                                </label>
                                                <Typography variant='p' component='p'>{developerDetail?.portfolio?.availability === 1 ? 'Part Time' : 'Full Time'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid className="profile-container my_profile-container">
                            <Grid container spacing={3} className="freelancer_profile-details">
                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                    <Grid className="profile-inner my_profile-inner">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab
                                                label="Professional Details"
                                                {...a11yProps(0)}
                                                className="tab-title"
                                            />
                                            <Tab
                                                label="Projects"
                                                {...a11yProps(1)}
                                                className="tab-title"
                                            />
                                            <Tab
                                                label="Certificates"
                                                {...a11yProps(2)}
                                                className="tab-title"
                                            />
                                        </Tabs>
                                        <TabPanel value={value} index={0}>
                                            <AgencyDeveloperPortfolio />
                                        </TabPanel>
                                        <TabPanel value={value} index={1} className="projects">
                                            <AgencyDeveloperProject />
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <AgencyDeveloperCertificate />
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <div className={classes.innerContainer}>
                                        <Grid className='moreDetails'>
                                            <List className='moreDetails-list'>
                                                <ListItem>
                                                    <Typography variant='p' component='p'>
                                                        Agency ID
                                                    </Typography>
                                                    <Typography variant='h6' component='h6'>
                                                        {developerDetail?.agency?.uid ? developerDetail?.agency?.uid : '-'}
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant='p' component='p'>
                                                        Experience
                                                    </Typography>
                                                    <Typography variant='h6' component='h6'>
                                                        {developerDetail?.portfolio?.experience ? `${developerDetail?.portfolio?.experience} Years` : 0}
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant='p' component='p'>
                                                        Projects
                                                    </Typography>
                                                    <Typography variant='h6' component='h6'>
                                                        {developerDetail?.portfolio?.projects_count ? developerDetail?.portfolio?.projects_count : 0}
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant='p' component='p'>
                                                        Education
                                                    </Typography>
                                                    <Typography variant='h6' component='h6'>
                                                        {developerDetail?.portfolio?.education_details ? developerDetail?.portfolio?.education_details : '-'}
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography variant='p' component='p'>
                                                        Price
                                                    </Typography>
                                                    <Typography variant='h6' component='h6'>
                                                        {findAmountPerType(developerDetail?.portfolio?.amount, developerDetail?.portfolio?.salary_type) || '-'}
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        {developerDetail?.profile_image &&
                            <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={developerDetail?.profile_image?.url ?? developerDetail?.profile_image} />
                        }
                    </>
            }
        </>
    );
}

export default withStyles(styles)(AgencyDeveloperProfile);