import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { Skeleton } from '@material-ui/lab';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';

const AgencyDetailTabSkeleton = () => {
    return (
        <Grid className='add_profile-form'>
            <Typography variant='p' component='p' className='categoryTitle'>
                <Skeleton width={100} height={20} />
            </Typography>
            <Grid>
                <List className='categoryList'>
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                </List>
            </Grid>
            <Grid container className='formGrid'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl variant="standard" className='formControl'>
                        <Grid style={{ width: '100%', }}>
                            <label className="website-label"><Skeleton width={60} height={20} /> <span></span></label>
                            <Skeleton width='100%' height={28} />
                        </Grid>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container className='formGrid'>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl variant="standard" className='formControl'>
                        <Grid>
                            <label className="idProof-label"><Skeleton width={60} height={20} /> <span></span></label>
                            <Typography variant='p' component='p' className='idProof-name'>
                                <Skeleton className='bg-dark' variant="circle" width={40} hight={40} />
                                <Skeleton className='bg-dark' width={150} height={20} />
                            </Typography>
                        </Grid>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: '24px 0', }} />
            <Typography variant='h5' component='h5' className='title'>
                <Skeleton width={157} height={26} />
            </Typography>
            <Grid container className='formGrid'>
                <Grid className='formGrid-inner'>
                    <FormControl variant="standard" className='formControl'>
                        <Grid>
                            <label><Skeleton width={60} height={14} /></label>
                            <Typography variant='p' component='p' className='value'>
                                <Skeleton width={160} height={23} />
                            </Typography>
                        </Grid>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AgencyDetailTabSkeleton