import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth/auth.reducer';
import domainReducer from './master/domain.reducer';
import skillReducer from './master/skill.reducer';
import profileTypeReducer from './master/profileType.reducer';
import technologyReducer from './master/technology.reducer';
import roleReducer from './role/role.reducer';
import subAdminReducer from './subAdmin/subAdmin.reducer';
import userRequestReducer from './registrationRequest/userRequest.reducer';
import commonReducer from './common/common.reducer';
import manageUsersReducer from './manageusers/manageusers.reducer';
import experienceReducer from './master/experience.reducer';
import newAgencyRequestReducer from './newAgencyRequest/newAgencyRequest.reducer';
import contactUsReducer from './contactus/contactus.reducer';
import subscription from './subscription/subscription.reducer';

const createReducer = asyncReducers =>
	combineReducers({
		form: formReducer,
		authReducer,
		domainReducer,
		technologyReducer,
		skillReducer,
		profileTypeReducer,
		roleReducer,
		subAdminReducer,
		userRequestReducer,
		commonReducer,
		manageUsersReducer,
		experienceReducer,
		newAgencyRequestReducer,contactUsReducer,subscription,
		...asyncReducers
	});

export default createReducer;
