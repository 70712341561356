const initialState = {
	subAdminList: [],
	roleFormList: [],
	permissionList: [],
};

const subAdminReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_SUB_ADMIN': {
			return { ...state, subAdminList: action.payload };
		}
		case 'GET_FORM_ROLE': {
			return { ...state, roleFormList: action.payload };
		}
		case 'GET_PERMISSION_LIST': {
			return { ...state, permissionList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default subAdminReducer;
