import React, { useState } from "react";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import 'assets/css/allModals.css';
import copyIcon from 'assets/img/icons/filecopy-icon.svg';
import checkIcon from 'assets/img/icons/check.svg';
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import { checkImage } from "utils/Comman";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
};

function CertificateDetailDialog(props) {
    const { open, close, detail } = props;
    const [copied, setCopied] = useState(copyIcon);

    const handleCopy = (e) => {
        e.preventDefault();
        setCopied(checkIcon);
        navigator.clipboard.writeText(detail?.certification_link);
        setTimeout(() => {
            setCopied(copyIcon);
        }, 1000);
    }
    const handleClose = (__, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") return false;
        close();
    }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="certi-detail-title"
                open={open}
                className='add_project-modal'
            >
                <BootstrapDialogTitle id="certi-detail-title" className='modal-title' onClose={handleClose}>
                    Certificate Details
                </BootstrapDialogTitle>
                <DialogContent className='content'>
                    <div className="certificateCard certificateView">
                        {detail?.certificate_file &&
                            checkImage(detail)
                        }
                        <div className="content detail">
                            <div className="certificate-title">
                                <Typography variant='p' component='p'>{detail?.title}</Typography>
                                <Typography variant='p' component='p' className="date">{moment(detail?.certification_date).format('LL')}</Typography>
                            </div>
                            <div className="about-certi">
                                <Typography variant='p' component='p'>
                                    <ReadMoreReadLess
                                        charLimit={100}
                                        readMoreText={"Read More"}
                                        readLessText={"Read Less"}
                                        readMoreClassName="read-moreLink"
                                        readLessClassName="read-moreLink"
                                        children={detail?.description || ''} //eslint-disable-line
                                        textType={true}
                                    />
                                </Typography>
                            </div>
                            {detail?.certification_link &&
                                <a className='project-link' href={detail?.certification_link} target="_blank" rel="noreferrer">
                                    {detail?.certification_link}
                                    <img className="cursor-pointer" src={copied} height='16px' alt="img" onClick={(e) => handleCopy(e)} />
                                </a>
                            }
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default CertificateDetailDialog;
