import React from "react";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import certificate1 from "assets/img/projectimage-1.png";
import copyIcon from 'assets/img/icons/filecopy-icon.svg';
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import 'assets/css/allModals.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                style={{ 
                    position: 'absolute',
                    right: 28,
                    top: 26,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
};


function CertificateDetailsDialog(props) {
    const { open, close } = props;

    return (
        <div>
            <BootstrapDialog
                onClose={close}
                aria-labelledby="certi-detail-title"
                open={open}
                className='add_project-modal'
            >
                <BootstrapDialogTitle id="certi-detail-title" className='modal-title' onClose={close}>
                    Project Details
                </BootstrapDialogTitle>
                <DialogContent className='content'>
                    <div className="certificateCard certificateView">
                        <div className="certificateImg">
                        <img src={certificate1} alt="img" />
                        </div>
                        <div className="content detail">
                            <div className="certificate-title">
                                <Typography variant='p' component='p'>Google IT Automation</Typography>
                                <Typography variant='p' component='p' className="date">01 March 2021</Typography>
                            </div>
                            <a href='#' className='project-link'>
                                https://www.trootech.com
                                <img src={copyIcon} height='16px' alt="img"/>
                            </a>
                            {/* <div className="about-certi">
                                <Typography variant='p' component='p'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text. 
                                </Typography>
                            </div> */}
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default CertificateDetailsDialog;
