import React from 'react';
import { Skeleton } from '@material-ui/lab';
import MUIDataTable from 'mui-datatables';
import Grid from '@material-ui/core/Grid';
import 'assets/css/skeleton.css';

const MasterSkeleton = (props) => {
    const { length, type } = props
    let columns = [];
    let singleData = {};
    let data = [];
    const options = {
        filter: false,
        search: true,
        download: false,
        print: false,
        viewColumns: false,
        selectableRows: 'none',
        serverSide: false,
        searchText: '',
        pagination: false,
    };

    const titleWidth = {
        technology: 118,
        profileType: 120,
        skill: 41,
        role: 45,
        subAdmin: 111
    }

    const buttonWidth = {
        technology: 153,
        profileType: 153,
        skill: 140,
        role: 140,
        subAdmin: 149
    }

    const searchSkeleton = () => {
        return (<Skeleton variant='circle' width={24} height={24} />)
    }

    const components = {
        icons: {
            SearchIcon: searchSkeleton
        }
    }
    for (let index = 1; index < length + 1; index++) {
        columns.push({
            name: `data${index}`,
            label: <Skeleton />,
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
            }
        });
        singleData[`data${index}`] = <Skeleton />;
        if (index === length) {
            data.push(singleData);
        }
    }
    return (
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <Skeleton className="transform-scale-1 bg-dark m-t-5" width={buttonWidth[type]} height={42} />
            </Grid>
            <Grid item xs>
                <MUIDataTable
                    className='master-table disabled-pointer-event'
                    title={<Skeleton width={titleWidth[type]} height={32} />}
                    data={data}
                    columns={columns}
                    options={options}
                    components={components}
                />
            </Grid>
        </Grid>
    );
}

export default MasterSkeleton;