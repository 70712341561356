import { Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Actions from '../../../store/actions/index';
import { LoaderContext } from 'utils/LoaderContext';
import AddIcon from '@material-ui/icons/Add';
import List from './component/List';
import MasterForm from 'components/MasterForm/MasterForm';
import Button from 'components/CustomButtons/Button'
import withReducer from 'store/withReducer';
import reducer from '../../../store/reducers';
import * as PERMISSION from "utils/permissionConstant"
import CheckPermission from "components/Hoc/CheckPermission";
import { errorToast, dispatchPageLoading } from 'utils/Comman';
import MasterSkeleton from 'components/MasterSkeleton/MasterSkeleton';

function Technology() {
    const dispatch = useDispatch();
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [action, setAction] = useState('add');
    const { toggalLoader } = useContext(LoaderContext);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [selectedTechnology, setSelectedTechnology] = useState(null);
    const [searchString, setSearchString] = useState('');
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);

    const getTechnologyListData = (limit, page, search, ordering) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search)
        dispatch(Actions.getTechnology(limit, page, search, ordering))
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: 'GET_TECHNOLOGY', payload: response.data });
                    dispatchPageLoading(dispatch);
                } else {
                    dispatch({ type: 'GET_TECHNOLOGY', payload: null });
                    dispatchPageLoading(dispatch);
                    errorToast(response);
                }
            }).catch((response) => {
                dispatchPageLoading(dispatch);
                errorToast(response);
            })

    }
    const handleCreateTechnology = (technology) => {
        Object.keys(technology).forEach((key) => {
            technology[key] = technology[key].trim();
        })
        toggalLoader(true);
        dispatch(Actions.createTechnology(technology)).then(response => {
            if (response.status === 201) {
                toast.success('Technology Created Successfully');
                toggalLoader(false);
                getTechnologyListData(rowLimit, offset, '');
                setOpenFormDialog(false);
            } else {
                toggalLoader(false);
                dispatchPageLoading(dispatch);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            dispatchPageLoading(dispatch);
            errorToast(response);
        })
    }
    const handleUpdateTechnology = (technology) => {
        const payload = {
            title: technology.title.trim(),
            description: technology.description.trim(),
            status: technology.status
        }
        toggalLoader(true);
        dispatch(Actions.editTechnology(selectedTechnology.id, payload)).then(response => {
            if (response.status === 200) {
                toast.success('Technology Updated Successfully');
                toggalLoader(false);
                setOpenFormDialog(false);
                setSelectedTechnology(null);
                getTechnologyListData(rowLimit, offset, '');
                handleCloseFormDialog();
            } else {
                toggalLoader(false);
                dispatchPageLoading(dispatch);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            dispatchPageLoading(dispatch);
            errorToast(response);
        })
    }
    const handleRemoveTechnology = (technologyId) => {
        toggalLoader(true);
        dispatch(Actions.deleteTechnology(technologyId)).then(response => {
            if (response.status === 204) {
                toast.success("Technology delete successfully");
                toggalLoader(false);
                getTechnologyListData(10, 0, '');
            } else {
                toggalLoader(false);
                dispatchPageLoading(dispatch);
                errorToast(response);
            }
        }).catch((response) => {
            toggalLoader(false);
            dispatchPageLoading(dispatch);
            errorToast(response);
        })
    }
    const handleOpenDialog = (data) => {
        dispatch(Actions.getTechnologyDetail(data.technology))
            .then((response) => {
                if (response.status === 200) {
                    response.data.status = (response.data.status).toString();
                    setSelectedTechnology(response.data);
                } else {
                    toggalLoader(false);
                    dispatchPageLoading(dispatch);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                dispatchPageLoading(dispatch);
                errorToast(response);
            });
        setAction(data.action);
        setOpenFormDialog(true);
    }
    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setSelectedTechnology(null);
        setAction('add');
    }
    useEffect(() => {
        getTechnologyListData(rowLimit, offset, '', '');
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
        }
    }, []);

    return (
        <div>
            {pageLoading ?
                <MasterSkeleton length={4} type='technology' />
                :
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <CheckPermission
                            component={
                                <Button
                                    variant="contained"
                                    className={"primaryButton"}
                                    startIcon={<AddIcon />}
                                    color="info"
                                    onClick={() => { setOpenFormDialog(true) }}>
                                    Add Technology
                                </Button>
                            }
                            permission={PERMISSION.MASTER_TECHNOLOGY_ADD}
                        />

                    </Grid>
                    <Grid item xs>
                        <List
                            pageLimit={rowLimit}
                            pageOffset={offset}
                            removeTechnology={handleRemoveTechnology}
                            handleOpenDialog={handleOpenDialog}
                            getList={getTechnologyListData}
                            searchText={searchString}
                        />
                    </Grid>
                </Grid>
            }
            {openFormDialog &&
                <MasterForm
                    action={action}
                    initialValues={(action === 'add') ? { status: "1" } : selectedTechnology}
                    message={action === 'add' ? 'Create a new technology' : 'Edit technology'}
                    open={openFormDialog}
                    handleClose={handleCloseFormDialog}
                    onSubmit={(values) => {
                        (action === 'add') ? handleCreateTechnology(values) : handleUpdateTechnology(values);
                    }} />
            }
        </div>
    )
}
export default withReducer('', reducer)(Technology);