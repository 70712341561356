import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-react/components/customTabsStyle.js";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
  const {
    headerColor,
    plainTabs,
    tabs,
    title,
    rtlActive,
    tabChange,
    activeTab,
    showButton = false,
    handleClick
  } = props;

  const [value, setValue] = React.useState(activeTab || 0);
  const classes = useStyles();
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  const customCard = classNames({
    [classes.customCard]: true,
  });

  const handleChange = (event, data) => {
    setValue(data);
    if (tabChange) {
      tabChange(event, data);
    }
  };

  return (
    <Card plain={plainTabs} className={customCard}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone,
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((prop, key) => {
            if (prop.tabPermission) {
              let icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />,
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            } else {
              return null;
            }
          })}
          {showButton && (
            <Button
              className="header-tab-button"
              onClick={handleClick}
              style={{ marginLeft: "auto" }}
              startIcon={<AddIcon fontSize="large" />}
              color="inherit"
              size="small"
            > 
            Add Subscription
              </Button>
          )}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  activeTab: 0,
};
