import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import MasterSkeletonForTable from 'components/MasterSkeleton/MasterSkeletonForTable';

const ContactUsSkeleton = (props) => {
    const { manageActiveTab } = props;
    return (
        <CustomTabs
            headerColor="info"
            tabChange={e => e}
            activeTab={manageActiveTab.activeTab}
            tabs={[
                {
                    tabName: <Skeleton width={99} />,
                    tabPermission: true,
                    tabContent: (
                        <MasterSkeletonForTable length={4} type="contactus" />
                    )
                },
                {
                    tabName: <Skeleton className='bg-light' width={108} />,
                    tabPermission: true,
                    tabContent: (
                        <MasterSkeletonForTable length={4} type="contactus" />
                    )
                },
            ]}
        />
    );
}

export default ContactUsSkeleton;