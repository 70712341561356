const initialState = {
	user: '',
	userDetails:'',
	userPermissions : []
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOGIN_USER': {
			return { ...state, user: action.payload };
		}
		case 'LOGOUT_USER': {
			return { ...state, user: action.payload };
		}
		case 'FORGOT_PASSWORD': {
			return { ...state };
		}
		case 'RESET_PASSWORD': {
			return { ...state };
		}
		case 'UPDATE_USER': {
			return { ...state, user: action.payload };
		}
		case 'GET_USER_DETAILS': {
			return { ...state, userDetails: action.payload };
		}
		case 'GET_USER_PERMISSIONS': {
			return { ...state, userPermissions: action.payload };
		}
		default:
			return { ...state };
	}
};
export default authReducer;
