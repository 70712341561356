import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Email from "@material-ui/icons/Email";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { reduxForm, Field } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { required,password } from 'utils/validation';

function Form(props) {
    const { classes, handleSubmit } = props;
    const [showPassoword, setShowPassword] = useState(false);

    const handleMouseDownPassword = () => {
        setShowPassword(false)
    };
    return (
        <div className={classes.container}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={4}>
                    <Card className={classes[""]}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h4>Login</h4>
                        </CardHeader>
                        <form onSubmit={handleSubmit} noValidate>
                            <CardBody>
                                <Field
                                    name="username"
                                    component={CustomInput}
                                    id="username"
                                    labelText="Username"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required]}
                                    inputProps={{
                                        type: "text",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputIconsColor} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Field
                                    name="password"
                                    component={CustomInput}
                                    id="pass"
                                    labelText="Password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, password]}
                                    inputProps={{
                                        type: (showPassoword ? 'text' : 'password'),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(true)}
                                                    onMouseDown={handleMouseDownPassword}
                                                    disableRipple={true}
                                                    disableFocusRipple={true}
                                                    style={{ padding: "0px 0px 0px 10px" }}
                                                    className={classes.inputIconsColor}
                                                >
                                                    {showPassoword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Typography style={{ textAlign: 'right' }}>
                                    <a style={{ cursor: 'pointer', color: '#548CA8', fontSize: '14px' }} target="_blank" href='/forgot-password'>Forgot Password?</a>
                                </Typography>
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>
                                <Button type="submit" color="info">
                                    Login
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}


const ReduxFormMapped = reduxForm({
    form: 'LoginForm', // a unique identifier for this form
    enableReinitialize: false
})(Form)
export default withStyles(styles)(ReduxFormMapped);