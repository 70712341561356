import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ReadMoreReadLess from 'components/ReadMoreReadLess/ReadMoreReadLess';
import { useSelector } from 'react-redux';
import { SpliceList } from 'components/SpliceList/SpliceList';
import Slider from '@material-ui/core/Slider';
import { PROFICIENCY_TAG, PROFICIENCY_DIVIDER } from 'utils/constant';
import FormControl from "@material-ui/core/FormControl";
import fileIcon from 'assets/img/icons/file-icon.svg';

export default function ProfessionalDetails() {
    const userDetail = useSelector((user) => (user.manageUsersReducer.portFolio))
    return (
        <>
            {userDetail?.portfolio?.technologies?.length > 0 &&
                <>
                    <Typography variant='p' component='p' className="CategoryTitle">Technologies</Typography>
                    <Grid>
                        <List className="categoryList">
                            <SpliceList data={userDetail?.portfolio?.technologies} />
                        </List>
                    </Grid>
                </>
            }
            {userDetail?.portfolio?.skills?.length > 0 &&
                <>
                    <Typography variant='p' component='p' className="CategoryTitle">Skill</Typography>
                    <Grid>
                        <List className="categoryList">
                            <SpliceList data={userDetail?.portfolio?.skills} />
                        </List>
                    </Grid>
                </>
            }
            {userDetail?.portfolio?.sub_skills?.length > 0 &&
                <>
                    <Typography variant='p' component='p' className="CategoryTitle">Sub-skills</Typography>
                    <Grid>
                        <List className="categoryList">
                            <SpliceList data={userDetail?.portfolio?.sub_skills} />
                        </List>
                    </Grid>
                </>
            }
            <Grid className="aboutContent">
                <Typography variant='p' component='p' className="profile-subtitle">About</Typography>
                <Typography variant='p' component='p' className="content">
                    <ReadMoreReadLess
                        charLimit={100}
                        readMoreText={"Read More"}
                        readLessText={"Read Less"}
                        readMoreClassName="read-moreLink"
                        readLessClassName="read-moreLink"
                        children={userDetail?.portfolio?.description || ''} //eslint-disable-line
                        textType={true}
                    />
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle">Specialties</Typography>
                <Typography variant='p' component='p' className="content">
                    <ReadMoreReadLess
                        charLimit={100}
                        readMoreText={"Read More"}
                        readLessText={"Read Less"}
                        readMoreClassName="read-moreLink"
                        readLessClassName="read-moreLink"
                        children={userDetail?.portfolio?.specialties || '-'} //eslint-disable-line
                        textType={true}
                    />
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle">Location</Typography>
                <Typography variant='p' component='p' className="content location">
                    {userDetail?.city?.display_name ?
                        userDetail?.city?.display_name : '-'
                    }
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle">Language</Typography>
                <Typography variant='p' component='p' className="content language">
                    <div className="language-section">
                        {userDetail?.portfolio?.communication_language?.length > 0 ?
                            userDetail?.portfolio?.communication_language?.map((lan) => (
                                <Grid className="language-div" key={lan.id} container
                                    direction="row" justifyContent="space-between">
                                    <Typography id="langEng-slider">
                                        {lan.communication_language.value}
                                    </Typography>
                                    <Typography id="langEng-slider">
                                        {PROFICIENCY_TAG[lan?.proficiency?.id]}
                                    </Typography>
                                    <Slider disabled={true} defaultValue={100 / PROFICIENCY_DIVIDER[lan?.proficiency?.id]} aria-label="Default" valueLabelDisplay="auto" className="language_slider" />
                                </Grid>
                            )) : '-'
                        }
                    </div>
                </Typography>
                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Grid>
                                <label className="idProof-label">ID Proof</label>
                                {userDetail?.portfolio?.file_name ?
                                    <Typography variant='p' component='p' className='idProof-name'>
                                        <span><img src={fileIcon} height="16px" alt="img" /></span>
                                        {userDetail?.portfolio?.file_name}
                                        <a href={userDetail?.portfolio?.document_file?.url ?? userDetail?.portfolio?.document_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                        >View</a>
                                    </Typography> : <Typography variant='p' component='p' className='idProof-name'>-</Typography>
                                }
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
