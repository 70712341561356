import apiService from 'api/ApiService';

export function getRole(limit, offset, search) {
    return () => apiService.get(`roles/?page_size=${limit}&page=${offset + 1}&search=${search}`);
}
export function getRoleById(id) {
    return () => apiService.get(`roles/${id}/`);
}
export function createRole(params) {
    return () => apiService.post('roles/', params);
}
export function updateRole(id, params) {
    return () => apiService.patch(`roles/${id}/`, params);
}
export function deleteRole(id) {
    return () => apiService.delete(`roles/${id}/`);
}

/* get ContentType Permission for CreateRole - start*/
export function getContentTypePermission() {
    return () => apiService.get(`contenttype-permission/`);
}
/* get ContentType Permission for CreateRole - end*/