import { toast } from "react-toastify";
import { FILETYPE } from "./constant";
import { isValidNumber } from "utils/Comman";

export const normalizePhone = (maxLength) => (value, previousValue) => {
  if (!value) {
    return value;
  }
  if (value.toString().trim().length <= maxLength) {
    return value;
  }
  return previousValue;
};

export const password = (value) =>
  value &&
  !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,}$/i.test(value)
    ? "Password must have five characters, at least one letter, one number and one special character(!@#$%^&*)"
    : undefined;

export const passwordsMatch = (value, allValues) => {
  if (value !== allValues.new_password1) {
    return "Password and confirm password does not match";
  }
  return undefined;
};

export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const numberWithoutZeroWithDecimal = (value) => {
  if (typeof value === "string") {
    value = value.trim();
    return value === ""||value === "0" ? "This field is required" : undefined;
  }
  if(value){
    return value && !/^[1-9]+([.][1-9]+)+$/i.test(value) ? "Please Enter Valid Number" :undefined
  }
  // return value ? undefined : "This field is required";
};

export const maxTaxNumber=(value)=>{
  if (parseFloat(value)>100) {
    return  "Please enter a value up to 100" 
  }else return undefined
}

export const numberNotAcceptFirstZero=(value)=>{
  if (typeof value === "string") {
    value = value.trim();
    return value === "0" ? "Please Enter Valid Number" : undefined;
  }
}

export const numberWithDot = (value) =>
  value && !/^[0-9]*\.?[0-9]*$/i.test(value) ? "Please Enter Valid Number" : undefined;

export const numberOnly = (value) =>
  value && !/^[0-9]+$/i.test(value) ? "Please Enter Valid Number" : undefined;

export const required = (value) => {
  if (typeof value === "string") {
    value = value.trim();
  } else if (Array.isArray(value)) {
    value = Object.keys(value).length > 0 ? value : null;
  }
  return value ? undefined : "This field is required";
};
export const company_size_required = (value) => {
  if (typeof value === "string") {
    value = value.trim();
    return value === "" ? "This field is required" : undefined;
  }
  if (value || value >= 0) {
    return undefined;
  } else {
    return "This field is required";
  }
  // return value ? undefined : "This field is required";
};

const URL_REGEX = new RegExp(
  /^((ftp|http[s]?):\/\/(?:www\.|(?!www))[a-zA-Z0-9]+[a-zA-Z0-9]\.[^\s]{2,})/gi
);

export const checkUrl = (value) =>
  value && !value.match(URL_REGEX)
    ? "Invalid url (eg: http://exampleurl)"
    : undefined;

export const minimumDate = (value, allValues) => {
  if (value <= allValues.start_date) {
    return "End date cannot less than or equal to the start date";
  }
  return undefined;
};

export const checkString = (value) => {
  // if (value && (!/^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/i.test(value))) {
  //     return 'Please Enter Valid String'
  // }
  value = value.toString();
  if (value && !value?.match(/\w/)) {
    return "This field is required";
  }
  if (value && value?.trim()?.length > 100) {
    return "Please enter only 100 character";
  }
};

export const normalizeCharacter = (maxLength) => (value, previousValue) => {
  if (!value) {
    return value.toString().trimStart();
  }
  // if (value) {
  //     return value.replace(/\W+/g, '');
  // }
  if (value.toString().trim().length <= maxLength) {
    return value.toString().trimStart();
  }
  return previousValue ? previousValue.toString().trimStart() : "";
};

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Please enter valid email address"
    : undefined;

export const checkFile = (file, type) => {
  if (type === "profile") {
    if (!FILETYPE.image.includes(file.replace("image/", ""))) {
      toast.error("Please select only allowed file");
    } else return true;
  } else {
    if (!FILETYPE.file.includes(file.replace("image/", ""))) {
      toast.error("Please select only allowed file");
    } else return true;
  }
};

export const trimmed = () => (value) => {
  return value.toString().trim();
};
export const registrationNumber = (value) => {
  if (value && !/^[a-z0-9]+$/i.test(value)) {
    return "Please enter valid registration number";
  }
  return undefined;
};

export const checkContactNumber = (value) => {
  if (value && !isValidNumber(value)) {
    return "Please enter valid phone number";
  }
  return undefined;
};

export const checkZeroMonth = (value) => {
  if (value == 0) return "Month should not be 0";
};
