import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { Field, reduxForm, getFormValues } from "redux-form";
import CustomInput from "components/CustomInput/CustomInput";
import {
  required,
  numberOnly,
  checkString,
  normalizeCharacter,
} from "utils/validation";
import { useHistory, useParams } from "react-router-dom";
import SingleAutocomplete from "components/CutomAutoComplete/SingleAutocomplete";
import { numberWithDot } from "utils/validation";
import { CustomEditor } from "components/CustomEditor/CustomEditor";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import { STATUS } from "utils/constant";
import { renderRadioGroup } from "utils/formComponent";
import { FormControlLabel, FormLabel, Radio } from "@material-ui/core";
import { numberNotAcceptFirstZero } from "utils/validation";
import { maxTaxNumber } from "utils/validation";

function Form(props) {
  const { handleSubmit, recurringList, currencyList,initialValues } = props;
  const history = useHistory();
  const params = useParams();
  const userType = params?.type;
  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <Grid className="add_profile-form subscription-form">
          <Grid container className="formGrid">
            <Grid className="formGrid-inner">
              <Field
                // key={Math.random()}
                name="name"
                component={CustomInput}
                id="plan"
                labelText="Plan Name*"
                formControlProps={{
                  fullWidth: true,
                  disabled:initialValues?.id
                }}
                validate={[required, checkString]}
                inputProps={{
                  placeholder: "Enter plan name",
                  type: "text",
                }}
                normalize={normalizeCharacter(25)}
              />
            </Grid>
            <Grid className="formGrid-inner">
              <Field
                name="amount"
                component={CustomInput}
                id="amount"
                labelText="Amount*"
                formControlProps={{
                  fullWidth: true,
                  disabled:initialValues?.id
                }}
                validate={[numberNotAcceptFirstZero, numberWithDot,required]}
                inputProps={{
                  placeholder: "Enter amount",
                  type: "number",
                }}
                normalize={normalizeCharacter(7)}
              />
            </Grid>
            <Grid className="formGrid-inner">
              <Field
                //  key={Math?.random()}
                name="currency"
                component={SingleAutocomplete}
                id="currency"
                labelText="Currency*"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={initialValues?.id}
                options={currencyList?.results}
                displayKey="sign"
                validate={[required]}
                onInputChange={(value) => value}
                inputProps={{
                  placeholder: "Select currency",
                  type: "text",
                }}
                noOptionsText="No currency"
              />
            </Grid>
            <Grid className="formGrid-inner">
              <Field
                name="recurring"
                component={SingleAutocomplete}
                id="recurring"
                labelText="Recurring*"
                formControlProps={{
                  fullWidth: true,  
                }}
                options={recurringList?.results}
                displayKey="name"
                validate={[required]}
                onInputChange={(value) => value}
                inputProps={{
                  placeholder: "Select recurring",
                  type: "text",
                }}
                disabled={initialValues?.id}
                noOptionsText="No recurring"
              />
            </Grid>
            {userType == "agency" && (
              <Grid className="formGrid-inner">
                <Field
                  name="num_of_developers"
                  component={CustomInput}
                  id="num_of_developers"
                  labelText="Number of developers*"
                  formControlProps={{
                    fullWidth: true,
                    disabled:initialValues?.id
                  }}
                  validate={[numberNotAcceptFirstZero, numberOnly,required]}
                  inputProps={{
                    placeholder: "Enter number of developers",
                    type: "number",
                  }}
                  normalize={normalizeCharacter(3)}
                />
              </Grid>
            )}
            {userType == "client" && (
              <Grid className="formGrid-inner">
                <Field
                  name="num_of_jobs"
                  component={CustomInput}
                  id="num_of_jobs"
                  labelText="Number of jobs*"
                  formControlProps={{
                    fullWidth: true,
                    disabled:initialValues?.id
                  }}
                  validate={[numberNotAcceptFirstZero, numberOnly,required]}
                  inputProps={{
                    placeholder: "Enter number of jobs",
                    type: "number",
                  }}
                  normalize={normalizeCharacter(3)}
                />
              </Grid>
            )}
            {/* {userType == "freelancer" && (
              <Grid className="formGrid-inner">
                <Field
                  name="num_of_months"
                  component={CustomInput}
                  id="num_of_months"
                  labelText="Number of months*"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  validate={[company_size_required, numberOnly]}
                  inputProps={{
                    placeholder: "Enter number of months",
                    type: "number",
                  }}
                />
              </Grid>
            )} */}

            <Grid className="formGrid-inner">
              <Field
                name="tax_percentage"
                component={CustomInput}
                
                id="tax_percentage"
                labelText="Tax Percentage"
                formControlProps={{
                  fullWidth: true,
                  disabled:initialValues?.id
                }}
                validate={[numberNotAcceptFirstZero, numberWithDot,maxTaxNumber]}
                inputProps={{
                  placeholder: "Enter tax percentage",
                  type: "number",
                }}
                normalize={normalizeCharacter(3)}
              />
            </Grid>
            <Grid item className="formGrid-inner">
              <Field
             
                name="description"
                component={CustomTextArea}
                id="description"
                labelText="Description"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={initialValues?.id}
                rows={1}
                multiline
                // validate={[]}
                inputProps={{
                  placeholder: "Enter Description",
                  type: "text",
                }}
                normalize={normalizeCharacter(255)}
              />
            </Grid>
            {(userType == "client" || userType == "agency") && (
              <Grid item className="formGrid-inner">
                <FormLabel shrink className="status">
                  Status
                </FormLabel>
                <Field
                key={Math?.random()}
                  name="status"
                  row
                  component={renderRadioGroup}
                  id="status"
                  required
                  validate={[required]}
                >
                  {STATUS.map((radio, index) => (
                    <FormControlLabel
                      key={index}
                      value={radio.value}
                      control={<Radio style={{ color: "#08D6A8" }} />}
                      label={radio.label}
                    />
                  ))}
                </Field>
              </Grid>
            )}

            <Grid
              className="formGrid-inner"
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Field
                name="plan_details"
                component={CustomEditor}
                id="plan_details"
                labelText="Plan Details"
                formControlProps={{
                  fullWidth: true,
                }}
                disabled={initialValues?.id}
                multiline={true}
                rows={4}
                maxRows={10}
                // validate={[required]}
                placeholder="Enter plan details"
              />
            </Grid>
            {userType == "freelancer" && (
              <Grid item className="formGrid-inner">
                <FormLabel shrink className="status">
                  Status
                </FormLabel>
                <Field
                  name="status"
                  row
                  component={renderRadioGroup}
                  id="status"
                  required
                  validate={[required]}
                >
                  {STATUS.map((radio, index) => (
                    <FormControlLabel
                      key={index}
                      value={radio.value}
                      control={<Radio style={{ color: "#08D6A8" }} />}
                      label={radio.label}
                    />
                  ))}
                </Field>
              </Grid>
            )}
          </Grid>
          <Grid className="action-div">
            <Link
              underline="none"
              onClick={() => history.push("/manage-subscription")}
              className="previousBtn"
            >
              Cancel
            </Link>
            <Button
              variant="contained"
              type="submit"
              className="primaryButton nextBtn"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

const ReduxFormMapped = reduxForm({
  form: "SubscriptionForm", // a unique identifier for this form
  enableReinitialize: true,
})(Form);
const mapStateToProps = (state) => {
  return { formStates: getFormValues("SubscriptionForm")(state) };
};
export default connect(mapStateToProps, "")(ReduxFormMapped);