import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import AppWrapper from "AppWrapper";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from '../store/actions/index';
// import classNames from "classnames";
// let ps;
/* eslint-disable */
// const allPermission = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));
// let isLoggedInVar = localStorage.getItem('ACCESS_TOKEN');

const checkLogin = (param) => {
  if (param.isLoggedInVar) {
    return true;
  } else {
    return false;
  }
};

const checkPermission = (param) => {

  if (param.path === '/notfound') return false;

  return param.allPermission && param.allPermission.some((permission) => (permission == param.permissions))
}

const ProtectedRoute = (props) =>
(
  checkLogin(props)
    ?
    checkPermission(props)
      ?
      <Route {...props} />
      :
      <Redirect
        to={{
          pathname: "/notfound",
        }}
      />
    :
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
)


const switchRoutes = ({ isLoggedInVar, allPermission }) => (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.items) {
          return prop.items.map((item, index) =>
            <ProtectedRoute
              path={item.path}
              component={item.component}
              key={index}
              permissions={item.permissions}
              isLoggedInVar={isLoggedInVar}
              allPermission={allPermission}
              exact
            />)
        } else {
          return (
            <ProtectedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              permissions={prop.permissions}
              isLoggedInVar={isLoggedInVar}
              allPermission={allPermission}
              exact
            />
          );
        }
      }
      return null;
    })}
    <Route path="/" component={AppWrapper} />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let isLoggedInVar = localStorage.getItem('ACCESS_TOKEN');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer?.userDetails);
  // const allPermission = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));
  // const allPermission = user?.user_permissions ? user.user_permissions.concat(['main.view_dashboard', 'main.view_userProfile']) : [];
  const [allPermission, setAllPermission] = useState([]);

  const getUserDetails = () => {
    dispatch(Actions.getUserDetails()).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
      } else {
        dispatch({ type: 'GET_USER_DETAILS', payload: '' });
      }
    });
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (mainPanel.current) {
      // if (navigator.platform.indexOf("Win") > -1) {
      //   ps = new PerfectScrollbar(mainPanel.current, {
      //     suppressScrollX: true,
      //     suppressScrollY: false,
      //   });
      //   // document.body.style.overflow = "hidden";
      // }
      window.addEventListener("resize", resizeFunction);
      // // Specify how to clean up after this effect:
      // return function cleanup() {
      //   if (navigator.platform.indexOf("Win") > -1) {
      //     ps.destroy();
      //   }
      //   window.removeEventListener("resize", resizeFunction);
      // };
    }
  }, [mainPanel]);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (user.user_permissions) {
      setAllPermission(user.user_permissions.concat(['main.view_dashboard', 'main.view_userProfile']));
    }
  }, [user]);

  return (
    (allPermission && allPermission.length > 0) &&
    <div className={classes.wrapper} >
      <Sidebar
        allPermission={allPermission}
        routes={routes}
        image={""}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"white"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes({ isLoggedInVar, allPermission })}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes({ isLoggedInVar, allPermission })}</div>
        )}

      </div>
    </div>
  );
}
