import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import { Avatar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import 'assets/css/skeleton.css';
import AgencyDetailTabSkeleton from './AgencyDetailTabSkeleton';
import DevelopersDetailTabSkeleton from './DevelopersDetailTabSkeleton';

const AgencyDetailSkeleton = (props) => {
    const { classes, activeTab } = props;
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <Skeleton className='innerBackBtn transform-scale-1 bg-dark m-t-5' width={140} height={42} />
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={9} lg={10} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Skeleton variant="circle" width={220} height={220}>
                            <Avatar className={classes.profileImg} />
                        </Skeleton>
                        <div className={classes.profileContent}>
                            <Grid className={classes.userInfo}>
                                <div>
                                    <Typography variant='h4'>
                                        <Skeleton width={200} height={34} /></Typography>
                                    <Typography variant='p' component='p' className={classes.clientId}><Skeleton width={151} height={23} /></Typography>
                                </div>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={70} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={431} height={23} />
                                    </Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={40} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={465} height={23} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={120} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={465} height={23} />
                                    </Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={60} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={465} height={23} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={12}>
                    <div className={classes.innerContainer}>
                        <Grid className='moreDetails manage_users-moreDetails'>
                            <List className='moreDetails-list'>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={330} height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={330} height={23} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={330} height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={330} height={23} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={330} height={20} />
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={330} height={23} />
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width={330} height={20} /> <span></span>
                                    </Typography>
                                    <Typography variant='h6' component='h6'>
                                        <Skeleton width={330} height={23} />
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </div>
                </Grid>
                <Grid item lg={12} >
                    <Grid className='profile-inner my_profile-inner'>
                        <Tabs
                            aria-label="basic tabs example"
                            value={activeTab}
                        >
                            <Tab
                                label={<Skeleton className='p-6-12' width={136} />}
                                className="tab-title"
                            />
                            <Tab
                                label={<Skeleton className='p-6-12' width={136} />}
                                className="tab-title"
                            />
                            {/* <Tab
                                label={<Skeleton className='p-6-12' width={136} />}
                                className="tab-title"
                            /> */}
                        </Tabs>
                        <Box sx={{ pt: 3, pb: 3, }}>
                            <Typography>
                                {activeTab === 0 ?
                                    <AgencyDetailTabSkeleton />
                                    :
                                    <DevelopersDetailTabSkeleton />
                                }
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyDetailSkeleton