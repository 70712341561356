import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { reduxForm, FieldArray, getFormValues } from "redux-form";
import { required } from 'utils/validation';
import CertificationFields from 'components/FieldArrayComponent/CertificationFields';
import Link from '@material-ui/core/Link';
import { connect, useDispatch } from 'react-redux';
import { checkForImageValidations } from 'utils/Comman';
import { toast } from 'react-toastify';
import * as Actions from '../../../../../store/actions/index'
import { useHistory } from 'react-router-dom';

function CertificationForm(props) {
    const { handleSubmit, change, formStates } = props;
    const [files, setFiles] = useState({ profile: null, identity: null });
    const dispatch = useDispatch();
    const history = useHistory();

    const handleImageUpload = async (e) => {
        let file = null;
        let whichFile = null;
        let dimensionCheck = true;
        let fileType=null;
        if (e instanceof File) {
            file = e;
            whichFile = 'identity';
            dimensionCheck = false;
            fileType='file'
        } else if (e.target.files.length !== 0) {
            file = e.target.files[0];
            whichFile = 'profile';
            fileType='image'
        }
        if (file) {
            const fileCheck = await checkForImageValidations(file, fileType, dimensionCheck);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message)
            } else {
                const formData = new FormData();
                formData.append("file_obj", file);
                dispatch(Actions.uploadImage(formData)).then(response => {
                    const filesObject = { ...files }
                    filesObject[whichFile] = response.data;
                    setFiles(filesObject);
                });
            }
        }
    };
    return (
        <form onSubmit={handleSubmit} noValidate>
            <FieldArray formStates={formStates?.certification} name="certification" component={CertificationFields} change={change} required={required} imageUpload={handleImageUpload} />
            <Grid className="action-div">
                <Link underline="none" className="previousBtn" onClick={() => (history.push('/manage-users'))}>
                    Cancel
                </Link>
                <Button type="submit" variant="contained" className="primaryButton nextBtn">
                    Save
                </Button>
            </Grid>
        </form>
    );
}
const ReduxCertificationFormMapped = reduxForm({
    form: "CertificationForm", // a unique identifier for this form
    enableReinitialize: true,
})(CertificationForm);
const mapStateToProps = (state) => {
    return { formStates: getFormValues('CertificationForm')(state) }
}
export default connect(mapStateToProps, "")(ReduxCertificationFormMapped);