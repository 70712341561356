import React from "react";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const icon = <RadioButtonUncheckedIcon fontSize="small" className='uncheckedCircle-icon' />;
const checkedIcon = <CheckCircleIcon fontSize="small" className='checkedCircle-icon' />;

/* AutocompleteTags start */
const AsynChipAutocomplete = ({ displayKey, valueKey, input, labelText, placeholder, id, formControlProps, inputProps,
    options, loading, meta: { touched, error }, onSearch, ...custom }) => {
    return (
        <>
            <FormControl
                variant="standard"
                className="formControl"
                {...formControlProps}
                error={touched && Boolean(error)}>
                {labelText !== undefined ? (
                    <InputLabel shrink htmlFor={id}>
                        {labelText}
                    </InputLabel>
                ) : null}
                <Autocomplete
                    {...custom}
                    multiple
                    id="checkboxes-tags-demo"
                    className="selectTextField"
                    options={options || []}
                    // defaultValue={input.value || []}
                    value={input.value || []}
                    freeSolo={true}
                    disablePortal={true}
                    getOptionLabel={(option) => option[displayKey] || ''}
                    onChange={(event, value) => {
                        input.onChange(value);
                    }}
                    getOptionSelected={(option, value) => option[displayKey] === value[displayKey]}
                    renderOption={(option, { selected }) => (
                        <React.Fragment key={valueKey}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option[displayKey]}
                        </React.Fragment>
                    )}
                    renderInput={(params) => {
                        return (
                            <TextField
                                className='multiSelect-Textfield multiselect-permission'
                                {...params}
                                {...custom}
                                error={touched && Boolean(error)}
                                placeholder={placeholder}
                                onChange={onSearch}
                                autoComplete="off"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    ...inputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="primary" size={30} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        );
                    }}
                />
                {touched && error && (
                    <FormHelperText style={{ color: "red" }}>
                        {touched && error}
                    </FormHelperText>
                )}
            </FormControl>
        </>
    );
};
AsynChipAutocomplete.propTypes = {
    input: PropTypes.object.isRequired,
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    meta: PropTypes.object,
    onSearch: PropTypes.func,
};
AsynChipAutocomplete.defaultProps = {
    meta: null,
    labelText: "",
    placeholder: "",
    options: [],
    onSearch: () => { },
    valueKey: 'id',
    loading: false,
};
export default AsynChipAutocomplete
