/* eslint-disable */
import React from "react";
import { connect } from 'react-redux';
import MasterTable from "components/MasterTable/MasterTable";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import moment from 'moment';

const List = (props) => {
    const history = useHistory();
    const {
        pageLimit,
        pageOffset,
        getList,
        tableName,
        registationRequestList,
        searchText,
    } = props;

    const columns = [
        {
            name: "first_name",
            label: "Developer Name",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return registationRequestList?.results[tableMeta.rowIndex]?.first_name + (' ') + registationRequestList?.results[tableMeta.rowIndex]?.last_name
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "parent",
            label: "Agency Name",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "request_handled_by",
            label: "Admin Name",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "modified",
            label: "Date",
            options: {
                customBodyRender: (value) => {
                    return moment(value).format("DD/MM/YYYY");
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "rejection_message",
            label: "Reason of rejection",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (<ReadMoreReadLess
                        charLimit={100}
                        readMoreText={"Read More"}
                        readLessText={"Read Less"}
                        readMoreClassName="read-moreLink"
                        readLessClassName="read-moreLink"
                        children={value || ''} //eslint-disable-line
                        textType={true}
                    />)
                },
                setCellProps: () => ({
                    style: {
                        maxWidth: "350px",
                        wordBreak: "break-word",
                    }
                }),
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    history.push("/rejected-logs/agency-developer-view/" + value);
                                }}>
                                <FormatListBulletedIcon />
                            </IconButton>
                        </>
                    )
                }
            }
        },
    ]

    return (
        <>
            <MasterTable
                list={registationRequestList}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        registationRequestList: state.userRequestReducer.registationRequestList
    }
}
export default connect(mapStateToProps, '')(List);
