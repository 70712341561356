import React, { useContext } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { LoaderContext } from "../../utils/LoaderContext"

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    }
}));

const Progress = () => {
    const { isLoaderOpen } = useContext(LoaderContext);
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={isLoaderOpen}>
            <div className="lds-dual-ring"></div>
        </Backdrop>
    );
};
export default React.memo(Progress);