import React from 'react';
import PropTypes from "prop-types";
// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export default function CustomTextArea(props) {
  const { meta: { touched, error }, labelText, id, labelProps, input, rows, placeholder, maxRows,disabled } = props;

  return (
    <FormControl
      variant="standard" className='formControl'
      error={touched && Boolean(error)}
    >
      {labelText !== undefined ? (
        <InputLabel
          shrink
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <TextareaAutosize
        className="inputField"
        inputProps={{
          autoComplete: 'new-password',
          ...input
        }}
        disabled={disabled}
        // multiline={multiline}
        minRows={rows}
        maxRows={maxRows}
        placeholder={placeholder}
        id={id}
        {...input}
        // error={touched && Boolean(error)}
        variant="outlined"
      />
      {touched && error ? (
        <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}


CustomTextArea.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  input: PropTypes.object,
};
