import * as React from 'react';
import Pagination from '@material-ui/lab/Pagination';
//import Stack from '@material-ui/lab/Stack';
import PaginationItem from '@material-ui/lab/PaginationItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'assets/css/profile.css';
import { Grid } from '@material-ui/core';

export default function PaginationRounded(props) {
    const { totalPages, handlePages } = props;
    return (
        // <Stack spacing={2} className="pagination-container">
        <Grid spacing={2} className="pagination-container">
            <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                className='pagination-inner'
                renderItem={(item) => (
                    <PaginationItem
                        components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                    />
                )}
                onChange={(e, page) => { handlePages(page) }}
            />
        </Grid>
        // </Stack>
    );
}