import React from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import "assets/css/skeleton.css";
import Avatar from '@material-ui/core/Avatar';
import { Skeleton } from '@material-ui/lab';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import DeveloperPortfolioSkeleton from './DeveloperPortfolioSkeleton';
import DeveloperCertificateSkeleton from './DeveloperCertificateSkeleton';
import DeveloperProjectSkeleton from './DeveloperProjectSkeleton';


const DeveloperRequestDetailsSkeleton = (props) => {
    const { classes, activeTab } = props;
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <Skeleton className='innerBackBtn transform-scale-1 bg-dark m-t-5' width={140} height={42} />
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={12} lg={12} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Skeleton variant="circle" width={220} height={220}>
                            <Avatar className={classes.profileImg} />
                        </Skeleton>
                        <div className={classes.profileContent}>
                            <Grid className={classes.userInfo}>
                                <div>
                                    <Typography variant='h4'><Skeleton width={200} height={34} /></Typography>
                                    <Typography variant='p' component='p' className={classes.clientId}><Skeleton width={151} height={23} /></Typography>
                                </div>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={38} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={118} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={81} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={75} height={15} />
                                    </label>
                                    <Typography variant='p' component='p'>
                                        <Skeleton width='100%' height={23} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid className="profile-container my_profile-container">
                <Grid container spacing={3} className="freelancer_profile-details">
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Grid className="profile-inner my_profile-inner">
                            <Tabs
                                aria-label="basic tabs example"
                                value={activeTab} >
                                <Tab
                                    label={<Skeleton className='p-6-12' width={136} />}
                                    className="tab-title"
                                />
                                <Tab
                                    label={<Skeleton className='p-6-12' width={136} />}
                                    className="tab-title"
                                />
                                <Tab
                                    label={<Skeleton className='p-6-12' width={136} />}
                                    className="tab-title"
                                />
                            </Tabs>
                            <Box sx={{ pt: 3, pb: 3, }}>
                                <Typography>
                                    {activeTab === 0 && <DeveloperPortfolioSkeleton />}
                                    {activeTab === 1 && <DeveloperProjectSkeleton />}
                                    {activeTab === 2 && <DeveloperCertificateSkeleton />}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <div className={classes.innerContainer}>
                            <Grid className='moreDetails'>
                                <List className='moreDetails-list'>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default DeveloperRequestDetailsSkeleton;