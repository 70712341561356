import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import 'assets/css/skeleton.css';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';

const AgencyDeveloperCertificateSkeleton = () => {
    return (
        <>
            <TableContainer>
                <Table className='project_detail-table certificate_table'>
                    <TableHead>
                        <TableRow>
                                <TableCell className='tableCellHead singleTableCell'>
                                <Skeleton width="100%" height={24} />
                                </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <TableRow hover role="checkbox">
                                <TableCell className='tableDataCell singleTableCell'>
                                <Skeleton width="100%" height={48} />
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Skeleton width={114} height={32} />
                </Grid>
            </TableContainer>
        </>
    );
}

export default AgencyDeveloperCertificateSkeleton;
