import React, { useRef, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions/index";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { reduxForm, Field, getFormValues } from 'redux-form';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { required, checkUrl, normalizeCharacter, checkString, checkZeroMonth } from "utils/validation";
import ChipAutocomplete from "components/CutomAutoComplete/ChipAutocomplete";
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import 'assets/css/allModals.css';
import { CustomEditor } from "components/CustomEditor/CustomEditor";
import { FILETYPE } from "utils/constant";
import { checkForImageValidations } from "utils/Comman";
import { toast } from "react-toastify";
import CustomCheckBox from "components/CustomCheckBox/CustomCheckBox";
import InputAdornment from '@material-ui/core/InputAdornment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 28,
                        top: 26,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
};

function ProjectForm(props) {
    const { open, close, uploadImage, handleSubmit, filesData, removeImage, customText } = props;
    const dispatch = useDispatch();
    const inputFile = useRef(null)
    const [time, setTime] = useState('');
    const [allImages, setAllImages] = useState([]);
    const technologies = useSelector(state => state.manageUsersReducer.technologies);

    const handleClose = (__, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") return false;
        close();
    }

    const getTechnologies = (search) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            dispatch(Actions.getTechnologyList(search));
        }, 1000));
    };
    const handleCapture = async (e) => {
        if (e.target.files[0]) {
            const fileCheck = await checkForImageValidations(e.target.files[0], 'image', false);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message);
                e.target.value = null;
            } else {
                uploadImage(e)
            }
        }

    }
    useEffect(() => {
        getTechnologies('');
    }, []);
    useEffect(() => {
        setAllImages(filesData);
    }, [filesData]);

    useEffect(() => {
        //window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="certi-detail-title"
                open={open}
                className='add_project-modal'
            >
                <form onSubmit={handleSubmit} >
                    <BootstrapDialogTitle id="certi-detail-title" className='modal-title' onClose={handleClose}>
                        {customText.titleText}
                    </BootstrapDialogTitle>
                    <DialogContent className='content'>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner'>
                                <span></span>
                                <Field
                                    name="title"
                                    id="title"
                                    labelText="Project Name"
                                    className='inputField'
                                    component={CustomInput}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[required, checkString]}
                                    inputProps={{
                                        type: "text",
                                        placeholder: "Project Name",
                                    }}
                                />
                            </Grid>
                            <Grid className='formGrid-inner'>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                    Project Duration*
                                </InputLabel>
                                <Grid container className='formGrid datepicker-formGrid'>
                                    <Grid className='project-duretion-month'>
                                        <Field
                                            name="duration"
                                            component={CustomInput}
                                            id="duration"
                                            labelText="Project Duration*"
                                            formControlProps={{
                                                fullWidth: true
                                            }}

                                            validate={[required, checkZeroMonth]}
                                            inputProps={{
                                                placeholder: "Enter number",
                                                type: 'number',
                                                endAdornment: (
                                                    <InputAdornment position="end" className="month-duration" >
                                                        Months
                                                    </InputAdornment>
                                                )
                                            }}
                                            // onKeyDown={avoidKeysForPlainNumber}
                                            normalize={normalizeCharacter(2)}
                                        />
                                        <Field
                                            id="is_on_going"
                                            name="is_on_going"
                                            component={CustomCheckBox}
                                            labelText="On going"
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner' item lg={12} md={12} sm={12} xs={12}>
                                <Field
                                    name="technologies"
                                    component={ChipAutocomplete}
                                    id="technologies"
                                    labelText="Used Technology*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    options={technologies || []}
                                    displayKey="title"
                                    validate={required}
                                    onChange={() => { }}
                                    inputProps={{
                                        placeholder: "Select technologies",
                                        type: "text",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Field
                                name="description"
                                component={CustomEditor}
                                id="description"
                                labelText="About"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                // validate={[required]}
                                inputProps={{
                                    placeholder: "Enter a text",
                                }}
                            />
                        </Grid>
                        <Grid className='add-image-container'>
                            <p className='label'>Images</p>
                            <List className='add-imageList'>
                                {allImages && allImages.map((file, index) => (
                                    <ListItem className="item" key={index}>
                                        <img src={file.url} alt="img" />
                                        <div className='imageOverlay'>
                                            <img onClick={() => { removeImage(index); }} src={deleteIcon} alt='img' />
                                        </div>
                                    </ListItem>
                                ))}
                                <ListItem className="add-imageBox">
                                    <Button onClick={() => { inputFile.current.click(); }} >
                                        <input ref={inputFile} accept={FILETYPE.image} type="file" multiple onChange={(e) => handleCapture(e)} style={{ 'display': 'none' }} />
                                        +<br />
                                        Add Image
                                    </Button>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Grid className="formGrid-inner" item lg={12} md={12} sm={12}>
                                {/* <FormControl variant="standard" className='formControl'> */}
                                <Field
                                    name="link"
                                    id="link"
                                    labelText="Project Link"
                                    className='inputField'
                                    component={CustomInput}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    required
                                    validate={[checkUrl]}
                                    inputProps={{
                                        type: "text",
                                        placeholder: "Enter text",
                                    }}
                                />
                                {/* </FormControl> */}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='action-div'>
                        <Link underline="none" onClick={handleClose} className="previousBtn">Cancel</Link>
                        <Button type='submit' variant="contained" className="primaryButton nextBtn">
                            {customText.buttonText}
                        </Button>
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </>
    );
}

const ReduxFormMapped = reduxForm({
    form: "CreateProjectForm", // a unique identifier for this form
    enableReinitialize: true,
})(ProjectForm);

const mapStateToProps = (state) => {
    return { formStates: getFormValues('CreateProjectForm')(state) }
}
export default connect(mapStateToProps, "")(ReduxFormMapped)