import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { Skeleton } from '@material-ui/lab';
import "assets/css/skeleton.css";
import Slider from '@material-ui/core/Slider';

const AgencyDeveloperPortfolioSkeleton = () => {
    return (
        <>
            <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={100} height={20} /></Typography>
            <Grid>
                <List className="categoryList">
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                </List>
            </Grid>
            <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={30} height={20} /></Typography>
            <Grid>
                <List className="categoryList">
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                </List>
            </Grid>
            <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={80} height={20} /></Typography>
            <Grid>
                <List className="categoryList">
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                    <Skeleton className='categoryList-item bg-dark' width={160} height={30} />
                </List>
            </Grid>
            <Grid className="aboutContent">
                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={50} height={20} /></Typography>
                <Typography variant='p' component='p' className="content">
                    <Skeleton width='100%' height={22} />
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={85} height={20} /></Typography>
                <Typography variant='p' component='p' className="content">
                    <Skeleton width='100%' height={22} />
                </Typography>

                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={80} height={20} /></Typography>
                <div className="language-section">
                    <Grid className="language-div" container
                        direction="row" justifyContent="space-between">
                        <Typography id="langEng-slider">
                            <Skeleton width={57} height={24} />
                        </Typography>
                        <Typography id="langEng-slider">
                            <Skeleton width={66} height={24} />
                        </Typography>
                        <Slider aria-label="Default" valueLabelDisplay="auto" className="language_slider" >
                            <Skeleton width='100%' height={6} />
                        </Slider>
                    </Grid>
                </div>
                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Grid>
                                <label className="idProof-label transform-scale-1"><Skeleton width={58} height={20} /></label>
                                <Typography variant='p' component='p' className='idProof-name'>
                                    <Skeleton className='bg-dark' variant="circle" width={40} hight={40} />
                                    <Skeleton className='bg-dark' width={150} height={20} />
                                </Typography>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AgencyDeveloperPortfolioSkeleton