import React from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import plusIcon from "assets/img/icons/plus-icon.svg";
import { Field } from "redux-form";
import { IconButton } from "@material-ui/core";
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import { SelectAutocomplete } from "components/CutomAutoComplete/AsynAutocomplete";
import SingleAutocomplete from "components/CutomAutoComplete/SingleAutocomplete";
import { useDispatch } from "react-redux";

const LanguageField = ({ fields, languageList, getLanguages, proficiencyList, required }) => {
    const dispatch = useDispatch();
    if (fields.length === 0) fields.push();
    const selectedLanguage = fields.getAll();
    
    return (
        <>
            <Grid className="language-container">
                <div className="languageTitle">
                    <p>Which language do you know?</p>
                </div>
                {fields?.map((name, index) => (
                    <Grid key={index} container className="formGrid languageContent">
                        <div className="no_of_lang">
                            <p>{index + 1}</p>
                        </div>
                        <Grid className="formGrid-inner">
                            <Field
                                name={`${name}.communication_language`}
                                displayKey="value"
                                id="language"
                                labelText="Language*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={languageList}
                                component={SelectAutocomplete}
                                loading={false}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Select Language",
                                }}
                                onSearch={(e) => {
                                    getLanguages(e.target.value, selectedLanguage.map((language) => (language?.communication_language)));
                                }}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name={`${name}.proficiency`}
                                component={SingleAutocomplete}
                                id="profile_type"
                                labelText="Proficenecy*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={proficiencyList}
                                displayKey="title"
                                validate={[required]}
                                onInputChange={(value) => value}
                                inputProps={{
                                    placeholder: "Select Profile Type",
                                    type: "text",
                                }}
                            />
                        </Grid>
                        <div className="deleteDiv">
                            <IconButton
                                onClick={() => fields.remove(index)}
                                className="deletebtn"
                            >
                                <img src={deleteIcon} alt="close" />
                            </IconButton>
                        </div>
                    </Grid>
                ))}
                <Link
                    onClick={() => {
                        fields.push();
                        dispatch({ type: "LANGUAGE", payload: [] });
                    }}
                    underline="none"
                    className="addBranchBtn addLang cursor-pointer"
                >
                    <img src={plusIcon} alt="add" /> add New
                </Link>
            </Grid>
        </>
    );
};

export default LanguageField;
