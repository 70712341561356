import React from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';

const icon = <RadioButtonUncheckedIcon fontSize="small" className='uncheckedCircle-icon' />;
const checkedIcon = <CheckCircleIcon fontSize="small" className='checkedCircle-icon' />;

const ChipAutocomplete = ({ input, options, id, inputProps, meta: { touched, error }, labelText, displayKey }) => {
    return (
        <>
            <FormControl
                variant="standard"
                className="formControl"
                fullWidth={true}
                error={touched && Boolean(error)}>
                {labelText !== undefined ? (
                    <InputLabel shrink htmlFor={id}>
                        {labelText}
                    </InputLabel>
                ) : null}
                <Autocomplete
                    id="checkboxes-tags-demo"
                    multiple
                    limitTags={3}
                    options={options || []}
                    value={input.value || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option[displayKey]}
                    onChange={(event, value) => {
                        input.onChange(value);
                    }}
                    getOptionSelected={(option, value) => option[displayKey] === value[displayKey]}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option[displayKey]}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params}
                            className='multiSelect-Textfield'
                            placeholder="Select category"
                            value={input.value}
                            InputProps={{
                                ...params.InputProps,
                                ...inputProps,
                            }}
                            fullWidth
                        />
                    )}
                />

                {touched && error ? (
                    <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>
                ) : null}
            </FormControl>
        </>
    )
}

export default ChipAutocomplete;
