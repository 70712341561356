import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import CustomInput from 'components/CustomInput/CustomInput';
import { required, email, normalizeCharacter, checkString, checkContactNumber } from 'utils/validation'
import { Field, getFormValues, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { connect, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { STATUS } from 'utils/constant';
import { withStyles } from '@material-ui/styles';
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import { renderRadioGroup } from "utils/formComponent";
import Radio from '@material-ui/core/Radio';
import AsynChipAutocomplete from 'components/CutomAutoComplete/AsynChipAutocomplete';
import SingleAutocomplete from 'components/CutomAutoComplete/SingleAutocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useHistory } from 'react-router-dom';
import { FormHelperText } from '@material-ui/core';
import PhoneNumberField from 'components/CustomPhoneNumber/PhoneNumberField';
import { getCountryCode } from 'utils/Comman';

const styles = () => ({
    radio: {
        color: primaryColor[4] + "!important"
    },
});
function Form(props) {
    const history = useHistory();
    const { handleSubmit, roleList, id, classes, getPermission, formStates, permissionErrorMessage, checkPermission, change, changeInitialValues, removePermission, initialValues } = props;
    const permissionList = useSelector(state => state.subAdminReducer.permissionList);
    const [time, setTime] = useState('');
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);

    const handleChange = (value) => {
        if (value) getPermission(value.id, '', value, formStates, true);
        else {
            change("user_permissions", []);
            changeInitialValues(formStates);
        }
    }
    const handleChangeCity = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }
    const permissionBySearch = (val) => {
        clearTimeout(time);
        setTime(setTimeout(() => {
            getPermission('', val, '', formStates);
        }, 1000));
    }
    useEffect(() => {
        checkPermission(formStates);
    }, [formStates]);

    useEffect(() => {
        if (initialValues?.contact_number) {
            setCountryCode(getCountryCode(initialValues.contact_number));
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number', `+91`);
        }
    }, [initialValues])
    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <Grid className='add_profile-form'>
                    <Grid container className='formGrid'>
                        {id &&
                            <>
                                <Grid className='formGrid-inner'>
                                    <Field
                                        name="first_name"
                                        component={CustomInput}
                                        id="first_name"
                                        labelText="First Name*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, checkString]}
                                        inputProps={{
                                            placeholder: "Enter first name",
                                            type: "text",
                                        }}
                                        normalize={normalizeCharacter(25)}
                                    />
                                </Grid>
                                <Grid item className="formGrid-inner">
                                    <Field
                                        name="last_name"
                                        component={CustomInput}
                                        id="last_name"
                                        labelText="Last Name*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, checkString]}
                                        inputProps={{
                                            placeholder: "Enter last name",
                                            type: "text",
                                        }}
                                        normalize={normalizeCharacter(25)}
                                    />
                                </Grid>
                                <Grid item className="formGrid-inner">
                                    <Field
                                        name="contact_number"
                                        component={PhoneNumberField}
                                        id="contact_number"
                                        labelText="Phone Number*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        validate={[required, checkContactNumber]}
                                        inputProps={{
                                            placeholder: "Enter phone number",
                                        }}
                                        phone={phoneNumber}
                                        countryCode={countryCode}
                                        handleChangeCity={handleChangeCity}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid container className="formGrid">
                            <Grid item className="formGrid-inner" lg={12} md={12} sm={12}>
                                <Field
                                    className="emailInput"
                                    name="email"
                                    component={CustomInput}
                                    id="email"
                                    labelText="Email*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    validate={[required, email]}
                                    inputProps={{
                                        placeholder: "Enter email",
                                        type: "email",
                                        disabled: id ? true : false,
                                    }}
                                    normalize={normalizeCharacter(50)}
                                />
                            </Grid>
                            <Grid className='formGrid-inner' item lg={12} md={12} sm={12}>
                                <Field
                                    name="groups"
                                    component={SingleAutocomplete}
                                    id="groups"
                                    labelText="Role*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    options={roleList}
                                    displayKey="name"
                                    validate={[required]}
                                    onChange={(value) => handleChange(value)}
                                    onInputChange={(value) => value}
                                    inputProps={{
                                        placeholder: "Select Role",
                                        type: "text",
                                    }}
                                    noOptionsText="No Roles"
                                />
                            </Grid>
                            <Grid container className='formGrid'>
                                <Grid container justifyContent='flex-end' style={{ position: "absolute", zIndex: "1" }}>
                                    <ButtonBase
                                        disableRipple
                                        disabled={!formStates?.groups}
                                        className={classes.button}
                                        aria-describedby={id}
                                        onClick={() => getPermission(formStates?.groups?.id, '', formStates.groups, formStates, true)}>
                                        <span style={{ color: "#2286df" }}>Reset</span>
                                    </ButtonBase>
                                </Grid>
                                <Grid className='formGrid-inner' item lg={12} md={12} sm={12}>
                                    <Field
                                        name="user_permissions"
                                        id="user_permissions"
                                        component={AsynChipAutocomplete}
                                        labelText="Permissions*"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        options={permissionList}
                                        displayKey="name"
                                        valueKey="id"
                                        onChange={(value) => { removePermission(formStates, value) }}
                                        validate={[required]}
                                        inputProps={{
                                            placeholder: "Select Permissions",
                                            type: "text",
                                        }}
                                        onSearch={(event) => {
                                            permissionBySearch(event.target.value)
                                        }}
                                    />
                                    <FormHelperText error={true}>{permissionErrorMessage}</FormHelperText>
                                </Grid>
                            </Grid>
                            {id &&
                                <FormControl component="fieldset">
                                    <FormLabel shrink className="status">Status</FormLabel>
                                    <Field name="is_active" row className={classes.inlineWrap}
                                        component={renderRadioGroup}
                                        id="is_active"
                                        required
                                        validate={[required]}
                                    >
                                        {STATUS.map((radio, index) => (
                                            <FormControlLabel key={index} value={radio.value} control={<Radio className={classes.radio} />} label={radio.label} />
                                        ))}
                                    </Field>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <Grid className='action-div'>
                        <Link onClick={() => (history.push('/sub-admin'))} underline="none" className='previousBtn'>Cancel</Link>
                        <Button variant="contained" type="submit" className='primaryButton nextBtn'>Submit</Button >
                    </Grid>
                </Grid>
            </form>
        </div >
    )
}
const ReduxFormMapped = reduxForm({
    form: 'SubAdminForm',
    enableReinitialize: true,

})(Form)
const mapStateToProps = (state) => {
    return {
        formStates: getFormValues('SubAdminForm')(state),
    }
}

export default connect(mapStateToProps, "")(withStyles(styles)(ReduxFormMapped));



