export const isAllowed = (user, rights) =>
  rights.some(right => user.rights.includes(right));

export const hasRole = (user, roles) =>
  roles.some(role => user.roles.includes(role));

export const hasPermission = (method, permissions) => {
  try {
    if (permissions) return permissions.includes(method);
  } catch (error) {
    return error;
  }
}

export const canHaveAnyPermissions = (methods, permissions) => {
  try {
    let access = false;
    methods.forEach(element => {
      if (permissions.includes(element)) {
        access = true;
        throw 'FOUND';
      }
    });
    return access;

  } catch (error) {
    if (error === 'FOUND') return true;
    else return error;
  }
}

export const getAllowedPermissions = (methods, permissions) => {
  try {
    let access = [];
    methods.forEach(element => {
      if (permissions.includes(element)) {
        access.push(element);
      }
    });
    return access;

  } catch (error) {
    return [];
  }
}

