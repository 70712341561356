import apiService from 'api/ApiService';

export function getTechnology(limit, offset, search, ordering) {
    return () => apiService.get(`technology/?page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}
export function createTechnology(params) {
    return () => apiService.post('technology/', params);
}
export function editTechnology(id, params) {
    return () => apiService.patch(`technology/${id}/`, params);
}
export function getTechnologyDetail(id) {
    return () => apiService.get(`technology/${id}/`);
}
export function deleteTechnology(id) {
    return () => apiService.delete(`technology/${id}/`);
}
export function getTechnologies() {
    return () => apiService.get(`technologies/`);
}