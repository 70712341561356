import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import "assets/css/profile.css";
import { required, checkString, normalizeCharacter, checkContactNumber } from 'utils/validation'
import { Field, getFormValues, reduxForm } from "redux-form";
import CustomInput from "components/CustomInput/CustomInput";
import { connect, useDispatch } from "react-redux";
import * as Actions from '../../../../../store/actions/index';
import { SelectAutocomplete } from "components/CutomAutoComplete/AsynAutocomplete";
import { useHistory } from "react-router-dom";
import { avoidPasting } from "utils/Comman";
import PhoneNumberField from "components/CustomPhoneNumber/PhoneNumberField";

function PersonalDetailForm(props) {
    const { initialValues, change, handleSubmit, formStates } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [cityList, setCityList] = useState({ userCityList: [] });
    const [countryList, setCountryList] = useState({ userCountryList: [] });

    const handleChangeCountrywithContact = (value) => {
        if (value?.code2) {
            setCountryCode(value?.code2?.toLowerCase());
            setPhoneNumber(value?.phone);
        } else if (countryCode !== 'in') {
            setCountryCode('in');
        } else {
            setPhoneNumber('+91');
        }
        // setCountryCode(value?.code2 ? value?.code2?.toLowerCase() : "in");
        change('city', '');
        setCityList({ userCityList: [] });
        setCountryList({ userCountryList: value, });
        change('contact_number', '');
        // setPhoneNumber(null);
    };

    const handleChangeCountry = (value) => {
        if (value.length >= 3) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                setLoading(true)
                dispatch(Actions.getCountryList(value))
                    .then((response) => {
                        setLoading(false)
                        if (response.status === 200 && response.data.length > 0) {
                            setCountryList({ userCountryList: response.data });
                        } else {
                            setCountryList({ userCountryList: [] });
                        }
                    })
            }, 1000));
        } else {
            setCountryList({ userCountryList: [] });
        }
        setCityList({ userCityList: [] });
    };
    const handleChangeCity = (name) => {
        if (name.length >= 3) {
            if (formStates.country?.id) {
                clearTimeout(time);
                setTime(setTimeout(() => {
                    setLoading(true)
                    dispatch(Actions.getCityList({ country: formStates.country?.id, name: name }))
                        .then((response) => {
                            setLoading(false)
                            if (response.status === 200) {
                                setCityList({ userCityList: response.data });
                            } else {
                                setCityList({ userCityList: [] });
                            }
                        })
                }, 1000));
            }
        } else {
            setCityList({ userCityList: [] });
        }
    };
    const handleChangeContactCity = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }
    const removeCountry = () => {
        setCountryList({ userCountryList: [] });
    }
    const removeCity = () => {
        setCityList({ userCityList: [] })
    }
    useEffect(() => {
        if (initialValues && initialValues.user_city) {
            setCityList({ userCityList: [initialValues.city] })
        }
        if (initialValues && initialValues.country) {
            setCountryList({ userCountry: initialValues.country })
            setCountryCode(initialValues?.country?.code2?.toLowerCase() || 'in');
        }
        if (initialValues?.contact_number) {
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number', `+91`);
        }
    }, [initialValues]);
    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid className="add_profile-form">
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="first_name"
                                component={CustomInput}
                                id="first_name"
                                labelText="First Name*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Enter first name",
                                    type: "text",
                                }}
                                normalize={normalizeCharacter(25)}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name="last_name"
                                component={CustomInput}
                                id="last_name"
                                labelText="Last Name*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkString]}
                                inputProps={{
                                    placeholder: "Enter first name",
                                    type: "text",
                                }}
                                normalize={normalizeCharacter(25)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="country"
                                id="country"
                                labelText="Country*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                className='formControl'
                                displayKey="name"
                                options={countryList?.userCountryList?.length > 0 ? countryList?.userCountryList : []}
                                component={SelectAutocomplete}
                                loading={loading}
                                required
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Search Country",
                                }}
                                onChange={(value) => handleChangeCountrywithContact(value)}
                                // onChange={() => {
                                //     change('city', '')
                                //     removeCountry();
                                // }}

                                blur={() => {
                                    removeCountry();
                                }}
                                onSearch={(e) => {
                                    handleChangeCountry(e.target.value)
                                }}
                                onKeyDown={avoidPasting}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name="city"
                                className='formControl'
                                id="city"
                                labelText="City*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                displayKey="display_name"
                                options={cityList?.userCityList?.length > 0 ? cityList?.userCityList : []}
                                component={SelectAutocomplete}
                                loading={loading}
                                required
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Search City",
                                }}
                                onChange={() => {
                                    removeCity();
                                }}
                                blur={() => {
                                    removeCity();
                                }}
                                disabled={formStates?.country ? false : true}
                                onSearch={(e) => {
                                    handleChangeCity(e.target.value)
                                }}
                                onKeyDown={avoidPasting}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name="email"
                                component={CustomInput}
                                id="email"
                                labelText="Email*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Enter email",
                                    type: "email",
                                    disabled: true,
                                }}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name="contact_number"
                                component={PhoneNumberField}
                                id="contact_number"
                                labelText="Phone Number*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkContactNumber]}
                                inputProps={{
                                    placeholder: "Enter phone number",
                                }}
                                phone={phoneNumber}
                                countryCode={countryCode}
                                disableDropdown={true}
                                handleChangeCity={handleChangeContactCity}
                            />
                        </Grid>
                    </Grid>

                    <Grid className="action-div">
                        <Link onClick={() => (history.push('/manage-users'))} underline="none" className="previousBtn">
                            Cancel
                        </Link>
                        <Button
                            variant="contained"
                            className="primaryButton nextBtn"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

const ReduxFormMapped = reduxForm({
    form: "PersonalDetailForm", // a unique identifier for this form
    enableReinitialize: true,
})(PersonalDetailForm);
const mapStateToProps = (state) => {
    return { formStates: getFormValues('PersonalDetailForm')(state) }
}
export default connect(mapStateToProps, "")(ReduxFormMapped)