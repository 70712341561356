const initialState = {
	roleList: [],
	contentTypePermissionList : [],
	editData : null
};

const roleReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_ROLE': {
			return { ...state, roleList: action.payload };
		}
		case 'GET_EDIT_ROLE': {
			return { ...state, editData: action.payload };
		}
		case 'GET_CONTENT_PERMISSION': {
			return { ...state, contentTypePermissionList: action.payload}
		}
		default:
			return { ...state };
	}
};
export default roleReducer;
