import { Redirect } from 'react-router-dom';
import React from 'react';

const AppWrapper = () => {
    let isLoggedIn = localStorage.getItem('ACCESS_TOKEN');
    if (isLoggedIn)
        return <Redirect to="/dashboard" />
    else
        return <Redirect to="/login" />
}
export default AppWrapper