const initialState = {
	subscriptionList: [],
	subscriptionDetail:{}
};

const subscriptionReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_SUBSCRIPTION_LIST': {
			return { ...state, subscriptionList: action.payload };
		}
		case 'GET_SUBSCRIPTION_DETAILS': {
			return { ...state, subscriptionDetail: action.payload };
		}
		default:
			return { ...state };
	}
};
export default subscriptionReducer;
