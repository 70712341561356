import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
export default function DatePickerField(props) {
    const [open, setOpen] = useState(false);
    const { input, id, labelText, labelProps, inputFormat, minDate, meta: { touched, error }, ...custom } = props;
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} className="formControl">
            {labelText !== undefined ? (
                <InputLabel shrink htmlFor={id} {...labelProps}>
                    {labelText}
                </InputLabel>
            ) : null}
            <KeyboardDatePicker
                disableToolbar={true}
                {...custom}
                autoOk
                error={touched && Boolean(error)}
                variant="inline"
                id={id}
                minDate={minDate}
                minDateMessage='Invalid date'
                format={inputFormat}
                value={input.value || null}
                onChange={(newValue) => {
                    input.onChange(moment(newValue).format(inputFormat));
                }}
                InputProps={{ readOnly: true }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(true)}
                InputAdornmentProps={{ position: "end" }}
            />
            {touched && error && <FormHelperText style={{ color: 'red' }}>{touched && error}</FormHelperText>}
        </MuiPickersUtilsProvider >
    )
}

DatePickerField.propTypes = {
    input: PropTypes.object.isRequired,
    labelText: PropTypes.string,
    name: PropTypes.string,
    meta: PropTypes.object
};