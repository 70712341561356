import apiService from 'api/ApiService';

export function getSubAdmin(limit, offset, search, ordering) {
    return () => apiService.get(`user-invitation/?page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}
export function createSubAdmin(params) {
    return () => apiService.post('user-invitation/', params);
}
export function updateSubAdmin(id, params) {
    return () => apiService.patch(`user-invitation/${id}/`, params);
}
export function editSubAdmin(id, params) {
    return () => apiService.patch(`user-invitation/${id}/`, params);
}
export function deleteSubAdmin(id) {
    return () => apiService.delete(`user-invitation/${id}/`);
}
export function getFormRole(limit, offset) {
    return () => apiService.get(`roles-list/?page_size=${limit}&page=${offset + 1}`);
}
export function subAdmin(id) {
    return () => apiService.get(`user-invitation/${id}/`);
}
export function getPermission(id, search) {
    return () => apiService.get(`permissions/?group=${id}&search=${search}`);
}
