import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import copyIcon from 'assets/img/icons/filecopy-icon.svg';
import checkIcon from 'assets/img/icons/check.svg';
import fileIcon from 'assets/img/icons/file-icon.svg';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { SpliceList } from 'components/SpliceList/SpliceList';
import AgencyDetailTabSkeleton from './Skeleton/AgencyDetailTabSkeleton';
import { dispatchContentLoading } from 'utils/Comman';

export default function AgencyDetailTab() {
    const dispatch = useDispatch();
    const userDetail = useSelector((state) => (state.manageUsersReducer.agencyUserDetail));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    const [copied, setCopied] = useState(copyIcon);

    const handleCopy = (e) => {
        e.preventDefault();
        setCopied(checkIcon);
        navigator.clipboard.writeText(userDetail?.company?.company_url);
        setTimeout(() => {
            setCopied(copyIcon);
        }, 1000);
    }

    useEffect(() => { dispatchContentLoading(dispatch); }, []);

    return (
        <>
            {contentLoading ?
                <AgencyDetailTabSkeleton />
                :
                <Grid className='add_profile-form'>
                    <Typography variant='p' component='p' className='categoryTitle'>Technologies <span></span></Typography>
                    <Grid>
                        {userDetail?.company?.technologies ?
                            <List className='categoryList'>
                                <SpliceList data={userDetail?.company?.technologies} />
                            </List> : <Typography variant='p' component='p' className='value'>-</Typography>
                        }
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl variant="standard" className='formControl'>
                                <Grid style={{ width: '100%', }}>
                                    <label className="website-label">Website <span></span></label>
                                    {userDetail?.company?.company_url ?
                                        <a className='project-link' href={userDetail?.company?.company_url} target="_blank" rel="noreferrer">
                                            {userDetail?.company?.company_url}
                                            <img className="cursor-pointer" src={copied} height='16px' alt="img" onClick={(e) => handleCopy(e)} />
                                        </a> : <Typography variant='p' component='p' className='value'>-</Typography>}
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <FormControl variant="standard" className='formControl'>
                                <Grid>
                                    <label className="idProof-label">ID Proof <span></span></label>
                                    {userDetail?.company?.identity ?
                                        <Typography variant='p' component='p' className='idProof-name'>
                                            <span><img src={fileIcon} height="16px" alt="img" /></span>
                                            {userDetail?.company?.file_name}
                                            <a href={userDetail?.company?.identity} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                            >View</a>
                                        </Typography>
                                        : <Typography variant='p' component='p' className='idProof-name'>-</Typography>}
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ margin: '24px 0', }} />
                    <Typography variant='h5' component='h5' className='title'>
                        Branches Details
                    </Typography>
                    <Grid container className='formGrid'>
                        {userDetail?.branches <= 0 ? '-' :
                            userDetail?.branches?.map((detail, index) => (
                                <Grid className='formGrid-inner' key={detail.id}>
                                    <FormControl variant="standard" className='formControl'>
                                        <Grid>
                                            <label>Branch {index + 1}</label>
                                            <Typography variant='p' component='p' className='value'>
                                                {detail?.city?.display_name}
                                            </Typography>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            }
        </>

    )
}
