import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Form from './component/Form';
import * as Actions from '../../../store/actions/index'
import { LoaderContext } from "utils/LoaderContext";
import { toast } from 'react-toastify';
import { errorToast } from 'utils/Comman';

function ForgotPassword(props) {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);

    const handleSubmit = (data) => {
        toggalLoader(true);
        dispatch(Actions.forgotPassword(data))
            .then(response => {
                if (response.status === 200) {
                    dispatch({ type: 'FORGOT_PASSWORD', payload: response.data });
                    toast.success(response.data.detail);
                    props.history.push('/login');
                    toggalLoader(false);
                } else {
                    errorToast(response)
                    dispatch({ type: 'FORGOT_PASSWORD', payload: null });
                    toggalLoader(false);
                    errorToast(response);
                }
            }).catch((response) => {
                toggalLoader(false);
                errorToast(response)
            })
    }

    return (
        <div>
            <Form onSubmit={(data) => {
                handleSubmit(data);
            }} />
        </div>
    )
}
export default ForgotPassword