import React, { useEffect } from 'react'
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import viewIcon from "assets/img/icons/visibility-icon.svg";
import CertificateDetailDialog from 'components/Dialog/CertificateDetailDialog';
import { certificationListImage } from 'utils/Comman';
import CertificatesSkeleton from 'views/RegistrationRequests/RejectedLogs/Skeleton/CertificatesSkeleton';
import { dispatchContentLoading } from 'utils/Comman';

export default function Certificates() {
    const dispatch = useDispatch();
    const [dialogDetail, setDialogDetail] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const certificateDetail = useSelector((state) => (state.userRequestReducer.requestDetail?.updated_data_with_request_change?.certifications));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);

    const handleClickOpen = (project) => {
        setDialogDetail(project)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const columns = [
        { id: 'image_id', label: '', minWidth: 100 },
        {
            id: 'certi_title',
            label: 'Certificate Title',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'certi_date',
            label: 'Certification Date',
            minWidth: 170,
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'action',
            label: '',
            minWidth: 50,
            format: (value) => value.toLocaleString('en-US'),
        },
    ];

    useEffect(() => { dispatchContentLoading(dispatch); }, []);

    return (
        <>
            {contentLoading ? <CertificatesSkeleton />
                :
                <>
                    {certificateDetail && certificateDetail.length > 0 ?
                        <TableContainer>
                            <Table className='project_detail-table certificate_table'>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ top: 50, minWidth: column.minWidth }}
                                                className='tableCellHead'
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {certificateDetail?.map((certificate) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={certificate.id}>
                                            <TableCell className='tableDataCell'>
                                                <div className="certificateImg">
                                                    <a href={certificate?.certificate_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'>
                                                        <img src={certificationListImage(certificate?.certificate_file)} alt="img" />
                                                    </a>
                                                </div>
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {certificate?.title}
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {moment(certificate?.certification_date).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell className='tableDataCell eyeIcon'>
                                                <img src={viewIcon} onClick={() => handleClickOpen(certificate)} alt='view' />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : <Typography variant='p' component='p'>No Records Found</Typography>
                    }
                    <CertificateDetailDialog open={open} detail={dialogDetail} close={() => handleClose()} />
                </>
            }
        </>
    )
}
