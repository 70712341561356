import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import fileIcon from 'assets/img/icons/file-icon.svg';
import 'assets/css/profile.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../../store/actions/index'
import { useParams, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomButton from "components/CustomButtons/Button";
import AgencyLogDialog from 'components/Dialog/AgencyLogDialog';
import { errorToast } from 'utils/Comman';
import ImagePreviewDialog from 'components/Dialog/ImagePreviewDialog';
import copyIcon from 'assets/img/icons/filecopy-icon.svg';
import checkIcon from 'assets/img/icons/check.svg';
import ClientDetailSkeleton from './ClientDetailSkeleton';

const styles = () => ({
    innerContainer: {
        background: '#FFFFFF',
        border: '1px solid rgba(99, 180, 253, 0.16)',
        borderRadius: '12px',
        marginBottom: '24px',
        fontFamily: 'Gordita !important',
    },
    profileImg: {
        border: "2px solid #FFFFFF",
        borderRadius: " 100%",
        width: '220px',
        height: '220px',
        cursor: 'pointer'
    },
    profileDetail: {
        display: 'flex',
        alignItems: 'center',
        padding: '32px 24px',
    },
    profileContent: {
        width: 'calc(100% - 120px)',
        paddingLeft: '24px',
        '& h4': {
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '34px',
            textAlign: 'left',
            marginTop: '0',
            marginBottom: '4px',
        }
    },
    clientId: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '23px',
        textTransform: 'capitalize',
        color: '#323232',
        marginBottom: '32px',
    },
    formGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '20px',
        '& label': {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#0E101A',
        },
        '& p': {
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#0E101A',
        }
    },
    formGridInner: {
        width: '48%',
    },
    userInfo: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& a': {
            color: '#548CA8',
        }
    },
    viewLogBtn: {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#548CA8",
        textDecoration: "none",
        marginLeft: "8px",
        cursor: 'pointer',
    },

});

function ClientDetail(props) {
    const assignedAgencyLogs = useSelector((state) => (state.manageUsersReducer.clientAgencyLog));
    const userDetail = useSelector((state) => (state.manageUsersReducer.clientDetail));
    const [openPreview, setOpenPreview] = useState(false);
    const [copied, setCopied] = useState(copyIcon);
    const [open, setOpen] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const { classes } = props;

    const handleCopy = (e) => {
        e.preventDefault();
        setCopied(checkIcon);
        navigator.clipboard.writeText(userDetail?.company?.company_url);
        setTimeout(() => {
            setCopied(copyIcon);
        }, 1000);
    }

    // const agencyLogs = () => {
    //     dispatch(Actions.assignAgencyLog(params.id)).then(response => {
    //         if (response.status === 200) {
    //             dispatch({ type: 'CLIENT_AGENCY_LOG', payload: response.data });
    //         } else {
    //             dispatch({ type: 'CLIENT_AGENCY_LOG', payload: null });
    //             errorToast(response);
    //         }
    //     }).catch((response) => {
    //         errorToast(response);
    //     })
    // }
    // const openAgencyDialog = () => {
    //     agencyLogs();
    //     setOpen(true);
    // }
    const closeLogDialog = () => {
        setOpen(false);
        dispatch({ type: 'CLIENT_AGENCY_LOG', payload: null });
    }
    const handleOpenPreview = () => {
        setOpenPreview(true);
    }
    const handleClosePreview = () => {
        setOpenPreview(false)
    }
    useEffect(() => {
        dispatch(Actions.getClientDetails(params.id)).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'CLIENT_DETAIL', payload: response.data });
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setPageLoading(false)
                }, 1000);
            } else {
                dispatch({ type: 'CLIENT_DETAIL', payload: null });
                errorToast(response);
            }
        }).catch((response) => {
            errorToast(response);
        })
    }, []);
    return (
        <>
            {pageLoading ?
                <ClientDetailSkeleton classes={classes} />
                :
                <>
                    <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <CustomButton
                            variant="contained"
                            className="primaryButton innerBackBtn"
                            startIcon={<ArrowBackIosIcon />}
                            color="info"
                            onClick={() => {
                                dispatch({ type: 'CLIENT_DETAIL', payload: null });
                                history.push("/manage-users");
                            }}
                        >
                            Back
                        </CustomButton>
                    </Grid>
                    <Grid container className={classes.innerContainer}>
                        <Grid item xl={12} lg={12} md={12} sm={12}>
                            <div className={classes.profileDetail}>
                                <Avatar alt="profile-img" onClick={() => handleOpenPreview()} src={userDetail?.profile_image?.url ?? userDetail?.profile_image} className={classes.profileImg} />
                                <div className={classes.profileContent}>
                                    <Grid className={classes.userInfo}>
                                        <div>
                                            <Typography variant='h4'>{
                                                userDetail?.company?.name ?
                                                    userDetail?.company?.name : '-'
                                            }</Typography>
                                            <Typography variant='p' component='p' className={classes.clientId}>ID : <span>{userDetail?.uid}</span></Typography>
                                        </div>
                                        {/* <a className={classes.viewLogBtn} onClick={openAgencyDialog}>View Agency Logs</a> */}
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Full Name
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.first_name ?
                                                    `${userDetail?.first_name} ${userDetail?.last_name}` : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Email
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.email ?
                                                    userDetail?.email : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.formGrid}>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Contact Number
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.contact_number ?
                                                    userDetail?.contact_number : '-'
                                            }</Typography>
                                        </Grid>
                                        <Grid className={classes.formGridInner}>
                                            <label>
                                                Location
                                            </label>
                                            <Typography variant='p' component='p'>{
                                                userDetail?.city?.display_name ?
                                                    userDetail?.city?.display_name : '-'
                                            }</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={8} md={8}>
                            <Grid className='profile-inner my_profile-inner'>
                                <Grid className='add_profile-form'>
                                    <Grid container className='formGrid'>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControl variant="standard" className='formControl'>
                                                <Grid style={{ width: '100%', }}>
                                                    <label className="website-label">Website <span></span></label>
                                                    {userDetail?.company?.company_url ?
                                                        <a className='project-link' href={userDetail?.company?.company_url} target="_blank" rel="noreferrer">
                                                            {userDetail?.company?.company_url}
                                                            <img className="cursor-pointer" src={copied} height='16px' alt="img" onClick={(e) => handleCopy(e)} />
                                                        </a>
                                                        : <Typography variant='p' component='p' className='value'>-</Typography>}
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='formGrid'>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControl variant="standard" className='formControl'>
                                                <Grid>
                                                    <label className="idProof-label">ID Proof <span></span></label>
                                                    {userDetail?.company?.file_name ?
                                                        <Typography variant='p' component='p' className='idProof-name'>
                                                            <span><img src={fileIcon} height="16px" alt="img" /></span>
                                                            {userDetail?.company?.file_name}
                                                            {/* <a onClick={() => saveAs(userDetail?.company?.identity, imageNameFromUrl(userDetail?.company?.identity))} className="downloadBtn"
                                                    >Download</a> */}
                                                            <a href={userDetail?.company?.identity} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                                            >View</a>
                                                        </Typography>
                                                        : <Typography variant='p' component='p' className='idProof-name'>-</Typography>}
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Divider variant="middle" style={{ margin: '24px 0', }} />
                                    <Typography variant='h5' component='h5' className='title'>
                                        Branches Details
                                    </Typography>
                                    <Grid container className='formGrid'>
                                        {userDetail?.branches?.length <= 0 ? <Typography variant='p' component='p' className='value'>-</Typography> :
                                            userDetail?.branches?.map((detail, index) => (
                                                <Grid className='formGrid-inner' key={detail.id}>
                                                    <FormControl variant="standard" className='formControl'>
                                                        <Grid>
                                                            <label>Branch {index + 1}</label>
                                                            <Typography variant='p' component='p' className='value'>
                                                                {detail?.city?.display_name}
                                                            </Typography>
                                                        </Grid>
                                                    </FormControl>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <div className={classes.innerContainer}>
                                <Grid className='moreDetails'>
                                    <List className='moreDetails-list'>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Main Company Office Location
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.branches?.length <= 0 ? '-' :
                                                    userDetail?.branches?.map((branch) =>
                                                        (branch.is_head_quarter === true ? branch.city.display_name : null)
                                                    )
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Company Size <span></span>
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.company?.size ?
                                                    userDetail?.company?.size : 0
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Number of Branch
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.branches ?
                                                    userDetail?.branches?.length : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                        <ListItem>
                                            <Typography variant='p' component='p'>
                                                Company Registration Number
                                            </Typography>
                                            <Typography variant='h6' component='h6'>
                                                {userDetail?.company?.registration_number ?
                                                    userDetail?.company?.registration_number : '-'
                                                }
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <AgencyLogDialog open={open} close={closeLogDialog} detail={assignedAgencyLogs} />
                    {userDetail?.profile_image &&
                        <ImagePreviewDialog open={openPreview} close={handleClosePreview} attachment={userDetail?.profile_image?.url ?? userDetail?.profile_image} />
                    }
                </>
            }
        </>
    )
}
export default withStyles(styles)(ClientDetail);