import apiService from 'api/ApiService';

export function getSkill(limit, offset, search, ordering) {
    return () => apiService.get(`skill/?page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}
export function getSkillDetail(id) {
    return () => apiService.get(`skill/${id}/`);
}
export function createSkill(params) {
    return () => apiService.post('skill/', params);
}
export function editSkill(id, params) {
    return () => apiService.patch(`skill/${id}/`, params);
}
export function deleteSkill(id) {
    return () => apiService.delete(`skill/${id}/`);
}
export function getSubSkill(limit, offset, id, search, ordering) {
    return () => apiService.get(`skill/${id}/sub-skill/?page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}
export function getSubSkillById(id) {
    return () => apiService.get(`skill/${id}/sub-skill/`);
}
export function getAllSkills(url) {
    return () => apiService.get(`${url}`);
}
