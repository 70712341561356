import apiService from 'api/ApiService';

export function getRegistrationRequest(limit, offset, user_type, isNewUser, search, ordering) {
    return () => apiService.get(`user-request/?user_type=${user_type}&page_size=${limit}&page=${offset + 1}&new_user=${isNewUser}&search=${search}&ordering=${ordering}`);
}
export function updateRequestStaus(id, params) {
    return () => apiService.patch(`user-request/${id}/`, params);
}
export function getSelectedCity(ids) {
    return () => apiService.get(`city/?id=${ids}`);
}
export function getRequestDetail(id) {
    return () => apiService.get(`user-request/${id}/`);
}
export function getRejectionlogs(limit, offset, user_type, isNewUser, search, ordering) {
    return () => apiService.get(`user-request-rejection/?user_type=${user_type}&page_size=${limit}&page=${offset + 1}&search=${search}&ordering=${ordering}`);
}

export function getRequestRejectionDetail(id) {
    return () => apiService.get(`user-request-rejection/${id}/`);
}

export function getAgencyList(id) {
    return (dispatch) => apiService.get(`client/${id}/agencies/`).then(response => {
        if (response.status === 200 && response.data.length > 0) {
            dispatch({ type: 'AGENCY_LIST', payload: response.data });
        }
        else {
            dispatch({ type: 'AGENCY_LIST', payload: [] });
        }
    })
}

export function getDeveloperRequestList(limit, offset, user_type, search, ordering = '', agency = null, status = "") {
    let url = `user-request/?user_type=${user_type}&page_size=${limit}&page=${offset}&search=${search}&ordering=${ordering}&new_registered=${status}`;
    url = agency ? `${url}&parent=${agency}` : url;
    return () => apiService.get(url);
}