import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import 'assets/css/skeleton.css';

const useStyles = makeStyles({
    container: {
        maxHeight: 550,
    },
});

const DevelopersDetailTabSkeleton = () => {
    const classes = useStyles();

    return (
        <div>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" className='project_detail-table manage_developer-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='tableCellHead transform-scale-1 singleTableCell'>
                                <Skeleton width="100%" height={24} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover role="checkbox">
                            <TableCell className='tableDataCell transform-scale-1 singleTableCell'>
                                <Skeleton width="100%" height={21} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}

export default DevelopersDetailTabSkeleton