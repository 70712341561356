import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import viewIcon from "assets/img/icons/visibility-icon.svg";
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/actions/index'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CertificateDetailDialog from 'components/Dialog/CertificateDetailDialog';
import moment from 'moment';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import { errorToast, certificationListImage, dispatchContentLoading } from 'utils/Comman';
import DeveloperCertificateSkeleton from '../Skeleton/DeveloperCertificateSkeleton';

const columns = [
    { id: 'image_id', label: '', minWidth: 100 },
    {
        id: 'certi_title',
        label: 'Certificate Title',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'certi_date',
        label: 'Certification Date',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        format: (value) => value.toLocaleString('en-US'),
    },
];

function DeveloperCertificate(props) {
    const { detail } = props;
    const CertificateDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperCertificateDetail));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    const pageSize = 10;
    const [dialogDetail, setDialogDetail] = useState({});
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = (project) => {
        setDialogDetail(project);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const agencyDeveloperDetail = (page) => {
        dispatch(Actions.getDeveloperRequestDetails(detail?.user?.id, 'certifications', true, page)).then(response => {
            if (response.status === 200) {
                dispatch({ type: "AGENCY_DEVELOPER_CERTIFICATEDETAIL", payload: response.data });
                dispatchContentLoading(dispatch);
            } else {
                dispatch({ type: "AGENCY_DEVELOPER_CERTIFICATEDETAIL", payload: null });
                dispatchContentLoading(dispatch);
                errorToast(response);
            }
        }).catch((response) => {
            dispatchContentLoading(dispatch);
            errorToast(response);
        })
    }
    useEffect(() => {
        agencyDeveloperDetail()
        return () => {
            dispatch({ type: 'CONTENT_LOADING', payload: true });
        }
    }, [])
    return (
        <>
            {contentLoading ? <DeveloperCertificateSkeleton />
                :
                <>
                    {CertificateDetail?.results && CertificateDetail?.results?.length > 0 ?
                        <TableContainer>
                            <Table className='project_detail-table certificate_table'>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ top: 50, minWidth: column.minWidth }}
                                                className='tableCellHead'
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {CertificateDetail?.results?.map((certificate, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            <TableCell className='tableDataCell'>
                                                <div className="certificateImg">
                                                    <a href={certificate?.certificate_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'>
                                                        <img src={certificationListImage(certificate?.certificate_file)} alt="img" />
                                                    </a>
                                                </div>
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {certificate?.title}
                                            </TableCell>
                                            <TableCell className='tableDataCell'>
                                                {moment(certificate?.certification_date).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell className='tableDataCell eyeIcon'>
                                                <img src={viewIcon} onClick={() => handleClickOpen(certificate)} alt='view' />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <PaginationRounded
                                    totalPages={Math.ceil(CertificateDetail?.count / pageSize) || 0}
                                    handlePages={(page) => agencyDeveloperDetail(page)}
                                />
                            </Grid>
                        </TableContainer>
                        : <Typography variant='p' component='p'>No Records Found</Typography>
                    }
                    <CertificateDetailDialog open={open} close={handleClose} detail={dialogDetail} />
                </>
            }
        </>

    );
}

export default DeveloperCertificate;
