import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { reduxForm, Field } from 'redux-form';
import CustomInput from 'components/CustomInput/CustomInput';
import validate from './validate';
import userIcon from 'assets/img/user.svg';
import editIcon from 'assets/img/edit-icon.svg';
// import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { Badge, Box, Grid, Input,Tooltip } from '@material-ui/core';
import { FILETYPE } from 'utils/constant';

const useStyles = makeStyles((theme) => ({
    inputIconsColor: {
        color: "#495057"
    },
    grid: {
        textAlign: 'end !important'
    },
    root: {
        alignSelf: 'center',
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: "none",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));
function ProfileForm(props) {
    const { handleSubmit, files, uploadImage } = props;
    const classes = useStyles();
    const [showTooltip,setShowTooltip]=useState(false);

    const circle = (
        <Box component="span" className="profileEditBox">
            <img src={files?.profile?.url ? files?.profile?.url : (files?.profile ? files?.profile : userIcon)} className={files?.profile ? `profile_image` : `default`} alt='img' />
        </Box>
    );
    const editImage = (
        <label htmlFor="icon-button-file">
            <Input accept={FILETYPE.image} name="profile_image" id="icon-button-file" type="file" onChange={uploadImage} inputProps={{ accept: FILETYPE.image }} />
            <IconButton aria-label="upload picture" component="span">
                <img src={editIcon} alt='img' />
            </IconButton>
        </label>
    );
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={handleSubmit}>
                        <GridContainer>
                            <Grid item lg={12} md={12} sm={12} className="text-center">
                            <Tooltip title="Please select an image file of 2MB or less."
                             open={showTooltip}
                             onOpen={() => setShowTooltip(true)}
                             onClose={() => setShowTooltip(false)}
                            >
                                <Badge color="secondary" onClick={() => setShowTooltip(!showTooltip)} overlap="circular" className='profileBadge' badgeContent={editImage}>
                                    {circle}
                                </Badge>
                            </Tooltip>
                            </Grid>
                            <GridItem xs={12} sm={12} md={6}>
                                <Field
                                    name="username"
                                    component={CustomInput}
                                    id="username"
                                    labelText="User Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "text",
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <People className={classes.inputIconsColor} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Field
                                    name="email"
                                    component={CustomInput}
                                    id="email"
                                    labelText="Email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "email",
                                        disabled: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputIconsColor} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Field
                                    name="first_name"
                                    component={CustomInput}
                                    id="first_name"
                                    labelText="First Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={validate.first_name}
                                    inputProps={{
                                        type: "text",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Person className={classes.inputIconsColor} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <Field
                                    name="last_name"
                                    component={CustomInput}
                                    id="last_name"
                                    labelText="Last Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={validate.last_name}
                                    inputProps={{
                                        type: "text",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Person className={classes.inputIconsColor} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} className={classes.grid} >
                                <Button type="submit" color="info">Update Profile</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}

const ReduxFormMapped = reduxForm({
    form: 'ProfileForm', // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(ProfileForm)
export default ReduxFormMapped;