/* eslint-disable */
import React from "react";
import { connect } from 'react-redux';
import MasterTable from "components/MasterTable/MasterTable";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

const List = (props) => {
    const history = useHistory();
    const { pageLimit, pageOffset, getList, tableName, registationRequestList, searchText, detailRoute } = props;
    const columns = [
        {
            name: "first_name",
            label: "Name",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return registationRequestList?.results[tableMeta.rowIndex]?.first_name + (' ') + registationRequestList?.results[tableMeta.rowIndex]?.last_name
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value == 1 ? <span style={{ color: "orange" }}>Pending</span> : <span style={{ color: "red" }}>Rejected</span>

                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <>
                            <Tooltip title="View details">
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        history.push(`${detailRoute}${value}`);
                                    }}>
                                    <FormatListBulletedIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                }
            }
        },
    ]

    return (
        <>
            <MasterTable
                list={registationRequestList}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        registationRequestList: state.userRequestReducer.registationRequestList
    }
}
export default connect(mapStateToProps, '')(List);
