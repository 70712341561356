import React, { useEffect } from 'react'
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import fileIcon from 'assets/img/icons/file-icon.svg';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import { useDispatch, useSelector } from 'react-redux';
import ReadMoreReadLess from 'components/ReadMoreReadLess/ReadMoreReadLess';
import { SpliceList } from 'components/SpliceList/SpliceList';
import { PROFICIENCY_TAG, PROFICIENCY_DIVIDER } from 'utils/constant';
import AgencyDeveloperPortfolioSkeleton from '../Skeleton/AgencyDeveloperSkeleton/AgencyDeveloperPortfolioSkeleton';
import { dispatchContentLoading } from 'utils/Comman';

export default function AgencyDeveloperPortfolio() {
    const dispatch = useDispatch();
    const developerDetail = useSelector((state) => (state.manageUsersReducer.agencyDeveloperDetail));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    useEffect(() => { dispatchContentLoading(dispatch); }, []);
    return (
        <>
            {contentLoading ? <AgencyDeveloperPortfolioSkeleton />
                :
                <>
                    {developerDetail?.portfolio?.technologies?.length > 0 &&
                        <>
                            <Typography variant='p' component='p' className="profile-subtitle">Technologies</Typography>
                            <Grid>
                                <List className="categoryList">
                                    {developerDetail?.portfolio?.technologies?.length > 0 ?
                                        <SpliceList data={developerDetail?.portfolio?.technologies} /> : '-'
                                    }
                                </List>
                            </Grid>
                        </>
                    }
                    {developerDetail?.portfolio?.skills?.length > 0 &&
                        <>
                            <Typography variant='p' component='p' className="profile-subtitle">Skill</Typography>
                            <Grid>
                                <List className="categoryList">
                                    {developerDetail?.portfolio?.skills?.length > 0 ?
                                        <SpliceList data={developerDetail?.portfolio?.skills} /> : '-'
                                    }
                                </List>
                            </Grid>
                        </>
                    }
                    {developerDetail?.portfolio?.sub_skills?.length > 0 &&
                        <>
                            <Typography variant='p' component='p' className="profile-subtitle">Sub-Skills</Typography>
                            <Grid>
                                <List className="categoryList">
                                    <SpliceList data={developerDetail?.portfolio?.sub_skills} />
                                </List>
                            </Grid>
                        </>
                    }
                    <Grid className="aboutContent">
                        <Typography variant='p' component='p' className="profile-subtitle">About</Typography>
                        <Typography variant='p' component='p' className="content">
                            {developerDetail?.portfolio?.description ?
                                <ReadMoreReadLess
                                    charLimit={100}
                                    readMoreText={"Read More"}
                                    readLessText={"Read Less"}
                                    readMoreClassName="read-moreLink"
                                    readLessClassName="read-moreLink"
                                    children={developerDetail?.portfolio?.description || ''} //eslint-disable-line
                                    textType={true}
                                /> : '-'
                            }

                        </Typography>
                        <Typography variant='p' component='p' className="profile-subtitle">Specialties</Typography>
                        <Typography variant='p' component='p' className="content">
                            {developerDetail?.portfolio?.specialties ?
                                <ReadMoreReadLess
                                    charLimit={100}
                                    readMoreText={"Read More"}
                                    readLessText={"Read Less"}
                                    readMoreClassName="read-moreLink"
                                    readLessClassName="read-moreLink"
                                    children={developerDetail?.portfolio?.specialties || '-'} //eslint-disable-line
                                    textType={true}
                                /> : '-'
                            }
                        </Typography>
                        <Typography variant='p' component='p' className="profile-subtitle">Language</Typography>
                        <div className="language-section">
                            {developerDetail?.portfolio?.communication_language?.length > 0 ?
                                developerDetail?.portfolio?.communication_language?.map((language) => (
                                    <Grid className="language-div" key={language.id} container
                                        direction="row" justifyContent="space-between">
                                        <Typography id="langEng-slider">
                                            {language.communication_language.value}
                                        </Typography>
                                        <Typography id="langEng-slider">
                                            {PROFICIENCY_TAG[language?.proficiency?.id]}
                                        </Typography>
                                        <Slider disabled={true} defaultValue={100 / PROFICIENCY_DIVIDER[language?.proficiency?.id]} aria-label="Default" valueLabelDisplay="auto" className="language_slider" />
                                    </Grid>
                                )) : '-'
                            }
                        </div>
                        <Grid container className='formGrid'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <FormControl variant="standard" className='formControl'>
                                    <Grid>
                                        <label className="idProof-label">ID Proof</label>
                                        {developerDetail?.portfolio?.file_name ?
                                            <Typography variant='p' component='p' className='idProof-name'>
                                                <span><img src={fileIcon} height="16px" alt="img" /></span>
                                                {developerDetail?.portfolio?.file_name}
                                                <a href={developerDetail?.portfolio?.document_file?.url ?? developerDetail?.portfolio?.document_file} className="downloadBtn" target="_blank" rel='noopener noreferrer'
                                                >View</a>
                                            </Typography> : <Typography variant='p' component='p' className='idProof-name'>-</Typography>
                                        }
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}
