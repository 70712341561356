import React, { useState, useEffect } from "react";
import List from "./component/List";
import * as Actions from "../../store/actions/index";
import { useDispatch,useSelector } from "react-redux";
import CustomTabs from "components/CustomTabs/CustomTabs";
import DomainIcon from '@material-ui/icons/Domain';
import PeopleIcon from '@material-ui/icons/People';
import MasterSkeletonForTable from "components/MasterSkeleton/MasterSkeletonForTable";
import { dispatchContentLoading, dispatchPageLoading } from "utils/Comman";
import ContactUsSkeleton from "./Skeleton/ContactUsSkeleton";

function ContactUs() {

    const dispatch = useDispatch();
    const manageActiveTab = useSelector(state => state.commonReducer.manageActiveTab);
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [value, setValue] = useState(0);

    const getContactUsList = (limit, page, search, ordering, userType) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search);
        let type = (userType !== undefined) ? userType : value
        dispatch(Actions.getContactUsRequestList(limit, page, search, ordering, type + 1));
        dispatchPageLoading(dispatch);
        dispatchContentLoading(dispatch);
    }
    const handleChange = (_event, newValue) => {
        if (manageActiveTab.activeTab !== newValue) {
            dispatch({ type: "CONTENT_LOADING", payload: true });
        }
        dispatch({ type: 'SET_ACTIVE_TAB', payload: { activeTab: newValue, } })
        setValue(newValue);
        setRowLimit(10);
        setOffset(0);
        setSearchString('');
        getContactUsList(rowLimit, offset, '', '', newValue);
    };
    useEffect(() => {
        getContactUsList(rowLimit, offset, '', '', value);
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
            dispatch({ type: 'CONTENT_LOADING', payload: true });
        }   
    }, []);

    return (
        <div >
           {pageLoading ?
                <ContactUsSkeleton manageActiveTab={manageActiveTab}/>
                :
                <CustomTabs
                    headerColor="info"
                    tabChange={handleChange}
                    activeTab={value}
                    tabs={[
                        {
                            tabName: "Company",
                            tabIcon: DomainIcon,
                            tabPermission: true,
                            tabContent: (
                                <>
                                    {contentLoading ?
                                        <MasterSkeletonForTable length={4} type='contactus' />
                                        :
                                        <List pageLimit={rowLimit} getList={getContactUsList}
                                            pageOffset={offset} searchText={searchString} />
                                    }
                                </>
                            )
                        },
                        {
                            tabName: "Resources",
                            tabIcon: PeopleIcon,
                            tabPermission: true,
                            tabContent: (
                                <>
                                    {contentLoading ?
                                        <MasterSkeletonForTable length={4} type='contactus' />
                                        :
                                        <List pageLimit={rowLimit} getList={getContactUsList}
                                            pageOffset={offset} searchText={searchString} />
                                    }
                                </>
                            )
                        },
                    ]}
                    />
                }
        </div>
    );
}

export default ContactUs;
