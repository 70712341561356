import { Container } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Preview = (props) => {
  return (
    <Container tabIndex={0} >
      <SlideWrapper>
        <ImageBox>
          {
            <div className='imagePreviewContainer'>
              <img src={props?.attachments} />
            </div>
          }
        </ImageBox>
      </SlideWrapper>
    </Container>
  );
};

const ImageBox = styled.div`
  max-width: 766px;
  height: 500px;
  margin: auto auto;
  border-radius: 12px;
  img {
    width: 100%;
    border-radius: 12px;
    height:100%
  }
  
`;

const SlideWrapper = styled.div`
  position: relative;
//   width: 100vw;
`;

export default Preview;
