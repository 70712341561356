import React from "react";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export const SelectField = ({
    input,
    label,
    name,
    meta: { touched, error },
    // eslint-disable-next-line react/prop-types
    children,
    required,
    ...custom
}) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    return (
        <FormControl
            variant="standard" className='formControl'
            size="small"
            error={touched && Boolean(error)}
            style={{ width: "100%" }}
        >
            <InputLabel
                ref={inputLabel}
                variant="standard"
                required={required}
                htmlFor="selection"
            >
                {label}
            </InputLabel>
            <Select
                {...input}
                {...custom}
                className='inputField'
                size="small"
                variant="standard"
                labelWidth={labelWidth}
                inputProps={{
                    name: `${name}`,
                }}
            >
                {children}
            </Select>
            {touched && error && <FormHelperText>{touched && error}</FormHelperText>}
        </FormControl>
    );
};
SelectField.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    meta: PropTypes.object,
};
SelectField.defaultProps = {
    label: "",
    name: "",
    meta: null,
    required: false,
};

/* AutocompleteTags start */
export const SelectAutocomplete = ({
    displayKey,
    input,
    labelText,
    placeholder,
    id,
    formControlProps,
    inputProps,
    options,
    loading,
    meta: { touched, error },
    onSearch,
    blur,
    ...custom
}) => {
    return (
        <>
            <FormControl
                variant="standard"
                // className="formControl"
                {...formControlProps}
                error={touched && Boolean(error)}
            >
                {labelText !== undefined ? (
                    <InputLabel shrink htmlFor={id}>
                        {labelText}
                    </InputLabel>
                ) : null}
                <Autocomplete
                    {...custom}
                    className="inputField selectTextField"
                    options={options || []}
                    value={input.value || ''}
                    freeSolo={true}
                    disablePortal={true}
                    noOptionsText="No Options found"
                    getOptionLabel={(option) => option[displayKey] || ''}
                    onChange={(event, value) => {
                        input.onChange(value);
                    }}
                    id={id}
                    onBlur={blur}
                    clearOnBlur={true}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                {...custom}
                                error={touched && Boolean(error)}
                                placeholder={placeholder}
                                onChange={onSearch}
                                autoComplete="off"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    ...inputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress color="primary" size={30} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        );
                    }}
                />
                {touched && error && (
                    <FormHelperText style={{ color: "red" }}>
                        {touched && error}
                    </FormHelperText>
                )}
            </FormControl>
        </>
    );
};
SelectAutocomplete.propTypes = {
    input: PropTypes.object.isRequired,
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    meta: PropTypes.object,
    onSearch: PropTypes.func,
};
SelectAutocomplete.defaultProps = {
    meta: null,
    labelText: "",
    placeholder: "",
    options: [],
    onSearch: () => { },
};

