import React, { useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { reduxForm, Field, reset } from 'redux-form';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { renderRadioGroup } from "utils/formComponent";
import { STATUS } from "utils/constant";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import { required, checkString, normalizeCharacter } from "utils/validation";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import 'assets/css/profile.css'

const styles = (theme) => ({
    titleroot: {
        margin: 0,
        padding: theme.spacing(2),
        // backgroundColor: '#39A1FF',
        backgroundColor: '#476072',
        color: '#fff',
    },
    listRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
    },
    radio: {
        color: primaryColor[4] + "!important"
    },
    labelRoot: {
        color: '#6A8CAA'
    }
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.titleroot} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
function MasterForm(props) {

    const { handleClose, open, handleSubmit, classes, message, action } = props;

    const handleCloseDialog = (__, reason) => {
        if (reason == "backdropClick" || reason == "escapeKeyDown") return false;
        handleClose();
    }

    useEffect(() => {
        reset('CreateProjectForm');
    }, [!open]);

    return (
        <div>
            <Dialog className="masterDialogBox" fullWidth={true} maxWidth="sm" open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {message}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner' item xs={12}>
                                <Field
                                    name="title"
                                    component={CustomInput}
                                    id="title"
                                    labelText="Title*"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    required
                                    validate={[required, checkString]}
                                    inputProps={{
                                        type: "text"
                                    }}
                                    normalize={normalizeCharacter(100)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className='formGrid'>
                            <Grid className='formGrid-inner' item xs={12}>
                                <Field
                                    name="description"
                                    component={CustomTextArea}
                                    id="description"
                                    labelText="Description"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    rows={5}
                                    multiline
                                    validate={[]}
                                    inputProps={{
                                        placeholder: "Enter Description",
                                        type: "text",
                                    }}
                                    normalize={normalizeCharacter(500)}
                                />
                            </Grid>
                        </Grid>
                        <FormControl component="fieldset">
                            <FormLabel className={`${classes.labelRoot} status`}>Status</FormLabel>
                            <Field name="status" row className={classes.inlineWrap}
                                component={renderRadioGroup}
                                id="status"
                                required
                                validate={[required]}
                            >
                                {STATUS.map((radio, index) => (
                                    <FormControlLabel key={index} value={radio.value} control={<Radio className={classes.radio} />} label={radio.label} />
                                ))}
                            </Field>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button round type="submit" color="info">
                            {action === 'edit' ? 'Update' : 'Add'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
const ReduxFormMapped = reduxForm({
    form: 'CreateProjectForm', // a unique identifier for this form
    enableReinitialize: true
})(MasterForm)

export default (withStyles(styles)(ReduxFormMapped));
