import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import plusIcon from 'assets/img/icons/plus-icon.svg';
import editIcon from 'assets/img/icons/edit-icon.svg';
import deleteIcon from 'assets/img/icons/delete-icon.svg';
import DeleteProjectDialog from 'components/Dialog/DeleteProjectDialog';
import 'assets/css/profile.css';
import 'assets/css/projectDetail.css';
import ProjectForm from 'components/Dialog/ProjectForm';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from "../../../../../store/actions/index";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PaginationRounded from 'components/Pagination/PaginationRounded';
import { errorToast } from 'utils/Comman';
import { Grid } from '@material-ui/core';

const columns = [
    { id: 'project_name', label: 'Project Name', minWidth: 170 },
    {
        id: 'durations',
        label: 'Project Duration',
        minWidth: 170,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: ' ',
        align: 'right',
        minWidth: 20,
        format: (value) => value.toLocaleString('en-US'),
    },
];

function ProjectListTab(props) {
    const { currentTab, getTabDetails } = props;
    const params = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [projectId, setprojectId] = useState(null);
    const [customChangedText, setCustomChangedText] = useState({ buttonText: "Save", titleText: 'Add Project' });
    const projectList = useSelector(state => state?.manageUsersReducer?.projectList);
    const openMenu = Boolean(anchorEl);
    const [selectedProject, setSelectedProject] = useState(null);
    const [filesData, setFilesData] = useState([]);
    const dispatch = useDispatch();
    const pageSize = 10;

    const handleAction = (event, id) => {
        setprojectId(id);
        setAnchorEl(event.currentTarget);
    };
    const handleActionMenuClose = () => {
        setAnchorEl(null);
    };
    const handleOpenDialog = (id) => {
        setprojectId(id);
        setAnchorEl(null);
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleProjectCloseDialog = () => {
        setSelectedProject(null)
        setFilesData([]);
        setOpenFormDialog(false);
    };
    const removeImage = (index) => {
        let temp = [...filesData];
        temp.splice(index, 1);
        setFilesData(temp);
    };
    const uploadImage = async (e) => {
        const tempArr = [...filesData];
        if (e?.target?.files?.length > 0) {
            [...e?.target?.files]?.forEach((file, index) => {
                const formData = new FormData();
                formData.append("file_obj", file);
                dispatch(Actions.uploadImage(formData)).then(response => {
                    if (response.status === 201) {
                        tempArr?.push(response.data);
                        if (e?.target?.files?.length === index + 1) {
                            setFilesData([...tempArr]);
                        }
                    } else {
                        errorToast(response);
                    }
                }).catch((response) => {
                    errorToast(response);
                })
            });
        }
    }
    const handleOpenFormDialog = async (project) => {
        if (project && project.id) {
            setCustomChangedText({ ...customChangedText, titleText: 'Edit Project' });
            setSelectedProject({ ...project });
            setFilesData(project?.images);
        } else {
            const changedText = { ...customChangedText, titleText: 'Add Project' };
            setCustomChangedText(changedText);
        }
        handleActionMenuClose();
        setOpenFormDialog(true);
    }
    const handleSubmit = (project) => {
        if (project.id) {
            dispatch(Actions.updateProject(params.id, project.id, project)).then(response => {
                if (response.status === 200) {
                    setOpenFormDialog(false);
                    toast.success('Details updated successfully');
                    setSelectedProject(null);
                    setFilesData([])
                    getTabDetails(currentTab);
                } else {
                    toast.error('Please try again!');
                }
            })
        } else {
            dispatch(Actions.createProject(params.id, project)).then(response => {
                if (response.status === 201) {
                    setOpenFormDialog(false);
                    toast.success('Project created successfully');
                    getTabDetails(currentTab);
                    setSelectedProject(null);
                    setFilesData([])
                } else {
                    toast.error('Please try again!');
                }
            })
        }
    }
    const deleteProject = () => {
        dispatch(Actions.deleteProject(params.id, projectId)).then(response => {
            if (response.status === 204) {
                toast.success('Project deleted Successfully')
                handleCloseDialog();
                getTabDetails(currentTab);
            } else {
                errorToast(response);
            }
        }).catch((response) => {
            errorToast(response);
        })
    }
    const handlePageChange = (page) => {
        dispatch(Actions.getFreelancerDetail(params.id, 'projects', false, page)).then(response => {
            if (response.status === 200) {
                //window.scrollTo(0, 0);
                dispatch({ type: 'GET_PROJECT', payload: response?.data });
                dispatch({ type: 'SET_PAGE', payload: page })
            } else {
                dispatch({ type: 'GET_PROJECT', payload: null });
            }
        });
    }
    return (
        <>
            <TableContainer>
                <Table className='project_detail-table'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ top: 50, minWidth: column.minWidth }}
                                    className='tableCellHead'
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projectList?.results && projectList?.results?.map(project => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={project.id} >
                                <TableCell className='tableDataCell'>
                                    {project?.title}
                                </TableCell>
                                <TableCell className='tableDataCell'>
                                    {project?.duration}
                                </TableCell>
                                <TableCell className='tableDataCell'>
                                    <div>
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openMenu ? 'long-menu' : undefined}
                                            aria-expanded={openMenu ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleAction(e, project.id)}
                                            className='actionBtn'>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && projectId === project.id}
                                            onClose={handleActionMenuClose}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            PaperProps={{
                                                style: {
                                                    width: '10ch',
                                                },
                                            }}
                                        >
                                            <MenuItem onClick={() => handleOpenFormDialog(project)} className='more_actionList'>
                                                <Link><img src={editIcon} style={{ width: '12px' }} alt='img' />Edit</Link>
                                            </MenuItem>
                                            <MenuItem onClick={() => handleOpenDialog(project.id)} className='more_actionList delete'>
                                                <Link><img src={deleteIcon} style={{ width: '12px' }} alt='img' />Delete</Link>
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Link underline="none" className="addBranchBtn cursor-pointer" onClick={() => handleOpenFormDialog()}>
                <img src={plusIcon} alt="add" /> add New
            </Link>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <PaginationRounded
                    totalPages={Math.ceil(projectList?.count / pageSize) || 0}
                    handlePages={(page) => handlePageChange(page)}
                />
            </Grid>
            <DeleteProjectDialog open={openDialog} close={handleCloseDialog} backToHome={deleteProject}
                message='Are you Sure you want to Delete this Project?'
                description='This action can’t be undo, When you delete all data of this project it will be erased our system.'
            />
            {openFormDialog &&
                <ProjectForm open={openFormDialog} customText={customChangedText}
                    filesData={filesData} initialValues={selectedProject}
                    close={handleProjectCloseDialog}
                    uploadImage={uploadImage}
                    removeImage={removeImage}
                    onSubmit={(values) => {
                        const newProject = {
                            ...values,
                            technologies: values.technologies.map(val => (val.id)),
                            images: filesData
                        };
                        handleSubmit(newProject);
                    }} />
            }
        </>
    );
}

export default ProjectListTab;
