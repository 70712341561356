import apiService from 'api/ApiService';

export function getContactUsRequestList(limit, page, search, ordering, user_type) {
    return (dispatch) => apiService.get(`contact-us-admin/?user_type=${user_type}&page_size=${limit}&page=${page + 1}&search=${search}&ordering=${ordering}`).then(response => {
        if (response.status === 200) {
            dispatch({ type: "CONTACT_US_LIST", payload: response.data });
        } else {
            dispatch({ type: "CONTACT_US_LIST", payload: null });
        }
    });
}
