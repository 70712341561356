/* eslint-disable */

import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import * as Actions from "../../store/actions/index";
import Button from "components/CustomButtons/Button";
import Form from "./component/Form";
import { LoaderContext } from "utils/LoaderContext";
import { toast } from "react-toastify";
import { errorToast } from "utils/Comman";

const CreateRole = (props) => {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [permissionErrorMessage, setPermissionErrorMessage] = useState('')
    const { history } = props;
    const [roleId] = useState(props.match.params.Id);
    const [action] = useState((roleId ? 'Edit' : "Add"));

    const handleCreateRole = (data) => {
        if (data?.permissions?.length > 0) {
            let permissionIds = data?.permissions?.map((val) => val.id);
            let formData = {
                name: data.name,
                permissions: permissionIds,
            };
            toggalLoader(true);
            dispatch(Actions.createRole(formData))
                .then((response) => {
                    if (response.status === 201) {
                        toast.success("Role created successfully.");
                        toggalLoader(false);
                        props.history.push("/role");
                    } else {
                        toggalLoader(false);
                        errorToast(response);
                    }
                })
                .catch((response) => {
                    toggalLoader(false);
                    errorToast(response);
                });
        } else {
            setPermissionErrorMessage('Please Select Permission');
            return false
        }
    };
    const handleUpdateRole = (data) => {
        if (data?.permissions?.length > 0) {
            let permissionIds = data.permissions.map((val) => val.id);
            let formData = {
                name: data.name,
                permissions: permissionIds,
            };
            toggalLoader(true);
            dispatch(Actions.updateRole(data.id, formData))
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("Role updated successfully.");
                        props.history.push("/role");
                        toggalLoader(false);
                    } else {
                        toggalLoader(false);
                        errorToast(response);
                    }
                })
                .catch(() => {
                    toggalLoader(false);
                    errorToast(response);
                });
        } else {
            setPermissionErrorMessage('Please Select Permission');
            return false
        }
    };

    const getCorePermissionData = () => {
        dispatch(Actions.getContentTypePermission())
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: "GET_CONTENT_PERMISSION",
                        payload: response.data.results,
                    });
                } else {
                    dispatch({ type: "GET_CONTENT_PERMISSION", payload: [] });
                    errorToast(response);
                }
            })
            .catch(() => {
                dispatch({ type: "GET_CONTENT_PERMISSION", payload: [] });
                errorToast(response);
            });
    };
    const checkPermission = (value) => {
        if (value?.permissions?.length > 0) {
            setPermissionErrorMessage('')
        }
    }
    const getRoleEditData = () => {
        if (roleId) {
            dispatch(Actions.getRoleById(roleId))
                .then((response) => {
                    if (response.status === 200) {
                        let EditData = response.data;
                        EditData.permissions = EditData.group_permissions;
                        delete EditData.group_permissions;
                        dispatch({
                            type: "GET_EDIT_ROLE",
                            payload: EditData,
                        });
                    } else {
                        dispatch({ type: "GET_EDIT_ROLE", payload: null });
                        errorToast(response);
                    }
                })
                .catch(() => {
                    dispatch({ type: "GET_EDIT_ROLE", payload: null });
                    errorToast(response);
                });
        }
        else {
            dispatch({ type: "GET_EDIT_ROLE", payload: null });
        }

    };
    useEffect(() => {
        getCorePermissionData();
        getRoleEditData();
    }, []);

    return (
        <>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                    <Button
                        variant="contained"
                        className={"primaryButton"}
                        startIcon={<ArrowBackIosIcon />}
                        color="info"
                        onClick={() => {
                            history.push("/role");
                        }}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={12} justifyContent="center" alignItems="flex-start">
                    <Form action={action} initialValues={useSelector(state => state.roleReducer.editData)}
                        message={{}}
                        checkPermission={checkPermission}
                        permissionErrorMessage={permissionErrorMessage}
                        onSubmit={(values) => {
                            action === "Add"
                                ? handleCreateRole(values)
                                : handleUpdateRole(values);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CreateRole;
