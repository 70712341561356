import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import reducer from "../../../store/reducers";
import withReducer from "../../../store/withReducer";
import AddIcon from "@material-ui/icons/Add";
import { LoaderContext } from "utils/LoaderContext";
import * as Actions from "../../../store/actions/index";
import Button from "components/CustomButtons/Button";
import List from "./component/List";
import MasterForm from "components/MasterForm/MasterForm";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import { errorToast } from "utils/Comman";
import { dispatchPageLoading } from "utils/Comman";
import MasterSkeleton from "components/MasterSkeleton/MasterSkeleton";

function Skill() {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [action, setAction] = useState("add");
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [searchString, setSearchString] = useState('');
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);

    const getSkillListData = (limit, page, search, ordering) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search)
        dispatch(Actions.getSkill(limit, page, search, ordering))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: "GET_SKILL", payload: response.data });
                    dispatchPageLoading(dispatch);
                } else {
                    errorToast(response);
                    dispatch({ type: "GET_SKILL", payload: null });
                    dispatchPageLoading(dispatch);
                }
            })
            .catch((response) => {
                dispatchPageLoading(dispatch);
                errorToast(response);
            });
    };
    const handleCreateSkill = (skill) => {
        Object.keys(skill).forEach((key) => {
            skill[key] = skill[key].trim();
        })
        toggalLoader(true);
        dispatch(Actions.createSkill(skill))
            .then((response) => {
                if (response.status === 201) {
                    toast.success("Skill added successfully");
                    toggalLoader(false);
                    getSkillListData(rowLimit, offset, '');
                    handleCloseFormDialog();
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    const handleRemoveSkill = (skillId) => {
        toggalLoader(true);
        dispatch(Actions.deleteSkill(skillId))
            .then((response) => {
                if (response.status === 204) {
                    toast.success("Skill delete successfully");
                    toggalLoader(false);
                    getSkillListData(10, 0, '');
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    const handleOpenDialog = (data) => {
        setAction(data.action);
        data.skill.status = data.skill.status.toString();
        setSelectedSkill(data.skill);
        setOpenFormDialog(true);
    };
    const handleCloseFormDialog = () => {
        setOpenFormDialog(false);
        setSelectedSkill(null);
        setAction("add");
    };
    useEffect(() => {
        getSkillListData(rowLimit, offset, '', '');
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
        }
    }, []);
    return (
        <>
            {pageLoading ?
                <MasterSkeleton length={4} type='skill' />
                :
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        <CheckPermission
                            component={
                                <Button
                                    variant="contained"
                                    className={"primaryButton"}
                                    startIcon={<AddIcon />}
                                    color="info"
                                    onClick={() => {
                                        setOpenFormDialog(true);
                                    }}
                                >
                                    Add Skill
                                </Button>
                            }
                            permission={PERMISSION.MASTER_SKILL_ADD}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <List
                            pageLimit={rowLimit}
                            pageOffset={offset}
                            removeSkill={handleRemoveSkill}
                            handleOpenDialog={handleOpenDialog}
                            getList={getSkillListData}
                            searchText={searchString}
                        />
                    </Grid>
                </Grid>
            }
            {openFormDialog &&
                <MasterForm
                    action={action}
                    initialValues={action == "add" ? { status: "1" } : selectedSkill}
                    message={action === "add" ? "Create a new Skill" : "Edit Skill"}
                    open={openFormDialog}
                    handleClose={handleCloseFormDialog}
                    onSubmit={(values) => {
                        handleCreateSkill(values);
                    }}
                />
            }
        </>
    );
}
export default withReducer("", reducer)(Skill);
