import apiService from 'api/ApiService';

export function loginUser(params) {
    return () => apiService.authPost('login/', params);
}
export function logoutUser(params) {
    return () => apiService.post('logout/', params);
}
export function registerUser(params) {
    return () => apiService.post('signup/', params);
}
export function forgotPassword(params) {
    return () => apiService.authPost('reset-password/', params);
}
export function resetPassword(params) {
    return () => apiService.authPost('password-reset-confirm/', params);
}
export function updateUserProfile(id, params) {
    return () => apiService.patch(`users/${id}/`, params)
}
export function changePassword(params) {
    return () => apiService.post(`change-password/`, params)
}
export function getUserDetails() {
    return () => apiService.get(`users/`);
}
export function getUserPermissions() {
    return () => apiService.get(`user-permissions/`);
}
export function setUserDetails(params) {
    return () => apiService.authPost(`accept-user-invitation-confirm/`, params);
}
export function addUserProfile(params) {
    return () => apiService.post(`user-profile/`, params);
}
export function validateToken(params) {
    return () => apiService.authPost(`validate-token/`, params);
}