import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import "assets/css/profile.css";
import "assets/css/skeleton.css";
import Avatar from '@material-ui/core/Avatar';
import { Skeleton } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import ProfessionalDetailsSkeleton from "./ProfessionalDetailsSkeleton";


function FreelancerDetailViewSkeleton(props) {
    const { classes } = props;
    return (
        <>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="flex-start">
                <Skeleton className='innerBackBtn transform-scale-1 bg-dark m-t-5' width={140} height={42} />
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='rejection-container'>
                        <Typography variant='h5' component='h5' className='title'>
                            <Skeleton width='100%' height={26} />
                        </Typography>
                        <div className='rejection-container-inner bg-dark skeleton-b-l'>
                            <Typography className='admin-name'>
                                <Skeleton width='100%' height={23} />
                            </Typography>
                            <Typography className='reason-content'>
                                <Skeleton width='100%' height={22} />
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container className={classes.innerContainer}>
                <Grid item xl={9} lg={10} md={12} sm={12}>
                    <div className={classes.profileDetail}>
                        <Skeleton variant="circle" width={224} height={224}>
                            <Avatar className={classes.profileImg} />
                        </Skeleton>
                        <div className={classes.profileContent}>
                            <Typography variant='h4'><Skeleton width={195} height={34} /></Typography>
                            <Typography variant='p' component='p' className={classes.clientId}><Skeleton width={195} height={23} /></Typography>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={70} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={38} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                            </Grid>
                            <Grid className={classes.formGrid}>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={118} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                                <Grid className={classes.formGridInner}>
                                    <label>
                                        <Skeleton width={60} height={14} />
                                    </label>
                                    <Typography variant='p' component='p'><Skeleton width={465} height={23} /></Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid className="profile-container my_profile-container">
                <Grid container spacing={3} className="freelancer_profile-details">
                    <Grid item lg={8} md={8} sm={12}>
                        <Grid className="profile-inner my_profile-inner">
                            <Tabs
                                value={0}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    label={<Skeleton className='p-6-12' width={136} />}
                                    className="tab-title"
                                />
                                <Tab
                                    label={<Skeleton className='p-6-12' width={136} />}
                                    className="tab-title"

                                />
                                <Tab
                                    label={<Skeleton className='p-6-12' width={136} />}
                                    className="tab-title"
                                />
                            </Tabs>
                            <Box sx={{ pt: 3, pb: 3, }}>
                                <Typography><ProfessionalDetailsSkeleton /></Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item lg={4}>
                        <div className={classes.innerContainer}>
                            <Grid className='moreDetails'>
                                <List className='moreDetails-list'>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant='p' component='p'>
                                            <Skeleton width='100%' height={20} />
                                        </Typography>
                                        <Typography variant='h6' component='h6'>
                                            <Skeleton width='100%' height={23} />
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default FreelancerDetailViewSkeleton;
