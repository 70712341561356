import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import MasterTable from "components/MasterTable/MasterTable";
import CheckPermission from "components/Hoc/CheckPermission";
import * as PERMISSION from "utils/permissionConstant";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ReadMoreReadLess from "components/ReadMoreReadLess/ReadMoreReadLess";
import { canHaveAnyPermissions } from "utils/auth";

function SkillDetailList(props) {
    const { subSkillList, handleOpenDialog, getList, pageLimit, removeSubSkill, pageOffset, searchText } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [subSkillId, setSubSkillId] = useState('');
    const [fileEl, setFileEl] = useState(null);
    const [fileElIndex, setFileElIndex] = useState(0);
    const tableName = 'Sub Skill';
    const user = useSelector(state => state.authReducer?.userDetails);
    const actionPermissions = [PERMISSION.MASTER_SKILL_EDIT, PERMISSION.MASTER_SKILL_DELETE];

    const handleConfirmDialog = (value) => {
        setOpenDialog(true);
        setSubSkillId(value);
    };
    const handleConfirmDelete = () => {
        setOpenDialog(false);
        removeSubSkill(subSkillId)
    };
    const handleClose = () => {
        setOpenDialog(false);
        setSubSkillId(null);
    }
    const handleFileELClick = (event, i) => {
        setFileEl(event.currentTarget);
        setFileElIndex(i)
    };
    const handleCloseFileEL = () => {
        setFileEl(null);
    }
    const handleEdit = (value) => {
        handleOpenDialog({ action: "edit", subSkill: value });
        setFileEl(null);
    }
    const handleDelete = (value) => {
        handleConfirmDialog(value);
        setFileEl(null);
    }
    const columns = [
        {
            name: "title",
            label: "Title",
            options: {
                customBodyRender: (value) => {
                    return (value) ? value : '-'
                },
                sortThirdClickReset: true
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value) ?
                        <ReadMoreReadLess
                            charLimit={100}
                            readMoreText={"Read More"}
                            readLessText={"Read Less"}
                            readMoreClassName="read-moreLink"
                            readLessClassName="read-moreLink"
                            children={value || ''} //eslint-disable-line
                            textType={true}
                        /> : '-'
                },
                setCellProps: () => ({
                    style: {
                        maxWidth: "350px",
                        wordBreak: "break-word",
                    }
                }),
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (value === 1) ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>
                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                sort: false,
                display: canHaveAnyPermissions(actionPermissions, user?.user_permissions),
                customBodyRender: (value) => {
                    return (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-owns={fileEl ? "long-menu" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleFileELClick(e, value)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={fileEl}
                                open={Boolean(fileEl) && fileElIndex === value}
                                onClose={handleCloseFileEL}
                            >
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleEdit(value)}>Edit Sub Skill</MenuItem>}
                                    permission={PERMISSION.MASTER_SKILL_EDIT} />
                                <CheckPermission
                                    component={<MenuItem onClick={() => handleDelete(value)}>Delete Sub Skill</MenuItem>}
                                    permission={PERMISSION.MASTER_SKILL_DELETE} />
                            </Menu>
                        </>
                    )
                }
            }
        },
    ]
    return (
        <div>
            <MasterTable
                list={subSkillList}
                getList={getList}
                pageLimit={pageLimit}
                pageOffset={pageOffset}
                columns={columns}
                name={tableName}
                searchText={searchText}
            />
            <ConfirmDialog open={openDialog} close={handleClose}
                save={handleConfirmDelete}
                title="Alert" alertMessage="Are you sure you want to delete sub Skill?" />
        </div>
    )
}
const mapStateToProps = (state) => {
    return { subSkillList: state.skillReducer.subSkillList }
}
export default connect(mapStateToProps, '')(SkillDetailList);
