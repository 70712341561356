import React, { useContext, useEffect, useState } from "react";
import ProfileForm from "./component/ProfileForm";
import PasswordForm from "./component/PasswordForm";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import AccountBox from "@material-ui/icons/AccountBox";
import Lock from "@material-ui/icons/Lock";
import * as Actions from '../../store/actions';
import { useDispatch, connect } from 'react-redux';
import { LoaderContext } from "../../utils/LoaderContext"
import { toast } from "react-toastify";
import ConfirmDialog from "components/Dialog/ConfirmDialog";
import { errorToast } from "utils/Comman";
import { checkForImageValidations } from "utils/Comman";

function UserProfile() {
    const dispatch = useDispatch();
    const { toggalLoader } = useContext(LoaderContext);
    const [userDetail, setUserDetail] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [files, setFiles] = useState({ profile: null });

    const handleUpdateProfile = (values) => {
        const id = values.id;
        toggalLoader(true);
        const payload = {
            first_name: values.first_name,
            last_name: values.last_name,
            profile_image: files.profile,
        }
        if (typeof payload.profile_image === 'string' || payload.profile_image === null) delete payload.profile_image
        dispatch(Actions.updateUserProfile(id, payload)).then(response => {
            if (response.status === 200) {
                setUserDetail(response.data);
                toast.success(`Profile updated successfully`);
                getUserDetails();
            } else {
                toast.error("Please try again");
            }
            toggalLoader(false);
        });
    }

    const handleChangePassword = (values) => {
        dispatch(Actions.changePassword(values)).then(response => {
            if (response.status === 200) {
                setConfirmDialog(true);
                toast.success(response.data.detail);
            } else {
                errorToast(response);
            }
        })
    }
    const handleConfirmLogout = () => {
        window.location.assign("/login");
        localStorage.clear();
    }
    const closeDailog = () => {
        setConfirmDialog(false)
    }
    const getUserDetails = () => {
        dispatch(Actions.getUserDetails()).then(response => {
            if (response.status === 200) {
                const userData = { ...response.data }
                const filesObject = {
                    ...files,
                    profile: userData?.profile_image,
                }
                setFiles(filesObject);
                setUserDetail(userData);
                dispatch({ type: 'GET_USER_DETAILS', payload: response.data });
            } else {
                dispatch({ type: 'GET_USER_DETAILS', payload: '' });
                errorToast(response);
            }
        });
    }
    useEffect(() => {
        getUserDetails();
    }, [])
    const handleImageUpload = async (e) => {
        let file = null;
        let whichFile = null;
        let fileType = null;
        let dimensionCheck = true;
        if (e instanceof File) {
            file = e;
            whichFile = 'identity';
            dimensionCheck = false;
            fileType = 'file';
        } else if (e.target.files.length !== 0) {
            file = e.target.files[0];
            whichFile = 'profile';
            fileType = 'image';
        }
        if (file) {
            const fileCheck = await checkForImageValidations(file, fileType, dimensionCheck);
            if (fileCheck instanceof Error) {
                toast.error(fileCheck.message)
            } else {
                const formData = new FormData();
                formData.append("file_obj", file);
                dispatch(Actions.uploadImage(formData)).then(response => {
                    const filesObject = { ...files }
                    filesObject[whichFile] = response.data;
                    setFiles(filesObject);
                });
            }
        }
    };
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTabs
                        title={userDetail.first_name + " " + userDetail.last_name + " Profile"}
                        headerColor="info"
                        tabs={[
                            {
                                tabName: "Profile",
                                tabIcon: AccountBox,
                                tabPermission: true,
                                tabContent: (
                                    <ProfileForm files={files} uploadImage={handleImageUpload} initialValues={userDetail} onSubmit={(values) => {
                                        let data = { ...values }
                                        handleUpdateProfile(data);
                                    }} />
                                )
                            },
                            {
                                tabName: "Change Password",
                                tabIcon: Lock,
                                tabPermission: true,
                                tabContent: (
                                    <PasswordForm onSubmit={(values) => {
                                        handleChangePassword(values);
                                    }} />
                                )
                            },
                        ]}
                    />
                </GridItem>
            </GridContainer>
            <ConfirmDialog open={confirmDialog} close={closeDailog}
                save={handleConfirmLogout}
                title="Alert" alertMessage="Password change successfully. You want to logout OR continue with this session?" />
        </div>
    );
}
const mapStateToProps = (state) => {
    return { user: state.authReducer.user }
}
export default connect(mapStateToProps, '')(UserProfile);
