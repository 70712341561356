import React from "react";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import closeIcon from 'assets/img/icons/close-icon.svg';
import plusIcon from 'assets/img/icons/plus-icon.svg';
import { Field } from "redux-form";
import { SelectAutocomplete } from "components/CutomAutoComplete/AsynAutocomplete";
import CustomCheckBox from 'components/CustomCheckBox/CustomCheckBox';
import { IconButton } from "@material-ui/core";
import { avoidPasting } from "utils/Comman";

const BranchFields = ({ fields, branchCC, handleChangeCity, handleChangeCountry, change, required, removeBranchCity, formState, removeBranchCountry }) => {
    if (fields.length == 0) {
        fields.push();
    }
    const handleClick = (index) => {
        fields.map((e, i) => {
            change(`${e}.is_head_quarter`, (index === i) ? true : false);
        });
    }
    const isHeadOffice = (index) => {
        let data = fields.get(index);
        return data?.is_head_quarter;
    }

    return (
        <>
            {fields.map((name, index) => (
                <Grid key={index} className="branch-container">
                    <div className="branch-count">
                        <p>Branch {index + 1}</p>
                    </div>
                    <IconButton disabled={isHeadOffice(index)} onClick={() => fields.remove(index)} className="closebtn">
                        <img src={closeIcon} alt="close" />
                    </IconButton>
                    <Grid container className="formGrid">
                        <Grid className="formGrid-inner">
                            <Field
                                name={`${name}.country`}
                                displayKey="name"
                                id={`${name}.country`}
                                labelText={`${(index === 0 ? 'Head' : '')} Office Country*`}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={branchCC?.country?.length > 0 ? branchCC?.country[index] : []}
                                component={SelectAutocomplete}
                                loading={false}
                                required
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Search Country",
                                }}
                                onChange={() => {
                                    change(`${name}.city`, "");
                                    removeBranchCountry(index);
                                }}
                                blur={() => {
                                    removeBranchCountry();
                                }}
                                onSearch={(e) => {
                                    handleChangeCountry(e.target.value, index);
                                }}
                                onKeyDown={avoidPasting}
                            />
                        </Grid>
                        <Grid className="formGrid-inner">
                            <Field
                                name={`${name}.city`}
                                displayKey="display_name"
                                id={`${name}.city`}
                                labelText={`${(index === 0 ? 'Head' : '')} Office City*`}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                options={branchCC?.city?.length > 0 ? branchCC.city[index] : []}
                                component={SelectAutocomplete}
                                loading={false}
                                required
                                disabled={formState?.branches[index]?.country ? false : true}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Search City",
                                }}
                                onSearch={(e) => {
                                    handleChangeCity(e.target.value, index);
                                }}
                                onChange={() => {
                                    removeBranchCity();
                                }}
                                blur={() => {
                                    removeBranchCity();
                                }}
                                onKeyDown={avoidPasting}
                            />
                        </Grid>
                    </Grid>
                    <Field
                        name={`${name}is_head_quarter`}
                        component={CustomCheckBox}
                        onChange={() => handleClick(index)}
                        labelText="Make it as a Main Branch"
                        id="company.is_head_quarter" />
                </Grid>
            ))}
            <Link
                onClick={() => fields.push({ is_head_quarter: false })}
                underline="none"
                className="addBranchBtn cursor-pointer"
            >
                <img src={plusIcon} alt="add" /> add New Branch
            </Link>
        </>
    );
};

export default BranchFields;