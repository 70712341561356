import React, { useContext, useEffect, useState } from 'react';
import Form from './component/Form'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import * as Actions from '../../store/actions/index'
import { toast } from 'react-toastify';
import { LoaderContext } from 'utils/LoaderContext';
import { errorToast } from 'utils/Comman';

export default function ManageSubAdmin() {
    const history = useHistory();
    const dispatch = useDispatch();
    const id = history.location.state;
    const [adminDetail, setAdminDetail] = useState(null)
    const { toggalLoader } = useContext(LoaderContext);
    const [roleList, setRoleList] = useState([])
    const [permissionErrorMessage, setPermissionErrorMessage] = useState('');

    const handleSubmit = (values) => {
        if (values?.user_permissions?.length > 0) {
            toggalLoader(true);
            const formData = new FormData();
            formData.append("email", values.email);
            formData.append("groups", values.groups.id);
            values.user_permissions.map((permission) => {
                formData.append("user_permissions", permission.id);
            });
            dispatch(Actions.createSubAdmin(formData))
                .then((response) => {
                    if (response.status === 201) {
                        toast.success('Sub-Admin Created Successfull');
                        history.push('/sub-admin');
                        toggalLoader(false);
                    } else {
                        toggalLoader(false);
                        errorToast(response);
                    }
                })
                .catch((response) => {
                    toggalLoader(false);
                    errorToast(response);
                });
        } else {
            setPermissionErrorMessage('Please Select Permission');
            return false
        }
    }
    const updateSubAdmin = (values) => {
        if (values?.user_permissions?.length > 0) {
            toggalLoader(true);
            const formData = new FormData();
            formData.append("email", values.email);
            formData.append("first_name", values.first_name.trim());
            formData.append("last_name", values.last_name.trim());
            formData.append("is_active", values.is_active);
            formData.append('contact_number', values.contact_number);
            if (values.groups.id) {
                formData.append("groups", values.groups.id);
            }
            values.user_permissions.map((permission) => {
                formData.append("user_permissions", permission.id);
            });
            dispatch(Actions.updateSubAdmin(id, formData))
                .then((response) => {
                    if (response.status === 200) {
                        toast.success('Sub Admin Updated Successfully');
                        history.push('/sub-admin');
                        toggalLoader(false);
                    } else {
                        toggalLoader(false);
                        errorToast(response);
                    }
                }).catch((response) => {
                    toggalLoader(false);
                    errorToast(response);
                });
        } else {
            setPermissionErrorMessage('Please Select Permission');
            return false
        }
    }
    const subAdminDetail = () => {
        dispatch(Actions.subAdmin(id)).then((response) => {
            if (response.status === 200) {
                let temp = response.data;
                temp.is_active = (temp.is_active) ? "1" : "0";
                temp.groups = temp.groups[0];
                setAdminDetail(temp);
                if (temp?.groups?.id) {
                    getPermission(temp.groups.id, '', null, temp);
                }
            } else {
                errorToast(response);
            }
        }).catch((response) => {
            errorToast(response);
        });
    }
    const getRoleList = () => {
        dispatch(Actions.getFormRole(100, 0)).then((response) => {
            if (response.status === 200) {
                dispatch({ type: "GET_FORM_ROLE", payload: response.data.results });
                let data = response.data.results.map(val => ({ id: val.id, name: val.name }));
                setRoleList(data);
            } else {
                dispatch({ type: "GET_FORM_ROLE", payload: [] });
                errorToast(response);
            }
        }).catch((response) => {
            dispatch({ type: "GET_FORM_ROLE", payload: [] });
            errorToast(response);
        });
    };
    useEffect(() => {
        if (id) {
            subAdminDetail();
        }
        getRoleList()
    }, []);
    const getPermission = (roleId, search, value, formValue, resetRolePermission = false) => {
        dispatch(Actions.getPermission(roleId, search)).then((response) => {
            if (response.status === 200) {
                dispatch({ type: "GET_PERMISSION_LIST", payload: response.data })
                let user_permissions = resetRolePermission ? [...response.data] : formValue?.user_permissions;
                setAdminDetail({
                    ...formValue, groups: (value) ? value : formValue?.groups,
                    user_permissions: (search) ? formValue?.user_permissions : [...user_permissions]
                });
            } else {
                dispatch({ type: "GET_PERMISSION_LIST", payload: [] });
                errorToast(response);
            }
        }).catch((response) => {
            dispatch({ type: "GET_PERMISSION_LIST", payload: [] });
            errorToast(response);
        });
    }
    const changeInitialValues = (formValue) => {
        setAdminDetail({ ...formValue, groups: "", user_permissions: [] });
    }
    const checkPermission = (value) => {
        if (value?.user_permissions?.length > 0) {
            setPermissionErrorMessage('')
        }
    }
    const handleRemovePermission = (formStates, permissions) => {
        setAdminDetail({ ...formStates, user_permissions: permissions });
    }

    return (
        <Form
            roleList={roleList}
            initialValues={adminDetail}
            getPermission={getPermission}
            id={id}
            checkPermission={checkPermission}
            permissionErrorMessage={permissionErrorMessage}
            changeInitialValues={changeInitialValues}
            removePermission={handleRemovePermission}
            onSubmit={(values) => {
                if (id) {
                    updateSubAdmin(values)
                } else {
                    handleSubmit(values);
                }
            }}
        />
    )
}
