import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Slider from '@material-ui/core/Slider';
import FormControl from "@material-ui/core/FormControl";
import { Skeleton } from '@material-ui/lab';

export default function ProfessionalDetailsSkeleton() {
    return (
        <>
            <>
                <Typography variant='p' component='p' className="CategoryTitle"><Skeleton width={105} height={16} /></Typography>
                <Grid>
                    <List className="categoryList">
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                    </List>
                </Grid>
            </>
            <>
                <Typography variant='p' component='p' className="CategoryTitle"><Skeleton width={31} height={16} /></Typography>
                <Grid>
                    <List className="categoryList">
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                    </List>
                </Grid>
            </>
            <>
                <Typography variant='p' component='p' className="CategoryTitle"><Skeleton width={75} height={16} /></Typography>
                <Grid>
                    <List className="categoryList">
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                        <Skeleton className='categoryList-item bg-dark' width={120} height={30} />
                    </List>
                </Grid>
            </>
            <Grid className="aboutContent">
                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={45} height={16} /></Typography>
                <Typography variant='p' component='p' className="content">
                    <Skeleton width='100%' height={14} />
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={77} height={16} /></Typography>
                <Typography variant='p' component='p' className="content">
                    <Skeleton width='100%' height={14} />
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={62} height={16} /></Typography>
                <Typography variant='p' component='p' className="content location">
                    <Skeleton width={70} height={14} />
                </Typography>
                <Typography variant='p' component='p' className="profile-subtitle"><Skeleton width={70} height={16} /></Typography>
                <Typography variant='p' component='p' className="content language">
                    <div className="language-section">
                        <Grid className="language-div" container
                            direction="row" justifyContent="space-between">
                            <Typography id="langEng-slider">
                                <Skeleton width={57} height={24} />
                            </Typography>
                            <Typography id="langEng-slider">
                                <Skeleton width={57} height={24} />
                            </Typography>
                            <Slider aria-label="Default" valueLabelDisplay="auto" className="language_slider" >
                                <Skeleton width='100%' height={6} />
                            </Slider>
                        </Grid>
                    </div>
                </Typography>
                <Grid container className='formGrid'>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="standard" className='formControl'>
                            <Grid>
                                <label className="idProof-label"><Skeleton width={59} height={14} /></label>
                                <Typography variant='p' component='p' className='idProof-name'>
                                    <Skeleton className='bg-dark' variant="circle" width={40} hight={40} />
                                    <Skeleton className='bg-dark' width={150} height={20} />
                                </Typography>
                            </Grid>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
