import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import 'assets/css/projectCard.css';
import ProjectDetailDialog from 'components/Dialog/ProjectDetailDialog';
import linkIcon from 'assets/img/icons/link-icon.svg';
import Tooltip from "@material-ui/core/Tooltip";
import { SpliceList } from 'components/SpliceList/SpliceList';
import ProjectsSkeleton from 'views/RegistrationRequests/RejectedLogs/Skeleton/ProjectsSkeleton';
import { dispatchContentLoading } from 'utils/Comman';

export default function Projects() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [dialogDetail, setDialogDetail] = React.useState({});
    const projectDetail = useSelector((state) => (state.userRequestReducer.requestDetail?.updated_data_with_request_change?.projects));
    const contentLoading = useSelector(state => state.commonReducer.contentLoading);

    const handleClickOpen = (project) => {
        setDialogDetail(project)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => { dispatchContentLoading(dispatch); }, []);

    return (
        <>
            {contentLoading ? <ProjectsSkeleton />
                :
                <>
                    {projectDetail && projectDetail?.length > 0 ?
                        <Grid container className="projectsList">
                            {projectDetail?.map((project) => (
                                <Grid item className="projectListItem" key={project.id}>
                                    <Card className="project-card" >
                                        <CardContent>
                                            <Grid className="project-top">
                                                <div className="icon-container">
                                                    <Typography variant='p' component='p'>{project?.title?.substring(0, 2).toUpperCase()}</Typography>
                                                </div>
                                                <div className="project-title">
                                                    <Typography variant='p' component='p' className="name cursor-pointer" onClick={() => handleClickOpen(project)}>{project.title}</Typography>
                                                </div>
                                            </Grid>
                                            <Grid className='skillset'>
                                                <Typography variant='p' component='p' className='profile-subtitle'>Used Technologies</Typography>
                                                {project?.technologies?.length > 0 ?
                                                    <List className="categoryList">
                                                        <SpliceList data={project?.technologies} />
                                                    </List> : '-'
                                                }
                                            </Grid>
                                            <Grid className='skillset'>
                                                <Typography variant='p' component='p' className='profile-subtitle'>Used Skills</Typography>
                                                {project?.skills?.length > 0 ?
                                                    <List className="categoryList">
                                                        <SpliceList data={project?.skills} />
                                                    </List> : '-'
                                                }
                                            </Grid>
                                            <Grid className='duration-details'>
                                                <Typography>
                                                    {(project?.duration > 0) &&
                                                        <>
                                                            <span>{project?.duration} Months</span>
                                                            {project?.is_on_going && <span> - Ongoing</span>}
                                                        </>
                                                    }
                                                </Typography>
                                                {project?.link &&
                                                    <Tooltip title="Project Link">
                                                        <a href={project?.link} target="blank">
                                                            <img src={linkIcon} alt="img" />
                                                        </a>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid> : <Typography variant='p' component='p'>No Records Found</Typography>}
                    <ProjectDetailDialog open={open} detail={dialogDetail} close={() => handleClose()}
                    />
                </>
            }
        </>
    )
}
