export const MAIN_DASHBOARD_VIEW = 'main.view_dashboard'
export const MAIN_USERPROFILE_VIEW = 'main.view_userProfile'

export const MAIN_MASTER_VIEW = 'common.can_view_master'

export const MASTER_DOMAIN_VIEW = 'common.view_domain'
export const MASTER_DOMAIN_ADD = 'common.add_domain'
export const MASTER_DOMAIN_EDIT = 'common.change_domain'
export const MASTER_DOMAIN_DELETE = 'common.delete_domain'

export const MASTER_TECHNOLOGY_VIEW = 'common.view_technology'
export const MASTER_TECHNOLOGY_ADD = 'common.add_technology'
export const MASTER_TECHNOLOGY_EDIT = 'common.change_technology'
export const MASTER_TECHNOLOGY_DELETE = 'common.delete_technology'

export const MASTER_SKILL_VIEW = 'common.view_skill'
export const MASTER_SKILL_ADD = 'common.add_skill'
export const MASTER_SKILL_EDIT = 'common.change_skill'
export const MASTER_SKILL_DELETE = 'common.delete_skill'

export const MASTER_PROFILE_TYPE_VIEW = 'common.view_profiletype'
export const MASTER_PROFILE_TYPE_ADD = 'common.add_profiletype'
export const MASTER_PROFILE_TYPE_EDIT = 'common.change_profiletype'
export const MASTER_PROFILE_TYPE_DELETE = 'common.delete_profiletype'

export const MASTER_CATEGORY_VIEW = 'common.view_category'
export const MASTER_CATEGORY_ADD = 'common.add_category'
export const MASTER_CATEGORY_EDIT = 'common.change_category'
export const MASTER_CATEGORY_DELETE = 'common.delete_category'

export const MASTER_EXPERIENCE_VIEW = 'common.view_experience'
export const MASTER_EXPERIENCE_ADD = 'common.add_experience'
export const MASTER_EXPERIENCE_EDIT = 'common.change_experience'
export const MASTER_EXPERIENCE_DELETE = 'common.delete_experience'

export const SUB_ADMIN_VIEW = 'user.can_view_sub_admin'
export const SUB_ADMIN_ADD = 'user.can_add_sub_admin'
export const SUB_ADMIN_EDIT = 'user.can_change_sub_admin'
export const SUB_ADMIN_DELETE = 'user.can_delete_sub_admin'

export const ROLE_VIEW = 'auth.view_group'
export const ROLE_ADD = 'auth.add_group'
export const ROLE_EDIT = 'auth.change_group'
export const ROLE_DELETE = 'auth.delete_group'

export const MAIN_REGISTERED_USER_VIEW = 'user.can_view_register'

export const REGISTERED_USER_CLIENTS_VIEW = 'user.can_view_client'
export const REGISTERED_USER_CLIENTS_ADD = 'user.can_add_client'
export const REGISTERED_USER_CLIENTS_EDIT = 'user.can_change_client'
export const REGISTERED_USER_CLIENTS_DELETE = 'user.can_delete_client'

export const REGISTERED_USER_AGENCY_VIEW = 'user.can_view_agency'
export const REGISTERED_USER_AGENCY_ADD = 'user.can_add_agency'
export const REGISTERED_USER_AGENCY_EDIT = 'user.can_change_agency'
export const REGISTERED_USER_AGENCY_DELETE = 'user.can_delete_agency'

export const REGISTERED_USER_FREELANCER_VIEW = 'user.can_view_freelancer'
export const REGISTERED_USER_FREELANCER_ADD = 'user.can_add_freelancer'
export const REGISTERED_USER_FREELANCER_EDIT = 'user.can_change_freelancer'
export const REGISTERED_USER_FREELANCER_DELETE = 'user.can_delete_freelancer'

export const ADD_USER_REQUEST = 'user.add_userrequest'
export const CHANGE_USER_REQUEST = 'user.change_userrequest'
export const VIEW_USER_REQUEST = 'user.view_userrequest'
export const DELETE_USER_REQUEST = 'user.delete_userrequest'

export const ADD_AGENCY_REQUEST = 'user.add_agencyrequest'
export const CHANGE_AGENCY_REQUEST = 'user.change_agencyrequest'
export const VIEW_AGENCY_REQUEST = 'user.view_agencyrequest'
export const DELETE_AGENCY_REQUEST = 'user.delete_agencyrequest'

export const VIEW_CONTACT_US = 'common.view_contactus'
export const ADD_CONTACT_US = 'common.add_contactus'
export const CHANGE_CONTACT_US = 'common.change_contactus'
export const DELETE_CONTACT_US = 'common.delete_contactus'

export const VIEW_SUBSCRIPTION_DETAIL = 'subscription.view_subscriptionplan'
export const ADD_SUBSCRIPTION_DETAIL = 'subscription.add_subscriptionplan'
export const CHANGE_SUBSCRIPTION_DETAIL = 'subscription.change_subscriptionplan'

export const REGISTERED_TEMP = 'user.temp'