import apiService from 'api/ApiService';

export function getCountryList(params) {
    return () => apiService.authGet(`country/?name=${params}`);
}
export function getCityList(params) {
    return () => apiService.authGet('city/', params);
}
export function uploadImage(params) {
    return () => apiService.post(`upload_file/`, params);
}
export function getSkills() {
    return () => apiService.get(`skills/?depth=1`);
}
export function getSubSkills(parent) {
    return () => apiService.get(`skills/?parent=${parent}`);
}
export function getLanguages(params) {
    return () => apiService.get(`languages/?search=${params}`);
}
export function getWorkExperience() {
    return () => apiService.get(`experience/`);
}
export function getProficiency() {
    return () => apiService.get(`proficiencies/`);
}
export function getNotifications(page = 1, pageSize = 10, data = []) {
    return (dispatch) => apiService.get(`notifications/all/?page=${page}&page_size=${pageSize}`).then(response => {
        if (response.status === 200) {
            if (data.length > 0) {
                response.data.results = data.concat(response?.data?.results)
            }
            dispatch({ type: "GET_NOTIFICATIONS", payload: response.data })
        } else {
            dispatch({ type: "GET_NOTIFICATIONS", payload: [] });
        }
    }).catch(() => {
        dispatch({ type: "GET_NOTIFICATIONS", payload: [] });
    });
}
export function markNotificationAsRead(id = null) {
    if (id) {
        return () => apiService.get(`notifications/mark-as-read/${id}/`);
    } else {
        return () => apiService.get(`notifications/mark-all-as-read/`);
    }
}
