const initialState = {
	technologyList: []
};

const technologyReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_TECHNOLOGY': {
			return { ...state, technologyList: action.payload };
		}
		default:
			return { ...state };
	}
};
export default technologyReducer;
