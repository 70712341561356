import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { connect, useDispatch } from 'react-redux';
import * as Actions from '../../../../store/actions/index';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Field, reduxForm, FieldArray, getFormValues } from "redux-form";
import CustomInput from 'components/CustomInput/CustomInput';
import { required, checkContactNumber, numberOnly, checkUrl, checkString, normalizeCharacter, trimmed, registrationNumber } from 'utils/validation'
import { SelectAutocomplete } from "components/CutomAutoComplete/AsynAutocomplete";
import BranchFields from 'components/BranchFields/BranchFields'
import { STATUS, FILETYPE } from "utils/constant";
import { renderRadioGroup } from "utils/formComponent";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Badge, Box, IconButton, Input,Tooltip } from "@material-ui/core";
import userIcon from 'assets/img/user.svg';
import editIcon from 'assets/img/edit-icon.svg';
import CustomCheckBox from "components/CustomCheckBox/CustomCheckBox";
import CustomFileUpload from "components/CustomFileUpload/CustomFIleUpload";
import { useHistory } from "react-router-dom";
import { avoidPasting } from "utils/Comman";
import PhoneNumberField from "components/CustomPhoneNumber/PhoneNumberField";
import { company_size_required } from "utils/validation";

function Form(props) {
    const { handleSubmit, change, initialValues, formStates, uploadImage, files } = props;
    const [branchCC, setBranchCC] = useState({ country: [], city: [] });
    const [cityList, setCityList] = useState({ userCityList: [] });
    const [countryList, setCountryList] = useState({ userCountryList: [] });
    const [countryCode, setCountryCode] = useState("in");
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [showTooltip,setShowTooltip]=useState(false);
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState('')
    const dispatch = useDispatch();
    const history = useHistory();

    const handleChangeCountrywithContact = (value) => {
        if (value?.code2) {
            setCountryCode(value?.code2?.toLowerCase());
            setPhoneNumber(value?.phone);
        } else if (countryCode !== 'in') {
            setCountryCode('in');
        } else {
            setPhoneNumber('+91');
        }
        change('city', '');
        setCityList({ userCityList: [] });
        setCountryList({ userCountryList: value, });
        change('contact_number', '');
        // setPhoneNumber(null);
    };

    const handleChangeBranchCountry = (name, index) => {
        if (name.length >= 3) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                setLoading(true)
                dispatch(Actions.getCountryList(name))
                    .then((response) => {
                        setLoading(false)
                        let tempArr = branchCC;
                        tempArr.country = []
                        if (response.status === 200 && response.data.length > 0) {
                            tempArr.country[index] = response.data
                            setBranchCC({ ...branchCC, ...tempArr })
                        } else {
                            tempArr.country[index] = []
                            setBranchCC({ ...branchCC, ...tempArr })
                        }
                    })
            }, 1000));
        } else {
            let tempArr = branchCC;
            tempArr.country[index] = [];
            setBranchCC({ ...branchCC, ...tempArr })
        }
    };
    const handleChangeBranchCity = (name, index) => {
        if (formStates?.branches[index]) {
            if (name.length >= 3) {
                clearTimeout(time);
                setTime(setTimeout(() => {
                    setLoading(true)
                    dispatch(Actions.getCityList({ country: formStates?.branches[index]?.country?.id, name: name }))
                        .then((response) => {
                            setLoading(false)
                            let tempArr = branchCC;
                            tempArr.city = []
                            if (response.status === 200 && response.data.length > 0) {
                                tempArr.city[index] = response.data
                                setBranchCC({ ...branchCC, ...tempArr })
                            } else {
                                tempArr.city[index] = []
                                setBranchCC({ ...branchCC, ...tempArr })
                            }
                        })
                }, 1000));
            } else {
                let tempArr = branchCC;
                tempArr.city[index] = []
                setBranchCC({ ...branchCC, ...tempArr })
            }
        }
    };
    const handleChangeContactCity = (value, data) => {
        if (data.countryCode === countryCode) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                change('contact_number', `+${value}`);
            }, 1000));
        } else {
            setCountryCode(data.countryCode)
            change('contact_number', `+${data.dialCode}`);
        }
    }
    const handleChangeCountry = (value) => {
        if (value.length >= 3) {
            clearTimeout(time);
            setTime(setTimeout(() => {
                setLoading(true)
                dispatch(Actions.getCountryList(value))
                    .then((response) => {
                        setLoading(false)
                        if (response.status === 200 && response.data.length > 0) {
                            setCountryList({ userCountryList: response.data });
                        } else {
                            setCountryList({ userCountryList: [] });
                        }
                    })
            }, 1000));
        } else {
            setCountryList({ userCountryList: [] });
        }
        setCityList({ userCityList: [] });
    };
    const removeBranchCountry = () => {
        let tempArr = branchCC;
        tempArr.country = []
        setBranchCC({ ...branchCC, ...tempArr })
    }
    const removeBranchCity = () => {
        let tempArr = branchCC;
        tempArr.city = []
        setBranchCC({ ...branchCC, ...tempArr })
    }
    const handleChangeCity = (name) => {
        if (name.length >= 3) {
            if (formStates.country?.id) {
                clearTimeout(time);
                setTime(setTimeout(() => {
                    setLoading(true)
                    dispatch(Actions.getCityList({ country: formStates.country?.id, name: name }))
                        .then((response) => {
                            setLoading(false)
                            if (response.status === 200) {
                                setCityList({ userCityList: response.data });
                            } else {
                                setCityList({ userCityList: [] });
                            }
                        })
                }, 1000));
            }
        } else {
            setCityList({ userCityList: [] });
        }
    };
    const removeCountry = () => {
        setCountryList({ userCountryList: [] });
    }
    const removeCity = () => {
        setCityList({ userCityList: [] })
    }
    const circle = (
        <Box component="span" className="profileEditBox">
            <img src={files.profile?.url ? files.profile?.url : (files.profile ? files.profile : userIcon)} className="default" alt='img' />
        </Box>
    );
    const editImage = (
        <label htmlFor="icon-button-file">
            <Input accept="image/*" name="profile_image" id="icon-button-file" type="file" onChange={uploadImage} inputProps={{ accept: ".png,.jpg,.jpeg" }} />
            <IconButton aria-label="upload picture" component="span">
                <img src={editIcon} alt='img' />
            </IconButton>
        </label>
    );
    useEffect(() => {
        if (initialValues && initialValues.user_city) {
            setCityList({ userCityList: [initialValues.user_city] })
        }
        if (initialValues && initialValues.country) {
            setCountryList({ userCountry: initialValues.country })
            setCountryCode(initialValues?.country?.code2?.toLowerCase() || 'in');
        }
        if (initialValues?.contact_number) {
            setTimeout(() => {
                setPhoneNumber(initialValues.contact_number);
            }, 200);
        } else {
            change('contact_number', `+91`);
        }
    }, [initialValues])

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate>
                <Grid className='add_profile-form'>
                    <h5 className='title'>
                        Personal Details
                    </h5>
                    <Grid item lg={12} md={12} sm={12} className="text-center">
                    <Tooltip title="Please select an image file of 2MB or less."
                     open={showTooltip}
                     onOpen={() => setShowTooltip(true)}
                     onClose={() => setShowTooltip(false)}
                    >
                        <Badge color="secondary" onClick={() => setShowTooltip(!showTooltip)} overlap="circular" className='profileBadge' badgeContent={editImage}>
                            {circle}
                        </Badge>
                    </Tooltip>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="first_name"
                                component={CustomInput}
                                id="first_name"
                                labelText="First Name*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkString]}
                                inputProps={{
                                    placeholder: "Enter first name",
                                    type: "text",
                                }}
                                normalize={normalizeCharacter(25)}
                            />
                        </Grid>
                        <Grid item className="formGrid-inner">
                            <Field
                                name="last_name"
                                component={CustomInput}
                                id="last_name"
                                labelText="Last Name*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkString]}
                                inputProps={{
                                    placeholder: "Enter last name",
                                    type: "text",
                                }}
                                normalize={normalizeCharacter(25)}
                            />
                        </Grid>
                        <Grid container className="formGrid">
                            <Grid className='formGrid-inner form-line-grid'>
                                <Field
                                    name="country"
                                    id="country"
                                    labelText="Country*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    displayKey="name"
                                    options={countryList?.userCountryList?.length > 0 ? countryList?.userCountryList : []}
                                    component={SelectAutocomplete}
                                    loading={loading}
                                    required
                                    validate={[required]}
                                    inputProps={{
                                        placeholder: "Search Country",
                                    }}
                                    onChange={(value) => handleChangeCountrywithContact(value)}
                                    onSearch={(e) => {
                                        handleChangeCountry(e.target.value);
                                    }}
                                    blur={() => {
                                        removeCountry()
                                    }}
                                    onKeyDown={avoidPasting}
                                />
                            </Grid>
                            <Grid className='formGrid-inner form-line-grid'>
                                <Field
                                    name="city"
                                    id="city"
                                    labelText="City*"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    displayKey="display_name"
                                    options={cityList?.userCityList?.length > 0 ? cityList?.userCityList : []}
                                    component={SelectAutocomplete}
                                    loading={loading}
                                    required
                                    validate={[required]}
                                    inputProps={{
                                        placeholder: "Search City",
                                    }}
                                    onChange={() => {
                                        removeCity();
                                    }}
                                    blur={() => {
                                        removeCity();
                                    }}
                                    disabled={formStates?.country ? false : true}
                                    onSearch={(e) => {
                                        handleChangeCity(e.target.value)
                                    }}
                                    onKeyDown={avoidPasting}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid item className="formGrid-inner">
                            <Field
                                name="email"
                                component={CustomInput}
                                id="email"
                                labelText="Email*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required]}
                                inputProps={{
                                    placeholder: "Enter email",
                                    type: "email",
                                    disabled: true,
                                }}
                            />
                        </Grid>
                        <Grid item className="formGrid-inner">
                            <Field
                                name="contact_number"
                                component={PhoneNumberField}
                                id="contact_number"
                                labelText="Phone Number*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkContactNumber]}
                                inputProps={{
                                    placeholder: "Enter phone number",
                                }}
                                phone={phoneNumber}
                                countryCode={countryCode}
                                disableDropdown={true}
                                handleChangeCity={handleChangeContactCity}
                            />
                        </Grid>
                    </Grid>
                    <h5 className='title'>
                        Company Details
                    </h5>
                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="company.name"
                                component={CustomInput}
                                id="name"
                                labelText="Company Name*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[required, checkString]}
                                inputProps={{
                                    placeholder: "Enter company name",
                                    type: "text",
                                }}
                            />
                        </Grid>
                        <Grid className='formGrid-inner registered-formGrid'>
                            <Field
                                name="company.is_registered_company"
                                component={CustomCheckBox}
                                labelText="Company Registration Number*"
                                id="company.is_registered_company"
                                defaultChecked={(formStates?.company?.is_registered_company) ? true : false}
                                onChange={(e) => {
                                    if (e.target.checked === false) {
                                        change('company.registration_number', '')
                                    }
                                }}
                            />
                            <Field
                                name="company.registration_number"
                                component={CustomInput}
                                id="registration_number"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={(formStates?.company?.is_registered_company === true) ? [required, registrationNumber] : null}
                                inputProps={{
                                    placeholder: "Enter company registration number",
                                    type: "text",
                                    disabled: formStates?.company?.is_registered_company ? false : true
                                }}
                                normalize={trimmed()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner'>
                            <Field
                                name="company.size"
                                component={CustomInput}
                                id="size"
                                labelText="Company Size*"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[company_size_required, numberOnly]}
                                inputProps={{
                                    placeholder: "Enter company size",
                                    type: "number",
                                }}
                            />
                        </Grid>
                        <Grid className='formGrid-inner'>
                            <Field
                                type="file"
                                name='company.identity'
                                id="company.identity"
                                component={CustomFileUpload}
                                onChange={(e) => uploadImage(e)}
                                validate={[required]}
                                mimeType={FILETYPE.file}
                                inputProps={{ accept: FILETYPE.file, required: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className='formGrid'>
                        <Grid className='formGrid-inner' item lg={12} md={12} sm={12}>
                            <Field
                                name="company.company_url"
                                component={CustomInput}
                                id="company.company_url"
                                labelText="Company Url"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                validate={[checkUrl]}
                                inputProps={{
                                    placeholder: "Enter Website Url",
                                    type: "text",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography className='title'>
                        Branches Details
                    </Typography>
                    <FieldArray name="branches" branchCC={branchCC}
                        component={BranchFields} handleChangeCity={handleChangeBranchCity} formState={formStates}
                        handleChangeCountry={handleChangeBranchCountry} removeBranchCity={removeBranchCity}
                        removeBranchCountry={removeBranchCountry} loading={loading} change={change} required={required} />
                    <Grid container className='formGrid'>
                        <Grid item xs={12} className='formGrid-inner'>
                            <FormLabel shrink className="status">Status</FormLabel>
                            <Field name="is_active" row component={renderRadioGroup} id="status" required validate={[required]}>
                                {STATUS.map((radio, index) => (
                                    <FormControlLabel key={index} value={radio.value} control={<Radio style={{ color: "#08D6A8" }} />} label={radio.label} />
                                ))}
                            </Field>
                        </Grid>
                    </Grid>
                    <Grid className='action-div'>
                        <Link underline="none" onClick={() => (history.push('/manage-users'))} className='previousBtn'>Cancel</Link>
                        <Button variant="contained" type="submit" className='primaryButton nextBtn'>Submit</Button >
                    </Grid>
                </Grid>
            </form>
        </div>);
}

const ReduxFormMapped = reduxForm({
    form: "ClientForm", // a unique identifier for this form
    enableReinitialize: true,
})(Form);
const mapStateToProps = (state) => {
    return { formStates: getFormValues('ClientForm')(state) }
}
export default connect(mapStateToProps, "")(ReduxFormMapped)