import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LoaderContext } from "utils/LoaderContext";
import * as Actions from "../../store/actions/index";
import List from "./component/List";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button";
import * as PERMISSION from "utils/permissionConstant";
import CheckPermission from "components/Hoc/CheckPermission";
import { useHistory } from "react-router-dom";
import "assets/css/profile.css";
import { errorToast } from "utils/Comman";
import { dispatchPageLoading } from "utils/Comman";
import MasterSkeleton from "components/MasterSkeleton/MasterSkeleton";

export default function SubAdmin() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { toggalLoader } = useContext(LoaderContext);
    const [rowLimit, setRowLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [searchString, setSearchString] = useState('');
    const pageLoading = useSelector(state => state.commonReducer.pageLoading);


    const getSubAdminListData = (limit, page, search, ordering) => {
        setRowLimit(limit);
        setOffset(page);
        setSearchString(search);
        dispatch(Actions.getSubAdmin(limit, page, search, ordering))
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: "GET_SUB_ADMIN", payload: response.data });
                    dispatchPageLoading(dispatch);
                    window.scrollTo(0, 0);
                } else {
                    dispatch({ type: "GET_SUB_ADMIN", payload: null });
                    dispatchPageLoading(dispatch);
                    errorToast(response);
                }
            })
            .catch((response) => {
                dispatchPageLoading(dispatch);
                errorToast(response);
            });
    };
    const handleRemoveSubAdmin = (subAdminId) => {
        toggalLoader(true);
        dispatch(Actions.deleteSubAdmin(subAdminId))
            .then((response) => {
                if (response.status === 204) {
                    toast.success("SubAdmin delete successfully");
                    toggalLoader(false);
                    getSubAdminListData(rowLimit, offset, '', '');
                } else {
                    toggalLoader(false);
                    errorToast(response);
                }
            })
            .catch((response) => {
                toggalLoader(false);
                errorToast(response);
            });
    };
    useEffect(() => {
        getSubAdminListData(rowLimit, offset, '', '');
        return () => {
            dispatch({ type: 'PAGE_LOADING', payload: true });
        }
    }, []);

    return (
        <div>
            {pageLoading ?
                <MasterSkeleton length={7} type='subAdmin' />
                :
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        <CheckPermission
                            component={
                                <Button
                                    variant="contained"
                                    className={"primaryButton"}
                                    startIcon={<AddIcon />}
                                    color="info"
                                    onClick={() => {
                                        history.push('/sub-admin/add')
                                    }}
                                >
                                    Add Sub Admin
                                </Button>
                            }
                            permission={PERMISSION.SUB_ADMIN_ADD}
                        />
                    </Grid>
                    <Grid item xs>
                        <List
                            pageLimit={rowLimit}
                            removeSubAdmin={handleRemoveSubAdmin}
                            pageOffset={offset}
                            getList={getSubAdminListData}
                            searchText={searchString}
                        />
                    </Grid>
                </Grid>
            }
        </div >
    );
}
