/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import history from "utils/history";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { hasChildren, isChildActive } from "utils/SidebarValidation";
import logo from "assets/img/logo.png";
import { canHaveAnyPermissions } from "utils/auth";

const useStyles = makeStyles(styles);
export default function Sidebar(props) {
	const manageActiveTab = useSelector(state => state?.commonReducer?.manageActiveTab);
	const { color, image, routes, allPermission } = props;
	const [menu, setMenu] = useState({});
	const dispatch = useDispatch();
	const classes = useStyles();

	function activeRoute(routeName) {
		return window.location.href.indexOf(routeName) > -1 ? true : false;
	}
	const handleCollpase = (item) => {
		setMenu({ [item]: !menu[item] });
	};

	const resetCommonTab = (isRouteActive) => {
		if (!isRouteActive && manageActiveTab?.activeTab != 0) {
			dispatch({
				type: 'SET_ACTIVE_TAB',
				payload: {
					activeTab: 0,
				}
			})
			dispatch({
				type: 'SET_DEVELOPER_TAB',
				payload: {
					activeTab: 0,
				}
			})
		}
	}

	const SingleLevel = ({ item }) => {
		const whiteFontClasses = classNames({ [" " + classes.blueFont]: activeRoute(item.path) });
		const listItemClasses = classNames({ [" " + classes[color]]: activeRoute(item.path) });

		if (allPermission && allPermission.some((permission) => (permission == item.permissions))) {
			if (item?.nested) {
				if (canHaveAnyPermissions(item.nested, allPermission)) {
					return (
						<NavLink
							to={item.path}
							className={activeRoute + classes.item}
							activeClassName="active"
							style={(item.isHide) ? { display: 'none' } : { display: "" }}
							onClick={() => resetCommonTab(activeRoute(item.path))}
						>
							<ListItem button className={classes.itemLink + listItemClasses} >
								{typeof item.icon === "string" ? (
									<Icon
										className={classNames(classes.itemIcon, whiteFontClasses)}
									>
										{item.icon}
									</Icon>
								) : (
									<item.icon
										className={classNames(classes.itemIcon, whiteFontClasses)}
									/>
								)}
								<ListItemText
									primary={item.name}
									className={classNames(classes.itemText, whiteFontClasses)}
									disableTypography={true}
								/>
							</ListItem>
						</NavLink>
					)
				}
			} else {
				return (
					<NavLink
						to={item.path}
						className={activeRoute + classes.item}
						activeClassName="active"
						style={(item.isHide) ? { display: 'none' } : { display: "" }}
						onClick={() => resetCommonTab(activeRoute(item.path))}
					>
						<ListItem button className={classes.itemLink + listItemClasses} >
							{typeof item.icon === "string" ? (
								<Icon
									className={classNames(classes.itemIcon, whiteFontClasses)}
								>
									{item.icon}
								</Icon>
							) : (
								<item.icon
									className={classNames(classes.itemIcon, whiteFontClasses)}
								/>
							)}
							<ListItemText
								primary={item.name}
								className={classNames(classes.itemText, whiteFontClasses)}
								disableTypography={true}
							/>
						</ListItem>
					</NavLink>
				)
			}

		}
		return null
	}

	let links = (
		<List className={classes.list}>
			{routes.map((item, index) => {
				const whiteFontClasses = classNames({ [" " + classes.blueFont]: activeRoute(item.path) });
				const listItemClasses = classNames({ [" " + classes[color]]: activeRoute(item.path) });
				if (hasChildren(item)) {
					const { items: children } = item;
					if (allPermission && allPermission.some((permission) => (permission == item.permissions))) {
						return (
							<React.Fragment key={index}>
								<ListItem style={(item.isHide) ? { display: 'none' } : { display: "" }} button onClick={() => handleCollpase(item.name)}
									className={classes.itemLink + listItemClasses} >
									{typeof item.icon === "string" ? (
										<Icon
											className={classNames(classes.itemIcon, whiteFontClasses)}
										>
											{item.icon}
										</Icon>
									) : (
										<item.icon
											className={classNames(classes.itemIcon, whiteFontClasses)}
										/>
									)}
									<ListItemText
										className={classNames(classes.itemText, whiteFontClasses)}
										disableTypography={true} primary={item.name} />
									{menu[item.name] ? <ExpandLessIcon className={classes.arrowIcon} /> : <ExpandMoreIcon className={classes.arrowIcon} />}
								</ListItem>
								<Collapse in={!!menu[item.name]} unmountOnExit>
									<List component="div" disablePadding >
										{children.map((child, i) => (
											<SingleLevel key={i} item={child} onClick={() => setMenu(true)} />
										))}
									</List>
								</Collapse>
							</React.Fragment>
						)
					}
					return null;
				} else {
					if (allPermission && allPermission.some((permission) => (permission == item.permissions))) {
						if (item?.nested) {
							if (canHaveAnyPermissions(item.nested, allPermission)) {
								return (
									<NavLink
										key={index * 98}
										to={item.path}
										className={activeRoute + classes.item}
										activeClassName="active"
										style={(item.isHide) ? { display: 'none' } : { display: "" }}
										onClick={() => resetCommonTab(activeRoute(item.path))}
									>
										<ListItem button className={classes.itemLink + listItemClasses}  >
											{typeof item.icon === "string" ? (
												<Icon
													className={classNames(classes.itemIcon, whiteFontClasses)}
												>
													{item.icon}
												</Icon>
											) : (
												<item.icon
													className={classNames(classes.itemIcon, whiteFontClasses)}
												/>
											)}
											<ListItemText
												primary={item.name}
												className={classNames(classes.itemText, whiteFontClasses)}
												disableTypography={true}
											/>
										</ListItem>
									</NavLink>
								)
							}
						} else {
							return (
								<NavLink
									key={index * 98}
									to={item.path}
									className={activeRoute + classes.item}
									activeClassName="active"
									style={(item.isHide) ? { display: 'none' } : { display: "" }}
									onClick={() => resetCommonTab(activeRoute(item.path))}
								>
									<ListItem button className={classes.itemLink + listItemClasses} >
										{typeof item.icon === "string" ? (
											<Icon
												className={classNames(classes.itemIcon, whiteFontClasses)}
											>
												{item.icon}
											</Icon>
										) : (
											<item.icon
												className={classNames(classes.itemIcon, whiteFontClasses)}
											/>
										)}
										<ListItemText
											primary={item.name}
											className={classNames(classes.itemText, whiteFontClasses)}
											disableTypography={true}
										/>
									</ListItem>
								</NavLink>
							)
						}

					}
					return null
				}

			})}
		</List >
	);

	let brand = (
		<div className={classes.logo}>
			<a
				onClick={() => { history.push('/project'); }}
				style={{ cursor: "pointer" }}
				className={classNames(classes.logoLink)}
			>
				<div className={classes.logoImage}>
					<img src={logo} alt="logo" className={classes.img} />
				</div>
			</a>
		</div>
	);
	return (
		<div>
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={"right"}
					open={props.open}
					classes={{
						paper: classes.drawerPaper
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						<AdminNavbarLinks />
						{links}
					</div>
					{image !== undefined ? (
						<div
							className={classes.background}
						//style={{ backgroundImage: "" }}
						/>
					) : null}
				</Drawer>
			</Hidden>

			<Hidden smDown implementation="css">
				<Drawer
					anchor="left"
					variant="permanent"
					open
					classes={{
						paper: classes.drawerPaper
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>{links}</div>
					{image !== undefined ? (
						<div
							className={classes.background}
						//style={{ backgroundImage: "url(" + image + ")" }}
						/>
					) : null}
				</Drawer>
			</Hidden>
		</div>
	);
}

Sidebar.propTypes = {
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
	logo: PropTypes.string,
	image: PropTypes.string,
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool
};
