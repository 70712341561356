import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { reduxForm, Field, getFormValues } from "redux-form";
import { capitalize, FormHelperText } from "@material-ui/core";
import CheckBox from "./CheckBox";
import "assets/css/profile.css";
import plusIcon from "assets/img/icons/plus-icon.svg";
import minusIcon from "assets/img/icons/minus-icon.svg";
import { required, checkString, normalizeCharacter } from "utils/validation";

const styles = (theme) => ({
    root: {
        padding: "2px 4px",
        alignItems: "center",
        width: "90%",
        margin: "auto",
    },
    titleroot: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: "#548CA8",
        color: "#fff",
    },
    listRoot: {
        width: "100%",
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "#fff",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rootAcordion: {
        margin: "10px 0px",
    },
    rootAcordionDetails: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        '@media (max-width: 1200px)': {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        '@media (max-width: 900px)': {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        '@media (max-width: 575px)': {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },
    filedLabel: {
        root: { width: "22%" },
    },
});
export const Form = (props) => {
    const { handleSubmit, action, classes, permissionErrorMessage, checkPermission, formStates } = props;
    const getCorePermissionData = useSelector(
        (state) => state.roleReducer.contentTypePermissionList
    );
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        checkPermission(formStates)
    }, [formStates])
    return (
        <>
            <form onSubmit={handleSubmit} className={styles.root}>
                <p className="titlehead">
                    {action === "Edit" ? "Edit Role" : "Create New Role"}
                </p>
                <Grid container className="formGrid">
                    <Grid className="formGrid-inner" item lg={12} md={12} sm={12} xs={12}>
                        <Field
                            name="name"
                            component={CustomInput}
                            id="name"
                            labelText="Role Name*"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={[required, checkString]}
                            inputProps={{
                                type: "text",
                            }}
                            normalize={normalizeCharacter(100)}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justifyContent="flex-end">
                    <FormHelperText error={true}>{permissionErrorMessage}</FormHelperText>
                </Grid>
                {getCorePermissionData.map((param, key) => (
                    <Accordion
                        square={false}
                        className={classes.rootAcordion}
                        defaultExpanded={true}
                        onChange={handleChange(key)}
                        expanded={expanded === key}
                        key={key}
                    >
                        <AccordionSummary
                            expandIcon={
                                expanded === key ? (
                                    <img src={minusIcon} alt="img" />
                                ) : (
                                    <img src={plusIcon} alt="img" />
                                )
                            }
                            aria-controls={param.model}
                            id={param.model}
                        >
                            <Typography className={styles.heading}>
                                {capitalize(param.model)}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.rootAcordionDetails}>
                            <Field
                                key={key}
                                name="permissions"
                                label={param.name}
                                component={CheckBox}
                                options={param}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-end"
                    alignItems="flex-start"
                >
                    <Button round type="submit" color="info">
                        {action === "Edit" ? "Update" : "Add"}
                    </Button>
                </Grid>
            </form>
        </>
    );
};

const ReduxFormMapped = reduxForm({
    form: "CreateRoleForm", // a unique identifier for this form
    enableReinitialize: true,
})(Form);

const mapStateToProps = (state) => {
    return { formStates: getFormValues('CreateRoleForm')(state) }
}
export default connect(mapStateToProps, "")(withStyles(styles)(ReduxFormMapped));

