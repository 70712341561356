import Dashboard from "@material-ui/icons/Dashboard";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import DashboardPage from "views/Dashboard/Dashboard.js";
// import Domain from "views/Master/Domain/Domain";
import BallotIcon from "@material-ui/icons/Ballot";
import UserProfile from "views/UserProfile/UserProfile";
import Technology from "views/Master/Technology/Technology";
import ProfileType from "views/Master/ProfileType/ProfileType";
import Role from "views/Role/Role";
import CreateRole from "views/Role/CreateRole";
import SubAdmin from "views/SubAdmin/SubAdmin";
import * as PERMISSION from "utils/permissionConstant";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import UpdatedRequests from "views/RegistrationRequests/UpdateRequest/UpdatedRequests";
import NewRequests from "views/RegistrationRequests/NewRequest/NewRequests";
import ManageAgency from "views/ManageUsers/component/Agency/ManageAgency";
import ManageUsers from "views/ManageUsers/ManageUsers";
import ManageClient from "views/ManageUsers/component/Client/ManageClient";
import Skill from "views/Master/Skill/Skill";
import SkillDetail from "views/Master/Skill/component/SkillDetail";
import ManageSubAdmin from "views/SubAdmin/ManageSubAdmin";
import WorkIcon from "@material-ui/icons/Work";
import BuildIcon from "@material-ui/icons/Build";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ClientDetail from "views/ManageUsers/component/Client/ClientDetail";
import AgencyDetail from "views/ManageUsers/component/Agency/AgencyDetail";
import AgencyDeveloperProfile from "views/ManageUsers/component/Agency/AgencyDeveloperProfile/AgencyDeveloperProfile";
import FreelancerDetail from "views/ManageUsers/component/Freelancers/FreelancerDetail/FreelancerDetail";
import ManageFreelancers from "views/ManageUsers/component/Freelancers/ManageFreelancers";
import ClientProfileView from "design/ClientProfileView";
// import DeveloperProfile from "views/ManageUsers/component/Agency/RequestTabDetail/DeveloperProfile";
// import NewAgencyRequest from "views/NewAgencyRequest/NewAgencyRequest";
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RejectedLogs from "views/RegistrationRequests/RejectedLogs/RejectedLogs";
import ClientProfile from "design/ClientProfile";
import ClientView from "views/RegistrationRequests/NewRequest/component/Client/ClientView";
import AgencyView from "views/RegistrationRequests/NewRequest/component/Agency/AgencyView";
import FreelancerDetailView from "views/RegistrationRequests/NewRequest/component/Freelancer/FreelancerDetailView";
import UpdateClientDetailView from "views/RegistrationRequests/UpdateRequest/component/ClientDetailView/ClientDetailView";
import UpdateAgencyDetailView from "views/RegistrationRequests/UpdateRequest/component/AgencyDetailView/AgencyDetailView";
import UpdateFreelancerDetailView from "views/RegistrationRequests/UpdateRequest/component/FreelancerDetailView/FreelancerDetailView";
import RejectClientDetailView from "views/RegistrationRequests/RejectedLogs/component/ClientDetailView";
import RejectAgencyDetailView from "views/RegistrationRequests/RejectedLogs/component/AgencyDetailView";
import RejectFreelancerDetailView from "views/RegistrationRequests/RejectedLogs/component/FreelancerDetailView/FreelancerDetailView";
import ContactUs from "views/ContactUs/ContactUs";
import InboxIcon from "@material-ui/icons//Inbox";
import DeveloperRequest from "views/RegistrationRequests/AgencyDeveloperRequest/DeveloperRequest";
import DeveloperRequestDetails from "views/RegistrationRequests/AgencyDeveloperRequest/Components/DeveloperRequestDetails";
import ManageSubscription from "views/Subscription/Subscription";
import PageNotFound from "PageNotFound";
import ManageSubscriptionForm from "views/Subscription/component/ManageSubscriptionForm";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    permissions: PERMISSION.MAIN_DASHBOARD_VIEW,
  },
  {
    name: "Master",
    icon: BallotIcon,
    layout: "/admin",
    permissions: PERMISSION.MAIN_MASTER_VIEW,
    items: [
      // {
      //     path: "/domain",
      //     name: "Domain",
      //     icon: "",
      //     component: Domain,
      //     permissions: PERMISSION.MASTER_DOMAIN_VIEW,
      // },
      {
        path: "/technology",
        name: "Technology",
        icon: "",
        component: Technology,
        permissions: PERMISSION.MASTER_TECHNOLOGY_VIEW,
      },
      {
        path: "/profile-type",
        name: "ProfileType",
        icon: "",
        component: ProfileType,
        permissions: PERMISSION.MASTER_PROFILE_TYPE_VIEW,
      },
      {
        path: "/skill",
        name: "Skill",
        icon: "",
        component: Skill,
        permissions: PERMISSION.MASTER_SKILL_VIEW,
      },
      {
        path: "/skill/:id",
        name: "Sub Skill",
        icon: "",
        component: SkillDetail,
        permissions: PERMISSION.MASTER_SKILL_EDIT,
        isHide: true,
      },
      // {
      //     path: "/experience",
      //     name: "Experience",
      //     icon: "",
      //     component: Experience,
      //     permissions: PERMISSION.MASTER_EXPERIENCE_VIEW,
      // },
    ],
  },
  {
    path: "/role",
    name: "Role",
    icon: WorkIcon,
    component: Role,
    layout: "/admin",
    permissions: PERMISSION.ROLE_VIEW,
  },
  {
    path: "/role/add",
    name: "Add Role",
    icon: "",
    component: CreateRole,
    layout: "/admin",
    permissions: PERMISSION.ROLE_ADD,
    isHide: true,
  },
  {
    path: "/role/edit/:Id",
    name: "Edit Role",
    icon: "",
    component: CreateRole,
    layout: "/admin",
    permissions: PERMISSION.ROLE_EDIT,
    isHide: true,
  },
  {
    path: "/sub-admin",
    name: "Sub Admin",
    icon: SupervisorAccountIcon,
    component: SubAdmin,
    layout: "/admin",
    permissions: PERMISSION.SUB_ADMIN_VIEW,
  },
  {
    path: "/sub-admin/add",
    name: "Sub Admin Add",
    icon: SupervisorAccountIcon,
    component: ManageSubAdmin,
    layout: "/admin",
    isHide: true,
    permissions: PERMISSION.SUB_ADMIN_ADD,
  },
  {
    path: "/sub-admin/edit",
    name: "Sub Admin Edit",
    icon: SupervisorAccountIcon,
    component: ManageSubAdmin,
    layout: "/admin",
    isHide: true,
    permissions: PERMISSION.SUB_ADMIN_EDIT,
  },
  {
    path: "/manage-users",
    name: "Manage Users",
    icon: BuildIcon,
    component: ManageUsers,
    layout: "/admin",
    permissions: PERMISSION.MAIN_REGISTERED_USER_VIEW,
    nested: [
      PERMISSION.REGISTERED_USER_CLIENTS_VIEW,
      PERMISSION.REGISTERED_USER_AGENCY_VIEW,
      PERMISSION.REGISTERED_USER_FREELANCER_VIEW,
    ],
  },
  {
    path: "/manage-user/client-details/:id",
    name: "Client Details",
    icon: "",
    layout: "/admin",
    component: ClientDetail,
    permissions: PERMISSION.REGISTERED_USER_CLIENTS_VIEW,
    isHide: true,
  },
  {
    path: "/manage-user/client-update",
    name: "Client Details",
    icon: "",
    layout: "/admin",
    component: ManageClient,
    permissions: PERMISSION.REGISTERED_USER_CLIENTS_EDIT,
    isHide: true,
  },
  {
    path: "/manage-user/agency-details/:id",
    name: "Agency Details",
    icon: "",
    layout: "/admin",
    component: AgencyDetail,
    permissions: PERMISSION.REGISTERED_USER_AGENCY_VIEW,
    isHide: true,
  },
  {
    path: "/manage-user/:agencyId/developer-details/:id",
    name: "Agency Details",
    icon: "",
    layout: "/admin",
    component: AgencyDeveloperProfile,
    permissions: PERMISSION.REGISTERED_USER_AGENCY_VIEW,
    isHide: true,
  },
  // {
  //     path: "/manage-user/:agencyId/developer-request/:id",
  //     name: "Agency Details",
  //     icon: "",
  //     layout: "/admin",
  //     component: DeveloperProfile,
  //     permissions: PERMISSION.REGISTERED_USER_AGENCY_VIEW,
  //     isHide: true
  // },
  {
    path: "/manage-user/agency-update",
    name: "Agency Details",
    icon: "",
    layout: "/admin",
    component: ManageAgency,
    permissions: PERMISSION.REGISTERED_USER_AGENCY_EDIT,
    isHide: true,
  },
  {
    path: "/manage-user/freelancer-details/:id",
    name: "Freelancer Details",
    icon: "",
    layout: "/admin",
    component: FreelancerDetail,
    permissions: PERMISSION.REGISTERED_USER_AGENCY_EDIT,
    isHide: true,
  },
  {
    path: "/manage-user/freelancer-update/:id",
    name: "Freelancer Details",
    icon: "",
    layout: "/admin",
    component: ManageFreelancers,
    permissions: PERMISSION.REGISTERED_USER_AGENCY_EDIT,
    isHide: true,
  },
  {
    name: "Profile Requests",
    icon: GroupAddIcon,
    layout: "/admin",
    permissions: PERMISSION.VIEW_USER_REQUEST,
    items: [
      {
        path: "/new-request",
        name: "New Requests",
        icon: "",
        component: NewRequests,
        permissions: PERMISSION.VIEW_USER_REQUEST,  
      },
      {
        path: "/updated-request",
        name: "Update Requests",
        icon: "",
        component: UpdatedRequests,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/developer-requests",
        name: "Developer Requests",
        icon: "",
        component: DeveloperRequest,
        layout: "/admin",
        permissions: PERMISSION.REGISTERED_USER_AGENCY_VIEW,
        isHide: false,
      },
      {
        path: "/developer-requests/:id",
        name: "Developer Requests Details",
        icon: "",
        component: DeveloperRequestDetails,
        layout: "/admin",
        permissions: PERMISSION.REGISTERED_USER_AGENCY_VIEW,
        isHide: true,
      },
      {
        path: "/rejected-logs",
        name: "Rejected Logs",
        icon: "",
        component: RejectedLogs,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/new-requests/client-view/:id",
        name: "Client Request View",
        icon: "",
        component: ClientView,
        isHide: true,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/new-requests/agency-view/:id",
        name: "Agency Request View",
        icon: "",
        component: AgencyView,
        isHide: true,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/new-requests/Freelancer-view/:id",
        name: "Freelancer Request View",
        icon: "",
        component: FreelancerDetailView,
        isHide: true,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/updated-request/client-view/:id",
        name: "Client Request View",
        icon: "",
        component: UpdateClientDetailView,
        isHide: true,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/updated-request/agency-view/:id",
        name: "Agency Request View",
        icon: "",
        component: UpdateAgencyDetailView,
        isHide: true,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/updated-request/freelancer-view/:id",
        name: "Client Request View",
        icon: "",
        component: UpdateFreelancerDetailView,
        isHide: true,
        permissions: PERMISSION.VIEW_USER_REQUEST,
      },
      {
        path: "/rejected-logs/client-view/:id",
        name: "View",
        icon: "",
        component: RejectClientDetailView,
        permissions: PERMISSION.VIEW_USER_REQUEST,
        isHide: true,
      },
      {
        path: "/rejected-logs/agency-view/:id",
        name: "View",
        icon: "",
        component: RejectAgencyDetailView,
        permissions: PERMISSION.VIEW_USER_REQUEST,
        isHide: true,
      },
      {
        path: "/rejected-logs/freelancer-view/:id",
        name: "View",
        icon: "",
        component: RejectFreelancerDetailView,
        permissions: PERMISSION.VIEW_USER_REQUEST,
        isHide: true,
      },
      {
        path: "/rejected-logs/agency-developer-view/:id",
        name: "View",
        icon: "",
        component: RejectFreelancerDetailView,
        permissions: PERMISSION.VIEW_USER_REQUEST,
        isHide: true,
      },
    ],
  },

  {
    path: "/user-profile",
    name: "User Profile",
    icon: Dashboard,
    component: UserProfile,
    layout: "/admin",
    permissions: PERMISSION.MAIN_USERPROFILE_VIEW,
    isHide: true,
  },
  // {
  //     path: "/new-agency-request",
  //     name: "New Agency Request",
  //     icon: PersonAddIcon,
  //     component: NewAgencyRequest,
  //     layout: "/admin",
  //     permissions: PERMISSION.VIEW_AGENCY_REQUEST,
  // },
  {
    path: "/agency-developer-profile",
    name: "Agency Developer Profile",
    icon: Dashboard,
    component: AgencyDeveloperProfile,
    layout: "/admin",
    permissions: PERMISSION.MAIN_DASHBOARD_VIEW,
    isHide: true,
  },
  {
    path: "/client-profile-view",
    name: "Client profile view",
    icon: Dashboard,
    component: ClientProfileView,
    layout: "/admin",
    permissions: PERMISSION.MAIN_DASHBOARD_VIEW,
    isHide: true,
  },
  {
    path: "/client-profile",
    name: "Client profile",
    icon: Dashboard,
    component: ClientProfile,
    layout: "/admin",
    permissions: PERMISSION.MAIN_DASHBOARD_VIEW,
    isHide: true,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    icon: InboxIcon,
    component: ContactUs,
    layout: "/admin",
    permissions: PERMISSION.VIEW_CONTACT_US,
    isHide: false,
  },
  {
    path: "/manage-subscription",
    name: "Subscription Plan",
    icon: AttachMoneyIcon,
    component: ManageSubscription,
    layout: "/admin",
    permissions: PERMISSION.VIEW_SUBSCRIPTION_DETAIL,
  },
  {
    path: "/manage-subscription/:type/add",
    name: "Subscription Form",
    icon: "",
    component: ManageSubscriptionForm,
    layout: "/admin",
    permissions: PERMISSION.ADD_SUBSCRIPTION_DETAIL,
    isHide: true,
  },
  {
    path: "/manage-subscription/:type/edit/:id",
    name: "Subscription Form",
    icon: "",
    component: ManageSubscriptionForm,
    layout: "/admin",
    permissions: PERMISSION.CHANGE_SUBSCRIPTION_DETAIL,
    isHide: true,
  },
  {
    path: "notfound",
    name: "Not Found",
    icon: "",
    component: PageNotFound,
    layout: "/admin",
    permissions: PERMISSION.MAIN_DASHBOARD_VIEW,
    isHide: true,
  },
  {
    path: "*",
    name: "Not Found",
    icon: "",
    component: PageNotFound,
    layout: "/admin",
    permissions: PERMISSION.MAIN_DASHBOARD_VIEW,
    isHide: true,
  },
];

export default dashboardRoutes;
