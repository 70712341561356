
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux';
import "assets/css/material-dashboard-react.css?v=1.9.0";
// core components
import Admin from "layouts/Admin.js";
import store from './store';
import history from "utils/history";
import LoginPage from "views/Auth/LoginPage/LoginPage";
import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
import UserConfirmation from "views/Auth/UserConfirmation/UserConfirmation";
import ResetPassword from "views/Auth/ResetPassword/ResetPassword";
import Scroll from "utils/Scroll";

function App() {

    let isLoggedInVar = localStorage.getItem('ACCESS_TOKEN');
    const getRoute = (name) => {
        if (!isLoggedInVar || isLoggedInVar === null) {
            return name;
        } else {
            return () => <Redirect to='/dashboard' />
        }
    };
    return (
        <Provider store={store}>
            <BrowserRouter history={history}>
                <Scroll />
                <Switch>
                    <Route exact path="/accept-user-invitation/:uid/:token" component={getRoute(UserConfirmation)} />
                    <Route exact path="/password-reset/:uid/:token" component={getRoute(ResetPassword)} />
                    <Route exact path="/forgot-password" component={getRoute(ForgotPassword)} />
                    <Route exact path="/login" component={getRoute(LoginPage)} />
                    <Route exact path='/' component={getRoute(LoginPage)} />
                    <Admin ></Admin>
                </Switch>
            </BrowserRouter>
        </Provider>
    );
}

export default App;